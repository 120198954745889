'use strict';
import angular from 'angular';
import _ from 'lodash';

import customIconSelectorHtmlUrl from '../../../../../analytics/app/modules/shared/views/directives/customiconselector.html';
import dataFreshnessDateDisplayHtmlUrl from './datafreshnessdatedisplay.html';

angular.module('core.service.directives', [])

    .directive('customIconSelector', customIconSelector)
    .directive('datafreshnessDateDisplay', datafreshnessDateDisplay);

/**
 *  Service icons - modal for selecting custom icon for a service
 */
function customIconSelector() {
    return {
        restrict: 'E',
        scope: {
            customIcon: '=',
            defaultIcon:'<',
            updateIconCb: '<'
        },
        templateUrl: customIconSelectorHtmlUrl,
        controller: function($scope) {

            $scope.setIcon = function(icon) {
                $scope.customIcon = icon;
                if ($scope.updateIconCb) {
                    $scope.updateIconCb($scope.customIcon);
                }
            };

            $scope.resetDefaultIcon = function() {
                $scope.customIcon = $scope.defaultIcon;
                if ($scope.updateIconCb) {
                    $scope.updateIconCb($scope.customIcon);
                }
            };

            $scope.icons = [
                'icon-search',
                'icon-envelope-o',
                'icon-heart',
                'icon-star',
                'icon-power-off',
                'icon-signal',
                'icon-gear',
                'icon-play-circle-o',
                'icon-list-alt',
                'icon-flag',
                'icon-tag',
                'icon-pie-chart',
                'icon-camera',
                'icon-picture-o',
                'icon-map-marker',
                'icon-info-circle',
                'icon-crosshairs',
                'icon-asterisk',
                'icon-leaf',
                'icon-fire',
                'icon-eye',
                'icon-comment',
                'icon-trophy',
                'icon-phone',
                'icon-rss',
                'icon-bullhorn',
                'icon-globe',
                'icon-tasks',
                'icon-filter',
                'icon-flask',
                'icon-table',
                'icon-magic',
                'icon-sellsy',
                'icon-dashboard',
                'icon-flash',
                'icon-sitemap',
                'icon-bar-chart-o',
                'icon-lightbulb-o',
                'icon-fighter-jet',
                'icon-desktop',
                'icon-suitcase',
                'icon-code',
                'icon-code-fork',
                'icon-shield',
                'icon-rocket',
                'icon-bullseye',
                'icon-sun-o',
                'icon-moon-o',
                'icon-pagelines',
                'icon-institution',
                'icon-paw',
                'icon-cubes',
                'icon-tree',
                'icon-database',
                'icon-ra',
                'icon-ge',
                'icon-diamond',
                'icon-send',
                'icon-bomb',
                'icon-line-chart',
                'serviceicon-adpro',
                'serviceicon-madhive',
                'serviceicon-sharethrough',
                'serviceicon-bidtellect',
                'serviceicon-sterling',
                'serviceicon-dbmorders',
                'serviceicon-dbm',
                'serviceicon-dfa',
                'serviceicon-adwords',
                'serviceicon-dcs',
                'serviceicon-liquidm',
                'serviceicon-whatconverts',
                'serviceicon-liveintentcampaigns',
                'serviceicon-liveintentinventory',
                'serviceicon-visto',
                'serviceicon-tmpi',
                'serviceicon-googleanalyticsmcf',
                'serviceicon-digdevdirect',
                'serviceicon-greenbananaseo',
                'serviceicon-salesforceleads',
                'serviceicon-measuredmarketing',
                'serviceicon-teads',
                'serviceicon-googlepagespeed',
                'serviceicon-siteimpact',
                'serviceicon-groundtruth',
                'serviceicon-vervevelocity',
                'serviceicon-adform',
                'serviceicon-uberall',
                'serviceicon-matchcraft',
                'serviceicon-invoca',
                'serviceicon-dotmailer',
                'serviceicon-convirzaforadvertisers',
                'serviceicon-verve',
                'serviceicon-shoutaboutus',
                'serviceicon-dataxu',
                'serviceicon-semrushdomains',
                'serviceicon-responsetap',
                'serviceicon-eqworks',
                'serviceicon-emma',
                'serviceicon-taboola',
                'serviceicon-authoritylabs',
                'serviceicon-appnexusio',
                'serviceicon-appnexus',
                'serviceicon-steprep',
                'serviceicon-simplifi',
                'serviceicon-dudamobile',
                'serviceicon-dudaone',
                'serviceicon-freespee',
                'serviceicon-liquidus',
                'serviceicon-googleanalytics',
                'serviceicon-googleanalyticscustom',
                'serviceicon-googleanalyticssegments',
                'serviceicon-unbounce',
                'serviceicon-aspenemail',
                'serviceicon-aspenshared',
                'serviceicon-aspenocpj',
                'serviceicon-aspendirect',
                'serviceicon-sitescout',
                'serviceicon-adobe-marketing-cloud',
                'serviceicon-appannie',
                'serviceicon-arrivalist',
                'serviceicon-cbslocal',
                'serviceicon-chatter',
                'serviceicon-cheetah',
                'serviceicon-conversant',
                'serviceicon-craiglist',
                'serviceicon-display',
                'serviceicon-espnradio',
                'serviceicon-eblast',
                'serviceicon-email',
                'serviceicon-emma',
                'serviceicon-engagetosell',
                'serviceicon-goodway',
                'serviceicon-hulu',
                'serviceicon-landing',
                'serviceicon-linkedininmail',
                'serviceicon-linkedingads',
                'serviceicon-marketo',
                'serviceicon-mixpanel',
                'serviceicon-notifyme',
                'serviceicon-pandora',
                'serviceicon-presslaf',
                'serviceicon-print',
                'serviceicon-promojam',
                'serviceicon-pubmatic',
                'serviceicon-quancast',
                'serviceicon-rocketfuel',
                'serviceicon-rubicon',
                'serviceicon-salesforce',
                'serviceicon-serpbook',
                'serviceicon-snapchat',
                'serviceicon-social',
                'serviceicon-strata',
                'serviceicon-theweatherc',
                'serviceicon-tripadvisor',
                'serviceicon-typhoon',
                'serviceicon-watchme',
                'serviceicon-waze',
                'serviceicon-yellowpages',
                'serviceicon-zapier',
                'serviceicon-adgear',
                'serviceicon-adjuster',
                'serviceicon-adobe',
                'serviceicon-adroll',
                'serviceicon-adtegrity',
                'serviceicon-adventive',
                'serviceicon-agendize',
                'serviceicon-aoladtech',
                'serviceicon-apexchat',
                'serviceicon-appnexusadtaxi',
                'serviceicon-avanser',
                'serviceicon-benchmarkemail',
                'serviceicon-bing',
                'serviceicon-bingadtaxi',
                'serviceicon-bingwebmasterstools',
                'serviceicon-birdeye',
                'serviceicon-bitly',
                'serviceicon-bmielite',
                'serviceicon-boostability',
                'serviceicon-brightcovevideocloud',
                'serviceicon-buffer',
                'serviceicon-callcap',
                'serviceicon-callrail',
                'serviceicon-callsource',
                'serviceicon-campaignmonitor',
                'serviceicon-cds',
                'serviceicon-celtra',
                'serviceicon-choozle',
                'serviceicon-clipcentric',
                'serviceicon-constantcontact',
                'serviceicon-coreaudience',
                'serviceicon-ctm',
                'serviceicon-datadyn',
                'serviceicon-default',
                'serviceicon-delacon',
                'serviceicon-devhub',
                'serviceicon-dfp',
                'serviceicon-dialogtech',
                'serviceicon-dropbox',
                'serviceicon-ecl',
                'serviceicon-eltoro',
                'serviceicon-eyereturn',
                'serviceicon-facebook',
                'serviceicon-foursquare',
                'serviceicon-frontseat',
                'serviceicon-googledrive',
                'serviceicon-googlemybusiness',
                'serviceicon-googleplus',
                'serviceicon-googlewebmastertools',
                'serviceicon-gshift',
                'serviceicon-hubspot',
                'serviceicon-icontact',
                'serviceicon-ifbyphone',
                'serviceicon-importwizard_plus',
                'serviceicon-instagramsocial',
                'serviceicon-ipromote',
                'serviceicon-ivinteractive',
                'serviceicon-jivox',
                'serviceicon-jumptap',
                'serviceicon-kenshoo',
                'serviceicon-lamarkmedia',
                'serviceicon-linkedin',
                'serviceicon-linkedinprofile',
                'serviceicon-litbreaker',
                'serviceicon-mailchimp',
                'serviceicon-marchex',
                'serviceicon-marchexproxy',
                'serviceicon-marketo',
                'serviceicon-mediamath',
                'serviceicon-mediaprowler',
                'serviceicon-megaleads',
                'serviceicon-micrositemasters',
                'serviceicon-microstrategy',
                'serviceicon-mixpo',
                'serviceicon-moatdisplay',
                'serviceicon-mocentric',
                'serviceicon-movingcompanyreviews',
                'serviceicon-nativo',
                'serviceicon-ndn',
                'serviceicon-outbrain',
                'serviceicon-pinterest',
                'serviceicon-pinterestads',
                'serviceicon-platform161',
                'serviceicon-pulsepoint',
                'serviceicon-reachlocal',
                'serviceicon-realmedia',
                'serviceicon-recrue',
                'serviceicon-recruemobile',
                'serviceicon-recruesocial',
                'serviceicon-reviewtrackers',
                'serviceicon-revlocal',
                'serviceicon-ripper',
                'serviceicon-rtbiq',
                'serviceicon-rubicon',
                'serviceicon-sendible',
                'serviceicon-seomoz',
                'serviceicon-shopify',
                'serviceicon-shoutlet',
                'serviceicon-silverpop',
                'serviceicon-simplififtp',
                'serviceicon-sizmek',
                'serviceicon-sizmekftp',
                'serviceicon-sizmekmdxnxt',
                'serviceicon-sizmeksearch',
                'serviceicon-strongemail',
                'serviceicon-strongemail2',
                'serviceicon-sweetiq',
                'serviceicon-tapclassifieds',
                'serviceicon-tapdrives',
                'serviceicon-taporders',
                'serviceicon-telmetrics',
                'serviceicon-thetradedesk',
                'serviceicon-tigerpistol',
                'serviceicon-tritonaudio',
                'serviceicon-tru',
                'serviceicon-tubemogul',
                'serviceicon-tubemogulapi',
                'serviceicon-twilio',
                'serviceicon-twitter',
                'serviceicon-ubermedia',
                'serviceicon-v12',
                'serviceicon-vast',
                'serviceicon-vertresp',
                'serviceicon-wufoo',
                'serviceicon-yahoo',
                'serviceicon-yahooapt',
                'serviceicon-yahoogemini',
                'serviceicon-yasabe',
                'serviceicon-yashi',
                'serviceicon-yelp',
                'serviceicon-yelpapi',
                'serviceicon-yext',
                'serviceicon-yextlocation',
                'serviceicon-youtube',
                'serviceicon-ypcalls',
                'serviceicon-yptraffic',
                'serviceicon-thryv',
                'serviceicon-zetadsp',
                'serviceicon-qmedia',
                'serviceicon-getintent',
                'serviceicon-beeswax',
                'serviceicon-chatmeter',
                'serviceicon-phonewagon',
                'serviceicon-pinterestaccounts',
                'serviceicon-sizmeksas',
                'serviceicon-spotifyads',
                'serviceicon-adcellerant',
                'serviceicon-amazondsp',
                'serviceicon-adobeanalytics',
                'serviceicon-tiktokads',
                'serviceicon-sc_knorex',
                'serviceicon-sc_wideorbit',
                'serviceicon-sc_amazondsp',
                'serviceicon-sc_admessenger',
                'serviceicon-sc_sqlapp',
                'serviceicon-sc_redcanyon',
                'serviceicon-sc_xpoapp',
                'serviceicon-sc_adlib',
                'serviceicon-sc_iqm',
                'serviceicon-sc_gamut',
                'serviceicon-sc_stirista',
                'serviceicon-sc_agencyplatform',
                'serviceicon-sc_appscience',
                'serviceicon-sc_datadrive',
                'serviceicon-sc_iovox',
                'serviceicon-sc_manual',
                'serviceicon-sc_googlebq',
                'serviceicon-sc_sftp',
                'serviceicon-sc_ftp',
                'serviceicon-sc_snowflake',
                'serviceicon-sc_postgresql',
                'serviceicon-sc_mysql',
                'serviceicon-sc_amazonredshift',
                'serviceicon-sc_amazons3',
                'serviceicon-sc_dropbox',
                'serviceicon-sc_salesforce',
                'serviceicon-sc_drive',
                'serviceicon-sc_tapapi',
                'serviceicon-sc_box',
                'serviceicon-sc_onedrive',
                'serviceicon-sc_geniusmonkey',
                'serviceicon-sc_a4media',
                'serviceicon-sc_loopme',
                'serviceicon-sc_gimbal',
                'serviceicon-sc_netsertive',
                'serviceicon-sc_infinity',
                'serviceicon-sc_googlesheet',
                'serviceicon-sc_adelphic',
                'serviceicon-sc_quickbooks',
                'serviceicon-sc_adtheorent',
                'serviceicon-sc_freewheel',
                'serviceicon-sc_friends2follow',
                'serviceicon-sc_leadsrx',
                'serviceicon-sc_logiqdigital',
                'serviceicon-yahoojapansearch',
                'serviceicon-sc_snowflake_isolated',
                'serviceicon-sc_sabio',
                'serviceicon-sc_mysql_isolated',
                'serviceicon-sc_madhive',
                'serviceicon-sc_brightedge',
                'serviceicon-marchexmarketingedge',
                'serviceicon-sc_amazonathena',
                'serviceicon-sc_azurebs',
                'serviceicon-sc_azuresql',
                'serviceicon-sc_amazonaurora',
                'serviceicon-sc_tapdirect',
                'serviceicon-sc_email',
                'serviceicon-sc_googlecloud',
                'serviceicon-sc_wehaa',
                'serviceicon-sc_onspot',
                'serviceicon-sc_agentz',
                'serviceicon-yahoojapandisplay',
                'serviceicon-googlelocalservices',
                'serviceicon-tritonnextgen',
                'serviceicon-klaviyonativemetrics',
                'serviceicon-basisbcireports',
                'serviceicon-tapleads',
                'serviceicon-sc_clickhouse',
                'serviceicon-sc_alloydb',
                'serviceicon-applesearchads',
                'serviceicon-stackadapt',
                'serviceicon-redditads',
                'serviceicon-sc_vistar_media',
                'serviceicon-sc_reveal',
                'serviceicon-sc_advanced_web_ranking',
                'serviceicon-sc_ad_geo',
                'serviceicon-adelphic',
                'serviceicon-linkedinbusinessreports'
            ];

            var alphabet = 'abcdefghijklmnopqrstuvwxyz';
            _.each(alphabet.split(''), function (letter) {
                $scope.icons.push('alphabet-l-' + letter);
            });
            _.each(alphabet.split(''), function (letter) {
                $scope.icons.push('alphabet-u-' + letter);
            });
        },
        link: function(scope, el) {
        }
    }
}

/**
 * Display data freshness date for services
 */
function datafreshnessDateDisplay() {
    return {
        restrict: 'E',
        controller: 'DataFreshnessDateDisplayController',
        templateUrl: dataFreshnessDateDisplayHtmlUrl,
        scope: {
            datasource: '<',
            predefinedData: '<'
        }
    }
}