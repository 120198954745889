'use strict';
import angular from 'angular';
import swal from 'bootstrap-sweetalert';
import _ from 'lodash';
import $ from 'jquery';

angular.module('design.page.resources', [])

    .factory('PageResource', PageResource)
    .factory('PageDataGridFactory', PageDataGridFactory);

/**
 * @ngInject
 */
function PageResource(
    Restangular,
    ColumnValueType
) {
    var dash = Restangular.all('dash');
    var pages = dash.all('pages');
    var values = pages.one('values');
    var enableSummary = pages.one('enablesummary');
    var toggleBenchmarks = pages.one('togglebenchmarks');

    return {
        pages: pages,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData,
        save: save,
        copy: copy,
        remove: remove,
        resetWidgetChartPalettes: resetWidgetChartPalettes,
        setEnableSummary: setEnableSummary,
        setEnableBenchmark: setEnableBenchmark,
    };

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return pages.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getFieldValues(fields) {
        return values.one(ColumnValueType.DISTINCT).get(_.isUndefined(fields) ? '' : {fields: fields});
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        queryParams.all = true;
        return pages.getList(queryParams);
    }

    /**
     *
     * @param model
     * @returns {*|{method, isArray}|{method}|HttpPromise|void}
     */
    function save(model) {
        return pages.all(model.id).post(model);
    }

    /**
     *
     * @param pageId
     * @param copyOptions
     */
    function copy(pageId, copyOptions) {
        return pages.one('copy').all(pageId).post({copy_options: copyOptions});
    }

    /**
     *
     * @param pageId
     */
    function remove(pageId) {
        return pages.all(pageId).remove();
    }

    /**
     * @param pageId
     */
    function resetWidgetChartPalettes(pageId) {
        return pages.all('resetwidgetpalette').one(pageId).get();
    }

    /**
     * @param pageId
     * @param model
     */
    function setEnableSummary(pageId, model) {
        return enableSummary.all(pageId).post(model);
    }

    function setEnableBenchmark(pageId, model) {
        return toggleBenchmarks.all(pageId).post(model);
    }
}

/**
 * @ngInject
 */
function PageDataGridFactory(
    $state,
    PageColumns,
    PageResource,
    DesignFactory,
    WidgetExportFactory,
    LayoutFactory,
    DataGridRender,
    UIFactory,
    gettextCatalog
) {

    var shared = {
        pageId: null,
        state: {
            isLoading: false,
            isShowing: false
        }
    };

    return {
        shared: shared,
        getDTOptions: getDTOptions,
        closeModal: closeModal,
        copyPage: copyPage,
        getCloneOptions: getCloneOptions
    };

    /**
     * Return manage dashboards specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.numActions = 2;
        dtOptions.isClientSide = true;
        dtOptions.customRenders = {};
        dtOptions.customRenders[PageColumns.TITLE] = function (data, type, full) {
            return '<a href="#/dash/' + full[PageColumns.ID] +'">' + data + '</a>';
        };
        dtOptions.customRenders[PageColumns.ID] = function (data, type, full) {
            var html = DataGridRender.getCopyButton(full.can_be_copied, 'dash/pages/copy/' + data, full['title']);
            html += DataGridRender.getDeleteButton(full.can_be_deleted, 'dash/pages/' + data, full['title']);
            return html;
        };
        dtOptions.customRenders[PageColumns.REPORTING_PROFILES] = function (data) {
            var html = '';
            if (!_.isEmpty(data)) {
                var tooltip = _.map(data, function(item) {
                    return item['name'];
                }).join(', ');

                html = '<span class="badge badge-default" data-toggle="tooltip" data-placement="right" title="'+tooltip+'">'
                    + data.length + ' '
                    + (data.length > 1 ? gettextCatalog.getString('data profiles') : gettextCatalog.getString('data profile'))
                    + '</span>';
                // Make Data Profile (Reporting Profile) searchable
                html += '<span class="hide">' + tooltip + '</span>';
            }
            return html;
        };
        dtOptions.customRenders[PageColumns.IS_TEMPLATE] = function (data) {
            var isChecked = data ? 'checked' : '';
            var html = '<input type="checkbox" data-field="'+PageColumns.IS_TEMPLATE+'" class="switch action-link" '+ isChecked +' />';
            return html;
        };
        dtOptions.customRenders[PageColumns.CLUSTER] = function (data) {
            var html = '';
            if (!_.isEmpty(data)) {
                html = '<a href="#/clusters/detail/' + data.id + '">' + data.name + '</a>';
            }
            return html;
        };

        // Custom action callbacks
        dtOptions.actionOptions = {};

        dtOptions.actionOptions.copy = {
            is_custom: true,
            callback: _initClonePageModal
        };

        dtOptions.actionOptions.delete = {
            callback: _deleteActionCallback
        };

        dtOptions.postCreatedRowCallback = function($link, row, data) {
            var field = $link.data('field');
            switch (field) {
                case PageColumns.IS_TEMPLATE:
                    $link.click(function () {
                        var isChecked = $(this).is(':checked');
                        PageResource.pages.all(data.id).one('updateistemplate', isChecked).get();
                    });
                    break;
            }
        };

        return dtOptions;
    }

    /**
     * @param pageId
     * @private
     */
    function _initClonePageModal(pageId) {
        shared.pageId = pageId;
        shared.isShowing = true;

        UIFactory.showModal('copy-page-modal', {
            backdrop: true,
            keyboard: true
        });
    }

    /**
     * @param pageId
     */
    function closeModal(pageId) {
        shared.pageId = null;
        shared.isShowing = false;

        UIFactory.hideModal('copy-page-modal');
    }

    /**
     * @param pageId
     * @param copyOptions
     * @returns {*}
     */
    function copyPage(pageId, copyOptions) {
        return PageResource.copy(pageId, copyOptions)
    }

    /**
     * @returns {{copy_exec_summaries: boolean}}
     */
    function getCloneOptions() {
        return {
            copy_exec_summaries: true
        }
    }

    /**
     *
     * Action to be executed after deleting a dashboard
     * @param shouldDeletePage
     * @private
     */
    function _deleteActionCallback(shouldDeletePage) {
        if (!shouldDeletePage) {
            return;
        }
        var self = this;

        return PageResource.remove(self.id).then(function (json) {
            if (!json.error) {
                _deleteComplete();
            }
        }, function() {
            swal.enableButtons();
        });
    }

    /**
     *
     * @private
     */
    function _deleteComplete() {
        DesignFactory.getNavPageList().then(function() {
            swal.close();
            DesignFactory.$refreshMenu();
            LayoutFactory.$rebuildAllWidgets();
        });
    }
}
