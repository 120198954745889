'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.leads.services', [])
    .factory('LeadsFactory', LeadsFactory);

/**
 * @ngInject
 */
function LeadsFactory(
    LeadColumns,
    LeadsResource,
    ServiceFactory,
    DetailsModalFactory,
    DataSourceType,
    DetailsModalModelFactory
) {

    return {
        setEditDetails: setEditDetails,
        getTypeLabel: getTypeLabel,
        getColorCodedLabel: getColorCodedLabel
    };


    function _getLeadDisplayName(lead) {
        var dataSourceDisplay = ServiceFactory.getServiceNameDisplay(lead[LeadColumns.SERVICE_ID], 24);
        var typeDisplay = getTypeLabel(lead[LeadColumns.TYPE]);
        var timeDisplay = lead[LeadColumns.FORMATTED_TIMESTAMP];
        var clientName = lead[LeadColumns.CLIENT_NAME];
        var result = dataSourceDisplay;
        if (!_.isEmpty(clientName)) {
            result += ' - ' + clientName;
        }
        if (!_.isEmpty(timeDisplay)) {
            result += ' - ' + timeDisplay;
        }
        result += ' - ' + typeDisplay;
        return result;
    }

    /**
     * @param data
     * @param row
     * @param index
     */
    function setEditDetails(data, row, index) {
        var url = _getLeadIdResourceUrl(data);

        var promises = [
            LeadsResource.leads.get(url, {all: true})
        ];
        return _setDetails(promises, _getLeadDisplayName(data), row, index);
    }

    /**
     * Will return a unique url pattern to be used to edit/access a lead that may not have been created
     *
     * @param data
     * @returns {string}
     * @private
     */
    function _getLeadIdResourceUrl(data) {
        return _.isEmpty(data[LeadColumns.ID])
            ? data[LeadColumns.SERVICE_ID] + '/' + data[LeadColumns.TYPE] + '/' + data[LeadColumns.SERVICE_LEAD_ID]
            : data[LeadColumns.ID];
    }

    /**
     * @param promises
     * @param title
     * @param row
     * @param index
     */
    function _setDetails(promises, title, row, index) {
        return DetailsModalFactory.setDetails(DetailsModalModelFactory.getModalOptions({
            dataSourceType: DataSourceType.LEADS,
            promises: promises,
            resource: LeadsResource.leads,
            display: {
                title: title,
                loadingMessage: 'Loading Lead',
                hideTitleIcon: true,
                icon: 'icomoon-share'
            },
            row: row,
            index: index
        }));
    }

    /**
     * @param data
     * @returns {string}
     */
    function getTypeLabel(data) {
        var icon = '';
        switch (data) {
            case ('call'):
                icon = 'phone';
                break;

            case ('form'):
                icon = 'file-text-o';
                break;
        }
        return '<span class="label label-default"><i class="icon icon-'+ icon +' mr5"></i>' + _.capitalize(data) + '</span>';
    }

    /**
     * @param data
     * @returns {string}
     */
    function getColorCodedLabel(text, color) {
        return '<span class="label" style="background-color:'+color+'">' + text + '</span>';
    }
}