export const LabelFontMultiplier = {
  MIN: 0.5,
  MAX: 0.7,
};

export const DataFontMultiplier = {
  MIN: 0.6,
  MAX: 0.8,
};

export const DeltaFontMultiplier = {
  MIN: 0.5,
  MAX: 0.7,
};

export const DeltaIconFontMultiplier = {
  MIN: 0.4,
  MAX: 0.5,
};

export const DataFontMultiplierSquare = {
  MIN: 1.1,
  MAX: 1.2,
};

export const DataComaprisonFontMultiplierSquare = {
  MIN: 0.5,
  MAX: 0.6,
};

export const DataComaprisonMultiplierSquare = {
  MIN: 0.7,
  MAX: 0.8,
};

export const DataLabelMultiplierSquare = {
  MIN: 0.6,
  MAX: 0.7,
};

export const DataComaprisonMultiplierMultiMetric = {
  MIN: 1.1,
  MAX: 1.2,
};

export const DataFontMultiplierMultiMetric = {
  MIN: 1.9,
  MAX: 2.0,
};

export const MaxFontSizes = {
  METRIC_LABEL: 16,
  COMPARISON_VALUE: 20,
  COMPARISON_LABEL: 12,
};

export const FONT_SIZE_THRESHOLD = 15;

export const BackgroudGradient = {
  SOLID: 'solid',
  LINEAR: 'linear',
  RADIAL: 'radial',
};

export const ComparisonOptions = {
  MONTH_OVER_MONTH: 'month_over_month',
  QUARTER_OVER_QUARTER: 'quarter_over_quarter',
  YEAR_OVER_YEAR: 'year_over_year',
};
