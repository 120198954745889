import { WidgetConfig } from '@/modules/ta/widget/widget.constants';
import { dispatches, getters } from '@/modules/core/app/helpers/store';
import { Constant, PointerOrientation } from '@/modules/core/charts/am5/charts.constants';
import { color, isDateAxis } from '@/modules/core/charts/am5/charts.helper';
import { useButton } from '@/modules/core/charts/am5/base/composables/useButton';
import { useAxes } from '@/modules/core/charts/am5/base/composables/axis/useAxes';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export function useXYDrillDown(context) {
  const { config, primaryColor, isRotated } = context();
  const { applyDrillDownTitleAndButton } = useButton(context);
  const { getCategoryAxis, getATagToUrlString } = useAxes(context);
  const RANGE_FILL_OPACITY = 0.3;

  function createDrillDownEffect() {
    if (!config.value.isSparkLine) {
      const { id } = config.value.get(WidgetConfig.WIDGET);
      applyDrillDownTitleAndButton(id);
    }
    createAxisRanges();
  }

  function createAxisRanges() {
    const categoryAxis = getCategoryAxis();

    const axisProps = categoryAxis.get(Constant.USER_DATA);
    const categoryField = axisProps.category;
    const isHtmlATagString = [ColumnFormat.FORMAT_URL, ColumnFormat.FORMAT_CALLBACK].includes(
      axisProps.dataItemFormat
    );

    categoryAxis.dataItems.forEach((item) => {
      const dataItemParams = isDateAxis(axisProps.dataItemFormat)
        ? {
            value: item.dataContext[categoryField],
            endValue: item.dataContext[categoryField] + 86400000,
          }
        : {
            category: item.dataContext[categoryField],
          };
      const range = categoryAxis.createAxisRange(categoryAxis.makeDataItem(dataItemParams));

      if (isHtmlATagString) {
        range.get(Constant.LABEL).setAll({
          text: getATagToUrlString(item.dataContext[categoryField]),
        });
      }

      range.set(Constant.USER_DATA, item.itemContext);
      range.get(Constant.LABEL).set(Constant.FORCE_HIDDEN, !isRotated.value);
      const axisFill = range.get(Constant.AXIS_FILL);
      axisFill.setAll({
        visible: true,
        fill: color(primaryColor.value),
        fillOpacity: 0,
        cursorOverStyle: canDrillDownFurther() ? Constant.POINTER : PointerOrientation.DEFAULT,
      });
      axisFill.events.on(Constant.CLICK, () => {
        if (canDrillDownFurther()) {
          drillDown(item);
        }
      });
      axisFill.events.on(Constant.POINTER_OVER, (e) => {
        e.target.set(Constant.FILL_OPACITY, RANGE_FILL_OPACITY);
      });
      axisFill.events.on(Constant.POINTER_OUT, (e) => {
        e.target.set(Constant.FILL_OPACITY, 0);
      });
    });
  }

  function drillDown(dataItem) {
    const { id } = config.value.get(WidgetConfig.WIDGET);
    let category = dataItem.get(Constant.CATEGORY);
    let title = '';
    const categoryField = dataItem.component.get(Constant.CATEGORY_FIELD);
    if (categoryField === Constant.LOG_DATE) {
      const { formatted_log_date, formatted_log_date_comparison } = dataItem.dataContext;
      category = `${formatted_log_date}${
        formatted_log_date_comparison ? `|${formatted_log_date_comparison}` : ''
      }`;
      if (formatted_log_date) {
        title += `${formatted_log_date}${
          // eslint-disable-next-line tap/no-raw-text-js
          formatted_log_date_comparison ? ` vs ${formatted_log_date_comparison}` : ''
        }`;
      } else {
        title += formatted_log_date_comparison;
      }
    }
    dispatches.dashboardDrilldown.setDrilldownConfig({
      id,
      category: { [categoryField]: category },
      title,
    });
    dispatches.dashboardDrilldown.reloadWidget(id);
  }

  function canDrillDownFurther() {
    if (!config.value.isSparkLine) {
      const { id } = config.value.get(WidgetConfig.WIDGET);
      return getters.dashboardDrilldown.canDrillDown(id);
    }
  }

  return {
    createDrillDownEffect,
  };
}
