export default class SliceWidgetConfigDataModel {
  /**
   * @var {number}
   */
  index;

  /**
   * @var {string}
   */
  metric;

  /**
   * @var {number}
   */
  value;

  /**
   * @var {number}
   */
  rawValue;

  /**
   * @var  {Array<SliceWidgetConfigDataModel>}
   */
  subs;

  /**
   * @var {number}
   */
  percent;

  /**
   * @var {boolean}
   */
  isOther;

  /**
   * @var {boolean}
   */
  isMetric;

  /**
   * @var {string}
   */
  rawMetric;

  /**
   * @var {string}
   */
  dataFormat;

  /**
   * @var {boolean}
   */
  isClicked;

  constructor(model = {}) {
    this.index = model.index;
    this.metric = model.metric;
    this.value = model.value ?? 0;
    this.rawValue = model.rawValue ?? 0;
    this.subs = model.subs ?? [];
    this.percent = model.percent ?? 0;
    this.isOther = model.isOther ?? false;
    this.isMetric = true;
    this.rawMetric = model.rawMetric;
    this.dataFormat = model.dataFormat;
    this.isClicked = false;
  }
}
