import { SerialChartDrawOption } from '@/modules/ta/widget/models/drawoptions/SerialChartDrawOption';
import { PlotType } from '@/modules/ta/widget/widget.constants';

export class BarChartDrawOption extends SerialChartDrawOption {
  /**
   * @var {number}
   */
  width;

  /**
   * @var {number}
   */
  rounded_corners;

  /**
   *
   * @type {Boolean}
   */
  show_column_shadows;

  /**
   * @var {string}
   */
  fill_type;

  /**
   * @var {string}
   */
  gradient_color;

  /**
   * @var {Boolean}
   */
  curved_columns;

  /**
   * @var {number}
   */
  radial_inner_radius;

  /**
   * @var {string}
   */
  font_color_picker;

  constructor(model = {}) {
    super(model);
    this.width = model.width ?? 80;
    this.rounded_corners = model.rounded_corners ?? 0;
    this.show_column_shadows = model.show_column_shadows ?? false;
    this.fill_type = model.fill_type ?? null;
    this.gradient_color = model.gradient_color ?? null;
    this.curved_columns = model.curved_columns ?? false;
    this.radial_inner_radius = model.radial_inner_radius ?? 40;
    this.font_color_picker = model.font_color_picker;
  }

  setValidPlotType(model) {
    const validTypes = [
      PlotType.CLUSTERED,
      PlotType.STACKED,
      PlotType.FULL_STACKED,
      PlotType.DEEP_STACKED,
      PlotType.CLUSTERED_V2,
      PlotType.STACKED_V2,
      PlotType.FULL_STACKED_V2,
      PlotType.LAYERED_V2,
      PlotType.LOLLIPOP_V2,
      PlotType.RADIAL_HISTOGRAM_V2,
      PlotType.RADIAL_BAR_V2,
    ];

    if (!validTypes.includes(model.plot_type)) {
      this.plot_type = PlotType.CLUSTERED;
    }
  }
}
