import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import exportBuilderHeaderToolsHtmlUrl from './exportbuilder.header.tools.html';
import exportBuilderReportHeaderHtmlUrl from './exportbuilder.reportheader.html';
import exportBuilderReportHeaderOverlayHtmlUrl from './exportbuilder.reportheader.overlay.html';
import reportHeaderOverlayWarningHtmlUrl from './exportbuilder.reportheader.overlay.warning.html';
import {WidgetTypeGrouping} from 'coreModules/design/widget/design.widget.constants';
import EventBus from "../../../../../../grok/src/modules/core/app/helpers/EventBus";
import { stripHtmlTags } from "../../../../../../grok/src/modules/core/app/helpers/StringHelper";

angular.module('exportbuilder.header.components', [])
    .component('exportBuilderHeaderTools', {
        bindings: {
        },
        templateUrl: exportBuilderHeaderToolsHtmlUrl,
        controller: ExportBuilderHeaderToolsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderReportHeader', {
        bindings: {
        },
        templateUrl: exportBuilderReportHeaderHtmlUrl,
        controller: ExportBuilderReportHeaderController,
        controllerAs: 'vm'
    })
    .component('exportBuilderHeaderDisplayOverlay', {
        bindings: {
        },
        templateUrl: exportBuilderReportHeaderOverlayHtmlUrl,
        controller: ExportBuilderHeaderDisplayOverlayController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderHeaderToolsController(
    $timeout,
    ExportBuilderDashboardService,
    ExportBuilderElementActionService,
    ExportBuilderDashboardItemService,
    PageSpecifications,
    ReportElementTypes,
    $WidgetLibraryEvents,
    $WidgetBuilderEvents,
    PubSub,
    TapColorsService,
    ExportBuilderFacadeUIService,
    $WidgetEvents,
    FullScreenUtilUIService,
    ExportBuilderDashboardUIModelFactory,
    $ExportBuilderShortcutsModalEvents,
    $ExportBuilderTemplateDownloadModalEvents,
    $ExportBuilderTemplatePublishModalEvents,
    ExportReportFactory,
    $ExportBuilderIconModalEvents,
    $ExportBuilderDashboardEvents,
    ReportElementMacroTypes,
    ReportElementShapeTypes,
    UIFactory,
    UndoRedoService,
    ReportStudioTemplateDataService,
    WidgetCreateFactory,
    ExportBuilderService,
    $ExportBuilderFormatChangeModalEvents,
    PageFormatConstants,
    PageFormatDropdownConstants,
    AppFactory,
    UserType,
    WidgetBuilderDataModelFactory,
    WidgetFactory,
    AppModule,
    $state,
    $location,
    $rootScope,
    $scope
) {
    var vm = this;

    /**
     * @type {ImageFileInputOptions}
     */
    vm.fileInputOptions = ExportBuilderDashboardUIModelFactory.getImageFileInputOptions();
    vm.aspectRatioSelectOptions = ExportBuilderDashboardUIModelFactory.getPanelAspectRatioOptions();

    vm.selectedColor = TapColorsService.getRandomColor();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.canGoToPreviousPage = canGoToPreviousPage;
    vm.canGoToNextPage = canGoToNextPage;
    vm.canShowWidgetOption = canShowWidgetOption;
    vm.isSaving = isSaving;

    vm.onColorElementClicked = onColorElementClicked;
    vm.onTapColorClose = onTapColorClose;

    vm.onDataWidgetClicked = onDataWidgetClicked;
    vm.onMediaWidgetClicked = onMediaWidgetClicked;
    vm.onAdminWidgetClicked = onAdminWidgetClicked;
    vm.onImageElementClicked = onImageElementClicked;
    vm.onTextElementClicked = onTextElementClicked;
    vm.onIconElementClicked = onIconElementClicked;
    vm.onMacroDateRangeClicked = onMacroDateRangeClicked;
    vm.onMacroCompanyLogoClicked = onMacroCompanyLogoClicked;
    vm.onMacroRecipientNameClicked = onMacroRecipientNameClicked;
    vm.onMacroPageTitleClicked = onMacroPageTitleClicked;
    vm.onMacroPageNumberClicked = onMacroPageNumberClicked;
    vm.onMacroCreatedOnClicked = onMacroCreatedOnClicked;
    vm.onSquareShapeClicked = onSquareShapeClicked;
    vm.onCircleShapeClicked = onCircleShapeClicked;
    vm.onNewPageClicked = onNewPageClicked;
    vm.canShowExecutiveSummaryOption = canShowExecutiveSummaryOption;
    vm.onExecutiveSummaryClicked = onExecutiveSummaryClicked;

    vm.onUndoClicked = onUndoClicked;
    vm.onRedoClicked = onRedoClicked;
    vm.hasUndos = hasUndos;
    vm.hasRedos = hasRedos;
    vm.canDisableUndoRedo = canDisableUndoRedo;

    vm.onKeyboardShortcutsClicked = onKeyboardShortcutsClicked;
    vm.onFileSelected = onFileSelected;
    vm.onFileUpload = onFileUpload;
    vm.onFileUploadError = onFileUploadError;
    vm.onMinusPageClicked = onMinusPageClicked;
    vm.onPlusPageClicked = onPlusPageClicked;
    vm.onFitToPageClicked = onFitToPageClicked;
    vm.onGridClicked = onGridClicked;
    vm.onRotateClicked = onRotateClicked;
    vm.onShowElementSizeClicked = onShowElementSizeClicked;
    vm.onOverflowClicked = onOverflowClicked;
    vm.getRotateIconClass = getRotateIconClass;
    vm.onPageFormatClicked = onPageFormatClicked;
    vm.onPreviewModeClicked = onPreviewModeClicked;
    vm.onDownloadClicked = onDownloadClicked;
    vm.onSaveClicked = onSaveClicked;
    vm.onPresentationModeClicked = onPresentationModeClicked;
    vm.onPublishClicked = onPublishClicked;

    vm.isFitPageButtonDisabled = isFitPageButtonDisabled;
    vm.isFitToPageActive = isFitToPageActive;
    vm.isGridActive = isGridActive;
    vm.areOutlinesActive = areOutlinesActive;
    vm.isOverflowActive = isOverflowActive;
    vm.canDownloadReport = canDownloadReport;
    vm.exportButtonToolTipText = exportButtonToolTipText;
    vm.canPublishTemplate = canPublishTemplate;
    vm.onAspectRatioChange = onAspectRatioChange;

    vm.canShowGranularControlButton = canShowGranularControlButton;
    vm.triggerGranularControlModal = triggerGranularControlModal;

    vm.createClientSpecificCalculation = createClientSpecificCalculation;
    vm.clientId = ReportStudioTemplateDataService.getReport().client_id; 
    vm.hasClientCalculationPerms = Permission.hasPermissionToWrite(Permission.moduleName.CLIENTCALCULATIONS) && AppFactory.getUser().isModuleAvailable(AppModule.CLIENT_CALCULATIONS)

    vm.userCanCreateClientCalculation = vm.clientId && vm.hasClientCalculationPerms;

    $scope.$on('clientIdChanged', function(event, newClientId) {
        vm.clientId = newClientId;
        vm.userCanCreateClientCalculation = vm.clientId && vm.hasClientCalculationPerms
    });

    function triggerGranularControlModal() {
        if (canShowGranularControlButton()) {
            EventBus.signal('$PageNuiEvents:TriggerGranularControlModal', {
                id: vm.report.id
            });
        }
    }

    function hasGranularAccess(report) {
        if (!report.has_granular_permissions) return true;

        return report.granular_access_levels.can_be_edited;
    }

    function canShowGranularControlButton() {
        const isPredefinedSuperAdmin = AppFactory.getUser().isPredefinedSuperAdmin();
        const permission = Permission.hasPermission(
            Permission.moduleName.REPORTSTUDIO,
            Permission.permissionKey.MANAGE_ACCESS_LISTS,
        );
        return isPredefinedSuperAdmin && permission;
    }

    function $onInit() {
        vm.publishClasses = window.isNUI
          ? ['ti', 'ti-book-flip-page']
          : ['icon', 'icomoon-upload'];
        
        vm.report = ReportStudioTemplateDataService.getReport();
        vm.isLocked = vm.report.has_granular_permissions;
        vm.hasGranularAccess = hasGranularAccess(vm.report);
        _setSelect2Values();
        _registerEvents();
    }

    function onAspectRatioChange($el, event) {
        ExportBuilderFacadeUIService.showFormatModal();
        var format = $el.select2('data').id;

        PubSub.emit($ExportBuilderFormatChangeModalEvents.SET_VALUE, {
            new_format: format,
            el: $el,
            event: event
        });
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function canGoToPreviousPage() {
        let value = ExportBuilderDashboardService.getPreviousDesiredPageIndex();
        return value === 0 || !!value;
    }

    function canGoToNextPage() {
        return !!ExportBuilderDashboardService.getNextDesiredPageIndex();
    }

    function onDataWidgetClicked() {
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: true});
    }

    function onMediaWidgetClicked() {
        WidgetCreateFactory.$initNew(WidgetTypeGrouping.DISPLAY);
    }

    function onAdminWidgetClicked() {
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: false});
    }

    function onImageElementClicked(event) {
        ExportBuilderFacadeUIService.setElementChangingImage(null);
        ExportBuilderFacadeUIService.showImageDialog(event);
    }

    function onTextElementClicked() {
        var params = {
            isEditing: true,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
    }

    function onMacroDateRangeClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.DATE_RANGE,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
    }

    function onMacroCompanyLogoClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.COMPANY_LOGO,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.IMAGE, params);
    }

    function onMacroRecipientNameClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.RECIPIENT_NAME,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
    }

    function onMacroPageTitleClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.PAGE_TITLE,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
    }

    function onMacroPageNumberClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.PAGE_NUMBER,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
    }

    function onMacroCreatedOnClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.CREATED_ON,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
    }

    function onSquareShapeClicked() {
        var params = {
            shape_type: ReportElementShapeTypes.SQUARE
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.SHAPE, params);
    }

    function onCircleShapeClicked() {
        var params = {
            shape_type: ReportElementShapeTypes.CIRCLE
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.SHAPE, params);
    }

    function onNewPageClicked() {
        ExportBuilderElementActionService.setAddPageAtIndex(ExportBuilderDashboardService.getReport().pages.length)
    }

    function onUndoClicked() {
        ExportBuilderFacadeUIService.performUndo();
    }

    function onRedoClicked() {
        ExportBuilderFacadeUIService.performRedo();
    }

    function hasUndos() {
        return UndoRedoService.hasUndos()
    }

    function hasRedos() {
        return UndoRedoService.hasRedos()
    }

    function canDisableUndoRedo() {
        return UndoRedoService.getIsPerformingAsyncMutation();
    }

    function onIconElementClicked() {
        PubSub.emit($ExportBuilderIconModalEvents.OPEN_NEW);
    }

    function isSaving() {
        return ExportBuilderFacadeUIService.getIsSavingEntireReport()
    }

    function onColorElementClicked() {
        vm.showTapColor = true;
    }

    function onTapColorClose() {
        vm.showTapColor = false;
    }

    function onKeyboardShortcutsClicked() {
        ExportBuilderFacadeUIService.showKeyboardShortCutsModal()
    }

    function createClientSpecificCalculation() {
        if (window.isNUI) {
            const queryParams = {
                client_id: encodeURI(vm.clientId)
            };
            $location.path("/calculations/new").search(queryParams);
        }
    }

    function onFileSelected() {
        if (ExportBuilderFacadeUIService.getEditingElementImage()) {
            var element = ExportBuilderFacadeUIService.getEditingElementImage();
            PubSub.$emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, true);
        } else {
            UIFactory.notify.showSuccess('Uploading your image...');
        }
    }

    function onFileUpload(data) {

        //ensure we don't create ghost image
        if (!data.url) {
            return;
        }

        var params = {
            image_url: data.url
        };

        var element;
        if (ExportBuilderFacadeUIService.getEditingElementImage()) {
            element = ExportBuilderFacadeUIService.getEditingElementImage();
            ExportBuilderElementActionService.setImage(data.url);
            PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + element.id);
            ExportBuilderFacadeUIService.setElementChangingImage(null);
        } else {
            ExportBuilderElementActionService.addNewItem(ReportElementTypes.IMAGE, params);
            // element = ExportBuilderDashboardService.addNewItem(ReportElementTypes.IMAGE, params, undefined, true);
        }
        // _postAddNewItem(element);
        element && PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, false);
        ExportBuilderFacadeUIService.setImageIsUploading(false);
    }

    function onFileUploadError(err) {
        console.error(err);
        ExportBuilderFacadeUIService.setImageIsUploading(false);
        ExportBuilderFacadeUIService.setElementChangingImage(null);
    }

    function focusOnItem(item) {
        $timeout(function() {
            ExportBuilderDashboardService.focusOnItem(item);
        }, 0);
    }

    function onMinusPageClicked() {
        ExportBuilderDashboardService.goToPreviousPage();
    }

    function onPlusPageClicked() {
        ExportBuilderDashboardService.goToNextPage();
    }

    function onFitToPageClicked() {
        ExportBuilderDashboardService.toggleFitToPage();
    }

    function onGridClicked() {
        ExportBuilderFacadeUIService.toggleGridMode();
    }

    function onRotateClicked() {
        ExportBuilderDashboardService.toggleLandscape();
        ExportBuilderDashboardService.focusOnItem(null);
    }

    function onShowElementSizeClicked() {
        ExportBuilderFacadeUIService.toggleOutlineActive();
    }

    function onOverflowClicked() {
        ExportBuilderFacadeUIService.toggleOverflow();
    }

    function getRotateIconClass() {
        return ExportBuilderDashboardService.getIsLandscape() ? 'rotated' : '';
    }

    function onPageFormatClicked() {

    }

    function onSaveClicked() {
        ExportBuilderFacadeUIService.setIsSavingEntireReport(true);
        ExportBuilderDashboardService.saveEntireReport()
            .then(function () {
            })
            .finally(function () {
                ExportBuilderFacadeUIService.setIsSavingEntireReport(false);
            });
    }

    function onDownloadClicked() {
        PubSub.emit($ExportBuilderTemplateDownloadModalEvents.OPEN);
    }

    function onPreviewModeClicked() {

    }

    function onPresentationModeClicked() {
        ExportBuilderFacadeUIService.togglePresentationMode();
    }

    function isFitPageButtonDisabled() {
        return ExportBuilderDashboardService.getIsLandscape();
    }

    function isFitToPageActive() {
        return ExportBuilderDashboardService.getIsFitToPage();
    }

    function isGridActive() {
        return ExportBuilderFacadeUIService.getIsGridMode();
    }

    function areOutlinesActive() {
        return ExportBuilderFacadeUIService.getIsOutlineActive();
    }

    function isOverflowActive() {
        return ExportBuilderFacadeUIService.getIsOverflow();
    }

    function exportButtonToolTipText() {
        return ExportBuilderDashboardService.getReport().isAllPagesHidden() ? 'There is nothing to export since all pages are hidden due to page assignments. Please modify your data filters.' : '';
    }

    function canDownloadReport() {
        const report = ExportBuilderDashboardService.getReport();
        return report.hasElements()
            && (!report.isAllPagesHidden());
    }

    function canPublishTemplate() {
      return (
        ExportBuilderDashboardService.getReport().can_be_edited &&
        [UserType.SUPERADMIN, UserType.CLUSTERADMIN, UserType.AGENT].includes(
          AppFactory.getUser().type
        ) &&
        Permission.hasPermission(
          Permission.moduleName.REPORTSTUDIO,
          Permission.permissionKey.CAN_PUBLISH_TO_LIBRARY
        ) &&
        !isReportUsingClientCalculation()
      );
    }
    
    function isReportUsingClientCalculation() {
        //TODO refresh pages if data changes
        const pagesArray = Object.values(ExportBuilderDashboardService.getBuilder().report.pages);
        return pagesArray.some(page => {
            return page.elements.some(element => {
                return element.widget?.metadata?.data_columns?.grouped?.some(column => column.client_id) ||
                    element.widget?.metadata?.data_columns?.selected?.some(column => column.client_id);
            });
        });
    }

    function onPublishClicked() {
        PubSub.emit($ExportBuilderTemplatePublishModalEvents.OPEN, ExportBuilderDashboardService.getReport());
    }
    
    function _postAddNewItem(item) {
        focusOnItem(item);
    }

    function _isDownloadingReportEvent(isDownloading) {
        vm.isDownloadingReport = isDownloading;
    }

    function canShowExecutiveSummaryOption() {
        return Permission.hasPermissionToWrite(Permission.moduleName.REPORTSTUDIOEXECUTIVESUMMARIES);
    }

    function onExecutiveSummaryClicked() {
        const currentPage = ExportBuilderDashboardService.getBuilder().currentPage;
        const currentPageIndex = currentPage.page_index;
        if (!currentPage.is_cover_page) {
            // widget model
            let widgetBuilderModel = WidgetBuilderDataModelFactory.getExecutiveSummaryWidgetBuilderModel({});
            widgetBuilderModel.id = null;

            ExportBuilderElementActionService.setAddPageAtIndex(currentPageIndex, true, widgetBuilderModel);
            ExportBuilderDashboardService.goToPageIndex(currentPageIndex);
        } else {
            UIFactory.notify.showWarning('Executive Summary Page cannot be created before Cover Page');
        }
    }

    /**
     * @returns {boolean}
     */
    function canShowWidgetOption() {
        let currentPage = ExportBuilderDashboardService.getBuilder().currentPage;
        return !currentPage.is_executive_summary_page;
    }

    function _setSelect2Values() {
        let format = (vm.report && vm.report.format) ? vm.report.format : PageFormatConstants.STANDARD;
        let formatDropdownValue =  format === PageFormatConstants.STANDARD ? PageFormatDropdownConstants.STANDARD : PageFormatDropdownConstants.WIDESCREEN;
        vm.aspectRatioSelectOptions.selectedValue = {
            id: format,
            text:`${formatDropdownValue}`
        }
    }

    function _resetFormatDropDown(value) {
        value.el.select2('data', value.event.removed)
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.RESET_FORMAT_DROPDOWN, _resetFormatDropDown);
        PubSub.on($ExportBuilderDashboardEvents.IS_DOWNLOADING, _isDownloadingReportEvent);
        EventBus.listen('$PageNuiEvents:ReloadState', () => {
            $state.reload();
        });
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.IS_DOWNLOADING, _isDownloadingReportEvent);
        EventBus.destroy('$PageNuiEvents:ReloadState');
    }
}

/**
 * @ngInject
 */
function ExportBuilderReportHeaderController(
    $rootScope,
    $stateParams,
    ExportBuilderDashboardService,
    $WidgetEvents,
    $ExportBuilderDashboardUIEvents,
    PubSub,
    ReportStudioTemplateNetworkService,
    ReportTemplateType,
    ExportBuilderService
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.onBackButtonClicked = onBackButtonClicked;
    vm.onTitleClicked = onTitleClicked;
    vm.toggleEditReportTitle = toggleEditReportTitle;
    vm.getReportIsFavorite = getReportIsFavorite;
    vm.getCanFavoriteReport = getCanFavoriteReport;
    vm.toggleFavorite = toggleFavorite;
    vm.canShowFavoriteButton = window.isNUI;

    function $onInit() {
        vm.builder = ExportBuilderDashboardService.getBuilder();
        vm.networkState = ReportStudioTemplateNetworkService.getState();
        vm.report = ExportBuilderDashboardService.getReport();
    }

    function onBackButtonClicked() {
        PubSub.emit($ExportBuilderDashboardUIEvents.ON_BACK_PRESSED);
    }

    function onTitleClicked() {
        if(vm.report.client_id) {
            $rootScope.$broadcast('refreshWidgetUsingCalculationSpecific');
        }
        PubSub.emit('init_over');
    }

    function toggleEditReportTitle() {
        if (vm.isEditingReportTitle) {
            //save and close
            vm.isEditingReportTitle = false;
            ExportBuilderDashboardService.saveReportTitle();
        }
        else {
            //open
            vm.isEditingReportTitle = true;
            //focus
            $('#export-builder .panel-tree__header input').focus();
        }
    }

    function getReportIsFavorite(report) {
        return report.is_favorite;
    }

    function getCanFavoriteReport(report) {
        return report.can_favorite;
    }

    function toggleFavorite(report) {
        if (getCanFavoriteReport(report)) {
            ExportBuilderService.setIsFavorite(report, !report.is_favorite);
        }
    }

    function _sendEvent() {
        //TODO: @dannyyassine
        $rootScope.$broadcast($WidgetEvents.WIDGET_REBUILD);
    }
}

/**
 * @ngInject
 */
function ExportBuilderHeaderDisplayOverlayController(
    $rootScope,
    $timeout,
    PubSub,
    AppFactory,
    ExportBuilderDashboardService,
    ExportBuilderFacadeUIService,
    ExportBuilderDashboardUIModelFactory,
    $ExportBuilderDashboardEvents,
    DataSourceType,
    UIFactory,
    gettextCatalog,
    UserType,
    AppModule,    
    $scope
) {
    var vm = this;
    vm.isActive = false;
    vm.isAgent = false;
    vm.isPrivateInputDisabled = false;
    vm.tooltipContainer = ".overlay-content-container";

    /**
     * @type {ReportTemplateModel}
     */
    vm.report = null;

    /**
     *
     * @type {OverlayClientGroupOption}
     */
    vm.clientGroupSelectOptions = ExportBuilderDashboardUIModelFactory.getOverlayClientGroupOption();

    /**
     * @type String
     */
    vm.selectedType = DataSourceType.CLIENT;

    /**
     *
     * @type {{}|*}
     */
    vm.brandMappings = AppFactory.getBrandMappings();

    /**
     * @type {OverlayClientOption}
     */
    vm.clientSelectOptions = ExportBuilderDashboardUIModelFactory.getOverlayClientOption();

    /**
     * @type Boolean
     */
    vm.isClusterModuleAvailable = $rootScope.util.isFeatureAvailable('clusters');


    vm.filterModeTooltipText = gettextCatalog.getString("Widget filters filter down to data views similar to Dashboard filter behavior. When Editing filters for a widget user will be able to select which widget filters are exposed for interaction. If Global filters are enabled only top level campaign filters will be made available in Report Studio");

    vm.DataSourceType = DataSourceType;

    /**
     * @type {{selectedCluster: null}}
     */
    vm.model = {
        selectedCluster: null,
        selectedClient: null,
        selectedClientGroup: null,
        filterMode: null,
        fontType:null
    };

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onBackgroudOverlayClicked = onBackgroudOverlayClicked;
    vm.onTitleChange = onTitleChange;
    vm.onChangeCluster = onChangeCluster;
    vm.onChangeClient = onChangeClient;
    vm.onChangeClientGroup = onChangeClientGroup;
    vm.onIsPrivateChange = onIsPrivateChange;
    vm.getIsPrivateTooltip = getIsPrivateTooltip;
    vm.toggleIsTemplate = toggleIsTemplate;
    vm.showDemoMode = showDemoMode;
    vm.toggleDemoMode = toggleDemoMode;
    vm.showBenchmarkToggle = showBenchmarkToggle;
    vm.toggleBenchmarks = toggleBenchmarks;
    vm.getDataPreFilter = getDataPreFilter;
    vm.toggleDataPreFilter = toggleDataPreFilter;
    
    vm.designTemplateCriteria = gettextCatalog.getString(`
        In order for this report to be used as a <b>Design Template</b>, 
        there must be a maximum of <b>3 pages (one cover page, one intermediate page and one back page)</b>. But the cover page and back page are optional pages,
        thus if they are ommited, there should only be one page present in the report. As well, to be considered as a Design Template, <b>there should be no
        widgets/<b>.
        <br>
        <br>
        If your design template <b>does not</b> meet these criterias, it will not appear as an option when creating a new report or exporting a dashboard to Report Studio.
        `);
    
    vm.reportHeaderOverlayWarningHtmlUrl = reportHeaderOverlayWarningHtmlUrl;

    $scope.$on('refreshWidgetUsingCalculationSpecific', function(event) {
        setWidgetUsingCalculationSpecific();
    });

    function hasClientId(columns) {
        return columns?.some(column => column.client_id);
    }
    
    function setWidgetUsingCalculationSpecific() {
        vm.widgetsUsingCalculationSpecific = Object.values(ExportBuilderDashboardService.getBuilder().report.pages).flatMap(page => 
            page.elements
                .filter(element => 
                    hasClientId(element.widget?.metadata.dynamic?.filters) || 
                    hasClientId(element.widget?.metadata?.data_columns?.grouped) ||
                    hasClientId(element.widget?.metadata?.data_columns?.selected)
                )
                .map(element => ({
                    title: `${stripHtmlTags(page.title)} → ${stripHtmlTags(element.widget.title)} (widget id: ${element.widget_id}${hasClientId(element.widget?.metadata.dynamic?.filters) ? ' see widget filters' : ''})`,
                }))
        );
        vm.isReportUsingClientCalculations = vm.widgetsUsingCalculationSpecific.length > 0;
    }
    

    function $onInit() {
        _registerEvents();

        if (vm.isClusterModuleAvailable) {
            vm.clusterSelectOptions = ExportBuilderDashboardUIModelFactory.getOverlayClusterOption();
        }

        if (!vm.isClusterModuleAvailable || AppFactory.getUser().isAgent()) {
            vm.clusterSelectOptions.disabled = true;
        }
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function getDataPreFilter() {
        return vm.selectedType;
    }

    function toggleDataPreFilter(type) {
        vm.selectedType = type;
        if (type === DataSourceType.CLIENT) {
            vm.model.selectedClientGroup = null;
        }
        if (type === DataSourceType.CLIENT_GROUP) {
            vm.model.selectedClient = null;
            $rootScope.$broadcast('clientIdChanged', null);
        }
    }

    function onBackgroudOverlayClicked() {
        if (_isDeletingCluster()
            || _isChangingDifferentCluster()) {
            return _notifyUserToSaveChanges();
        }

        vm.isActive = false;
        _applyReportAssociationsChanges();
    }

    function _isDeletingCluster() {
        return _.isNil(vm.model.selectedCluster) && !_.isNil(ExportBuilderDashboardService.getReport().cluster);
    }

    function _isChangingDifferentCluster() {
        const { id: clusterId } = _.pick(ExportBuilderDashboardService.getReport().cluster, ['id']);
        const { id: selectedClusterId } = _.pick(vm.model.selectedCluster, ['id']);

        return clusterId !== selectedClusterId;
    }

    function _isChangingFilterMode() {
        const oldValue = ExportBuilderDashboardService.getReport().metadata.filter_mode;
        const newValue = vm.model.filterMode;

        return oldValue !== newValue;
    }

    function _isChangingFontType() {
        const oldValue = ExportBuilderDashboardService.getReport().metadata.font_type;
        const newValue = vm.model.fontType;

        return oldValue !== newValue;
    }

    function _isChangingDifferentClient() {
        const { id: clientId } = _.pick(ExportBuilderDashboardService.getReport().client, ['id']);
        const { id: selectedClientId } = _.pick(vm.model.selectedClient, ['id']);

        return clientId !== selectedClientId;
    }

    function _isChangingDifferentClientGroup() {
        const { id: clientGroupId } = _.pick(ExportBuilderDashboardService.getReport().client_group, ['id']);
        const { id: selectedClientGroupId } = _.pick(vm.model.selectedClientGroup, ['id']);

        return clientGroupId !== selectedClientGroupId;
    }

    function _applyReportAssociationsChanges() {
        const isChangingFilterMode = _isChangingFilterMode();
        const isChangingFonType = _isChangingFontType();
        const isChangingCluster = _isChangingDifferentCluster();
        const isChangingClient = _isChangingDifferentClient();
        const isChangingClientGroup = _isChangingDifferentClientGroup();
        const canShowAssignmentOption = AppFactory.getUser().isModuleAvailable(AppModule.REPORT_STUDIO_PAGE_ASSIGNMENT);

        let loadingText = getSelectedEntityText(isChangingClient, isChangingCluster, isChangingClientGroup);
        isChangingCluster && ExportBuilderDashboardService.setCluster(vm.model.selectedCluster);
        isChangingClient && ExportBuilderDashboardService.setClient(vm.model.selectedClient);
        isChangingClientGroup && ExportBuilderDashboardService.setClientGroup(vm.model.selectedClientGroup)
        isChangingFilterMode && ExportBuilderDashboardService.setFilterMode(vm.model.filterMode);
        isChangingFonType && ExportBuilderDashboardService.setFontType(vm.model.fontType);

        if (isChangingClientGroup || isChangingCluster || isChangingClient || isChangingFilterMode || isChangingFonType) {
            ExportBuilderDashboardService.updateReportAssociations()
                .then(() => {
                    (isChangingClient || isChangingClientGroup) && ExportBuilderFacadeUIService.refreshWidgets();
                    isChangingFonType && ExportBuilderFacadeUIService.refreshWidgets();
                    isChangingFilterMode && PubSub.emit($ExportBuilderDashboardEvents.ON_REMOVE_ALL_FILTERS)
                })
                .finally(() => {
                    canShowAssignmentOption && PubSub.emit($ExportBuilderDashboardEvents.SET_PAGE_PANEL_LOADER_TEXT, loadingText);
                    if (isChangingFilterMode) {
                        $timeout(() => {
                            ExportBuilderFacadeUIService.refreshWidgets();
                        }, 0);
                    }
                })
        }
    }

    function getSelectedEntityText(isChangingClient,isChangingCluster, isChangingClientGroup) {
        if (isChangingClient) {
            const { text: selectedClientText } = _.pick(vm.model.selectedClient, ['text']);
            return selectedClientText;
        }
        else if (isChangingCluster) {
            const { text: selectedClusterText } = _.pick(vm.model.selectedCluster, ['text']);
            return selectedClusterText;
        }
        else if (isChangingClientGroup) {
            const { text: selectedClientGroupText } = _.pick(vm.model.selectedClientGroup, ['text']);
            return selectedClientGroupText;
        }
    }

    function _notifyUserToSaveChanges() {
        UIFactory.confirmDelete(_.assign(_confirmOptions(), {
            callback: isConfirm => {
                $timeout(() => {
                    if (isConfirm) {
                        vm.isActive = false;
                        _applyReportAssociationsChanges();
                    }
                }, 0);
            },
            cancelFn: () => {
                $timeout(() => {
                    if (ExportBuilderDashboardService.getReport().cluster) {
                        vm.model.selectedCluster = ExportBuilderDashboardService.getReport().cluster;
                    }
                    if (ExportBuilderDashboardService.getReport().client) {
                        vm.model.selectedClient = ExportBuilderDashboardService.getReport().client;
                    }
                    if (ExportBuilderDashboardService.getReport().client_group) {
                        vm.model.selectedClientGroup = ExportBuilderDashboardService.getReport().client_group;
                    }
                    vm.hideClusterSelect = true;
                        $timeout(() => {
                            vm.hideClusterSelect = false;
                        })
                }, 0);
            }
        }))
    }

    function _confirmOptions() {
        return {
            title: gettextCatalog.getString("You have changed an important property"),
            text: gettextCatalog.getString("A Business Unit change could result in many users not having access to the report template."),
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: gettextCatalog.getString("#d9534f"),
            confirmButtonText: gettextCatalog.getString("Yes, continue")
        }
    }

    function onTitleChange() {
        ExportBuilderDashboardService.delayUpdateReport();
    }

    function onIsPrivateChange() {
        ExportBuilderDashboardService.delayUpdateReport();
    }
    
    function toggleIsTemplate() {
        ExportBuilderDashboardService.delayUpdateReport();
    }
    
    function showDemoMode() {
        return AppFactory.getUser().isSuperAdmin();
    }

    function showBenchmarkToggle() {
        return AppFactory.getUser().isModuleAvailable(AppModule.BENCHMARKS);
    }
    
    function toggleDemoMode() {
        ExportBuilderDashboardService.delayUpdateReport();
        ExportBuilderFacadeUIService.refreshWidgets();
    }

    function toggleBenchmarks() {
        toggleDemoMode();
    }

    function onChangeCluster($el, event) {
        const selection = $el.select2('data');
        if (selection) {
            const {id, text} = selection;
            vm.model.selectedCluster = {id, text};
        } else {
            vm.model.selectedCluster = null;
        }
    }

    function onChangeClient($el, event) {
        const selection = $el.select2('data');
        if (selection) {
            const {id, text} = selection;
            vm.model.selectedClient = {id, text};
        } else {
            vm.model.selectedClient = null;
        }
        $rootScope.$broadcast('clientIdChanged', vm.model.selectedClient?.id ?? null);
    }

    function onChangeClientGroup($el, event) {
        const selection = $el.select2('data');
        if (selection) {
            const {id, text} = selection;
            vm.model.selectedClientGroup = {id, text};
        } else {
            vm.model.selectedClientGroup = null;
        }
        $rootScope.$broadcast('clientIdChanged', vm.model.selectedClient?.id ?? null);   
    }

    /**
     * @returns {string}
     */
    function getIsPrivateTooltip() {
        return vm.isPrivateInputDisabled
            ? 'You can only change privacy settings on reports you author.'
            : 'If enabled, you will be the only user to this report.';
    }

    function _init() {
        vm.report = ExportBuilderDashboardService.getReport();
        vm.user = AppFactory.getUser();
        vm.isActive = true;
        vm.isPrivateInputDisabled = AppFactory.getUser().userId != vm.report.user_id;
        vm.model.selectedCluster = angular.copy(vm.report.cluster);
        vm.model.selectedClient = angular.copy(vm.report.client);
        vm.model.selectedClientGroup = angular.copy(vm.report.client_group);
        vm.model.filterMode = angular.copy(vm.report.metadata.filter_mode);
        vm.model.fontType = angular.copy(vm.report.metadata.font_type);
        if (vm.model.selectedClient) {
            vm.selectedType = DataSourceType.CLIENT;
        } else if (vm.model.selectedClientGroup) {
            vm.selectedType = DataSourceType.CLIENT_GROUP;
        }


    }

    function _registerEvents() {
        PubSub.on('init_over', _init);
    }

    function _unregisterEvents() {
        PubSub.off('init_over', _init);
    }
}
