import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class Client extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {?string}
   */
  cluster_id;

  /**
   * @var {?string}
   */
  default_reporting_profile_id;

  /**
   * @var {?string}
   */
  company_name;

  /**
   * @var {?string}
   */
  first_name;

  /**
   * @var {?string}
   */
  primary_contact;

  /**
   * @var {?string}
   */
  last_name;

  /**
   * @var {?string}
   */
  email;

  /**
   * @var {?string}
   */
  address_1;

  /**
   * @var {?string}
   */
  address_2;

  /**
   * @var {?string}
   */
  city;

  /**
   * @var {?string}
   */
  state;

  /**
   * @var {?string}
   */
  zip;

  /**
   * @var {?string}
   */
  country;

  /**
   * @var {?string}
   */
  country_display;

  /**
   * @var {?string}
   */
  phone;

  /**
   * @var {?string}
   */
  fax;

  /**
   * @var {?string}
   */
  website;

  /**
   * @var {?string}
   */
  account_manager_user_id;

  /**
   * @var {boolean}
   */
  has_light_logo;

  /**
   * @var {boolean}
   */
  has_dark_logo;

  /**
   * @var {?string}
   */
  crm_id;

  /**
   * @var {?string}
   */
  billing_id;

  /**
   * @var {?string}
   */
  proposal_tool_id;

  /**
   * @var {?string}
   */
  additional_details_1;

  /**
   * @var {?string}
   */
  additional_details_2;

  /**
   * @var {?string}
   */
  additional_details_3;

  /**
   * @var {?string}
   */
  created_by_user_id;

  /**
   * @var {?string}
   */
  created_by_user;

  /**
   * @var {?string}
   */
  report_language;

  /**
   * @var {?string}
   */
  source_service_id;

  /**
   * @var {?datetime}
   */
  creation_time;

  /**
   * @var {?datetime}
   */
  formatted_creation_time;

  /**
   * @var {?datetime}
   */
  last_modification_time;

  /**
   * @var {?datetime}
   */
  formatted_last_modification_time;

  /**
   * @var {string}
   */
  cluster_name;

  /**
   * @var {string}
   */
  default_reporting_profile_name;

  /**
   * @var {?string}
   */
  client_category_id;

  /**
   * @var {string}
   */
  client_category_name;

  /**
   * @var {string}
   */
  reporting_status;

  /**
   * @var {string}
   */
  reporting_status_display;

  /**
   * @var {string}
   */
  iotool_status;

  /**
   * @var {string}
   */
  iotool_status_display;

  /**
   * @var {boolean}
   */
  has_hipaa_data;

  /**
   * @var {array}
   */
  client_group_ids;

  /**
   * @var {boolean}
   */
  activate_tapleads;

  /**
   * @var {string}
   */
  client_lead_code;

  /**
   * @var {string}
   */
  account_manager;

  /**
   * @var {string}
   */
  account_manager_email;

  /**
   * @var {string}
   */
  user_image_metadata;

  /**
   * @var {string}
   */
  services;

  /**
   * @var {string}
   */
  users_count;

  /**
   * @var {string}
   */
  users;

  /**
   * @var {string}
   */
  objectives;

  /**
   * @var {string}
   */
  score;

  /**
   * @var {string}
   */
  is_favourite;

  /**
   * @var {boolean}
   */
  user_can_select_cluster;

  /**
   * @var {boolean}
   */
  enable_lead_management_module;

  /**
   * @var {boolean}
   */
  allow_all_business_units_for_booking_orders;

  /**
   * @var {array}
   */
  product_ids;

  /**
   * @var {string}
   */
  currency;

  /**
   * @var {string}
   */
  currency_display;

  /**
   * @var {string}
   */
  timezone;

  /**
   * @var {string}
   */
  logo_metadata;

  /**
   * @var {string}
   */
  logo_url;

  /**
   * @var {string}
   */
  logo_file;

  /**
   * @var {?string}
   */
  report_language_display;

  /**
   * @var {?string}
   */
  naics_code;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.cluster_id = model.cluster_id;
    this.default_reporting_profile_id = model.default_reporting_profile_id;
    this.company_name = model.company_name;
    this.first_name = model.first_name;
    this.last_name = model.last_name;
    this.primary_contact = model.primary_contact;
    this.email = model.email;
    this.address_1 = model.address_1;
    this.address_2 = model.address_2;
    this.city = model.city;
    this.state = model.state;
    this.zip = model.zip;
    this.country = model.country;
    this.country_display = model.country_display;
    this.phone = model.phone;
    this.fax = model.fax;
    this.website = model.website;
    this.account_manager_user_id = model.account_manager_user_id;
    this.has_light_logo = model.has_light_logo;
    this.has_dark_logo = model.has_dark_logo;
    this.crm_id = model.crm_id;
    this.billing_id = model.billing_id;
    this.proposal_tool_id = model.proposal_tool_id;
    this.additional_details_1 = model.additional_details_1;
    this.additional_details_2 = model.additional_details_2;
    this.additional_details_3 = model.additional_details_3;
    this.created_by_user_id = model.created_by_user_id;
    this.created_by_user = model.created_by_user;
    this.report_language = model.report_language;
    this.source_service_id = model.source_service_id;
    this.creation_time = model.creation_time;
    this.formatted_creation_time = model.formatted_creation_time;
    this.last_modification_time = model.last_modification_time;
    this.formatted_last_modification_time = model.formatted_last_modification_time;
    this.cluster_name = model.cluster_name;
    this.default_reporting_profile_name = model.default_reporting_profile_name;
    this.client_category_id = model.client_category_id;
    this.client_category_name = model.client_category_name;
    this.reporting_status = model.reporting_status;
    this.reporting_status_display = model.reporting_status_display;
    this.iotool_status = model.iotool_status;
    this.iotool_status_display = model.iotool_status_display;
    this.has_hipaa_data = model.has_hipaa_data;
    this.client_group_ids = model.client_group_ids;
    this.activate_tapleads = model.activate_tapleads;
    this.client_lead_code = model.client_lead_code;
    this.account_manager = model.account_manager;
    this.account_manager_email = model.account_manager_email;
    this.user_image_metadata = model.user_image_metadata;
    this.services = model.services;
    this.users_count = model.users_count;
    this.users = model.users;
    this.objectives = model.objectives;
    this.score = model.score;
    this.is_favourite = model.is_favourite;
    this.user_can_select_cluster = model.user_can_select_cluster;
    this.enable_lead_management_module = model.enable_lead_management_module;
    this.allow_all_business_units_for_booking_orders =
      model.allow_all_business_units_for_booking_orders ?? 0;
    this.product_ids = model.product_ids;
    this.currency = model.currency;
    this.currency_display = model.currency_display;
    this.timezone = model.timezone;
    this.logo_metadata = model.logo_metadata;
    this.logo_url = model.logo_metadata?.secure_url || model.logo_url;
    this.logo_file = model.logo_file;
    this.report_language_display = model.report_language_display;
    this.naics_code = model.naics_code ? model.naics_code.toString() : null;
  }
}
