'use strict';
import angular from 'angular';
import _ from 'lodash';
import SeleniumHelper from '../../../../seleniumtest';
import DevTools from '../../../../devtools';
import { DrawOption } from 'coreModules/design/layout/drawoptionpanel/drawoptionpanel.constants';
import Permission from '../../../../permission';
import EventBus from "../../../../../../grok/src/modules/core/app/helpers/EventBus";
import { UPDATE_NAV_ITEM } from "../../../../../../grok/src/modules/core/nav/nav.constants";
import UuidService from "../../../../../../grok/src/modules/core/app/services/UuidService";
import { stripHtmlTags } from "../../../../../../grok/src/modules/core/app/helpers/StringHelper";

angular.module('design.page.ctrls', [])

    .controller('PageListController', PageListController)
    .controller('PageController', PageController)
    .controller('EditPageController', EditPageController)
    .controller('EditPageReportOptionsController', EditPageReportOptionsController);

/**
 * @ngInject
 */
function PageListController(
    PubSub,
    $PageLibraryEvents,
    $scope,
    PageResource,
    PageFactory
) {
    $scope.toggleEditPage = toggleEditPage;
    $scope.exportTemplatesToJson = exportTemplatesToJson;
    $scope.showPageLibraryModal = showPageLibraryModal;
    $scope.hasDevTools = DevTools.isEnabled();

    function toggleEditPage() {
        PageFactory.$editPage();
    }

    function showPageLibraryModal() {
        PubSub.emit($PageLibraryEvents.INIT_MODAL);
    }

    function exportTemplatesToJson() {
        PageResource.pages.get('templatestojson').then(function(json) {
            var data = 'data:text/json;charset=utf-8,' + encodeURI(JSON.stringify(json.plain(), null, 2));
            var link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', 'dump.json');
            link.click();
        });
    }
}

/**
 * @ngInject
 */
function PageController(
    PubSub,
    $timeout,
    $scope,
    DesignFactory,
    PageFactory,
    LayoutFactory,
    $LayoutEvents,
    $LayoutLibraryEvents,
    $WidgetBuilderEvents,
    WidgetLibraryPublishFactory,
    PageEntity,
    DataSourceType,
    DataSourceFactory,
    WidgetCreateFactory,
    gettextCatalog,
    WidgetFactory,
    $WidgetCreateEvents,
    $WidgetFilterEvents,
    $DateRangeEvents,
    $WidgetEvents,
    DashboardFilterPanelFactory,
    WidgetBuilderUIService,
    UIFactory,
    DrawOptionFactory,
    $rootScope,
    $isoEvents,
    $DataGridEvents,
    PageUpdateThumbnailService
) {
    var vm = this;
    vm.setActiveLayout = setActiveLayout;

    $scope.addFirstWidget = addFirstWidget;
    $scope.canShowTabs = canShowTabs;
    $scope.canShowAddSectionTab = canShowAddSectionTab;
    $scope.shouldForceShowAddTab = shouldForceShowAddTab;
    $scope.widgetIsInBuildMode = widgetIsInBuildMode;
    $scope.canShowEmptyLayoutNotice = canShowEmptyLayoutNotice;
    $scope.getExportLayoutTitle = getExportLayoutTitle;
    $scope.showLayoutLibraryModal = showLayoutLibraryModal;
    $scope.setActiveLayout = setActiveLayout;
    $scope.getCurrentLayout = DesignFactory.getCurrentLayout;
    $scope.shared = PageFactory.shared;
    $scope.canShowExecutiveSummary = canShowExecutiveSummary;
    $scope.getDataSourceColor = getDataSourceColor;
    $scope.getDataSourceIcon = getDataSourceIcon;
    $scope.getDataSourceName = getDataSourceName;
    $scope.pageHasLayouts = pageHasLayouts;
    $scope.isWidgetProductPanelActive = isWidgetProductPanelActive;
    $scope.hasGranularAccess = hasGranularAccess;

    let _dataSourcedWidgetsLoaded = 0;
    init();

    function init() {
        const currentLayout = DesignFactory.getCurrentLayout();
        if (!_.isEmpty(currentLayout)
          && !DrawOptionFactory.resolveDrawOptionValue(DrawOption.SHOW_WIDGETS_WITH_NO_DATA, currentLayout)
          && LayoutFactory.getDataSourcedWidgetsCount(currentLayout)) {
            $scope.loadingLayout = true;
            _dataSourcedWidgetsLoaded = 0;
        }
        $scope.$on('$destroy', function () {
            _triggerUpdateThumbnail();
            destroy();
        });
        _registerEvents();
    }

    function destroy() {
        _unregisterEvents();
    }

    PageFactory.updatePageLayouts();

    $scope.showWidgetPublishModal = function () {
        return WidgetLibraryPublishFactory.getIsActive();
    };

    function hasGranularAccess() {
        if (!$scope.page.has_granular_permissions) return true;

        return $scope.page.granular_access_levels.can_be_edited;
    }

    /**
     * @param layout
     */
    function setActiveLayout(layout) {
        _.each(layout.widgets, widget => {
           widget.metadata.dynamic.shouldHide = false;
        });
        var didChangeLayout = LayoutFactory.setActiveLayout(layout);
        if (!layout.metadata.draw_options.show_widgets_with_no_data.value
          && LayoutFactory.getDataSourcedWidgetsCount(layout)) {
            $scope.loadingLayout = true;
            _dataSourcedWidgetsLoaded = 0;
        }
        // If the layout is not loaded yet, show loading screen.
        if(!layout.loaded){
            $scope.loadingLayout = true;
        }

        if (didChangeLayout) {
            PageFactory.toggleWidgetDisplay(layout);
            UuidService.kill();
        }
    }

    /**
     * Add widget from Empty Dashboard notice
     */
    function addFirstWidget() {
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: true});
    }

    /**
     * If more than 1 layout, show tabs
     * @returns {boolean}
     */
    function canShowTabs() {
        return _.size($scope.page.layouts) > 0
            && (!$scope.page.is_predefined || $scope.page.entity === PageEntity.PAGE_LEADS || $scope.page.entity === PageEntity.PAGE_INDUSTRY_BENCHMARK || $scope.page.entity === PageEntity.PAGE_EMAILS_OVERVIEW);
    }

    /**
     * @returns {boolean}
     */
    function canShowAddSectionTab() {
        return (!$scope.page.is_predefined || $scope.page.entity === PageEntity.PAGE_LEADS)
            && $scope.page.can_be_edited && Permission.hasPermissionToWrite(Permission.moduleName.LAYOUT, $scope.page.can_be_edited) && hasGranularAccess();
    }

    /**
     * @returns {boolean}
     */
    function shouldForceShowAddTab() {
        return _.size($scope.page.layouts) === 0
            && !$scope.page.is_predefined
            && canShowActionTabs();
    }

    /**
     * @private
     */
    function canShowActionTabs() {
        var layout = $scope.getCurrentLayout();
        return layout &&
            !_.isEmpty(layout.widgets)
            || _.size($scope.page.layouts) > 1;
    }

    function canShowEmptyLayoutNotice() {
        var layout = $scope.getCurrentLayout();
        return layout && !DesignFactory.canShowEmptyLayoutsNotice()
            && _.isEmpty(layout.widgets)
            && ( _.size($scope.page.layouts) > 1 || (layout.widgets.length && !_.find(layout.widgets, widget => !widget.metadata.dynamic.shouldHide)));
    }

    function widgetIsInBuildMode() {
        return WidgetBuilderUIService.isActive();
    }

    function getExportLayoutTitle() {
        return 'Export ' + DesignFactory.getCurrentLayout().title;
    }

    function getDataSourceColor(metadata) {
        return DataSourceFactory.getDataSourceColor(metadata.data_source);
    }

    function getDataSourceIcon(metadata) {
        return DataSourceFactory.getDataSourceIcon(metadata.data_source);
    }

    function getDataSourceName(metadata) {
        return DataSourceFactory.getDataSourceName(metadata.data_source);
    }

    function pageHasLayouts() {
        return $scope.page
            && !_.isEmpty($scope.page.layouts);
    }

    function showLayoutLibraryModal() {
        PubSub.emit($LayoutLibraryEvents.INIT_MODAL);
    }

    function canShowExecutiveSummary() {
        const currentPage = DesignFactory.getCurrentPage();
        return !currentPage.is_predefined
            && currentPage.executive_summary_enabled;
    }

    function _onWidgetAdded(newWidget) {
        if (WidgetCreateFactory.hasSwappedWidget()) {
            WidgetCreateFactory.resetSwappedWidget();
            PubSub.emit($WidgetEvents.SWAP_WIDGET + newWidget.id, angular.copy(newWidget));
        } else {
            LayoutFactory.addWidget(angular.copy(newWidget));
        }
    }

    function _onWidgetCreation(dataEvent) {
        const { widget, isAdding } = dataEvent;

        if (isAdding) {
            LayoutFactory.addWidget(angular.copy(widget));
        } else {
            LayoutFactory.updateWidget(angular.copy(widget));
        }

        if (WidgetCreateFactory.hasSwappedWidget()) {
            WidgetCreateFactory.resetSwappedWidget();
        }
    }

    function _onWidgetFilterSaved(widget) {
        WidgetFactory.$rebuildWidget(widget.id);
        // Force dashboard filter panel to reset widget exposed filters
        DashboardFilterPanelFactory.resetWidgetFilterOptionList();
        UIFactory.notify.showSuccess(gettextCatalog.getString('Filter set successfully saved'));
    }

    function _onDidSetWidgetDateRange(widget) {
        LayoutFactory.updateWidget(widget);
    }

    function _onDataSourcedWidgetLoaded() {
        if (++_dataSourcedWidgetsLoaded >= LayoutFactory.getDataSourcedWidgetsCount(DesignFactory.getCurrentLayout())
            && $scope.loadingLayout) {
            $scope.loadingLayout = false;
            _dataSourcedWidgetsLoaded = 0;
            $timeout(() => {
                PageFactory.toggleWidgetDisplay(DesignFactory.getCurrentLayout());

                $rootScope.$broadcast($isoEvents.LAYOUT);
                $timeout(function() {
                    PubSub.emit($DataGridEvents.UPDATE_ALL_SCROLL_BODY_HEIGHT);
                }, 0, false);
            });
        }
    }

    function _triggerUpdateThumbnail() {
        if ($scope.page) {
            PageUpdateThumbnailService.triggerUpdateThumbnails($scope.page.id);
        }
    }

    function _registerEvents() {
        PubSub.on($WidgetCreateEvents.CREATED_FROM_LIBRARY, _onWidgetAdded);
        PubSub.on($WidgetCreateEvents.CREATED, _onWidgetCreation);
        PubSub.on($WidgetFilterEvents.WIDGET_FILTER_SAVE, _onWidgetFilterSaved);
        PubSub.on($DateRangeEvents.DID_SET_WIDGET_DATE_RANGE, _onDidSetWidgetDateRange);
        PubSub.on($WidgetEvents.DATASOURCED_WIDGET_LOADED, _onDataSourcedWidgetLoaded);
        window.addEventListener('beforeunload', _triggerUpdateThumbnail);
    }

    function _unregisterEvents() {
        PubSub.off($WidgetCreateEvents.CREATED_FROM_LIBRARY, _onWidgetAdded);
        PubSub.off($WidgetCreateEvents.CREATED, _onWidgetCreation);
        PubSub.off($WidgetFilterEvents.WIDGET_FILTER_SAVE, _onWidgetFilterSaved);
        PubSub.off($DateRangeEvents.DID_SET_WIDGET_DATE_RANGE, _onDidSetWidgetDateRange);
        PubSub.off($WidgetEvents.DATASOURCED_WIDGET_LOADED, _onDataSourcedWidgetLoaded);
        window.removeEventListener('beforeunload', _triggerUpdateThumbnail);
    }

    function isWidgetProductPanelActive() {
        return WidgetBuilderUIService.isWidgetProductPanelActive();
    }
}

/**
 * @ngInject
 */
function EditPageController(
    $timeout,
    $state,
    $scope,
    $rootScope,
    $UISelect2Events,
    FontSize,
    AppModelFactory,
    DataSourceType,
    AppFactory,
    DesignFactory,
    PageFactory,
    PageResource,
    LayoutFactory,
    UIFactory,
    ReportingProfileFactory,
    ClusterService,
    ClientFactory,
    ClientGroupFactory,
    LibraryUIFactory,
    PageType,
    UIColor,
    UserThemes,
    gettextCatalog,
    PubSub,
    $PageNuiEvents,
    $PageGenerateThumbnailEvents,
    AppModule,
    WidgetFactory,
    WidgetFilterFactory,
    WidgetBuilderDataModelFactory,
    $WidgetBuilderEvents,
    $WidgetFilterEvents
) {
    PageFactory.$registerScope($scope);

    $scope.FontSize = FontSize;
    $scope.initNew = initNew;
    $scope.initEdit = initEdit;
    $scope.updateReportingProfiles = updateReportingProfiles;
    $scope.updateCluster = updateCluster;
    $scope.toggleFontSize = toggleFontSize;
    $scope.resetChartPalette = resetChartPalette;
    $scope.onColorChange = onColorChange;
    $scope.resetReportingProfilesSelect = resetReportingProfilesSelect;
    $scope.resetClusterSelect = resetClusterSelect;
    $scope.resetClientSelect = resetClientSelect;
    $scope.resetClientGroupSelect = resetClientGroupSelect;
    $scope.savePage = savePage;
    $scope.reset = reset;
    $scope.showDemoMode = showDemoMode;
    $scope.showBenchmarks = showBenchmarks;
    $scope.brandMappings = AppFactory.getBrandMappings();
    $scope.originalThemeColorCount = UIFactory.getOriginalThemeColorCount();
    $scope.page = null;
    $scope.isActive = false;
    $scope.isEditing = false;
    $scope.getThemeText = getThemeText;
    $scope.onClientChange = onClientChange;
    $scope.onClientGroupChange = onClientGroupChange;
    $scope.canAutoAssignClusterSelectedValue = canAutoAssignClusterSelectedValue;
    $scope.hasClusterFeature = hasClusterFeature;
    $scope.getDataPreFilter = getDataPreFilter;
    $scope.DataSourceType = DataSourceType;
    $scope.toggleDataPreFilter = toggleDataPreFilter;

    var isNewPage = false;

    function getThemeText() {
        return 'Reset to theme defaults';
    }

    function initNew() {
        $scope.page = {};
        isNewPage = true;
        _init();
        let user = AppFactory.getUser();
        $scope.model.selectedCluster = $scope.canAutoAssignClusterSelectedValue()
            ? { id: user.clusterId, text: user.clusterName }
            : {};
    }

    function initEdit() {
        $scope.page = angular.copy(DesignFactory.getCurrentPage());
        isNewPage = false;
        _init();
    }

    function _init() {
        $scope.isActive = true;
        $scope.isEditing = !isNewPage;
        $scope.$broadcast($UISelect2Events.RESET);

        var page = $scope.page;
        $scope.model = {};
        $scope.model.id = page.id || 0;
        $scope.model.title = page.title || null;
        $scope.model.type = page.type || (AppFactory.getUser().isIORoute ? PageType.TYPE_IO : PageType.TYPE_DASH);

        $scope.model.selectedReportingProfiles = [];
        $scope.model.selectedCluster = {};

        $scope.model.showLiveWidgetWarningText = false;
        $scope.model.showBenchmarksWarning = false;
        $scope.showClientSpecificWidgetWarningText = false;

        // On first creation metadata is null
        $scope.model.metadata = angular.copy(page.metadata || {});

        $scope.model.metadata.selected_entity = isNewPage
            ? null
            : page.metadata.selected_entity;

        $scope.selectedType = $scope.model.metadata.selected_entity ? page.metadata.selected_entity.type : DataSourceType.CLIENT;

        // Check if palette is dirty by storing it. If so, rebuild chart widgets
        $scope.model.metadata.chart_palette = isNewPage
            ? _generateThemeColorPalette()
            : page.metadata.chart_palette;

        $scope.model.metadata.font_size = isNewPage
            ? FontSize.SIZE_SMALL
            : page.metadata.font_size;

        if (!isNewPage && !_.isNull(page.reporting_profiles)) {
            $scope.model.selectedReportingProfiles = _.map(page.reporting_profiles, function (item) {
                return {id: item.id, text: (item.name || item.text)};
            });
        }

        if (!isNewPage && !_.isNull(page.cluster)) {
            $scope.model.selectedCluster = !_.isEmpty(page.cluster)
                ? {id: page.cluster.id, text: (page.cluster.name || page.cluster.text)}
                : {};
        }

        _setSelectOptions();
    }

    function updateReportingProfiles($el, event) {
        $scope.model.selectedReportingProfiles = $el.select2('data');
    }

    function hasClusterFeature() {
        return $rootScope.util.isFeatureAvailable('clusters')
    }

    function getDataPreFilter(){
       return $scope.selectedType;
    }

    function updateCluster($el, event) {
        $scope.model.selectedCluster = $el.select2('data');
    }

    function  onClientChange() {
        let $selected_entity = $scope.model.metadata.selected_entity;
        if (isNewPage) {
            $scope.model.showLiveWidgetWarningText = false;
            $scope.model.showBenchmarksWarning = false;
        } else if ($scope.isEditing &&
            (($selected_entity && $selected_entity.id) === (DesignFactory.getCurrentPage().metadata.selected_entity && DesignFactory.getCurrentPage().metadata.selected_entity.id))) {
            $scope.model.showLiveWidgetWarningText = false;
            $scope.model.showBenchmarksWarning = false;
        } else {
            $scope.model.showLiveWidgetWarningText = true;
            $scope.model.showBenchmarksWarning = showBenchmarks();
        }
        $scope.showClientSpecificWidgetWarningText = true;
    }

    function  onClientGroupChange() {
        let $selected_entity = $scope.model.metadata.selected_entity;
        if (isNewPage) {
            $scope.model.showLiveWidgetWarningText = false;
            $scope.model.showBenchmarksWarning = false;
        } else if ($scope.isEditing &&
            (($selected_entity && $selected_entity.id) === (DesignFactory.getCurrentPage().metadata.selected_entity && DesignFactory.getCurrentPage().metadata.selected_entity.id))) {
            $scope.model.showLiveWidgetWarningText = false;
            $scope.model.showBenchmarksWarning = false;
        } else {
            $scope.model.showLiveWidgetWarningText = true;
            $scope.model.showBenchmarksWarning = showBenchmarks();
        }
        $scope.showClientSpecificWidgetWarningText = true;
    }

    function resetReportingProfilesSelect(e, $element) {
        if (isNewPage || _.isEmpty(DesignFactory.getCurrentPage().reporting_profiles)) {
            $element.select2('data', null);
        } else {
            $element.select2('data', DesignFactory.getCurrentPage().reporting_profiles);
        }
    }

    function resetClusterSelect(e, $element) {
        if (isNewPage || _.isEmpty(DesignFactory.getCurrentPage().cluster)) {
            $element.select2('data', null);
        } else {
            $element.select2('data', DesignFactory.getCurrentPage().cluster);
        }
    }

    function resetClientSelect(e, $element) {
        if (isNewPage || _.isEmpty(DesignFactory.getCurrentPage().metadata.selected_entity)) {
            $element.select2('data', null);
        } else {
            $element.select2('data', DesignFactory.getCurrentPage().metadata.selected_entity);
        }
    }

    function resetClientGroupSelect(e, $element) {
        if (isNewPage || _.isEmpty(DesignFactory.getCurrentPage().metadata.selected_entity)) {
            $element.select2('data', null);
        } else {
            $element.select2('data', DesignFactory.getCurrentPage().metadata.selected_entity);
        }
    }

    /**
     * Provide the correct color palette
     * @returns {*}
     * @private
     */
    function _generateThemeColorPalette() {
        var user = AppFactory.getUser();
        if (user.theme === UserThemes.CUSTOM) {
            return UIColor.generateColorPalette(user.primaryThemeColor, user.secondaryThemeColor)
        }
        return UIFactory.getThemeColors();
    }

    function _setSelectOptions() {
        $scope.reportingProfileOptions = AppModelFactory.getSelectOptions({
            selectorType: 'reportingProfile',
            multiple: true,
            width: '100%',
            loaded: false,
            placeholder: gettextCatalog.getString('Select...'),
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                $scope.showClusterSelector = !_.isEmpty(this.values);
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {};
                if (query) {
                    params.q = query + '|name';
                }

                return ReportingProfileFactory.getFieldValues('id', params);
            }
        });

        SeleniumHelper.addSelectClasses($scope.reportingProfileOptions, 'reporting-profile');


        $scope.clusterSelectOptions = AppModelFactory.getSelectOptions({
            selectorType: 'cluster',
            multiple: false,
            width: '100%',
            placeholder: gettextCatalog.getString('No business unit assigned: all business units can access this dashboard.'),
            loaded: false,
            allowClear: AppFactory.getUser().isSuperAdmin(),
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                if ($scope.hasClusterFeature()) {
                    var params = {status: 'active', 'is_cluster_group': false};
                    if (query) {
                        params.q = query + '|name';
                    }
                    return ClusterService.getFieldValues('id', params);
                }
            }
        });

        SeleniumHelper.addSelectClasses($scope.clusterSelectOptions, 'cluster');


        $scope.clientSelectOptions = AppModelFactory.getSelectOptions({
            selectorType: 'client',
            width: '100%',
            placeholder: gettextCatalog.getString('No {{client}} assigned: dashboard will load data for all {{client}}s.',
                {
                    client: $scope.brandMappings.client
                }),
            loaded: false,
            formatSelection: function(item) {
                $scope.model.metadata.selected_entity = item;
                $scope.model.metadata.selected_entity.type = DataSourceType.CLIENT;
                return item.text || item.name;
            },
            onClear: function() {
                $scope.model.metadata.selected_entity = null;
            },
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {};
                if (query) {
                    params.q = query + '|company_name';
                }

                return ClientFactory.getFieldValues('id', params);
            }
        });

        $scope.widgetsUsingCalculationSpecific = Object.values(DesignFactory.getAllWidgets())
                .filter(widget => 
                    widget.metadata.dynamic?.filters.some(column => column.client_id) || 
                    widget.metadata.data_columns?.grouped.some(column => column.client_id) || 
                    widget.metadata.data_columns?.selected.some(column => column.client_id)
                )
                .map(widget => ({
                    id: widget.id,
                    title: stripHtmlTags(DesignFactory.getLayout(widget.layout_id).title)+' → '+stripHtmlTags(widget.title),
                }));
        $scope.isDashboardUsingClientCalculation = $scope.widgetsUsingCalculationSpecific.length > 0 ;

        SeleniumHelper.addSelectClasses($scope.clientSelectOptions, DataSourceType.CLIENT);

        $scope.clientGroupSelectOptions = AppModelFactory.getSelectOptions({
            selectorType: 'client_group',
            width: '100%',
            placeholder: gettextCatalog.getString('No {{client_group}} assigned: dashboard will load data for all {{client_group}}s.',
                {
                    client_group: $scope.brandMappings.client_group
                }),
            loaded: false,
            formatSelection: function(item) {
                item.name= item.text ?? item.name;
                $scope.model.metadata.selected_entity = item;
                $scope.model.metadata.selected_entity.type = DataSourceType.CLIENT_GROUP;
                return item.text || item.name;
            },
            onClear: function() {
                $scope.model.metadata.selected_entity = null;
            },
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {};
                if (query) {
                    params.q = query + '|name';
                }

                return ClientGroupFactory.getFieldValues('id', params);
            }
        });

        SeleniumHelper.addSelectClasses($scope.clientGroupSelectOptions, 'client_group');

    }

    function saveWidgetsWithRemovedClientAssignments() {
        Object.values($scope.page.layouts).forEach(layout => {
            Object.values(layout.widgets).forEach(widget => {
                const { metadata } = widget;
                const { data_columns, dynamic, filter_set_id } = metadata;
                const { grouped, selected } = data_columns;
                // Filter columns without a client_id
                const filterColumns = columns => columns.filter(column => !column.client_id);
                const newGrouped = filterColumns(grouped);
                const newSelected = filterColumns(selected);
                const newFilters = filterColumns(dynamic.filters);
                const filtersChanged = filter_set_id ? dynamic.filters.length !== newFilters.length : false;
                const selectedOrGroupedChanged = newGrouped.length !== grouped.length || newSelected.length !== selected.length;

                if (!filtersChanged && !selectedOrGroupedChanged) {   
                    return;
                } 
                
                const updatedWidget = {
                    ...widget,
                    metadata: {
                        ...metadata,
                        data_columns: {
                            ...data_columns,
                            grouped: newGrouped,
                            selected: newSelected
                        },
                        dynamic: {
                            ...dynamic,
                            filters: newFilters,
                        }
                    }
                };

                if (filtersChanged) {
                    const filterModel = WidgetBuilderDataModelFactory.getFilterSetModel({
                        id: filter_set_id,
                        name: updatedWidget.metadata.dynamic.filter_set_name,
                        widget_id: updatedWidget.id, 
                        data_source: updatedWidget.metadata.data_source,
                        filters: updatedWidget.metadata.dynamic.filters,
                        is_live_integration: updatedWidget.has_live_integration
                    });
                    WidgetFilterFactory.updateFilterSet(filterModel).then(() => {
                        PubSub.emit($WidgetFilterEvents.WIDGET_FILTER_SAVE, updatedWidget);
                    });
                }

                if (selectedOrGroupedChanged) {
                    WidgetFactory.save(updatedWidget).then(() => {
                        PubSub.emit($WidgetBuilderEvents.UPDATE_WIDGET, updatedWidget);
                    });
                }
            });
        });

    }

    function savePage() {
        var postModel = angular.copy($scope.model);
        const originalDashboard = angular.copy(DesignFactory.getCurrentPage());
        postModel.selectedCluster = $scope.model.selectedCluster && $scope.model.selectedCluster.id;
        postModel.selectedReportingProfiles = _.map($scope.model.selectedReportingProfiles, 'id');
        postModel.client_id = $scope.page.client_id; //this is always null  postModel.selected_entity.id if postModel.selected_entity.type === DatasourceType.CLIENT leaving as is but needs validation 

        PageResource.save(postModel).then(function(json) {
            if (window.isNUI) {
                PubSub.emit("SegmentEvents", {
                    event: "EditDashboard",
                    payload: postModel
                });
            }

            $scope.hideModal();
            UIFactory.notify.showSuccess(gettextCatalog.getString('Dashboard successfully saved'));

            // Update nav
            DesignFactory.getNavPageList().then(function() {
                DesignFactory.$refreshMenu();
            });

            if(window.isNUI && $scope.page.is_favorite) {
                EventBus.signal(UPDATE_NAV_ITEM);
            }

            // Add - Reset the model and page for new dashboards
            if (isNewPage) {
                LibraryUIFactory.hideModal('page-library-modal');
                // Go to new created page
                $state.go('dash', {id: json});
            }
            else {
                var originalPageMetadata = angular.copy(DesignFactory.getCurrentPage().metadata);
                var modelMetadata = $scope.model.metadata;
                const originalReportingProfileIds = _.map(DesignFactory.getCurrentPage().reporting_profiles, 'id');
                
                var newPage = _.extend($scope.page, $scope.model);
                newPage.cluster = $scope.model.selectedCluster;
                newPage.reporting_profiles = $scope.model.selectedReportingProfiles;
                DesignFactory.setCurrentPage(newPage);
                AppFactory.setPageTitle(newPage.title);

                // If chart palette has changed or font size has changed, redraw all widgets
                if (!_.isEqual(originalPageMetadata.chart_palette, modelMetadata.chart_palette)
                    || !_.isEqual(originalPageMetadata.font_size, modelMetadata.font_size)
                ) {
                    LayoutFactory.$rebuildAllWidgets();
                    const firstLayout = _.find($scope.page.layouts, layout => layout.display_order === 1);
                    if (firstLayout) {
                        PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: firstLayout.id, page: DesignFactory.getCurrentPage() });
                    }
                }

                if(!_.isEqual(originalPageMetadata.enable_demo_mode, modelMetadata.enable_demo_mode)){
                    DesignFactory.$rebuildPage();
                }

                if(!_.isEqual(originalPageMetadata.enable_benchmarks, modelMetadata.enable_benchmarks)){
                    DesignFactory.$rebuildPage();
                }

                // If client assignment has changed, redraw all widgets and update page metadata
                var originalEntityId = originalPageMetadata.selected_entity ? originalPageMetadata.selected_entity.id : null;
                var modelEntityId = modelMetadata.selected_entity ? modelMetadata.selected_entity.id : null;

                const updatedReportingProfileIds = _.map(DesignFactory.getCurrentPage().reporting_profiles, 'id');
                // Update the NUI dashboard nav items when data profile or selected entity is changed.
                if (!_.isEqual(originalEntityId , modelEntityId)) {
                    if ($scope.isDashboardUsingClientCalculation && 
                        (originalDashboard.metadata.selected_entity?.type !== postModel.metadata.selected_entity?.type 
                            || originalDashboard.metadata.selected_entity?.id !== postModel.metadata.selected_entity?.id)
                    ) {
                        saveWidgetsWithRemovedClientAssignments();
                    }
                    DesignFactory.$rebuildPage();
                    if (AppFactory.getUser().shouldUseNui()) {
                      _updateDashboardNavItems(json, originalDashboard);
                    }
                } else if (
                  AppFactory.getUser().shouldUseNui() &&
                  _.xor(originalReportingProfileIds, updatedReportingProfileIds).length
                ) {
                  _updateDashboardNavItems(json, originalDashboard);
                }
            }

            $scope.isActive = false;
        });
    }

    function canAutoAssignClusterSelectedValue() {
        const user = AppFactory.getUser();
        return user.isClusterAdmin() && user.cluster_id && !user.cluster_is_cluster_group;
    }

    /**
     * To update the dashboard nav items.
     * @param dashboardId
     * @param originalDashboard - dashboard before updation.
     * @private
     */
    function _updateDashboardNavItems(dashboardId, originalDashboard) {
        import(
          '../../../../../../grok/src/modules/core/app/helpers/EventBus'
        ).then(module => {
          const eventBus = module.default;
          eventBus.signal(
            $PageNuiEvents.UPDATE_DASHBOARD_NAV_ITEM,
            dashboardId,
            originalDashboard
          );
        });
    }

    function showDemoMode() {
        return AppFactory.getUser().isSuperAdmin();
    }

    function showBenchmarks() {
        return AppFactory.getUser().isModuleAvailable(AppModule.BENCHMARKS);
    }

    function reset() {
        $scope.page = {};
        isNewPage = false;
        $scope.hideModal();
        $scope.isActive = false;
    }

    /**
     * Update font size value
     * @param size
     */
    function toggleFontSize(size) {
        $scope.model.metadata.font_size = size;
        if ($scope.model.metadata.datagrid_with_fixed_header_size) {
            $scope.model.metadata.datagrid_with_fixed_header_size = false;
        }
    }

    function toggleDataPreFilter(type){
        $scope.model.metadata.selected_entity = null;
        $scope.selectedType = type ?? DataSourceType.CLIENT;
    }

    function resetChartPalette() {
        $scope.model.metadata.chart_palette = _generateThemeColorPalette();
    }

    function onColorChange(color, index) {
        $scope.model.metadata.chart_palette[index] = color;
    }

}

/**
 * @ngInject
 */
function EditPageReportOptionsController(
    $scope,
    PubSub,
    PageFactory,
    PageResource,
    UIFactory,
    gettextCatalog,
    AppFactory,
    AppModule
) {
    PageFactory.$registerReportOptionsScope($scope);
    $scope.initEdit = initEdit;
    var page = $scope.page;
    $scope.showIncludeSummaryPage = AppFactory.getUser().isModuleAvailable(AppModule.DASHBOARD_FILTERS_SUMMARY);

    function initEdit() {
        $scope.model = {};
        $scope.model.id = page.id;
        $scope.model.title = page.title;
        $scope.model.type = page.type;
        $scope.model.metadata = angular.copy(page.metadata);
        $scope.model.selectedReportingProfiles = !_.isEmpty(page.reporting_profiles) ? _.map(page.reporting_profiles, 'id') : [];
        $scope.model.selectedCluster = !_.isEmpty(page.cluster) ? page.cluster.id : {};
    }

    $scope.moveToPageBottom = function() {
        if (_.isEmpty($scope.model)) {
            return;
        }
        var metadata = $scope.model.metadata;
        metadata.append_exported_datagrids = true;
        metadata.append_exported_datagrids_to_layout = false;
    };

    $scope.moveToLayoutBottom = function() {
        if (_.isEmpty($scope.model)) {
            return;
        }
        var metadata = $scope.model.metadata;
        metadata.append_exported_datagrids = false;
        metadata.append_exported_datagrids_to_layout = true;
    };

    $scope.reset = function() {
        if (_.isEmpty($scope.model)) {
            return;
        }
        var metadata = $scope.model.metadata;
        metadata.append_exported_datagrids = false;
        metadata.append_exported_datagrids_to_layout = false;
    };

    $scope.savePage = function() {
        PageResource.save($scope.model).then(function() {
            var metadata = $scope.model.metadata;
            page.metadata.append_exported_datagrids = metadata.append_exported_datagrids;
            page.metadata.append_exported_datagrids_to_layout = metadata.append_exported_datagrids_to_layout;
            page.metadata.hide_empty_exported_widgets = metadata.hide_empty_exported_widgets;
            page.metadata.include_summary_page = metadata.include_summary_page;

            if (window.isNUI) {
                let showAllPages = 'off';
                if (metadata.append_exported_datagrids) {
                    showAllPages = 'dashboard'
                }
                else if (metadata.append_exported_datagrids_to_layout) {
                    showAllPages = 'section'
                }
                PubSub.emit('SegmentEvents', {
                    event: 'EditReportingOptionsEvent',
                    payload: { page, hide_empty_widgets: metadata.hide_empty_exported_widgets, show_all_pages: showAllPages }
                });
            }

            $scope.hideModal();
            UIFactory.notify.showSuccess(gettextCatalog.getString('Reporting options successfully saved'));
        });
    };

    $scope.isInactive = function() {
        return $scope.model
            && !$scope.model.metadata.append_exported_datagrids
            && !$scope.model.metadata.append_exported_datagrids_to_layout;
    };
}
