import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';

export class ChatGptSummary extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  page_id;

  /**
   * @var {string}
   */
  widget_id;

  /**
   * @var {string}
   */
  title;

  /**
   * @var {string}
   */
  content;

  /**
   * @var {string}
   */
  metadata;

  /**
   * @var {string}
   */
  widget_data;

  /**
   * @var {string}
   */
  start_date;

  /**
   * @var {string}
   */
  end_date;

  /**
   * @var {boolean}
   */
  is_human_curated;

  /**
   * @var {string}
   */
  formatted_start_date;

  /**
   * @var {string}
   */
  formatted_end_date;

  /**
   * @var {string}
   */
  usage;

  /**
   * @var {string}
   */
  page_title;

  /**
   * @var {string}
   */
  widget_title;

  user_details;

  /**
   * @var {string}
   */
  layout_id;

  /**
   * @var {string}
   */
  layout_title;

  /**
   * @var {string}
   */
  created_at;

  /**
   * @var {string}
   */
  updated_at;

  /**
   * @var {boolean}
   */
  is_deleted;

  /**
   * @var {boolean}
   */
  is_gpt_widget_summary;

  /**
   * @var {string}
   */
  formatted_created_at;

  /**
   * @var {string}
   */
  formatted_updated_at;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.page_id = model.page_id;
    this.widget_id = model.widget_id;
    this.title = model.title;
    this.content = model.content;
    this.metadata = model.metadata;
    this.is_human_curated = model.is_human_curated;
    this.widget_data = model.widget_data;
    this.usage = model.usage;
    this.page_title = model.page_title;
    this.widget_title = model.widget_title;
    this.user_details = model.user_details;
    this.layout_id = model.layout_id;
    this.layout_title = model.layout_title;
    this.start_date = model.start_date;
    this.end_date = model.end_date;
    this.is_executive_summary_created = model.is_executive_summary_created;
    this.formatted_start_date = DateUtil.formatDateFromUnixTimeStamp(model.start_date);
    this.formatted_end_date = DateUtil.formatDateFromUnixTimeStamp(model.end_date);
    this.created_at = model.created_at;
    this.updated_at = model.updated_at;
    this.is_deleted = model.is_deleted;
    this.rate_limit_exceeded = model?.rate_limit_exceeded || false;
    this.rate_limit_reset_time = model?.rate_limit_reset_time || 0;
    this.is_gpt_widget_summary = model.is_gpt_widget_summary;
    this.formatted_created_at = model.formatted_created_at || false;
    this.formatted_updated_at = model.formatted_updated_at || false;
  }
}
