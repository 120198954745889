import moment from 'moment';
import { isNil } from 'lodash';
import { WidgetRenderService } from '@/modules/ta/widget/services/WidgetRenderService';
import { getDateFormatFromTimeGrouping } from '@/modules/ta/widget/helper';
import { TimeGrouping } from '@/modules/ta/widget/widget.constants';
import { DrillDownConfig } from '@/modules/ta/widget/models/DrillDownConfig';
import { getters } from '@/modules/core/app/helpers/store';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export default class ChartWidgetRenderService extends WidgetRenderService {
  /**
   * @var {String}
   */
  static UNDO_DRILL_DOWN_TEXT = 'Back';

  /**
   * @var {Number}
   */
  currentGroupByIndex = 0;

  /**
   * @var {Array<DrillDownConfig>}
   */
  drillDownConfigStack = [];

  initConfig() {
    Logger.log(i18n.$t('This method must be overridden'), Logger.LEVEL_ERROR);
  }

  getChartSeries() {
    Logger.log(i18n.$t('This method must be overridden'), Logger.LEVEL_ERROR);
  }

  /**
   * @returns {boolean}
   * @private
   */
  _isCurrentGroupedColumnDate() {
    return [ColumnFormat.FORMAT_DATE, ColumnFormat.FORMAT_DATETIME].includes(
      this.getCurrentGroupByColumn()?.format
    );
  }

  /**
   * @param datum
   * @param columnName
   * @returns {string}
   * @private
   */
  _getVisibleDate(datum, columnName = Constant.LOG_DATE) {
    const widget = this.getWidget();
    if (
      this.isSampleData() &&
      widget.metadata.time_grouping === TimeGrouping.GROUPING_DAY_OF_WEEK
    ) {
      return datum[columnName];
    }

    // eslint-disable-next-line no-restricted-properties
    const logDate = window.isNaN(datum[columnName]) ? null : moment.unix(datum[columnName]).utc();
    const drawOptions = this.getDrawOptions();

    const format = getDateFormatFromTimeGrouping(
      widget.metadata.time_grouping,
      drawOptions.date_format_type
    );
    return logDate ? logDate.format(format) : '';
  }

  /**
   * @returns {{marginRight: number, marginBottom: number, sequencedAnimation: boolean, marginTop: number, marginLeft: number}}
   */
  getLabelDefaultOptions() {
    return {
      marginTop: 20,
      marginBottom: 50,
      isMeasured: false,
      horizontalCenter: 'middle',
      // Conditionally set these values based on font fontSize...
      marginLeft: this.getFontSize() * 4,
      marginRight: this.getFontSize() * 4,
    };
  }

  /**
   * @returns {{}}
   */
  getPeriodLabel() {
    return {
      ...this.getLabelDefaultOptions(),
      text: this.isComparison ? i18n.$t('Prior Period') : i18n.$t('Current Period'),
      xPosition: '50%',
      yPosition: '0%',
      align: 'middle',
      fontSize: 14,
      fontWeight: 'bold',
    };
  }

  /**
   * @returns {{any}}
   */
  getChartLegend() {
    return {
      active: this.getDrawOptions().has_legend,
    };
  }

  /**
   * @returns {{}}
   */
  getUndoDrillDownLabel() {
    return {
      text: ChartWidgetRenderService.UNDO_DRILL_DOWN_TEXT,
      align: 'left',
      fill: this.getCurrentThemeColor(),
      fontWeight: 600,
      fontSize: 12,
      marginLeft: 10,
      cursorOverStyle: [{ property: 'cursor', value: 'pointer' }],
    };
  }

  /**
   * @returns {[]}
   */
  getChartTitles() {
    return [];
  }

  /**
   * @returns {boolean}
   */
  is3D() {
    return !!Number(this.getDrawOptions().depth);
  }

  /**
   * @returns {null|Array}
   */
  getCursorOverStyle() {
    return this.canDrillDown() ? [{ property: 'cursor', value: 'pointer' }] : null;
  }

  getChartConfigProperties() {
    const metadata = this.getWidget()?.metadata;
    return {
      // Property `data` should always be computed first as others will depend on it
      data: this.getChartData(),
      series: this.getChartSeries(),
      legend: this.getChartLegend(),
      is3D: this.is3D(),
      titles: this.getChartTitles(),
      isExporting: this.isExporting(),
      currencyDiscrepancy: metadata.currency_discrepancy,
      showCurrency: metadata.show_currency,
    };
  }

  /**
   * @param metadata
   * @returns {*}
   */
  getChartConfig(metadata) {
    this.setup(metadata);
    return this.initConfig(this.getChartConfigProperties());
  }

  /**
   * @returns {string|undefined}
   */
  getCurrentGroupByField() {
    return this.getCurrentGroupByColumn()?.groupby_name_field;
  }

  /**
   * @returns {string|undefined}
   */
  getNextGroupByField() {
    return this.getNextGroupByColumn()?.groupby_name_field;
  }

  /**
   * @returns {string|undefined}
   */
  getPreviousGroupByField() {
    return this.getPreviousGroupByColumn()?.groupby_name_field;
  }

  /**
   * @returns {Object}
   */
  getCurrentGroupByColumn() {
    const widget = this.getWidget();
    const config = getters.dashboardDrilldown.getWidgetConfig(widget.id);
    return this.getGroupedColumns()[config?.currentGroupByIndex ?? 0];
  }

  /**
   * @returns {Object}
   */
  getNextGroupByColumn() {
    if (this.currentGroupByIndex < this.getGroupedColumns().length - 1) {
      return this.getGroupedColumns()[this.currentGroupByIndex + 1];
    }
    return this.getGroupedColumns()[this.currentGroupByIndex];
  }

  /**
   * @returns {Object}
   */
  getPreviousGroupByColumn() {
    if (this.currentGroupByIndex > 0) {
      return this.getGroupedColumns()[this.currentGroupByIndex - 1];
    }
    return this.getGroupedColumns()[this.currentGroupByIndex];
  }

  /**
   * @param index
   */
  setCurrentGroupByIndex(index) {
    if (this.currentGroupByIndex !== index) {
      this.currentGroupByIndex = index;
    }
  }

  /**
   * @returns {Number}
   */
  getGroupByIndexOnDrillDown() {
    if (this.currentGroupByIndex < this.getGroupedColumns().length - 1) {
      return this.currentGroupByIndex + 1;
    }
    return this.currentGroupByIndex;
  }

  /**
   * @returns {Number}
   */
  getGroupByIndexOnUndoDrillDown() {
    if (this.currentGroupByIndex > 0) {
      return this.currentGroupByIndex - 1;
    }
    return this.currentGroupByIndex;
  }

  /**
   * @returns {boolean}
   */
  canDrillDown() {
    return this.isDrillDownEnabled();
  }

  /**
   * @returns {boolean}
   */
  canUndoDrillDown() {
    return (
      this.getWidget()?.isDrillDownEnabled() &&
      this.getGroupByIndexOnUndoDrillDown() !== this.currentGroupByIndex
    );
  }

  /**
   * @returns {DrillDownConfig}
   */
  undoDrillDown() {
    this.setCurrentGroupByIndex(this.getGroupByIndexOnUndoDrillDown());
    // Below we pop and return last element in drillDownConfigStack
    this.drillDownConfigStack.pop();
    return this.drillDownConfigStack[this.currentGroupByIndex - 1];
  }

  /**
   * @param currentGroupByCategoryIndex
   * @param data
   * @returns {DrillDownConfig}
   */
  getDrillDownConfig(currentGroupByCategoryIndex, data) {
    if (this.currentGroupByIndex < 1) {
      return null;
    }
    const previousGroupByField = this.getPreviousGroupByField();
    const queryParams = {
      [previousGroupByField]: data[currentGroupByCategoryIndex][previousGroupByField],
    };
    return new DrillDownConfig({ groupByIndex: this.currentGroupByIndex, queryParams });
  }

  /**

   * @param value
   * @returns {null|number}
   * @private
   */
  _getMetricValidValue(value) {
    return isNil(value) ? null : Number.parseFloat(value);
  }

  /**
   * @param data
   * @param datumIndex
   * @param column
   */
  setCurrentAndPriorPeriodDatum(data, datumIndex, column) {
    if (data[datumIndex]) {
      if (column.isFormatNumerical()) {
        data[datumIndex][column.field] = this._getMetricValidValue(data[datumIndex][column.field]);
      } else if (column.isFormatTime()) {
        data[datumIndex][column.field] = moment
          .duration(data[datumIndex][column.field])
          .asSeconds();
      }
    }
  }

  setCurrentAndPriorPeriodData() {
    super.setCurrentAndPriorPeriodData();
    this.nonGroupedColumns.forEach((column) => {
      for (let index = 0; index < this.totalData; index++) {
        this.setCurrentAndPriorPeriodDatum(this.currentPeriodData, index, column);
        this.setCurrentAndPriorPeriodDatum(this.priorPeriodData, index, column);
      }
    });
  }

  reset() {
    super.reset();
    if (!this.isDrillDownEnabled()) {
      this.currentGroupByIndex = 0;
      this.drillDownConfigStack = [];
    }
  }
}
