export const ONBOARDING_STEPS = 3;
export const ONBOARDING = 'onboarding';
export const OnboardingStep = {
  CONNECT_DATA: 'connect_data',
  ANALYZE: 'analyze',
  SHARE: 'share',
  DONE: 'done',
};

export const OnboardingEvent = {
  CONNECT_DATA: 'OnboardingEvent:connect_data',
  CREATE_DASHBOARD: 'OnboardingEvent:create_dashboard',
  CREATE_REPORT: 'OnboardingEvent:create_report',
  ADDED_CREDIT_CARD: 'OnboardingEvent:added_credit_card',
  DASHBOARD_GENERATED: 'OnboardingEvent:dashboard_generated',
};

export const BOOK_DEMO_URL =
  'https://calendly.com/d/ywt-xfk-b7z?utm_source=calendly&utm_medium=inapp_freetrial&utm_campaign=platform_demo';
