export default class SliceWidgetConfigData {
  /**
   * @var {number}
   */
  index;

  /**
   * @var {string}
   */
  metric;

  /**
   * @var {number}
   */
  value;

  /**
   * @var {number}
   */
  rawValue;

  /**
   * @var  {Array<SliceWidgetConfigData>}
   */
  subs;

  /**
   * @var {number}
   */
  percent;

  /**
   * @var {boolean}
   */
  isOther;

  /**
   * @var {boolean}
   */
  isMetric;

  /**
   * @var {string}
   */
  rawMetric;

  constructor(model = {}) {
    this.index = model.index;
    this.metric = model.metric;
    this.value = model.value ?? 0;
    this.rawValue = model.rawValue ?? 0;
    this.subs = model.subs ?? [];
    this.percent = model.percent ?? 0;
    this.isOther = model.isOther ?? false;
    this.isMetric = true;
    this.rawMetric = model.rawMetric;
  }
}
