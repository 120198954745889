'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { Locale } from 'coreModules/shared/scripts/constants/language.constants';
import { MomentDateFormat } from 'coreModules/daterange/base/daterange.constants';
import {WidgetDataViews} from "../design.widget.constants";

angular.module('design.widget.services', [])
    .factory('WidgetUtilService', WidgetUtilService)
    .factory('WidgetFactory', WidgetFactory)
    .factory('WidgetFactoryDelegate', WidgetFactoryDelegate)
    .service('WidgetSortUtilService', WidgetSortUtilService)
    .factory('WidgetQueryParamService', WidgetQueryParamService);

/**
 * @ngInject
 */
function WidgetUtilService(
    WidgetSize,
    WidgetType,
    DrawOption,
    ChartPlotType
) {
    let baseWidgetHeight = WidgetSize.BASE_HEIGHT; // px
    let maxWidgetWidth = WidgetSize.MAX_WIDTH; // grid units
    let maxWidgetHeight = WidgetSize.MAX_HEIGHT; // grid units

    return {
        maxWidgetWidth: maxWidgetWidth,
        maxWidgetHeight: maxWidgetHeight,
        getWidgetWidth: getWidgetWidth,
        getWidgetHeight: getWidgetHeight,
        hasMetadataErrors: hasMetadataErrors,
        hasGroupedColumns: hasGroupedColumns,
        hasPrimaryDateSelectedColumn: hasPrimaryDateSelectedColumn,
        hasPrimaryDateGroupedColumn: hasPrimaryDateGroupedColumn,
        trimWidgetMetadata: trimWidgetMetadata,
        isChartWidget: isChartWidget,
        isSerialChart: isSerialChart,
        isSortableChart: isSortableChart,
        isAm5Chart: isAm5Chart,
        isBarChart: isBarChart,
        isBubbleChart: isBubbleChart,
        isSliceChart: isSliceChart,
        isGaugeChart: isGaugeChart,
        isFunnelChart: isFunnelChart,
        isGeoChart: isGeoChart,
        isDataGrid: isDataGrid,
        isMediaWidget: isMediaWidget,
        isAdminWidget: isAdminWidget,
        isChatGptWidget: isChatGptWidget,
        isBigNumber: isBigNumber,
        isComboChart: isComboChart,
        isBulletedChart: isBulletedChart,
        isGroupedColumnPlotType: isGroupedColumnPlotType,
        isHeatMapPlotType: isHeatMapPlotType,
        isConditionalPlotType: isConditionalPlotType,
        handleTotalAtBottomStatus: handleTotalAtBottomStatus,
        isDataWidget: isDataWidget,
        isGroupByNameMetric: isGroupByNameMetric,
        isGridFormattedPlotType: isGridFormattedPlotType,
        isEmbeddedSparklinesPlotType: isEmbeddedSparklinesPlotType,
    };

    /**
     * Calculate widget width
     * @param widgetWidth
     * @returns {string}
     */
    function getWidgetWidth(widgetWidth) {
        return widgetWidth * 100 / maxWidgetWidth + '%';
    }

    /**
     * Calculate widget height
     * @param widgetHeight
     * @returns {string}
     */
    function getWidgetHeight(widgetHeight) {
        widgetHeight = widgetHeight > maxWidgetHeight ? maxWidgetHeight : widgetHeight;
        return widgetHeight * baseWidgetHeight + 'px';
    }

    /**
     * Helper function determining if widget contains an error or warning
     * @returns {Boolean}
     */
    function hasMetadataErrors(metadata) {
        return metadata.dynamic
            && (!_.isEmpty(metadata.dynamic.warnings) || !_.isEmpty(metadata.dynamic.errors))
    }

    /**
     * @param metadata
     * @returns {{}|metadata.data_columns|boolean}
     */
    function hasGroupedColumns(metadata) {
        return metadata.data_columns && _.size(metadata.data_columns.grouped) > 0;
    }

    function hasPrimaryDateSelectedColumn(metadata) {
        return !!_.find(metadata.data_columns.selected, {is_primary_date_field: true});
    }

    function hasPrimaryDateGroupedColumn(metadata) {
        return hasGroupedColumns(metadata) && !!_.find(metadata.data_columns.grouped, {is_primary_date_field: true});
    }

    /**
     * Trims down widget metadata to its database structure
     *
     * @param model
     *
     * @returns {any}
     */
    function trimWidgetMetadata(model) {
        // Create copy of model to avoid breaking metadata caused by changes below
        const payload = angular.copy(model);

        // Never send back full column metadata, only keep field names
        if (!_.isUndefined(payload.metadata.data_columns)) {
            let dataColumns = payload.metadata.data_columns;
            let selectedColumns = dataColumns.selected;
            let groupedColumns = dataColumns.grouped;
            let benchmarkColumns = dataColumns.benchmarks;

            if (!_.isUndefined(payload.metadata.time_grouping)) {
                // Only set time grouping if grouped field is primary date field, ALL other cases set to null.
                let primaryDateColumn = _.find(groupedColumns, {is_primary_date_field: true});
                if (_.isEmpty(groupedColumns) || _.isEmpty(primaryDateColumn)) {
                    payload.metadata.time_grouping = null;
                }
            }

            if (model.type === WidgetType.GAUGECHART && selectedColumns.length > 2) {
                selectedColumns = selectedColumns.slice(0, 2);
            }

            dataColumns.benchmarks = _.map(benchmarkColumns, 'field');
            dataColumns.selected = _.map(selectedColumns, 'field');
            dataColumns.grouped = _.map(groupedColumns, (groupedColumn) => {
                if (isGroupByNameMetric(groupedColumn, payload.metadata)) {
                  return groupedColumn.groupby_name_field;
                } else {
                  return groupedColumn.field;
                }
            });
        }

        // Never send dynamic metadata
        delete payload.metadata.dynamic;

        return payload;
    }


    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isChartWidget(widgetType) {
        return isSliceChart(widgetType)
            || isSerialChart(widgetType)
            || isGeoChart(widgetType)
            || isGaugeChart(widgetType);
    }

    function isBulletedChart(widgetType) {
        return isLineChart(widgetType) || isComboChart(widgetType);
    }

    /**
     * Helper function to tell us if a chart is a line chart
     * @param widgetType
     * @returns {boolean}
     */
    function isLineChart(widgetType) {
        return widgetType === WidgetType.LINECHART;
    }

    /**
     * Helper function to tell us if a chart is a combo chart
     * @param widgetType
     * @returns {boolean}
     */
    function isComboChart(widgetType) {
        return widgetType === WidgetType.COMBINATIONCHART;
    }

    /**
     * Helper function to tell us if a chart is a slice chart
     * @param widgetType
     * @returns {boolean}
     */
    function isSliceChart(widgetType) {
        return widgetType === WidgetType.PIECHART
            || widgetType === WidgetType.FUNNELCHART;
    }

    /**
     * Helper function to tell us if the widget type is bar chart
     * @param widgetType
     * @returns {boolean}
     */
    function isBarChart(widgetType) {
        return widgetType === WidgetType.BARCHART;
    }

    /**
     * Helper function to tell us if the widget type is bubble chart
     * @param widgetType
     * @returns {boolean}
     */
    function isBubbleChart(widgetType) {
        return widgetType === WidgetType.BUBBLECHART;
    }

    /**
     * Helper function to tell us if a chart is a slice chart
     * @param widgetType
     * @returns {boolean}
     */
    function isFunnelChart(widgetType) {
        return widgetType === WidgetType.FUNNELCHART;
    }

    /**
     * Helper function to tell us if a chart is a serial chart
     * @param widgetType
     * @returns {boolean}
     */
    function isSerialChart(widgetType) {
        return widgetType === WidgetType.BARCHART
            || widgetType === WidgetType.BUBBLECHART
            || widgetType === WidgetType.LINECHART
            || widgetType === WidgetType.COMBINATIONCHART;
    }

    /**
     * Helper function to tell us if a chart is sortable
     * @param widgetType
     * @returns {boolean}
     */
    function isSortableChart(widgetType) {
        return widgetType === WidgetType.BARCHART
            || widgetType === WidgetType.LINECHART
            || widgetType === WidgetType.COMBINATIONCHART
            || widgetType === WidgetType.BUBBLECHART;
    }

    /**
     * Helper function to tell us if a chart is implemented in amCharts5
     * @param widgetType
     * @param plotType
     * @returns {boolean}
     */
    function isAm5Chart(widgetType, plotType = null) {
        switch (widgetType) {
            case WidgetType.FUNNELCHART:
                return (
                    plotType &&
                    [ChartPlotType.FUNNEL, ChartPlotType.PYRAMID, ChartPlotType.PICTORIAL].includes('' + plotType)
                );
            case WidgetType.GAUGECHART:
            case WidgetType.BARCHART:
            case WidgetType.COMBINATIONCHART:
            case WidgetType.LINECHART:
            case WidgetType.GEOCHART:
            case WidgetType.PIECHART:
                return plotType?.endsWith('(v2)');
            case WidgetType.BUBBLECHART:
                return true;
            default:
                return false;
        }
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isGaugeChart(widgetType) {
        return widgetType === WidgetType.GAUGECHART;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isDataGrid(widgetType) {
        return widgetType === WidgetType.DATAGRID;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isGeoChart(widgetType) {
        return widgetType === WidgetType.GEOCHART;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isBigNumber(widgetType) {
        return widgetType === WidgetType.BIGNUMBER;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isAdminWidget(widgetType) {
        return widgetType === WidgetType.ACCOUNTMANAGER;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isMediaWidget(widgetType) {
        return widgetType === WidgetType.MEDIA;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isChatGptWidget(widgetType) {
        return widgetType === WidgetType.CHATGPT;
    }

    /**
     * @param widgetType
     * @returns {boolean}
     */
    function isDataWidget(widgetType) {
        return !isMediaWidget(widgetType) &&
            !isAdminWidget(widgetType) &&
            !isChatGptWidget(widgetType);
    }

    /**
     * Helper function to return if a datagrid widget is of grouped column plot type or not
     * @param drawOptions
     * @returns {boolean}
     */
    function isGroupedColumnPlotType(drawOptions) {
        return drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.GROUPED_COLUMN;
    }

    /**
     * Helper function to return if a datagrid widget is of conditional plot type or not
     * @param drawOptions
     * @returns {boolean}
     */
    function isConditionalPlotType(drawOptions) {
        return drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.CONDITIONAL_MAP;
    }

    /**
     * Helper function to return if a datagrid widget is of embedded sparkline plot type or not
     * @param drawOptions
     * @returns {boolean}
     */
    function isEmbeddedSparklinesPlotType(drawOptions) {
        return drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.EMBEDDED_SPARKLINES;
    }

    /**
     * Helper function to return if a datagrid widget is of heat map plot type or not
     * @param drawOptions
     * @returns {boolean}
     */
    function isHeatMapPlotType(drawOptions) {
        return drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.HEAT_MAP;
    }

    /**
     * Helper function to handle enabling, disabling of the total row at bottom toggle
     * @param enableDrawOption
     */
    function handleTotalAtBottomStatus(drawOptions, enableDrawOption = false) {
        drawOptions[DrawOption.GRID_TOTAL_ROW_BOTTOM] = enableDrawOption;
    }


    /**
     * Helper function to check if the plot type is formatted [grouped, heat map, conditional]
     * @param drawOptions
     */
    function isGridFormattedPlotType(drawOptions) {
        return [ChartPlotType.GROUPED_COLUMN, ChartPlotType.CONDITIONAL_MAP, ChartPlotType.HEAT_MAP].includes(drawOptions[DrawOption.PLOT_TYPE]);
    }

    function isGroupByNameMetric(groupByColumn, metadata) {
        return (
          groupByColumn.format === "id" &&
          groupByColumn.groupby_name_field !== groupByColumn.groupby_id_field &&
          _.isEmpty(groupByColumn.alternate_field) &&
          groupByColumn.postprocess_type === "custom" &&
          (groupByColumn.is_groupby_name_field ||
            (metadata.data_source.data_view_name ===
              WidgetDataViews.DATA_VIEW_AGE &&
              groupByColumn.is_groupable))
        );
    }

    /**
     * Helper function to return if a widget is an Executive Summary widget or not
     *
     * @param widgetType
     * @returns {boolean}
     */
    function isExecutiveSummaryWidget(widgetType) {
        return widgetType === WidgetType.EXECUTIVESUMMARY;
    }
}

/**
 * @ngInject
 */
function WidgetFactory(
    PubSub,
    $rootScope,
    $timeout,
    $isoEvents,
    $WidgetHeaderEvents,
    $WidgetEvents,
    Restangular,
    LoadingState,
    ApiDataFields,
    WidgetFactoryDelegate,
    ScopeFactory,
    DesignFactory,
    WidgetCreateFactory,
    WidgetData,
    DrawOption,
    WidgetType,
    WidgetTypeGrouping,
    ColumnValueType,
    ColumnFormat,
    DateFormatType,
    TimeGrouping,
    ExportBuilderPrefixes,
    WidgetUtilService,
    WidgetBuilderUIService,
    LanguageService,
    ReportStudioTemplateDataService,
    gettextCatalog,
    PageEvents,
    AppFactory,
) {
    var dash = Restangular.all('dash');
    var widgets = dash.all('widgets');
    var values = widgets.one('values');

    /**
     * Refers to the list of php class objects WidgetType
     * @type {{}}
     */
    var widgetTypes = {};

    /**
     * Sets the widgetTypes var to be exposed externally
     * @param values
     * @returns {*}
     */
    function setWidgetTypes(values) {
        widgetTypes = _.map(values, function(value) {
            return {
                id: value.id,
                name: value.name,
                icon: value.icon,
                type_groupings: value.type_groupings,
                can_group_by: value.can_group_by,
                can_drilldown: value.can_drilldown,
                requires_group_by: value.requires_group_by,
                is_in_production: value.is_in_production ?? true,
                is_disabled: false //Front end only
            };
        });
    }

    /**
     * Allows read only access to the ALL widgetTypes
     * @returns {{}}
     */
    function getAllWidgetTypes() {
        return widgetTypes;
    }

    /**
     * Allows read only access to the widgetTypes
     * @param typeGrouping
     * @param excludedGroupings
     * @returns {{}}
     */
    function getWidgetTypes(typeGrouping, excludedGroupings) {
        return _.filter(widgetTypes, function(value) {
            if (_.includes(value.type_groupings, typeGrouping)
                && (_.difference(value.type_groupings, excludedGroupings).length === value.type_groupings.length)) {
                return value;
            }
        });
    }

    /**
     * Get a specific widgetType by id
     * @param id
     * @returns {WidgetTypeModel}
     */
    function getWidgetType(type) {
        return _.find(widgetTypes, {id: type});
    }

    /**
     *
     * @param widgetType
     * @returns {*}
     */
    function getWidgetTypeGrouping(widgetType) {
        switch (widgetType) {
            case WidgetType.MEDIA:
                return WidgetTypeGrouping.DISPLAY;
            case WidgetType.ACCOUNTMANAGER:
                return WidgetTypeGrouping.ADMIN;
            case WidgetType.CHATGPT:
                return WidgetTypeGrouping.CHATGPT;
            case WidgetType.EXECUTIVESUMMARY:
                return WidgetTypeGrouping.EXECUTIVESUMMARY;
            default:
                return WidgetTypeGrouping.DATASOURCED;
        }
    }

    /**
     * Get data for widgets
     * @param queryParams
     * @returns {*}
     */
    function getData(queryParams) {
        return widgets.all(queryParams.widget_request).one('data').get(queryParams);
    }

    /**
     * Get single widget by id
     * @param id (int)
     * @param queryParams
     */
    function getWidget(id, queryParams) {
        return widgets.one(id).get(queryParams).then(function(json) {
            return json.plain();
        });
    }

    /**
     * Get all widgets in layout
     * @param layoutId
     * @returns {*}
     */
    function getLayoutWidgets(layoutId) {
        return widgets.getList({layout_id: layoutId}).then(function(json) {
            return json.plain();
        });
    }

    /**
     *
     * @returns {{}}
     */
    var getDefaultState = function() {
        return {
            // Widget is in process of loading data
            isLoading: true,
            // Action required for widget, but widget can still be saved and displayed
            helpNeeded: false,
            // Is currently drilling down into a chart widget
            isDrilling: false,
            // Current widget if of chart type
            isChartWidget: false,
            // Show widget filter display panel
            showFilterPanel: false,
            // Show widget date range filter display panel
            showDateRangeFilterPanel: false,
            // DEV ONLY- Show widget dev display panel
            showDevToolsWidgetOptions: false,
            // Show widget filter dropzone panel
            showFilterDropZone: false,
            // Is publishing widget to library
            isPublishing: false,
            // Widget is annotate mode
            isAnnotating: false,
            // Widget is actively grouping by time
            isTimeGroupedWidget: false,
            // Widget is building/editing
            isBuilding: false,
            // Widget is in process of loading sample data
            isLoadingData: false,
            // Widget is in process of being filtered
            isFiltering: false,
            // Widget is done applying filters
            isDoneFiltering: false,
            // This loading state is more complex than true/false: it is used to determine where in the process of data-fetching the widget is currently in.
            loadingState: null,
            // searching for data in a datagrid
            isQueryingDatagrid: false,
        }
    };

    /**
     * Get values for a specific field (enum values or distinct possible values)
     * @param fields {string | array}
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    var getColumnValues = function(fields) {
        return values.one(ColumnValueType.SET).get(_.isNull(fields) ? '' : {fields: _.isArray(fields) ? fields.join(',') : fields});
    };

    /**
     *
     * @param widgetType
     * @param location
     * @param params
     * @returns {Promise}
     */
    var getMetadataColumnValues = function(widgetType, location, params) {
        if (widgetType) {
            // TYPE_ALL + TYPE_WIDGET
            return widgets.one('metadata').one(widgetType).one('values').one(ColumnValueType.SET).get(params);
        }
        else {
            // TYPE_ALL only (location specifies 'dashboard|export')
            return dash.one('drawoptions').one(location).get(params);
        }
    };

    /**
     * Return an array of active grouped columns for a widget
     *  NOTE: not all grouped columns are always active
     *
     * @param metadata
     * @param widgetType
     * @returns {Array}
     */
    function getActiveGroupedColumns(metadata, widgetType) {
        var activeGroupedColumns = [];
        if (!metadata.data_columns.grouped) {
            return [activeGroupedColumns];
        }

        switch (widgetType) {
            case WidgetType.DATAGRID:
                return metadata.data_columns.grouped;

            case WidgetType.BARCHART:
            case WidgetType.LINECHART:
            case WidgetType.COMBINATIONCHART:
                activeGroupedColumns.push(metadata.data_columns.grouped[0]);
                if (metadata.is_multi_grouped){
                    activeGroupedColumns.push(metadata.data_columns.grouped[1]);
                }

                break;

            default:
                activeGroupedColumns.push(_.first(metadata.data_columns.grouped));
                break;
        }
        return activeGroupedColumns;
    }

    /**
     * Returns Restangular resource for plot types by widget type
     * @param widgetType
     * @returns {any[]|HTMLElement}
     */
    function getPlotTypes(widgetType) {
        if (_isReportStudioContext()) {
            const reportLanguage = ReportStudioTemplateDataService.getReportLanguage();
            return widgets.one('plottypes').one(widgetType).get({lang: reportLanguage});
        }
        else {
            return widgets.one('plottypes').one(widgetType).get();
        }
    }

    function getComparisonOptions() {
        return widgets.one('comparisonoptions').get();
    }

    function getSparklineOptions() {
        return widgets.one('sparklineoptions').get();
    }

    /**
     * Returns Restangular resource for plot types by widget type
     * @param widgetType
     * @returns {any[]|HTMLElement}
     */
    function getMapBoxLayers() {
        return widgets.one('plottypes').one('mapboxlayers').get();
    }

    /**
     *
     * @param selectedTimeGroupingMetric
     * @returns {{canShowEmptyDates: boolean}}
     * date format object for a chart
     */
    function getDateFormats(selectedTimeGroupingMetric) {
        var dateObj = {
            canShowEmptyDates: true
        };
        if (selectedTimeGroupingMetric) selectedTimeGroupingMetric = selectedTimeGroupingMetric.toLowerCase();
        switch (selectedTimeGroupingMetric) {

            case TimeGrouping.GROUPING_YEARLY:
                dateObj.canShowEmptyDates = false;
                dateObj.momentFormat = 'YYYY';
                return dateObj;

            case TimeGrouping.GROUPING_DAY_OF_MONTH:
                dateObj.canShowEmptyDates = false;
                dateObj.momentFormat = 'Do';
                return dateObj;

            case TimeGrouping.GROUPING_WEEKLY:
            case TimeGrouping.GROUPING_NON_ISO_WEEK:
                dateObj.minPeriod = '7DD';
                dateObj.momentFormat = MomentDateFormat.MONTH_DAY_YEAR_NO_COMMA;
                return dateObj;

            case TimeGrouping.GROUPING_MONTHLY:
                dateObj.minPeriod = 'MM';
                dateObj.momentFormat = 'MMM YYYY';
                return dateObj;

            case TimeGrouping.GROUPING_MONTH_OF_YEAR:
                dateObj.momentFormat = 'MMMM';
                dateObj.canShowEmptyDates = false;
                return dateObj;

            case TimeGrouping.GROUPING_DAY_OF_WEEK:
                dateObj.momentFormat = 'dddd';
                dateObj.canShowEmptyDates = false;
                return dateObj;

            case TimeGrouping.GROUPING_HOURLY:
                // Currently breaks amcharts
                dateObj.canShowEmptyDates = false;
                dateObj.minPeriod = 'hh';
                dateObj.momentFormat = 'hh:mmA MMM DD, YYYY';
                return dateObj;

            case TimeGrouping.GROUPING_HOUR_OF_DAY:
            case TimeGrouping.GROUPING_HOURLY_ADVERTISER:
            case TimeGrouping.GROUPING_HOURLY_AUDIENCE:
                // Currently breaks amcharts
                dateObj.canShowEmptyDates = false;
                dateObj.momentFormat = 'hhA';
                return dateObj;

            case TimeGrouping.GROUPING_DAILY:
                dateObj.minPeriod = 'DD';
                dateObj.momentFormat = LanguageService.getDisplayDateFormat();
                return dateObj;
            // Needed for sma
            case TimeGrouping.GROUPING_QUARTERLY:
                dateObj.momentFormat = LanguageService.getDisplayDateFormat();
                return dateObj;

            default:
                dateObj.momentFormat = 'YYYY-MM-DD HH:mm:ss';
                return dateObj;
        }
    }

    /**
     *
     * @param id int
     * @param options Object [Optionnal]
     * @returns {*}
     */
    var get = function(id, options) {
        options = options || {};
        var widgetsRoute = widgets.withHttpConfig(options);
        return widgetsRoute.get(id);
    };

    /**
     * Helper function to format date string based on time_grouping and format type
     * @param dateString
     * @param formatType
     * @param timeGrouping
     */
    function formatDate(dateString, formatType, timeGrouping) {
        if (formatType === DateFormatType.NO_YEAR) {

            // must provide date format: https://stackoverflow.com/a/39005997
            const dateFormat = timeGrouping === TimeGrouping.GROUPING_DAILY
                || timeGrouping === TimeGrouping.GROUPING_QUARTERLY
                ? MomentDateFormat.MONTH_DAY_YEAR_NO_COMMA
                : getDateFormats(timeGrouping).momentFormat;
            const dateMoment = moment(dateString, dateFormat, Locale.EN).locale(gettextCatalog.getCurrentLanguage());
            switch(timeGrouping) {
                case TimeGrouping.GROUPING_MONTHLY:
                    return dateMoment.format('MMM');
                case TimeGrouping.GROUPING_WEEKLY:
                    return dateMoment.format('MMM DD');
                case TimeGrouping.GROUPING_DAILY:
                    return dateMoment.format('MMM DD');
                case TimeGrouping.GROUPING_HOURLY:
                    return dateMoment.format('hh:mmA MMM DD');
                default:
                    return dateString;
            }
        }
        return dateString;
    }

    /**
     *
     * @param column
     * @param row
     * @returns {number}
     */
    function getComparisonDeltaValue(column, row) {

        var previousValue = null;
        var currentValue = null;

        if (column.format === ColumnFormat.FORMAT_TIME) {
            previousValue = moment.duration(row.comparisonValue, 'HH:mm:ss').asSeconds();
            currentValue = moment.duration(row.value, 'HH:mm:ss').asSeconds();
        }
        else {
            previousValue = row.comparisonValue;
            currentValue = row.value;
        }

        let result = currentValue - previousValue;
        if (!column.compare_as_value) {
            result = previousValue !== 0 ? result / Math.abs(previousValue) * 100 : 0;
        }

        return result;
    }

    /**
     * Widget can be deleted
     * @param widget
     * @returns {boolean}
     */
    function canDelete(widget) {
        return widget.can_be_deleted && !widget.is_predefined;
    }

    function _isReportStudioContext() {
        return  !_.isEmpty(ReportStudioTemplateDataService.getReport());
    }

    var publish = function(clonedId, override) {
        return widgets.all('library').all('publish').all(clonedId).post(override);
    }

    /**
     * @param model Object
     * @param options Object [Optional]
     * @returns {*}
     */
    var save = function(model, options) {
        options = options || {};
        var widgetsRoute = widgets.withHttpConfig(options); //TODO handle ignoreLoadingBar in rootscope for walkme...

        // Create copy of model to avoid breaking metadata caused by changes below
        var payload = WidgetUtilService.trimWidgetMetadata(model);

        var promise = _.isNull(payload.id) ? widgetsRoute.post(payload) : widgetsRoute.all(payload.id).post(payload);

        return promise.then(function(newWidgetId) {
            var newWidget = model;
            newWidget.id = newWidgetId;

            WidgetCreateFactory.resetCurrentWidget();

            // If widget was added/edited, it is safe to assume that it can be modified by the same user
            newWidget.can_be_edited = true;
            newWidget.can_be_deleted = true;
            newWidget.can_be_copied = true;

            PubSub.emit($WidgetHeaderEvents.REFRESH_TITLE + newWidget.id);

            return newWidget;
        });
    };

    /**
     * @param widgetToClone
     * @param dataOverride
     * @returns {*}
     */
    var copy = function(widgetToClone, dataOverride) {
        var clonedWidget = angular.copy(widgetToClone);
        return widgets.one('copy').all(clonedWidget.id).post(dataOverride).then(function(json) {
            var newWidget = json.plain();
            // Set new ids and any dependent metadata
            clonedWidget.id = newWidget.id;
            clonedWidget.layout_id = newWidget.layout_id;
            clonedWidget.is_predefined = newWidget.is_predefined;
            clonedWidget.is_in_library = newWidget.is_in_library;
            clonedWidget.created_from_library = newWidget.created_from_library;

            if (clonedWidget.hasOwnProperty('chartId')) {
                delete clonedWidget.chartId; // will reset chart display in the dashboard
            }

            if (clonedWidget.metadata) {
                clonedWidget.metadata.filter_set_id = newWidget.metadata.filter_set_id;
                clonedWidget.metadata.filter_set_name = newWidget.metadata.filter_set_name;
                clonedWidget.metadata.draw_options = newWidget.metadata.draw_options;
                clonedWidget.metadata.dynamic.alerts = [];
            }
            return clonedWidget;
        });
    };

    /**
     * @param model
     * @returns {*}
     */
    var remove = function(model) {
        return widgets.all(model.id).remove();
    };

    /**
     * @delegate method
     * Calls the respective init function for a particular widget type (ex: LineChartFactory.init())
     * @param options
     * @param columns
     * @param widget
     * @returns {*}
     */
    var init = function (options, columns, widget) {
        return WidgetFactoryDelegate.getFactory(widget.type).init(options, columns, widget);
    };

    /**
     * Set the appropriate state params based on widgetType and metadata columns
     * @param state
     * @param widgetType
     * @param metadata
     */
    var setStateForWidgetType = function (state, widgetType, metadata) {

        state.widgetType = getWidgetType(widgetType);

        var typeGroupings = state.widgetType.type_groupings;
        state.isChartWidget =  _.includes(typeGroupings, WidgetTypeGrouping.CHART);
        state.isDataSourcedWidget = _.includes(typeGroupings, WidgetTypeGrouping.DATASOURCED);
        state.isDisplayWidget = _.includes(typeGroupings, WidgetTypeGrouping.DISPLAY);
        state.isAdminWidget = _.includes(typeGroupings, WidgetTypeGrouping.ADMIN);
        state.isSeriesWidget = _.includes(typeGroupings, WidgetTypeGrouping.SERIES);
        state.isChatGptWidget = _.includes(typeGroupings, WidgetTypeGrouping.CHATGPT);

        if (metadata && state.isDataSourcedWidget && !WidgetUtilService.hasMetadataErrors(metadata)) {
            var firstGroupedMetric = _.first(metadata.data_columns.grouped);
            // Set isTimeGroupedWidget state based on grouped columns
            state.isTimeGroupedWidget = !state.isDisplayWidget
                && firstGroupedMetric
                && firstGroupedMetric.is_primary_date_field;
        }
    };

    /**
     * Tells us if exporting a datagrid with the option to display all rows enabled
     * @param metadata
     * @returns {boolean}
     */
    function isExportAllRows(metadata) {
        return DesignFactory.getIsExportingPage() && metadata.draw_options.report_display_length < 0;
    }

    /**
     * @returns {boolean}
     * @param selectedColumns
     */
    function isValidBubbleChartSelectedColumns(selectedColumns) {
        const filtered = _.filter(selectedColumns, function(column) {
            return AppFactory.util.column.isNumeric(column.format) && !AppFactory.util.column.isTime(column.format);
        });
        return filtered.length > 2;
    }

    /**
     *
     * @param data
     * @param metadata
     * @param isComparing
     * @returns {*}
     */
    var evaluateLoadingState = function(data, metadata, isComparing) {
        PubSub.emit($WidgetEvents.DATASOURCED_WIDGET_LOADED);

        var loadingState = LoadingState.BUILDING;

        const isSampleData = useSampleData(metadata) && !WidgetUtilService.hasMetadataErrors(metadata);
        
        if (isSampleData && WidgetUtilService.isAm5Chart(metadata.type, metadata?.draw_options?.plot_type)) {
            if (WidgetUtilService.isBubbleChart(metadata.type) &&
                !isValidBubbleChartSelectedColumns(metadata.data_columns.selected)
            ) {
                return LoadingState.BUBBLE_CHART_REQUIRED_METRICS;
            }
            return LoadingState.DONE;
        } else if (isSampleData) {
            return true;
        }

        //
        // INACTIVE WIDGET
        //
        if (metadata.dynamic && metadata.dynamic.is_inactive) {
            return LoadingState.INACTIVE;
        }

        if (WidgetUtilService.isBubbleChart(metadata.type, metadata?.draw_options?.plot_type) &&
            !isValidBubbleChartSelectedColumns(metadata.data_columns.selected)
        ) {
            return LoadingState.BUBBLE_CHART_REQUIRED_METRICS;
        }

        //
        // ERROR
        //
        if ((_.isString(data) && data === 'error')
            || metadata.dynamic.warnings.length
            || metadata.dynamic.errors.length) {
            return LoadingState.HAS_ERROR;
        }

        // Backend formatted error
        if (data.error) {
            metadata.dynamic.errors = data.data || [];
            metadata.dynamic.retriable_error = data.retriable_error || false;
            return LoadingState.HAS_ERROR;
        }

        //
        // DATA OVERFLOW (TOO MUCH DATA)
        //
        if (data.length > WidgetData.MAX_RESULTS
            && !metadata.geo_code
            && !isExportAllRows(metadata)
            && !metadata.load_all_data) {
            return LoadingState.TOO_MUCH_DATA;
        }

        //
        // NO COLUMNS
        //
        if (_.isEmpty(metadata.data_columns.selected)) {
            // It is possible that a data profile has no columns selected, hence we cannot continue
            loadingState = LoadingState.NO_COLUMNS;
        }

        //
        // NO DATA
        //
        if (loadingState === LoadingState.BUILDING) {
            if (!data.length) {
                loadingState = LoadingState.NO_DATA;
            }
            else {
                var priorDatum;
                var currentDatum;
                let isAllZero = true;
                _.each(data, function (datum) {
                    priorDatum = isComparing ? datum.prior_period : null;
                    currentDatum = isComparing ? datum.current_period : datum;
                    if (!isComparing && (parseInt(currentDatum[ApiDataFields.ROW_GROUPING_COUNT]) !== 0)) {
                        isAllZero = false;
                    }
                    else if ((isComparing || metadata.compare_to_prior_period)
                        && ((!_.isEmpty(currentDatum) && parseInt(currentDatum[ApiDataFields.ROW_GROUPING_COUNT]) !== 0)
                        || (!_.isEmpty(priorDatum) && parseInt(priorDatum[ApiDataFields.ROW_GROUPING_COUNT]) !== 0))) {
                        isAllZero = false;
                    }
                });
                loadingState = isAllZero ? LoadingState.NO_DATA : LoadingState.BUILDING;
            }
        }

        //
        // NO RECORDS AT ALL in the system (can only work with predefined data)
        //
        if (loadingState === LoadingState.BUILDING || loadingState === LoadingState.NO_DATA) {
            var predefinedData = metadata.dynamic ? metadata.dynamic.predefined_data : null;
            if (predefinedData) {
                var extraData = !_.isNull(predefinedData.extra_data) ? predefinedData.extra_data : null;
                if (extraData) {
                    if (parseInt(extraData['total_client_count']) === 0) {
                        loadingState = LoadingState.NO_RECORDS;
                    }
                }
            }
        }

        if (loadingState === LoadingState.BUILDING && WidgetUtilService.isAm5Chart(metadata.type, metadata.draw_options.plot_type)) {
            return LoadingState.DONE;
        }

        return loadingState;
    };

    /**
     * Tells us if we need to enforce displaying the widget loaded state
     * @param loadingState
     * @returns {*}
     */
    var hasNoDataLoadingState = function(loadingState) {

        return loadingState == LoadingState.NO_RECORDS
            || loadingState == LoadingState.NO_DATA
            || loadingState == LoadingState.TOO_MUCH_DATA
            || loadingState == LoadingState.NO_COLUMNS
            || loadingState == LoadingState.INACTIVE
            || loadingState == LoadingState.INCOMPLETE
            || loadingState == LoadingState.HAS_ERROR
            || loadingState == LoadingState.BUBBLE_CHART_REQUIRED_METRICS;
    };

    /**
     * Update widget's width and height to the system
     * @param widgetModel
     * @param widgetModel.id
     * @param widgetModel.width
     * @param widgetModel.height
     */
    function setWidgetDimensions(widgetModel) {
        var widgets = DesignFactory.getCurrentWidgets();
        var newWidth = widgetModel.width;
        var newHeight = widgetModel.height;
        var prevWidth = widgets[widgetModel.id].width;
        var prevHeight = widgets[widgetModel.id].height;

        widgets[widgetModel.id].width = newWidth;
        widgets[widgetModel.id].height = newHeight;

        var $widget = $getElement(widgetModel.id);
        $widget.css({
            'width': WidgetUtilService.getWidgetWidth(newWidth),
            'height': WidgetUtilService.getWidgetHeight(newHeight)
        });

        if (newWidth !== prevWidth || newHeight !== prevHeight) {
            $rootScope.$broadcast($isoEvents.RESIZE);
        }
    }

    /**
     * Returns x/y coordinates of widget's relative position
     */
    function getWidgetPosition(widget) {
        var $widget = $getElement(widget.id);
        return { x: $widget.css('left'), y: $widget.css('top') };
    }

    /**
     * Determines whether or not widget should be showing mock sample data or real data
     * @param metadata
     */
    function useSampleData(metadata) {
        if (
          (_isReportStudioContext() &&
            ReportStudioTemplateDataService.isDemoModeEnabled()) ||
          DesignFactory.isDemoModeEnabled()
        ) {
          return true;
        }

        var drawOptions = metadata.draw_options;
        return drawOptions[DrawOption.FORCE_SAMPLE_DATA]
            || (WidgetBuilderUIService.isActive() && drawOptions[DrawOption.SHOW_SAMPLE_DATA]);
    }

    /**
     * Local implementation of $registerScope
     * @param scope
     */
    function $registerScope(scope) {
        ScopeFactory.$registerScope(scope, getKey(scope.widget.id, scope.widget.is_export));
    }

    /**
     * Returns the current target scope
     * @returns {*}
     */
    function $getScope(id, isExport) {
        return ScopeFactory.$getScope(getKey(id, isExport));
    }

    /**
     * Returns the appropriate widget id key
     * @param id
     * @param isExport
     */
    function getKey(id, isExport) {
        // Check if there is currently a widget in creation
        var isPreview = WidgetCreateFactory.isActive();
        return isPreview
            ? 'widget-preview'
            : (isExport ? ExportBuilderPrefixes.WIDGETID + id : 'widget-' + id);
    }

    /**
     * Returns the DOM element of a specified widget
     * @param id
     * @param isExport Boolean: true if the widget is in the export builder
     */
    var $getElement = function (id, isExport) {
        return angular.element('#' + getKey(id, isExport));
    };

    /**
     * Targets a specific scope using the widgetId to trigger the $on rebuild event in a specific widget
     * @param id
     * @param isExport Boolean: true if the widget is in the export builder
     */
    function $rebuildWidget(id, isExport) {
        // On rebuild always require a little buffer
        $timeout(function() {
            $getScope(id, isExport).$broadcast($WidgetEvents.WIDGET_REBUILD);
        }, 0, false);
    }

    function widgetLoaded(widget) {
        PubSub.emit(PageEvents.PERFORMANCE_TRACKING, {
            event: $WidgetEvents.WIDGET_LOADED,
            payload: {
                id: widget.id,
                type: widget.type,
            }
        })
    }
    /**
     * @param id
     * @param isExport
     * @param status
     */
    function $updateWidgetFetchStatus(id, isExport, status) {
        $timeout(function() {
            $getScope(id, isExport).$broadcast($WidgetEvents.WIDGET_FETCH_STATUS, status);
        }, 0, false);
    }

    /**
     * Returns plain title after stripping html strings
     *
     * @param widgetTitle
     * @returns {*}
     */
    function getWidgetPlainTitle(title) {
        if (title) {
            return _.unescape(title.replace(/<[^>]*>/g, ''));
        }
    }

    function isHTML(string) {
        return /<(\w+)[^>]*>/.test(string);
    }

    function appendOffset(title, offset) {
        const newSpan = document.createElement('span');
        newSpan.style.fontSize = '10px';
        newSpan.textContent = ` [Cont.] - ${offset}`;
        const parser = new DOMParser();
        const doc = parser.parseFromString(title, 'text/html');
        doc.body.firstChild.append(newSpan);
        return doc.body.firstChild.outerHTML;
    }

    return {
        widgets: widgets,
        values: values,
        setWidgetTypes: setWidgetTypes,
        getAllWidgetTypes: getAllWidgetTypes,
        getWidgetTypes: getWidgetTypes,
        getWidgetType: getWidgetType,
        getWidgetTypeGrouping: getWidgetTypeGrouping,
        getData: getData,
        getWidget: getWidget,
        getLayoutWidgets: getLayoutWidgets,
        getDefaultState: getDefaultState,
        getColumnValues: getColumnValues,
        getMetadataColumnValues: getMetadataColumnValues,
        getActiveGroupedColumns: getActiveGroupedColumns,
        getPlotTypes: getPlotTypes,
        getComparisonOptions: getComparisonOptions,
        getSparklineOptions: getSparklineOptions,
        getDateFormats: getDateFormats,
        get: get,
        getComparisonDeltaValue: getComparisonDeltaValue,
        formatDate: formatDate,
        canDelete: canDelete,
        publish: publish,
        save: save,
        copy: copy,
        remove: remove,
        init: init,
        setStateForWidgetType: setStateForWidgetType,
        evaluateLoadingState: evaluateLoadingState,
        hasNoDataLoadingState: hasNoDataLoadingState,
        setWidgetDimensions: setWidgetDimensions,
        getWidgetPosition: getWidgetPosition,
        useSampleData: useSampleData,
        $registerScope: $registerScope,
        $getElement: $getElement,
        $getScope: $getScope,
        $rebuildWidget: $rebuildWidget,
        widgetLoaded: widgetLoaded,
        $updateWidgetFetchStatus: $updateWidgetFetchStatus,
        getMapBoxLayers: getMapBoxLayers,
        getWidgetPlainTitle: getWidgetPlainTitle,
        isHTML: isHTML,
        appendOffset: appendOffset
    };
}

/**
 * Allows to get child factory based on widget type
 * @ngInject
 */
function WidgetFactoryDelegate(
    $injector,
    WidgetType
) {
    /**
     *
     * WARNING: Any delegate function used from a returned factory must be implemented
     * Every factory returnable should have the same function signatures
     * Ex: DataGridFactory.getConfig() ==> BarChartFactory.getConfig()
     * @param widgetType
     * @returns {*}
     */
    var getFactory = function(widgetType) {
        switch (widgetType) {
            case WidgetType.DATAGRID:
                return $injector.get('DataGridFactory');

            case WidgetType.BIGNUMBER:
                return $injector.get('BigNumberFactory');

            case WidgetType.BARCHART:
            case WidgetType.BUBBLECHART:
            case WidgetType.LINECHART:
            case WidgetType.COMBINATIONCHART:
                return $injector.get('SerialChartFactory');

            case WidgetType.GOALCHART:
            case WidgetType.GOAL:
                return $injector.get('GoalFactory');

            case WidgetType.PIECHART:
            case WidgetType.FUNNELCHART:
                return $injector.get('SliceChartFactory');

            case WidgetType.GAUGECHART:
                return $injector.get('GaugeChartFactory');

            case WidgetType.GEOCHART:
                return $injector.get('GeoChartFactory');

            default:
                // return $injector.get('WidgetFactory');
                alert('Unknown widget type');
        }
    };

    return {
        getFactory: getFactory
    };
}

/**
 * @ngInject
 */
function WidgetSortUtilService() {

    this.applySort = applySort;
    this.applyComparisonSort = applyComparisonSort;

    this.updateColumnSort = updateColumnSort;
    this.updateColumnSorting = updateColumnSorting;
    this.ensureColumnOrder = ensureColumnOrder;

    /**
     * Applies the sorting algorithm base on `sort_by` and `sort_order` inside metadata
     * @param data Data to be sorted
     * @param metadata Widget's metadata
     * @return {Array} A new array
     */
    function applySort(data, metadata) {
        try {
            _validateMetadata(metadata);
        } catch (e) {
            return data;
        }

        return _.orderBy(data, metadata.sort_by, metadata.sort_order)
    }

    /**
     * Applies the comparison data sorting algorithm base on `sort_by` and `sort_order` inside metadata
     * @param data Data to be sorted
     * @param metadata Widget's metadata
     * @return {Array} A new array
     */
    function applyComparisonSort(data, metadata) {
        try {
            _validateMetadata(metadata);
        } catch (e) {
            return data;
        }

        var iteratees = _.map(metadata.sort_by, function (sortBy) {
            return function (object) {
                return object.current_period[sortBy] ? object.current_period[sortBy] : object.prior_period[sortBy] ;
            }
        });

        return _.orderBy(data, iteratees, metadata.sort_order);
    }

    /**
     * @param metadata          Widget's metadata
     * @param column            Column wanting to sort
     * @param isMultiSorting
     */
    function updateColumnSort(metadata, column, isMultiSorting) {
        if (isMultiSorting) {
            var index = _.indexOf(metadata.sort_by, column.field);
            ensureColumnOrder(metadata, index);
            metadata.sort_by.push(column.field);
            metadata.sort_order.push(column.sorting);
        }
        // Single sort
        else {
            metadata.sort_by = [column.field];
            metadata.sort_order = [column.sorting];
        }
        updateColumnSorting(metadata);
    }

    /**
     * Helper function to update sorting field on selected columns
     * @param metadata
     */
    function updateColumnSorting(metadata) {
        if (!metadata.sort_by) {
            return;
        }

        var orderObj = _getOrderObj(metadata);
        var selectedColumns = metadata.data_columns.selected;
        var groupedColumns = metadata.data_columns.grouped;
        _.each(selectedColumns, _updateColumnFn);
        _.each(groupedColumns, _updateColumnFn);

        /**
         * Update's column with proper sorting
         * @param selectedColumn
         */
        function _updateColumnFn(selectedColumn) {
            if (orderObj[selectedColumn.field]) {
                selectedColumn.sorting = orderObj[selectedColumn.field].toLowerCase();
            }
            else {
                selectedColumn.sorting = false;
            }
        }
    }

    /**
     * If column is selected before, remove it and push it to the end to maintain order
     * @param index
     */
    function ensureColumnOrder(metadata, index) {
        if (index >= 0) {
            _.remove(metadata.sort_by, function(field, i) {
                return index === i;
            });
            _.remove(metadata.sort_order, function(dir, i) {
                return index === i;
            });
        }
    }

    /**
     * Helper function to get order object {field: 'asc' / 'desc'}
     * @param metadata
     * @returns {{}}
     */
    function _getOrderObj(metadata) {
        var sortBy = metadata.sort_by;
        var sortOrder = metadata.sort_order;
        var sortObj = {};

        if (sortBy.length === sortOrder.length) {
            _.each(sortBy, function(field, index) {
                sortObj[field] = sortOrder[index];
            });
        } else {
            console.error('Length of sortBy should equal to length of sortOrder. Something went wrong!');
        }

        return sortObj;
    }

    /**
     * Validates if metadata contains required metadata properties
     * @param metadata
     * @private
     */
    function _validateMetadata(metadata) {
        if (!metadata.sort_by
            || !metadata.sort_order
            || metadata.sort_by.length !== metadata.sort_order.length) {
            throw {};
        }
    }
}

/**
 *
 * @ngInject
 */
function WidgetQueryParamService(
    WidgetSearchFilter
) {
    return {
        sanitizeSearchString: sanitizeSearchString
    };

    /**
     * This function replaces any reserved character used by the API
     * in its query param operations with some unique constant.
     * @param search
     */
    function sanitizeSearchString(search) {
        return search.replace(/\|/g, WidgetSearchFilter.LIKE_VALUE);
    }
}
