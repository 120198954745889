import angular from 'angular';
import _ from 'lodash';

import {
    WidgetBuilderConstants
} from 'coreModules/design/widget/builder/widget.builder.constants';

import {
    DataView,
    DataSourceType,
    PostProcessType,
} from 'coreModules/shared/scripts/app.constants';

import {
    WidgetType,
    WidgetSize,
    TimeGrouping,
} from 'coreModules/design/widget/design.widget.constants';

import WidgetBuilderUtilService from "coreModules/design/widget/builder/base/WidgetBuilderUtilService";
import {RelativeDateRange} from "coreModules/daterange/base/daterange.constants";
import {SparklineOptions} from "../../design.widget.constants";

angular.module('widget.builder.models')
    .factory('WidgetBuilderDataModelFactory', WidgetBuilderDataModelFactory);

/**
 * @ngInject
 */
function WidgetBuilderDataModelFactory(
    gettextCatalog,
    AppFactory,
    CategoryFactory,
    UIColor,
    ApiDataFields,
    AppModelFactory,
    DataSourceFactory,
    WidgetUtilService,
    DashboardContextService,
    ServiceFactory,
    ServiceDataFactory,
    CategoryDataViewFactory,
    WidgetBuilderStylesModelFactory,
    $injector,
    ReportStudioTemplateDataService,
    ClientFactory,
    ServiceOrderItemFactory,
    MappingCandidateHistoryFactory,
    FilterParam,
    ColumnFormat,
    WidgetFactory,
    ComparisonOptions,
    SparklineOptions,
    ChartFactory,
    WidgetTypeGrouping
) {
    const _dataTypes = AppFactory.getDataTypes();
    const _keyedDataTypes = AppFactory.arrayToMemoizedObj(_dataTypes, 'key');
    const _columnUtil = AppFactory.util.column;

    return {
        getTabState: getTabState,
        getTabData: getTabData,
        getTabAdminData: getTabAdminData,
        getWidgetBuilderModel: getWidgetBuilderModel,
        getAdminWidgetBuilderModel: getAdminWidgetBuilderModel,
        getMediaWidgetBuilderModel: getMediaWidgetBuilderModel,
        getChatGptWidgetBuilderModel: getChatGptWidgetBuilderModel,
        getDataSourceModel: getDataSourceModel,
        getDataViewModel: getDataViewModel,
        getItemFilterModel: getItemFilterModel,
        getDataViewSelect: getDataViewSelect,
        getWidgetAssignmentSelect: getWidgetAssignmentSelect,
        getLiveFormulaSelect: getLiveFormulaSelect,
        getSelectedColumnsSelect: getSelectedColumnsSelect,
        getGroupedColumnsSelect: getGroupedColumnsSelect,
        getLiveFormulaItemSelect: getLiveFormulaItemSelect,
        getLiveStaticFilterItemSelect: getLiveStaticFilterItemSelect,
        getFilterColumnsSelect: getFilterColumnsSelect,
        getFilterSetModel: getFilterSetModel,
        getTextFilterValuesModel: getTextFilterValuesModel,
        getSelectedAssignmentsFromWidget: getSelectedAssignmentsFromWidget,
        getExecutiveSummaryWidgetBuilderModel: getExecutiveSummaryWidgetBuilderModel,
    };

    /**
     * @constructor
     */
    function WidgetBuilderDataTabState() {
        let self = this;

        self.isActive = false;
        self.isLoading = false;
        self.isConfiguringColumns = false;
    }

    /**
     * @param {WidgetBuilderModel} widgetModel
     * @param selectedEntity
     * @constructor
     */
    async function WidgetBuilderDataTabData(widgetModel, selectedEntity) {
        let self = this;
        let dataSource = widgetModel
        ? widgetModel.metadata.data_source
        : null;

        let liveIntegration = widgetModel
        ? widgetModel.has_live_integration
        : false;

        self.title = widgetModel
        ? widgetModel.title
        : gettextCatalog.getString('Untitled Widget');
        let dataSourceParams = selectedEntity;

        if (liveIntegration) {
            dataSourceParams.has_live_integration = 1;
        }

        self.dataSourceSelect = await new DataSourceSelect(
            dataSource,
            dataSourceParams
        );

        if (DataSourceFactory.dataSourceContainsServices(self.dataSourceSelect.selectedValue.type)) {
            self.dataViewSelect = new DataViewSelect(
                new DataSourceModel(
                    widgetModel
                        ? widgetModel.metadata.data_source
                        : self.dataSourceSelect.selectedValue,
                    widgetModel
                        ? new DataViewModel({
                            id: widgetModel.metadata.data_source.data_view,
                            name: widgetModel.metadata.data_source.data_view_name
                        })
                        : undefined
                ),
                widgetModel
                    ? widgetModel.has_live_integration
                    : false,
                widgetModel ? WidgetUtilService.isGeoChart(widgetModel.metadata.type) : false);

            if (widgetModel && widgetModel.has_live_integration) {
                self.widgetAssignmentSelect = await new WidgetAssignmentSelect(
                    new DataSourceModel(
                        widgetModel
                            ? widgetModel.metadata.data_source
                            : self.dataSourceSelect.selectedValue,
                        widgetModel
                            ? new DataViewModel({
                                id: widgetModel.metadata.data_source.data_view,
                                name: widgetModel.metadata.data_source.data_view_name
                            })
                            : undefined
                    ),
                    widgetModel
                        ? widgetModel.assignments
                        : null);

                self.filtersColumnsSelect = await new FiltersColumnsSelect(
                    new DataSourceModel(
                        widgetModel
                            ? widgetModel.metadata.data_source
                            : self.dataSourceSelect.selectedValue,
                        widgetModel
                            ? new DataViewModel({
                                id: widgetModel.metadata.data_source.data_view,
                                name: widgetModel.metadata.data_source.data_view_name
                            })
                            : undefined
                    ),
                    widgetModel
                        ? widgetModel.metadata.dynamic.filters
                        : null,
                    widgetModel
                        ? widgetModel.type
                        : null,
                    widgetModel
                        ? widgetModel.has_live_integration
                        : true,
                    widgetModel
                        ? widgetModel.assignments
                        : getSelectedAssignmentsFromWidget(self.widgetAssignmentSelect)
                );

                self.liveFormulaSelect = new LiveFormulaSelect(
                    widgetModel
                        ? widgetModel.metadata.data_columns.selected
                        : null,
                    widgetModel
                );
            }

        } else {
            self.dataViewSelect = null;
        }

        self.selectedColumnsSelect = new SelectedColumnsSelect(
            widgetModel
                ? widgetModel.metadata.data_source
                : self.dataSourceSelect.selectedValue,
            widgetModel
                ? widgetModel.metadata.data_columns.selected
                : null,
            widgetModel
                ? widgetModel.type
                : null,
            widgetModel
                ? widgetModel.has_live_integration
                : false,
            widgetModel
                ? widgetModel.assignments
                : getSelectedAssignmentsFromWidget(self.widgetAssignmentSelect),
            false,
            widgetModel
                ? widgetModel.metadata?.data_columns?.benchmarks
                : [],
        );

        self.selectedBenchmarkColumnsSelect = self.selectedColumnsSelect.benchmarkSelector;

        self.groupedColumnsSelect = new GroupedColumnsSelect(
            widgetModel
                ? widgetModel.metadata.data_source
                : self.dataSourceSelect.selectedValue,
            widgetModel
                ? widgetModel.metadata.data_columns.grouped
                : null,
            widgetModel
                ? widgetModel.has_live_integration
                : false,
            widgetModel
                ? widgetModel.assignments
                : getSelectedAssignmentsFromWidget(self.widgetAssignmentSelect),
            false
        );

        self.comparisonOption = {};
        self.comparisonOption.values = [];
        WidgetFactory.getComparisonOptions().then(function (data) {
            _.each(data.plain(), function(plotType) {
                self.comparisonOption.values.push(plotType);
            });
        });

        self.sparklineOption = {};
        self.sparklineOption.values = [];
        WidgetFactory.getSparklineOptions().then(function (data) {
            _.each(data.plain(), function(plotType) {
                self.sparklineOption.values.push(plotType);
            });
        });

        self.comparisonOption.value = widgetModel ? widgetModel.metadata.comparison_option : ComparisonOptions.MONTH_OVER_MONTH;
        self.sparklineOption.value = widgetModel ? widgetModel.metadata.sparkline_option : SparklineOptions.LAST_3_MONTHS;
        return self;
    }

    /**
     * Get the assignments from widget assignment select
     * If selectedValues are present then join them
     * selectedValues: [{id: 'a123', text: 'a'}, {id: 'a345', text: 'b'}]
     * result: 'a123,a345'
     * @param widgetAssignmentSelect
     * @returns {string|null}
     */
    function getSelectedAssignmentsFromWidget(widgetAssignmentSelect) {
        let selectedValues = widgetAssignmentSelect?.selectedValues;
        if (!selectedValues) return null;
        if (!_.isArray(selectedValues)) {
            selectedValues = [selectedValues];
        }
        return _.join(_.map(selectedValues, 'id'))
    }



    /**
     * @param {WidgetBuilderModel} widgetModel
     * @constructor
     */
    function WidgetBuilderAdminDataTabData(widgetModel) {
        let self = this;

        self.title = widgetModel
            ? widgetModel.title
            : gettextCatalog.getString('Untitled Widget');

        self.selectedColumnsSelect = new SelectedColumnsSelect(
            new AdminDataSourceModel(),
            widgetModel
                ? widgetModel.metadata.data_columns.selected
                : null,
            WidgetTypeGrouping.ADMIN
        );
    }

    /**
     * The widget being added or edited
     *
     * @constructor
     */
    function WidgetBuilderModel(model = {}) {
        let self = this;

        // Load new widget with sensible defaults for quick start creation
        self.id = model.id || WidgetBuilderConstants.NEW_WIDGET_ID;
        self.layout_id = model.layout_id || null;
        self.report_id = model.report_id || null;
        self.type = model.type || WidgetType.BARCHART;
        self.width = model.width || WidgetSize.MAX_WIDTH;
        self.height = model.height || WidgetSize.PREVIEW_HEIGHT;
        self.display_order = model.display_order || Number.MAX_SAFE_INTEGER;
        self.title = model.title || gettextCatalog.getString('Untitled Widget');
        self.is_predefined = model.is_predefined || false;
        self.is_in_library = model.is_in_library || false;
        self.created_from_library = model.created_from_library || false;
        self.library_widget_clicked = model.library_widget_clicked || false;
        self.metadata = new _WidgetMetadataModel(model.metadata, model.has_live_integration);
        self.assignments = model.assignments || null;
        self.metadata.type = model.type;
        self.has_live_integration = model.has_live_integration || false;
        self.can_be_edited = model.can_be_edited || false;
        self.can_be_deleted = model.can_be_deleted || false;
        self.can_be_copied = model.can_be_copied || false;
        self.data_view_id = model.data_view_id;
        self.data_type = model.data_type;
    }

    /**
     * @param model
     * @param has_live_integration
     * @private
     * @constructor
     */
    function _WidgetMetadataModel(model = {}, has_live_integration = false) {
        let self = this;

        self.id = model.id || null;
        self.description = model.description || null;
        self.tags = model.tags || null;
        self.data_source = model.data_source
            ? new DataSourceModel(
                model.data_source,
                model.data_source ? new DataViewModel({
                    id: model.data_source.data_view,
                    name: model.data_source.data_view_name,
                    is_geo: model.data_source.data_view_is_geo || !!model.geo_code
                }) : undefined
            )
            : null;
        self.data_columns = new DataColumnsModel(model.data_columns);
        self.draw_options = WidgetBuilderStylesModelFactory.getDrawOptionsModel(model.draw_options);
        self.chart_palette = model.chart_palette || null;
        self.time_grouping = model.time_grouping || TimeGrouping.GROUPING_DAILY;
        self.weekly_start_day = model.weekly_start_day || AppFactory.getUser().getDefaultWeeklyStartDay();
        self.is_multi_grouped = model.is_multi_grouped || false;
        self.export_options = model.export_options || null;
        self.move_y_axes = model.move_y_axes || {};
        self.sort_by = model.sort_by || false;
        self.sort_order = model.sort_order || false;
        self.load_all_data = model.load_all_data || false;
        self.line_columns = model.line_columns || {};
        self.thumbnail_metadata = model.thumbnail_metadata || null;
        self.is_predefined = model.is_predefined || false;
        self.dynamic = new _DynamicPropsModel(model.dynamic);
        self.filter_set_id = model.filter_set_id || null;
        self.map_id = model.map_id || null;
        self.geo_code = model.geo_code || null;
        self.map_zoom = model.map_zoom || null;

        self.rounded_metrics = model.rounded_metrics || [];

        self.relative_date_range = model.relative_date_range || RelativeDateRange.DEFAULT;
        self.is_overriding_date_range = model.is_overriding_date_range || false;
        self.start_date_override = model.start_date_override || null;
        self.end_date_override = model.end_date_override || null;
        self.compare_to_prior_period = model.compare_to_prior_period || false;
        self.comparison_start_date_override = model.comparison_start_date_override || null;
        self.comparison_end_date_override = model.comparison_end_date_override || null;
        self.comparison_relative_date_range = model.comparison_relative_date_range || RelativeDateRange.PRIOR_PERIOD;
        self.ignore_date_range = model.ignore_date_range || false;
        self.comparison_option = model.comparison_option || ComparisonOptions.MONTH_OVER_MONTH;
        self.sparkline_option = model.sparkline_option || SparklineOptions.LAST_3_MONTHS;
        self.comparison_overwrite = model.comparison_overwrite || [];
        self.user_grouped_columns = model.user_grouped_columns || [];
        self.heatmap_gradient = model.heatmap_gradient || [];
        self.user_conditional_columns = model.user_conditional_columns || [];
        self.fields_to_embed_sparklines = model.fields_to_embed_sparklines || [];
        self.widget_products = model.widget_products || [];
        self.mapbox_position = model.mapbox_position;
        self.show_overflow_message = model.show_overflow_message || false;
        self.product_widget_can_be_accessed = model.product_widget_can_be_accessed || true;
        if (has_live_integration) {
            // Adding these fields only for On Demand widget
            self.live_formula_fields = model.live_formula_fields || [];
            self.live_formulas = model.live_formulas || [];
        }
        self.benchmark = fieldsOnly(model.data_columns?.benchmarks);
    }

    function fieldsOnly(columns) {
        return columns ? columns.map(column => column.field || column) : [];
    }

    /**
     * @param model
     * @private
     * @constructor
     */
    function AdminWidgetBuilderModel(model = {}) {
        let self = _.assign(this, new WidgetBuilderModel(model));

        self.type = model.type || WidgetType.ACCOUNTMANAGER;
        self.metadata.data_source = self.metadata.data_source && self.metadata.data_source.type
            ? self.metadata.data_source
            : new AdminDataSourceModel({});

        self.metadata.admin_source = self.metadata.admin_source || {
            id: 'account_manager_user_id',
            text: gettextCatalog.getString('Account Manager')
        };
    }

    /**
     *
     * @param model
     * @constructor
     */
    function MediaWidgetBuilderModel(model = {}) {
        let self = _.assign(this, new WidgetBuilderModel(model));
        self.type = model.type || WidgetType.MEDIA;
        self.metadata.content = model.metadata.content || '';
    }

    function ChatGptWidgetBuilderModel(model = {}) {
        let self = _.assign(this, new WidgetBuilderModel(model));
        self.type = model.type || WidgetType.CHATGPT;
    }

    function ExecutiveSummaryWidgetBuilderModel(model = {}) {
        let self = _.assign(this, new WidgetBuilderModel(model));
        self.type = model.type || WidgetType.EXECUTIVESUMMARY;
        self.metadata.type = model.type || WidgetType.EXECUTIVESUMMARY;
        self.title = gettextCatalog.getString('Executive Summary Widget');
    }

    /**
     * @param dataSource
     * @param {DataViewModel} dataView
     * @constructor
     */
    function DataSourceModel(dataSource = {}, dataView = new DataViewModel()) {
        let self = this;

        self.type = dataSource.type || null;
        self.id = dataSource.id || null;
        self.name = dataSource.name || null;
        self.id_name = dataSource.id_name || null;
        self.icon = dataSource.icon || null;
        self.data_view = dataView.id || null;
        self.data_view_name = dataView.name || null;
        self.data_view_is_geo = dataView.is_geo || false;
        self.has_custom_icon = dataSource.has_custom_icon || null;
        self.color = dataSource.color || null;
        self.requires_group_by = _.isNil(dataSource.requires_group_by) ? true : dataSource.requires_group_by;
        self.requires_date_range = _.isNil(dataSource.requires_date_range) ? true : dataSource.requires_date_range;
        self.is_of_type_service = _.isNil(dataSource.is_of_type_service) ? true : dataSource.is_of_type_service;
        self.primary_date_field = dataSource.primary_date_field;
        self.include_num_entities = dataSource.include_num_entities || null;
        self.include_data_freshness_date = dataSource.include_data_freshness_date || null;
        self.is_custom_service = dataSource.is_custom_service || false;
        self.geo_columns = dataSource.geo_columns || dataView.geo_columns || null;
        self.mapbox_config = dataSource.mapbox_config || [];
        self.live_assignments_threshold = dataSource.live_assignments_threshold;
    }

    /**
     * @param dataSource
     * @param {DataViewModel} dataView
     * @constructor
     */
    function AdminDataSourceModel(dataSource = {}) {
        let self = _.assign(this, new DataSourceModel(dataSource));

        self.type = DataSourceType.USER;
        self.name = gettextCatalog.getString('Admin Widget');
        self.icon = 'icomoon-admin-widget';
    }

    /**
     * @param model
     * @constructor
     */
    function DataViewModel(model = {}) {
        let self = this;

        self.id = model.id || null;
        self.name = model.name || null;
        self.is_geo = model.is_geo || null;
        self.geo_columns = model.geo_columns || null;
    }

    /**
     * @param model
     * @constructor
     */
    function DataColumnsModel(model = {}) {
        let self = this;

        self.selected = model.selected || [];
        self.grouped = model.grouped || [];
        self.benchmarks = model.benchmarks || [];
    }

    /**
     * @param model
     * @constructor
     */
    function ItemFilterModel(model = {}) {
        let self = this;

        self.field = model.field;
        self.label = model.label;
        self.groupby_id_field = model.label;
        self.format = model.format;
        self.type = model.type || getType(model.is_metric);
        self.is_metric = model.is_metric;
        self.association = model.association || FilterParam.ASSOCIATION_AND;
        self.expose_as_dash_filter = model.expose_as_dash_filter || false;
        self.order = model.order;
        self.values = model.values || null;
        self.warnings = model.warnings || [];
        self.errors = model.errors || [];
        self.limit_available_values = model.limit_available_values;
        self.is_enum = model.is_enum || model.has_set_values;

        function getType(isMetric) {
            return isMetric ? FilterParam.FILTER_EQUAL : FilterParam.FILTER_IN;
        }
    }


    /**
     * @param model
     * @constructor
     */
    function TextFilterValuesModel(field) {
        let self = this;

        self.id = field || null;
        self.text = field || null;
        self.key = field || null;
    }


    /**
     * @param model
     * @constructor
     */
    function FilterSetModel(model = {}) {
        let self = this;

        self.name = model.name;
        self.id = model.id || null,
        self.data_source = model.datasource;
        self.filters = model.filters;
        self.widget_id = model.widget_id || null;
        self.is_live_integration = model.is_live_integration || false;
    }

    /**
     * @param model
     * @constructor
     * @private
     */
    function _DynamicPropsModel(model = {}) {
        let self = this;

        self.is_inactive = model.is_inactive || false;
        self.filters = model.filters || [];
        self.alerts = model.alerts || [];
        self.annotations = model.annotations || [];
        self.predefined_data = model.predefined_data || null;
        self.predefined_filters = model.predefined_filters || null;
        self.warnings = model.warnings || [];
        self.errors = model.errors || [];
        self.is_live_filter = model.is_live_filter || false;
    }

    /**
     * @param {DataSourceModel} selectDataSource
     * @param {} params
     * @constructor
     * @private
     */
    async function DataSourceSelect(selectDataSource = null, params) {
        let self = this;
        self.isLoading = true;
        self.options = AppModelFactory.getSelectOptions(new _DataSourceOptions());
        self.getSelectValues = getDataSourceSelectValues;

        await self.getSelectValues(selectDataSource, params);

        return self;
    }

    /**
     * @param selectDataSource
     * @param params
     * @constructor
     */
    async function getDataSourceSelectValues(selectDataSource, params) {
        let self = this;
        let values = {};
        let dataSourceTypes = _.omit(AppFactory.getDataSourceTypes(), [DataSourceType.SERVICE_DATA, DataSourceType.CATEGORY_DATA, DataSourceType.SEO_WEBSITES]);

        const extraParams = {};
        if (!DashboardContextService.isDemoModeEnabled()) {
            extraParams.is_connected = true;
        }
        if (!params.has_live_integration) {
            extraParams.include_cdp_services = 1;
        }

        const queryParams = _.extend(params, extraParams);

        self.promise = ServiceFactory.getServices(queryParams);
        let services = await self.promise;
        ServiceFactory.setEntityServices(services);
        if (queryParams.has_live_integration && services) {
            services = services.filter((service) => !service.name?.includes('(Deprecated)'));
        }
        let connectedCategories = [];
        if (!queryParams.has_live_integration) {
            connectedCategories = AppFactory.getConnectedCategories();
            if (params.client_id) {
                let categories = await CategoryFactory.getCategoriesForClients({client_ids: params.client_id, sort: 'name'});
                categories =  _.map(categories, 'id');
                connectedCategories = _.filter(connectedCategories, category => _.indexOf(categories, category.id) !== -1);
            }
            if (params.client_group_id) {
                let categories = await CategoryFactory.getCategoriesForClients({client_group_id: params.client_group_id, sort: 'name'});
                categories =  _.map(categories, 'id');
                connectedCategories = _.filter(connectedCategories, category => _.indexOf(categories, category.id) !== -1);
            }
        }
        let filteredDataSourceTypes = queryParams.has_live_integration ? [] : dataSourceTypes;

        values[DataSourceType.SERVICE_DATA] = _.map(services, service => {
            service = AppFactory.mapService(service);
            service.group = DataSourceType.SERVICE_DATA;
            return service;
        });
        values[DataSourceType.CATEGORY_DATA] = _mapDataSourceSelectValues(connectedCategories, DataSourceType.CATEGORY_DATA);
        const websites = AppFactory.getSeoWebsites();
        const clientWebsites = websites.filter( website => website.clientId == params['client_id'] );
        values[DataSourceType.SEO_WEBSITES] = _mapSeoWebsiteDataSourceSelectValues(clientWebsites);
        values[DataSourceType.OTHER] = _mapOtherDataSourceSelectValues(filteredDataSourceTypes);

        self.values = _groupDataSourceValues(values);
        self.selectedValue = _getDataSourceValue(values, selectDataSource);
    }

    /**
     * @param data
     * @returns {*[]}
     * @private
     */
    function _groupDataSourceValues(data) {
        let groups = [{text: gettextCatalog.getString('Data Sources'), children: data[DataSourceType.SERVICE_DATA]}];

        if (!_.isEmpty(data[DataSourceType.CATEGORY_DATA])) {
            groups.push({text: gettextCatalog.getString('Channels'), children: data[DataSourceType.CATEGORY_DATA]});
        }
        if (!_.isEmpty(data[DataSourceType.SEO_WEBSITES])) {
            groups.push({text: gettextCatalog.getString('SEO Websites'), children: data[DataSourceType.SEO_WEBSITES]});
        }
        if (!_.isEmpty(data[DataSourceType.OTHER])) {
            groups.push({text: gettextCatalog.getString('Other Sources'), children: data[DataSourceType.OTHER]});
        }

        return groups;
    }

    /**
     * @param values
     * @param selectedDataSource
     * @returns {*}
     * @private
     */
    function _getDataSourceValue(values, selectedDataSource = null) {
        if (selectedDataSource) {
            let matchingDataSource;
            if (_.includes([DataSourceType.SERVICE_DATA, DataSourceType.CATEGORY_DATA, DataSourceType.SEO_WEBSITES], selectedDataSource.type)) {
                matchingDataSource = _.find(values[selectedDataSource.type], {id: selectedDataSource.id});
            } else {
                matchingDataSource = _.find(values[DataSourceType.OTHER], {type: selectedDataSource.type});
            }
            if (matchingDataSource) {
                return matchingDataSource;
            }
        }
        const dataSourceTypes = [
            DataSourceType.SERVICE_DATA,
            DataSourceType.SEO_WEBSITES,
        ];

        return dataSourceTypes
            .map(dataSourceType => _.first(values[dataSourceType]))
            .find(dataSource => !!dataSource);
    }

    /**
     * @param selectedDataSource
     * @param liveIntegration
     * @param isGeoType
     * @constructor
     */
    function DataViewSelect(selectedDataSource = null, liveIntegration = false, isGeoType = false) {
        let self = this;
        let params = {};

        if (liveIntegration) {
            params.has_live_integration = 1;
        }

        if (!_.isEmpty(ReportStudioTemplateDataService.getReport())){
            const reportLanguage = ReportStudioTemplateDataService.getReportLanguage();
            _.assign(params, {lang: reportLanguage});
        }

        self.values = [];
        self.selectedValue = {};

        let isCategoryDataSource = DataSourceFactory.isCategoryDataSourceType(selectedDataSource.type);
        let isSeoWebsiteDataSource = DataSourceFactory.isSeoWebsiteDataSourceType(selectedDataSource.type);

        let dataSourceId = selectedDataSource
            ? selectedDataSource.id
            : AppFactory.getFirstConnectedService().id;

        let promise;
        if (isCategoryDataSource) {
            params.strict = 1;
            params.fields = "id,name,is_geo,parent";
            promise = CategoryDataViewFactory.categoryDataView(dataSourceId).getList(params);
        } else if (isSeoWebsiteDataSource) {
           promise = $injector.get('SeoWebsitesFactory').dataViews().get(params);
        } else {
            // todo: @ekansh.s fix this performance issue.
            params.geo_columns = 1;
            promise = ServiceDataFactory.dataViews(dataSourceId).get(params);
        }

        self.promise = promise.then(function (dataViews) {
            self.values = _mapDataViewSelectValues(dataViews.plain());
            self.selectedValue = _.find(self.values, {
                id: selectedDataSource.data_view || DataView.CGN
            });

            if (isCategoryDataSource && !self.selectedValue) {
                self.selectedValue = _.find(self.values, {parent: null});
            }
            if (isSeoWebsiteDataSource && !self.selectedValue) {
                self.selectedValue = _.first(self.values);
            }

            // Update data source after setting data view
            selectedDataSource = getDataSourceModel(selectedDataSource, self.selectedValue)
        });
        self.options = AppModelFactory.getSelectOptions(new _DataViewOptions());
    }

    /**
     * Check the assigned client / client group for the dashboard
     * or client for the report
     * or client / client group / business unit filter for dashboard or report and
     * get the corresponding client ids to fetch the service order items
     *
     * @returns {Promise<string|null|*>}
     */
    async function getClientIdsToFetchServiceOrderItems() {
        let pageEntityQueryParam = DashboardContextService.resolvePageEntityQueryParam();
        if (_.isEmpty(pageEntityQueryParam)) {
            return null;
        }
        if (pageEntityQueryParam.client_id) {
            return pageEntityQueryParam.client_id;
        }

        let selectedEntity = DashboardContextService.resolveSelectedEntity();
        if (selectedEntity?.client_ids) {
            return _.join(selectedEntity.client_ids, ',');
        }

        let queryParams;
        if (pageEntityQueryParam.client_group_id) {
            queryParams = {
                client_group_ids: pageEntityQueryParam.client_group_id,
            };
        } else if (pageEntityQueryParam.cluster_id) {
            queryParams = {
                cluster_id: pageEntityQueryParam.cluster_id,
            };
        }
        const clientsData = await ClientFactory.getData(queryParams);
        return _.join(_.map(clientsData, 'id'), ',');
    }

    /**
     *
     * @param selectedDataSource
     * @param selectedAssignments
     * @returns {WidgetAssignmentSelect}
     * @constructor
     */
    async function WidgetAssignmentSelect(selectedDataSource, selectedAssignments) {
        let self = this;
        const dataSource = selectedDataSource || AppFactory.getFirstConnectedService();
        const isMultiSelect = dataSource.live_assignments_threshold > 1;
        self.options = AppModelFactory.getSelectOptions(new _WidgetAssignmentsOptions(isMultiSelect));
        self.values = [];
        self.selectedValues = [];

        let dataSourceId = dataSource.id;
        let data;
        const customer_id = await getClientIdsToFetchServiceOrderItems();
        let baseParams = {service_id: dataSourceId, has_live_integration: 1, include_account_label: true, get_active_records: true};
        let params = customer_id ? {...baseParams, customer_id} : baseParams;
        data = await ServiceOrderItemFactory.getData(params);
        self.values = _mapServiceOrderItemsSelectValues(data, dataSourceId);

        let selectedValues;

        let missingAssignments = [];
        if (selectedAssignments) {
            selectedAssignments = selectedAssignments.split(',');
            selectedValues = _.filter(self.values, (item) => selectedAssignments.includes(item.id));
            missingAssignments = _.difference(selectedAssignments, selectedValues.map((value) => value.id));
        }

        if (missingAssignments.length > 0) {
            selectedValues = selectedValues.concat(await _getInvalidSelectedLiveAssignments(missingAssignments, baseParams, selectedValues));
        }

        if (selectedValues) {
            if (isMultiSelect && !_.isArray(selectedValues)) {
                selectedValues = [selectedValues];
            } else if (!isMultiSelect && _.isArray(selectedValues)) {
                selectedValues = selectedValues[0];
            }
        } else {
            selectedValues = null;
        }

        self.selectedValues = selectedValues;

        return self;
    }

    /**
     * If the assignments are not present in the current list of valid service order items
     * then fetch them from Service order items / Mapping Candidate Itemssss
     * @param missingAssignments
     * @param baseParams
     * @returns {Promise<*[]>}
     * @private
     */
    async function _getInvalidSelectedLiveAssignments(missingAssignments, baseParams) {
        if (missingAssignments.length <= 0) return [];
        let invalidSelectedAssignments = [];
        let params;
        let data;

        // If the assignments are not present in the current list of valid service order items
        // then fetch the service order items corresponding to the missing assignments
        params = { ...baseParams, account_id: missingAssignments.join(',')};
        data = await ServiceOrderItemFactory.getData(params);
        invalidSelectedAssignments = _mapServiceOrderItemsSelectValues(data, baseParams.service_id, true);
        missingAssignments = _.difference(missingAssignments, invalidSelectedAssignments.map((value) => value.id));

        if (missingAssignments.length > 0) {
            // If the assignments are still not present in the service order items (if client mapping is removed for the assignments)
            // then fetch the mapping candidate items corresponding to the missing assignments
            params = {service_id: baseParams.service_id, item_id: missingAssignments.join(',')};
            data = await MappingCandidateHistoryFactory.getData(params);
            invalidSelectedAssignments = invalidSelectedAssignments.concat(_mapServiceOrderItemsSelectValues(data, baseParams.service_id, true));
        }
        return invalidSelectedAssignments;
    }

    /**
     * Benchmark selector has a requirement of the columns selector having loaded its values
     * @constructor
     */
    function SelectedBenchmarkColumnsSelect() {
        let self = this;

        self.values = [];
        self.selectedValues = [];

        self.options = AppModelFactory.getSelectOptions(new _ColumnsSelectOptions());
        self.options.$elSelector = '#widget-builder-panel div.selected-benchmark-columns div.select2-container';
        self.options.placeholder = gettextCatalog.getString('Select a benchmark metric...');
        self.options.formatNoMatches = function (value) {
            if (value === '') {
                return '';
            }
            return gettextCatalog.getString('None left') + ': <span>' + value + '</span>';
        };
    }

    /**
     * @param dataSource
     * @param selectedColumns
     * @param widgetType
     * @param liveIntegration
     * @param assignments
     * @param removeInvalidLiveFields
     * @param selectedBenchmarks
     * @constructor
     */
    function SelectedColumnsSelect(dataSource, selectedColumns = null, widgetType = null, liveIntegration = false, assignments = null, removeInvalidLiveFields = true, selectedBenchmarks = []) {
        let self = this;

        self.values = [];
        self.selectedValues = [];
        self.benchmarkSelector = new SelectedBenchmarkColumnsSelect();

        let queryParams = {
            is_hidden: false,
            widget_request: true,
        };

        if (liveIntegration) {
            queryParams.has_live_integration = 1;
            if (assignments) {
                queryParams.widget_assignments = assignments;
            }
        }

        const dashboardClientId = DashboardContextService.resolveSelectedEntityIdforType();
        self.promise = DataSourceFactory.getColumns(dataSource, queryParams).then(function (columns) {
            const benchmarkColumns = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].format === ColumnFormat.FORMAT_CURRENCY) {
                    columns[index].groupby_field_format = columns[index].format;
                }
                if (columns[index].has_benchmark) {
                    benchmarkColumns.push(columns[index]);
                }

                if (!isMetricClientCompatible(dashboardClientId, columns[index])) {
                    columns.splice(index, 1);
                    index--;
                }
                if (
                  widgetType === WidgetTypeGrouping.ADMIN &&
                  _.isEmpty(columns[index].label)
                ) {
                  columns[index].label = columns[index].field
                    .split("_")
                    .map((word) => word[0].toUpperCase() + word.slice(1))
                    .join(" ");
                }
            }
            self.values = _groupColumnValues(columns, false);
            if (selectedColumns && liveIntegration && removeInvalidLiveFields) {
                selectedColumns = _getValidSelectedLiveColumns(columns, selectedColumns);
            }

            if (selectedColumns) {
                selectedColumns = _preProcessColumnsLabel(columns, selectedColumns);
                self.selectedValues = _preProcessSelectColumns(selectedColumns);
            } else {
                if (liveIntegration) {
                    // For On Demand Columns the first metric has to be selected by default
                    self.selectedValues = [_.find(columns, 'is_metric')];
                } else {
                    self.selectedValues = [_.first(_.filter(columns, function(column) {
                        return column.format === ColumnFormat.FORMAT_INTEGER && column.field !== ApiDataFields.ROW_GROUPING_COUNT;
                    }))];
                }
            }

            if (_.isNil(self.selectedValues[0])) {
                let filter = {};
                // If nothing found, try selecting the first text column
                if (WidgetUtilService.isDataGrid(widgetType)) {
                    filter.format = ColumnFormat.FORMAT_STRING;
                    self.selectedValues = [_.first(_.filter(columns, filter))]
                } else {
                    filter.field = ApiDataFields.ROW_GROUPING_COUNT;
                    self.selectedValues = _.filter(columns, filter);
                }
            }

            // pass values to the benchmark selector once they're resolved
            self.benchmarkSelector.values = _groupColumnValues(benchmarkColumns, false);

            if (selectedBenchmarks) {
                selectedBenchmarks = _preProcessColumnsLabel(columns, selectedBenchmarks);
                self.benchmarkSelector.selectedValues = _preProcessSelectColumns(selectedBenchmarks);
            }
        });
        self.options = AppModelFactory.getSelectOptions(new _ColumnsSelectOptions());
    }

    /**
     * @param {dashboardClientId} int
     * @param {metric} object
     * @returns {boolean}
     * @private
     */
    function isMetricClientCompatible(dashboardClientId, metric) {
        if (!metric.field.includes('calculation_') || !metric.client_id) {
            return true;
        }
        return Number(dashboardClientId) === Number(metric.client_id);
    }

    /**
     * @param selectedColumns
     * @param widgetModel
     * @constructor
     */
    function LiveFormulaSelect(selectedColumns = null, widgetModel = null) {
        let self = this;
        self.values = [];
        self.selectedValues = [];
        if (selectedColumns && selectedColumns.length) {
            const preProcessedSelectedColumns = [];
            selectedColumns.forEach((selectedColumn) => {
               preProcessedSelectedColumns.push({
                   ...selectedColumn,
                   id: selectedColumn.id || selectedColumn.field,
                   text: selectedColumn.text || selectedColumn.label,
               })
            });
            self.values = _.cloneDeep(preProcessedSelectedColumns.filter((column) => column.is_metric && column.formula === 'undefined'));
        }
        const liveFormulaItemSelect = new LiveFormulaItemSelect();
        if (widgetModel?.metadata?.live_formula_fields?.length) {
            widgetModel.metadata.live_formula_fields.forEach((formula_field, index) => {
               const currentColumn = _.find(self.values, { field: formula_field });
               if (currentColumn) {
                   currentColumn.disabled = true;
                   self.selectedValues.push({
                       ...currentColumn,
                       formula: _.find(liveFormulaItemSelect.values, {id: widgetModel.metadata.live_formulas[index]}),
                   });
               }
            });
        }
        self.options = AppModelFactory.getSelectOptions(new _WidgetFiltersOptions());
        self.options.$elSelector = '#widget-builder-panel div.selected-formula-columns div.select2-container';
        self.options.placeholder = gettextCatalog.getString('Select a problem metric...');
        self.options.formatNoMatches = function (value) {
            return gettextCatalog.getString('No problem metrics found with value') + ': <span>' + value + '</span>';
        };
        self.options.isGrouped = false;
    }

    /**
     * @constructor
     */
    function LiveFormulaItemSelect() {
        let self = this;
        self.values = [
            { id: 'NA', text: gettextCatalog.getString('Do not aggregate') },
            { id: 'SUM', text: gettextCatalog.getString('Sum') },
            { id: 'AVG', text: gettextCatalog.getString('Average') },
            { id: 'AVG_NO_ZERO', text: gettextCatalog.getString('Average, No Zeros') },
            { id: 'MAX', text: gettextCatalog.getString('Maximum') },
            { id: 'MIN', text: gettextCatalog.getString('Minimum') },
        ];
        self.selectedValues = [];
        self.options = AppModelFactory.getSelectOptions(new _WidgetFiltersOptions());
        self.options.formatSelection = _formatSelection;
        function _formatSelection(item) {
            let result = '<div class="label-text">';
            result += '<span>' + item.text + '</span>';
            result += '</div>';

            return result;
        }
        self.options.placeholder = gettextCatalog.getString('Select a formula');
        self.options.formatNoMatches = function (value) {
            return gettextCatalog.getString('No formula found with value') + ': <span>' + value + '</span>';
        };
        self.options.formatResult = function (item) {
            return '<span class="ml5">' + item.text + '</span>';
        }
        self.options.isGrouped = false;
    }


    /**
     * @constructor
     */
    function LiveStaticFilterItemSelect(params) {
        let self = this;
        self.selectedValues = null;

        self.promise = DataSourceFactory.getFieldValues(params.datasource, params.field, { has_live_integration: 1 }).then(function (json) {
            if (json?.values?.length) {
                self.values = json.values.filter(function (item) {
                    return item.key && item.value;
                });
            } else {
                self.values = [];
            }

            let selectedValue;
            if (params.selectedValues && params.selectedValues[0] && params.selectedValues[0].id) {
                selectedValue = _.find(self.values, { id: params.selectedValues[0].id });
            }
            if (selectedValue) {
                self.selectedValues = selectedValue;
            } else {
                self.selectedValues = null;
            }
        });
        self.options = AppModelFactory.getSelectOptions(new _WidgetFiltersOptions());
        self.options.formatSelection = _formatSelection;
        function _formatSelection(item) {
            let result = '<div class="label-text">';
            result += '<span>' + item.text + '</span>';
            result += '</div>';

            return result;
        }
        self.options.placeholder = gettextCatalog.getString('Select an item');
        self.options.formatNoMatches = function (value) {
            return gettextCatalog.getString('No item found with value') + ': <span>' + value + '</span>';
        };
        self.options.formatResult = function (item) {
            return '<span class="ml5">' + item.text + '</span>';
        }
        self.options.isGrouped = false;
    }

    /**
     * @param dataSource
     * @param selectedColumns
     * @param widgetType
     * @param liveIntegration
     * @param assignments
     * @constructor
     */
    function FiltersColumnsSelect(dataSource, selectedColumns = null, widgetType = null, liveIntegration = true, assignments = null) {
        let self = this;

        self.values = [];
        self.selectedValues = selectedColumns || [];

        let queryParams = {
            is_filterable: true,
            widget_request: true,
            is_hidden: false
        };

        if (liveIntegration) {
            queryParams.has_live_integration = 1;

            if (assignments) {
                queryParams.widget_assignments = assignments;
            }
        }

        DashboardContextService.resolveQueryParam(queryParams);

        self.promise = DataSourceFactory.getColumns(dataSource, queryParams).then(function (columns) {
            self.values = _groupColumnValues(columns, false);
            let groups = self.values;

			self.values = _.reject(groups, function(group) {
			    // Booleans and Date not currently supported for live calls, make sure none are returned
				return group.key == ColumnFormat.FORMAT_DATETIME || group.key == ColumnFormat.FORMAT_BOOLEAN;
			});
        });

        self.options = AppModelFactory.getSelectOptions(new _WidgetFiltersOptions());
    }

    /**
     * @param dataSource
     * @param {bool} liveIntegration
     * @param groupedColumns
     * @constructor
     */
    function GroupedColumnsSelect(dataSource, groupedColumns = null, liveIntegration = false, assignments = null, removeInvalidLiveFields = true) {
        let self = this;

        self.values = [];
        self.selectedValues = [];

        let queryParams = {
            is_groupable: true,
            widget_request: true
        };
        if (liveIntegration) {
            queryParams.has_live_integration = 1;
            if (assignments) {
                queryParams.widget_assignments = assignments;
            }
        }
        
        const dashboardClientId = DashboardContextService.resolveSelectedEntityIdforType();
        self.promise = DataSourceFactory.getColumns(dataSource, queryParams).then(function (columns) {
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].format === ColumnFormat.FORMAT_CURRENCY) {
                    columns[index].groupby_field_format = columns[index].format;
                }
                if (!isMetricClientCompatible(dashboardClientId, columns[index])) {
                    columns.splice(index, 1);
                    index--;
                }
            }
            self.values = _groupColumnValues(columns, true);
            if (groupedColumns && liveIntegration && removeInvalidLiveFields) {
                groupedColumns = _getValidSelectedLiveColumns(columns, groupedColumns);
            }
            if (groupedColumns) {
                groupedColumns = _preProcessColumnsLabel(columns, groupedColumns);
                self.selectedValues = _preProcessGroupColumns(groupedColumns);
            } else {
                self.selectedValues = [WidgetBuilderUtilService.findGroupedColumnCandidate(columns)];
            }
        });

        self.options = AppModelFactory.getSelectOptions(new _ColumnsGroupOptions());
    }

    /**
     * @private
     */
    function _DataSourceOptions() {
        /**
         * @type {SelectOptions}
         */
        let self = this;
        let _noMatch = function (value) {
            return gettextCatalog.getString('No data source found') + ': <span>' + value + '</span>';
        };

        self.multiple = false;
        self.allowClear = false;
        self.label = gettextCatalog.getString('data source');
        self.dropdownCssClass = 'select2-datasource-dropdown';
        self.$elSelector = '#widget-builder-panel div#data-source-select div.select2-container';
        self.formatResult = _formatDisplay;
        self.formatSelection = _formatDisplay;
        self.formatNoMatches = _noMatch;
        self.matcher = _matchCustom;

        /**
         * @param value
         * @param data
         * @param opt
         * @returns {*}
         */
        function _matchCustom(value, data, opt) {
            if (!opt.group) {
                return null;
            }
            if (data.toLowerCase().contains(value.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * Select2 override How to show selected value in dropdown or as a selectable value
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            let result = null;
            if (DataSourceFactory.dataSourceContainsServices(item.type)) {
                let icon = DataSourceFactory.getDataSourceIcon(item);
                let color = DataSourceFactory.getDataSourceColor(item);
                result = '<div class="service-square service-square-24" style="background-color:'+ color +'"><div class="icon ' + icon + '"></div></div><span>' + item.text + '</span>';
            } else {
                result = item.text;
            }
            return result;
        }
    }

    /**
     * @private
     */
    function _WidgetFiltersOptions() {
        /**
         * @type {SelectOptions}
         */
        let self = this;

        let _noMatch = function (value) {
            return gettextCatalog.getString('No filters found with value') + ': <span>' + value + '</span>';
        };


        self.placeholder = gettextCatalog.getString('Select a filter...'),
        self.multiple = false;
        self.allowClear = false;
        self.dropdownCssClass = 'select2-columns-dropdown';
        self.$elSelector = '#widget-builder-panel div.selected-filter-columns div.select2-container';
        self.formatResult = _formatResult;
        self.formatSelection = _formatSelection;
        self.formatNoMatches = _noMatch;
        self.matcher = _matchCustom;

        /**
         * @param value
         * @param data
         * @param opt
         * @returns {*}
         */
        function _matchCustom(value, data, opt) {
            if (opt.children) {
                return null;
            }
            if (data.toLowerCase().contains(value.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatResult(item) {
            if (item.children) { // Group header item
                return item.text;
            }

            let dataType = _getColumnFormat(item);
            let result = '<label class="label">' + _getIconHtml(dataType.icon) + '</label>';
            result += _getLabelHtmlForColumn(item, "ml5");

            return result;
        }

        function _formatSelection(item) {
            if (item.children) { // Group header item
                return item.text;
            }

            let bgColor = item.color || '#474f5e';
            let textColor = UIColor.textColorWithBackgroundColor(item.color);
            let dataType = _getColumnFormat(item);
            let result = '<div class="select-data-column" style="color:'+textColor+';background-color:'+bgColor+'">';
            result += _getIconHtml(dataType.icon);
            result += _getLabelHtml(item);
            result += '</div>';

            return result;
        }

        /**
         * @param icon
         * @returns {string}
         * @private
         */
        function _getIconHtml(icon) {
            if (_.startsWith(icon, 'icon')) {
                return '<span class="icon '+ icon +'"></span>';
            } else {
                return '<span class="icon">'+ icon +'</span>';
            }
        }

        /**
         * @param item
         * @returns {*}
         * @private
         */
        function _getColumnFormat(item) {
            const condition = item.is_groupable && item.format !== ColumnFormat.FORMAT_CURRENCY;
            return _keyedDataTypes[condition ? item.groupby_field_format : item.format];
        }
    }

    /**
     * @private
     */
    function _WidgetAssignmentsOptions(isMultiSelect = false) {
        /**
         * @type {SelectOptions}
         */
        let self = _.assign(this, new _ColumnsSelectOptions());

        let _noMatch = function (value) {
            return gettextCatalog.getString('No Profile found') + ': <span>' + value + '</span>';
        };

        self.multiple = isMultiSelect;
        self.$elSelector = '#widget-builder-panel div#assignments-drop-down div.select2-container';

        self.allowClear = true;
        self.label = gettextCatalog.getString('Profile');
        self.formatResult = isMultiSelect ? _formatResultMultiSelect : _formatDisplay;
        self.formatSelection = isMultiSelect ? _formatSelectionMultiSelect : _formatDisplay;
        self.formatNoMatches = _noMatch;
        self.matcher = _matchCustom;

        function _formatSelectionMultiSelect(item) {
            let bgColor = '#474f5e';
            let textColor = UIColor.textColorWithBackgroundColor(bgColor);
            let result = '<div class="select-data-column" style="color:'+textColor+';background-color:'+bgColor+'">';
            result += _getLabelHtmlForAssignment(item);
            result += '</div>';

            return result;
        }

        /**
         * Return the data if it contains value
         * or return null
         * @param value
         * @param data
         * @returns {*}
         */
        function _matchCustom(value, data) {
            if (data.toLowerCase().contains(value.toLowerCase())) {
                return data;
            }
            return null;
        }

        function _formatResultMultiSelect(item) {
            return _getLabelHtmlForAssignment(item, 'ml5');
        }

        /**
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            return _getLabelHtmlForAssignment(item);
        }
    }

    /**
     * @private
     */
    function _DataViewOptions() {
        /**
         * @type {SelectOptions}
         */
        let self = _.assign(this, new _DataSourceOptions());

        let _noMatch = function (value) {
            return gettextCatalog.getString('No data view found') + ': <span>' + value + '</span>';
        };

        self.label = gettextCatalog.getString('data view');
        self.formatResult = _formatDisplay;
        self.formatSelection = _formatDisplay;
        self.formatNoMatches = _noMatch;
        self.matcher = undefined;

        /**
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatDisplay(item) {
            let result = '<span>' + item.name + '</span>';
            if (item.is_geo) {
                result += '<div class="right"><div class="icon icomoon-geochart-bold mt2"></div></div>';
            }
            return result;
        }
    }

    /**
     * @private
     */
    function _ColumnsSelectOptions() {
        /**
         * @type {SelectOptions}
         */
        let self = this;
        let _noMatch = function (value) {
            const url = "https://support.tapclicks.com/hc/en-us/articles/4491652141851-Product-Feedback-Portal-Overview";

            return '<span>' + value + ' '+ gettextCatalog.getString('not found in this data view') + '. <a href="' + url + '" onclick="window.open(\'' + url + '\',\'_blank\')">' + gettextCatalog.getString('Submit feedback to the team') + '</a></span>';

        };

        self.$elSelector = '#widget-builder-panel div.selected-columns div.select2-container';
        self.multiple = true;
        self.allowClear = false;
        self.isSortable = true;
        self.label = gettextCatalog.getString('metric');
        self.dropdownCssClass = 'select2-columns-dropdown';
        self.formatResult = _formatResult;
        self.formatSelection = _formatSelection;
        self.formatNoMatches = _noMatch;
        self.matcher = _matchCustom;

        /**
         * @param value
         * @param data
         * @param opt
         * @returns {*}
         */
        function _matchCustom(value, data, opt) {
            if (opt.children) {
                return null;
            }
            if (data.toLowerCase().contains(value.toLowerCase())) {
                return data;
            }
            return null;
        }

        /**
         * @param item select2 item object
         * @returns {*}
         * @private
         */
        function _formatResult(item) {
            if (item.children) { // Group header item
                return item.text;
            }
            
            let dataType = _getColumnFormat(item);
            let result = '<label class="label">' + _getIconHtml(dataType.icon) + '</label>';
            result += _getLabelHtmlForColumn(item, 'ml5');
            if (item.client_id) {
                result += '<div class="right"><div class="icon icon-calculator mt2"></div></div>';
            }

            return result;
        }

        function _formatSelection(item) {
            if (item.children) { // Group header item
                return item.text;
            }
            let bgColor = item.color || '#474f5e';
            let textColor = UIColor.textColorWithBackgroundColor(item.color);
            let dataType = _getColumnFormat(item);
            let result = '<div class="select-data-column" style="color:'+textColor+';background-color:'+bgColor+'">';
            result += _getIconHtml(dataType.icon);
            result += _getLabelHtmlForColumn(item);
            result += '</div>';

            return result;
        }

        /**
         * @param icon
         * @returns {string}
         * @private
         */
        function _getIconHtml(icon) {
            if (_.startsWith(icon, 'icon')) {
                return '<span class="icon '+ icon +'"></span>';
            } else {
                return '<span class="icon">'+ icon +'</span>';
            }
        }

        /**
         * @param item
         * @returns {*}
         * @private
         */
        function _getColumnFormat(item) {
            const condition = item.is_groupable && item.format !== ColumnFormat.FORMAT_CURRENCY;
            return _keyedDataTypes[ condition ? item.groupby_field_format : item.format];
        }
    }

    /**
     * @private
     */
    function _ColumnsGroupOptions() {
        /**
         * @type {SelectOptions}
         */
        let self = _.assign(this, new _ColumnsSelectOptions());

        self.$elSelector = '#widget-builder-panel div.grouped-columns div.select2-container';
        self.label = gettextCatalog.getString('dimension');
    }

    /**
     * @param columns
     * @param isGroupByColumns
     * @returns {*[]}
     * @private
     */
    function _groupColumnValues(columns, isGroupByColumns) {
        columns = isGroupByColumns
            ? _preProcessGroupColumns(columns)
            : _preProcessSelectColumns(columns);


        return _.orderBy(_.reduce(_dataTypes, function(acc, dataType) {
            let columnsOfType = _.filter(columns,
                isGroupByColumns ? {groupby_field_format: dataType.key} : {format: dataType.key}
            );
            if (!_.isEmpty(columnsOfType)) {
                let children = !isGroupByColumns
                    ? _mapColumnSelectValues(columnsOfType)
                    : _mapColumnGroupValues(columnsOfType);

                if (children.length) {
                    acc.push({
                        key: dataType.key,
                        text: dataType.value,
                        children: children
                    });
                }
            }
            return acc;
        }, []), 'text');
    }

    /**
     * @param columns
     * @param isGroupByColumns
     * @returns {*[]}
     * @private
     */
    function _groupFilterValues(columns) {
        columns = _preProcessSelectColumns(columns);



        return _.orderBy(_.reduce(_dataTypes, function(acc, dataType) {
            let columnsOfType = _.filter(columns, {format: dataType.is_numeric ? 'metric' : 'attribute'});
            if (!_.isEmpty(columnsOfType)) {
                let children = _mapColumnSelectValues(columnsOfType);

                if (children.length) {
                    acc.push({
                        key: dataType.key,
                        text: dataType.value,
                        children: children
                    });
                }
            }
            return acc;
        }, []), 'text');
    }


    /**
     * @param values
     * @param group
     * @returns {Array|*}
     * @private
     */
    function _mapDataSourceSelectValues(values, group = null) {
        return _.orderBy(_.map(values, function(value) {
            return _.assign(value, {
                id: value.id,
                text: value.name,
                type: value.type,
                group: group
            });
        }), 'text');
    }

    /**
     * @param values
     * @returns {Array|*}
     * @private
     */
    function _mapSeoWebsiteDataSourceSelectValues(values) {
        return _.orderBy(_.map(values, function(value) {
            const text = `${value.name} (${value.url})`;
            /**
             * A deterministic way to get color for an seo website.
             * Since sum of character of a url will produce mostly darker colors,
             * adding some amount of minimum saturation and lightness to make colors
             * a little brighter.
             */
            const hue = text.split('').reduce((agg, value) => agg + value.charCodeAt(0), 0) % 360;
            const saturation = 15 + (text.split('').filter((val, i) =>  i % 2).reduce((agg, value) => agg + value.charCodeAt(0), 0) % 86);
            const lightness = 30 + (text.split('').filter((val, i) =>  !(i % 2)).reduce((agg, value) => agg + value.charCodeAt(0), 0) % 71);
            const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
            return _.assign(value, {
                id: value.id,
                type: value.type,
                group: DataSourceType.SEO_WEBSITES,
                text,
                color
            });
        }), 'text');
    }

    /**
     * @param values
     * @param group
     * @returns {Array|*}
     * @private
     */
    function _mapOtherDataSourceSelectValues(values) {
        return _.orderBy(_.map(values, function(value) {
            return _.assign(value, {
                id: value.type,
                text: value.name,
                type: value.type,
                group: DataSourceType.OTHER
            });
        }), 'text');
    }

    /**
     * @param values
     * @returns {Array|*}
     * @private
     */
    function _mapDataViewSelectValues(values) {
        return _.orderBy(_.map(values, function(value) {
            return _.assign(value, {
                id: value.id,
                text: value.name
            });
        }), 'text');
    }

    /**
     * @returns {Array|*}
     * @private
     * @param data
     * @param dataSourceId
     * @param is_invalid
     */
    function _mapServiceOrderItemsSelectValues(data, dataSourceId, is_invalid = false) {
        data = data.plain();
        data = !_.isArray(data) ? [data] : data;
        let serviceOrderItems = dataSourceId ? _.filter(data, {service_id: dataSourceId}) : data;
        let mappedServiceOrderItemsSelectValues = [];
        serviceOrderItems.forEach((value) => {
            mappedServiceOrderItemsSelectValues.push({
                id: value.account_id || value.item_id,
                text: value.account_label || value.item_label,
                is_invalid,
            });
        });
        mappedServiceOrderItemsSelectValues = _.uniqWith(mappedServiceOrderItemsSelectValues, _.isEqual);
        return _.orderBy(mappedServiceOrderItemsSelectValues, 'text');
    }

    /**
     * @param columns
     * @returns {Array|*}
     * @private
     */
    function _mapColumnSelectValues(columns) {
        return _.orderBy(_.filter(columns, function(column) {
            return !_columnUtil.isID(column.format);
        }), 'label');
    }

    /**
     * @param column
     */
    function _preProcessSelectColumns(columns) {
        return _.each(columns, function(column) {
            return _preProcessSelectColumn(column);
        });
    }

    /**
     * Get the html for the label of Assignment by constructing the tag with the title and className (if present).
     * @param item
     * @param className
     * @returns {string}
     */
    function _getLabelHtmlForAssignment(item, className) {
        const title = item.is_invalid
            ? gettextCatalog.getString(`Assignment is incompatible with current request`)
            : item.text;
        return _getLabelHtml(item, title, className);
    }

    /**
     * Get the html for the label of Column by constructing the tag with the title and className (if present).
     * @param item
     * @param className
     * @returns {string}
     */
    function _getLabelHtmlForColumn(item, className) {
        const title = item.is_invalid
            ? gettextCatalog.getString('Column is incompatible with current request')
            : '';
        return _getLabelHtml(item, title, className);
    }

    /**
     * Get the html for the label by constructing the tag with the title and className (if present).
     * @param item
     * @param title
     * @param className
     * @returns {string}
     */
    function _getLabelHtml(item, title, className) {
        let classText = '';
        if (className) classText = `class="${className}"`;
        let titleText = '';
        if (title) titleText = `title="${title}"`;
        if (item.is_invalid) {
            // Strike-through
            return `<s ${classText} ${titleText}>${item.text}</s>`;
        } else {
            return `<span ${classText} ${titleText}>${item.text}</span>`;
        }
    }

    /**
     * @param column
     */
    function _preProcessSelectColumn(column) {
        if (_columnUtil.isCallback(column.format)) {
            if (column.postprocess_type === PostProcessType.POSTPROCESS_IFRAME) {
                column.format = ColumnFormat.FORMAT_IMAGE;
            } else {
                column.format = ColumnFormat.FORMAT_STRING;
            }
        }

        return _.assign(column, {
            id: column.field,
            text: column.label,
        });
    }

    /**
     * Get only the selectedColumns which are available in the columns
     * @param columns
     * @param selectedColumns
     * @private
     */
    function _getValidSelectedLiveColumns(columns, selectedColumns) {
        const columnsFieldMap = _.keyBy(columns, 'field');
        selectedColumns = _.filter(selectedColumns, (selectedColumn) => {
            return columnsFieldMap[selectedColumn.field];
        });
        return selectedColumns;
    }

    /**
     * @param columns
     * @returns {Array|*}
     * @private
     */
    function _mapColumnGroupValues(columns) {
        return _.orderBy(_.filter(columns, function(column) {
            return !_columnUtil.isOrphanPrimaryKeyColumn(column);
        }), 'label');
    }

    /**
     * @param column
     */
    function _preProcessGroupColumns(columns) {
        return _.each(columns, function(column) {
            return _preProcessGroupColumn(column);
        });
    }

    /**
     * @param column
     * @returns {*}
     * @private
     */
    function _preProcessGroupColumn(column) {
        if (_columnUtil.isCallback(column.groupby_field_format)) {
            column.format = ColumnFormat.FORMAT_ID;
            column.groupby_field_format = ColumnFormat.FORMAT_STRING;
        }

        return _.assign(column, {
            id: column.field,
            text: column.label
        });
    }

    /**
     * Replaces the label of the selected columns with the label of the columns
     * The right side panel should not be changed with the changed label
     * so replacing the selected column label with the column label
     *
     * @param columns
     * @param selectedColumns
     * @returns {Array}
     * @private
     */
    function _preProcessColumnsLabel(columns, selectedColumns) {
        const columnLabelMap = _.mapValues(_.keyBy(columns, 'field'), column => column.label);

        return _.map(selectedColumns, (selectedColumn) => {
            if (columnLabelMap[selectedColumn.field]) {
                selectedColumn.label = columnLabelMap[selectedColumn.field];
            }
            return selectedColumn;
        });
    }

    /**
     * @returns {WidgetBuilderDataTabState}
     */
    function getTabState() {
        return new WidgetBuilderDataTabState();
    }

    /**
     * @param model
     * @param selectedEntity
     * @returns {WidgetBuilderDataTabData}
     */
    function getTabData(model, selectedEntity) {
        return new WidgetBuilderDataTabData(model, selectedEntity);
    }

    /**
     * @param model
     * @returns {WidgetBuilderAdminDataTabData}
     */
    function getTabAdminData(model) {
        return new WidgetBuilderAdminDataTabData(model);
    }

    /**
     * @param model
     * @returns {WidgetBuilderModel}
     */
    function getWidgetBuilderModel(model) {
        return new WidgetBuilderModel(model);
    }

    /**
     * @param model
     * @returns {AdminWidgetBuilderModel}
     */
    function getAdminWidgetBuilderModel(model) {
        return new AdminWidgetBuilderModel(model);
    }

    /**
     * @param model
     * @returns {MediaWidgetBuilderModel}
     */
    function getMediaWidgetBuilderModel(model) {
        return new MediaWidgetBuilderModel(model);
    }

    function getChatGptWidgetBuilderModel(model) {
        return new ChatGptWidgetBuilderModel(model);
    }

    /**
     * @param model
     * @returns {DataColumnsModel}
     */
    function getDataColumnsModel(model) {
        return new DataColumnsModel(model);
    }

    /**
     * @param dataSource
     * @param dataView
     * @returns {DataSourceModel}
     */
    function getDataSourceModel(dataSource, dataView) {
        return new DataSourceModel(dataSource, dataView);
    }

    /**
     * @param model
     * @returns {DataViewModel}
     */
    function getDataViewModel(model) {
        return new DataViewModel(model);
    }

    /**
     * @param model
     * @returns {ItemFilterModel}
     */
    function getItemFilterModel(model) {
        return new ItemFilterModel(model);
    }

    /**
     * @param model
     * @returns {TextFilterValuesModel}
     */
    function getTextFilterValuesModel(model) {
        return new TextFilterValuesModel(model);
    }

    /**
     * @param model
     * @returns {FilterSetModel}
     */
    function getFilterSetModel(model) {
        return new FilterSetModel(model);
    }

    /**
     * @param dataSource
     * @param liveIntegration
     * @param isGeoChart
     * @returns {DataViewSelect}
     */
    function getDataViewSelect(dataSource, liveIntegration, isGeoChart = false) {
        return new DataViewSelect(dataSource, liveIntegration, isGeoChart);
    }

    /**
     *
     * @param dataSource
     * @param selectedAssignments
     * @returns {WidgetAssignmentSelect}
     */
    function getWidgetAssignmentSelect(dataSource, selectedAssignments) {
        return new WidgetAssignmentSelect(dataSource, selectedAssignments);
    }

    /**
     * @param {DataSourceModel} dataSource
     * @param {array} selectedColumns
     * @param {string} widgetType
     * @param {bool} liveIntegration
     * @param {array} assignments
     * @param {array} benchmarks
     * @returns {SelectedColumnsSelect}
     */
    function getSelectedColumnsSelect(dataSource, selectedColumns, widgetType, liveIntegration, assignments, benchmarks) {
        return new SelectedColumnsSelect(dataSource, selectedColumns, widgetType, liveIntegration, assignments, benchmarks);
    }

    /**
     * @param {DataSourceModel} dataSource
     * @param {array} groupedColumns
     * @param {bool} liveIntegration
     * @returns {GroupedColumnsSelect}
     */
    function getGroupedColumnsSelect(dataSource, groupedColumns, liveIntegration, assignments) {
        return new GroupedColumnsSelect(dataSource, groupedColumns, liveIntegration, assignments);
    }

    /**
     * @param {DataSourceModel} dataSource
     * @param {array} groupedColumns
     * @param {boolean} liveIntegration
     * @returns {FiltersColumnsSelect}
     */
    function getFilterColumnsSelect(dataSource, filterColumns, widgetType, liveIntegration = false, selectedAssignments = null) {
        return new FiltersColumnsSelect(dataSource, filterColumns, widgetType, liveIntegration, selectedAssignments);
    }

    /**
     * @param {array} selectedColumns
     * @param widgetModel
     * @returns {LiveFormulaSelect}
     */
    function getLiveFormulaSelect(selectedColumns, widgetModel) {
        return new LiveFormulaSelect(selectedColumns, widgetModel);
    }

    /**
     * @returns {LiveFormulaSelect}
     */
    function getLiveFormulaItemSelect() {
        return new LiveFormulaItemSelect();
    }

    /**
     * @returns {LiveFormulaSelect}
     */
    function getLiveStaticFilterItemSelect(params) {
        return new LiveStaticFilterItemSelect(params);
    }

    /**
     * @param model
     */
    function getExecutiveSummaryWidgetBuilderModel(model) {
        return new ExecutiveSummaryWidgetBuilderModel(model);
    }
}
