'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from "moment";
import {AppConstants} from "coreModules/shared/scripts/app.constants";

angular.module('lineitems.ctrls', [])
    .controller('AddLineItemController', AddLineItemController)
    .controller('ViewLineItemController', ViewLineItemController)
    .controller('ViewFlightController', ViewFlightController)
    .controller('AuditHistoryController', AuditHistoryController)
    .controller('WorkflowLogHistoryController', WorkflowLogHistoryController);

AddLineItemController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$ioAlert', '$filter', 'OrdersService', 'ProductService', 'FormService', 'MediaService', 'CommentService', 'RuleService', 'ClusterService', '$modal', 'ModalService', 'creativesService', 'CreativeServicePro', 'UserService', '$timeout'];

function AddLineItemController($rs, $scope, $stateParams, $state, $ioAlert, $filter, OrdersService, ProductService, FormService, MediaService, CommentService, RuleService, ClusterService, $modal, ModalService, creativesService, CreativeServicePro, UserService, $timeout) {
    $scope.order = $stateParams.order;
    $scope.lineItem = null;
    $scope.lineItemIdToFormattedEOAPreviewsArray = [];
    $scope.products = null;
    $scope.creatives = [];
    $scope.realCreativeFilenames = [];
    $scope.chosenProduct = null;
    $scope.chosenPackage = null;
    $scope.model = {};
    $scope.ruleSet = {};
    $scope.validationErrors = [];

    $scope.productFields = null;
    $scope.productFieldMap = {};
    $scope.savedRequiredCreativeFieldIds = [];
    $scope.schema = {};
    $scope.form = [];
    $scope.entireForm = [];
    $scope.formParentChildInfo = {};
    $scope.hasForm = false;
    $scope.isEditing = false;
    $scope.hasCreatives = false;
    $scope.savedSuccessfully = false;
    $scope.comments = [];
    $scope.packages = null;
    $scope.productPackageEnabled = false;
    $scope.client = null;
    $scope.addCommentRequired = true;
    $scope.uploadFileSetIndex = 1;
    $scope.uploadFileSet = new Set();
    $scope.waitForLineItemCustomFileUpload = false;
    $scope.enableFlightPush = false;

    var buildSchema = function(productFields) {
        // Remember the required creative field ids so we can later restore their required flag.
        $scope.savedRequiredCreativeFieldIds = FormService.getRequiredCreativeFieldIds(productFields);
        FormService.buildDynamicFormSchema($scope.schema, $scope.productFieldMap, productFields, $scope.isEditing, $scope.lineItem, $scope.creatives, $scope.realCreativeFilenames, !$scope.isEditing);
        FormService.addCommentSchema($scope.schema);
        if ($scope.isEditing) {
            var editArray = [];
            for (var x in $scope.schema.properties) {
                if ($scope.schema.properties.hasOwnProperty(x)) {
                    editArray.push({
                        "prop": x,
                        "type": $scope.schema.properties[x].type
                    });
                }
            }
            updateForm($scope.lineItem, editArray);
        }
    };

    var resolvePredefinedValue = function(key) {
        var val = null;
        var keyArray = key.split('_');
        var objectName = keyArray.shift();
        var prop = keyArray[0];

        function rebuildObjectPropertiesContainingUnderscores() {
            if (keyArray.length > 1) {
                prop = keyArray.join('_');
            }
        }
        rebuildObjectPropertiesContainingUnderscores();

        switch (objectName) {
            case 'order':
                val = ($scope.order && $scope.order[prop]) ? $scope.order[prop] : 'NA';
                break;

            case 'lineItem':
                val = ($scope.lineItem && $scope.lineItem[prop]) ? $scope.lineItem[prop] : 'NA';
                break;
        }

        return val;
    };

    $scope.calculate = function(data) {
        if(data === undefined) {
            calQueueExc();
            return;
        }
        let dataArray = data.split(',');
        let fieldId = dataArray.shift();
        let productFieldsLen = $scope.productFields.length;

        let calculateFields = {
            'fieldId': parseInt(fieldId)
        };

        if($scope.chosenPackage) {
            calculateFields.packageId = $scope.chosenPackage.id;
        }

        let getFamilyNestedCondition = function(field) {
            let condition = true;
            if(field.parentField){
                let modelValue = $scope.model[field.parentField.field_name];
                let isConditionValueAnArray = Array.isArray(field.conditionValue);


                if (isConditionValueAnArray && field.parentField.input_type == 'checkbox' && _.isBoolean(modelValue)) {
                    modelValue = modelValue === true ? '1' : '0'
                }

                if (Array.isArray(modelValue)) {
                    condition = isConditionValueAnArray ?
                        modelValue.some(oneVal => field.conditionValue.includes(oneVal)) :
                        modelValue.includes(field.conditionValue)
                } else {
                    condition = isConditionValueAnArray ?
                        field.conditionValue.includes(modelValue) :
                        modelValue == (field.conditionValue);
                }
                condition = getFamilyNestedCondition(field.parentField) && condition;
            }
            return condition;
        }

        const numericTypes = ['integer', 'decimal', 'currency', 'percentage'];

        for (let i = 0; i < productFieldsLen; i++) {
            for (let j = 0, len = dataArray.length; j < len; j++) {
                if (parseInt(dataArray[j]) == $scope.productFields[i].id) {
                    const parameterName = 'pf' + $scope.productFields[i].id;
                    const field = $scope.productFields[i];
                    const condition = getFamilyNestedCondition(field);
                    const valueOfFieldInFormula = $scope.model[$scope.productFields[i].field_name];
                    if (valueOfFieldInFormula && condition) {
                        if ($scope.productFields[i].lookup_content) {
                            let formValue = valueOfFieldInFormula;
                            formValue = $scope.productFields[i].input_type == 'select2' ? formValue.text : formValue;
                            let lookupContentIds = FormService.getLookupContentIds(
                                $scope.productFields[i].field_name,
                                $scope.productFields[i].lookup_content,
                                formValue, false, $scope.model,
                                $scope.formParentChildInfo);
                            if (Array.isArray(lookupContentIds)) {
                                lookupContentIds = lookupContentIds.length === 0 ? "" : lookupContentIds;
                                calculateFields[parameterName + "[]"] = lookupContentIds;
                            } else {
                                calculateFields[parameterName] = lookupContentIds;
                            }
                            // we do not need to handle symbol or format for ids in the formula
                        } else {
                            calculateFields[parameterName] = valueOfFieldInFormula;
                            // we need to handle symbol or format for numbers in the formula
                            if ($scope.productFields[i].data_type == "currency" &&
                                calculateFields[parameterName].contains('$')) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/\$/g, '');
                            }
                            if ($scope.productFields[i].data_type == "percentage" &&
                                calculateFields[parameterName].contains('%')) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/\%/g, '');
                            }
                            if (numericTypes.includes($scope.productFields[i].data_type)) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/,/g, '');
                            }
                            if ($scope.productFields[i].data_type == "date") {
                                calculateFields[parameterName] = $filter('date')(calculateFields[parameterName], 'yyyy-MM-dd');
                            }
                            if ($scope.productFields[i].input_type == "timeselect") {
                                if (typeof valueOfFieldInFormula == 'object') {
                                    calculateFields[parameterName] = valueOfFieldInFormula.toLocaleTimeString();
                                }
                            }
                        }
                    } else if(numericTypes.includes($scope.productFields[i].data_type)){
                        calculateFields[parameterName] = 0;
                    } else {
                        calculateFields[parameterName] = '';
                    }
                } else {
                    const predefinedValue = resolvePredefinedValue(dataArray[j]);

                    if (predefinedValue) {
                        const pname = 'pf' + dataArray[j];
                        calculateFields[pname] = predefinedValue;
                    }
                }
            }
        }

        calculateFields['orderId'] = $scope.order.id;
        if ($scope.lineItem) {
            calculateFields['lineItemId'] = $scope.lineItem.id;
        }
        FormService.calculate(calculateFields).then(function(success) {
            if (success) {
                if (angular.isUndefined(success.value) || success.status === 'fail') {
                    if (angular.isUndefined(success.message)) {
                        $ioAlert.show('', "Please make sure all required fields are filled in.", 'error');
                    } else {
                        $ioAlert.show('', success.message, 'error');
                    }
                    success.value = '';

                    if (success.message) {
                        console.log(success.message);
                    }
                }

                $scope.productFields.forEach((field) => {
                    if (parseInt(fieldId) == field.id) {
                        switch (field.data_type) {
                            case 'currency':
                                $scope.model[field.field_name] = FormService.currencyFormating(
                                    String(success.value),
                                    true
                                );
                                break;
                            case 'integer':
                                $scope.model[field.field_name] = FormService.numberFormating(String(success.value), 0);
                                break;
                            case 'decimal':
                                $scope.model[field.field_name] = FormService.numberFormating(String(success.value));
                                break;
                            case 'percentage':
                                $scope.model[field.field_name] = String(success.value) + '%';
                                break;
                            case 'date':
                                $scope.model[field.field_name] = FormService.convertStringToDate(String(success.value));
                                break;
                            default:
                                $scope.model[field.field_name] = String(success.value);
                        }
                    }
                });
            }
            calQueueExc();
        }, function() {
            $ioAlert.show("Error!", "An error occurred calculating the fields.", 'error');
            $rs.loadingInProgress = false;
            calQueueExc();
        });
    };

    function calQueueExc() {
        if ($scope.calQueue.length > 0) {
            var fieldIds = $scope.calQueue.shift();
            $scope.calculate(fieldIds);
        }else if($scope.calQueue.length == 0){
            $rs.loadingInProgress = false;
        }
    }

    $scope.calculateAllFields = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Calculate Details";
        $scope.calQueue = [];
        for (let i = 0, len = $scope.entireForm.length; i < len; i++) {
            if ($scope.entireForm[i].items) {
                for (let j = 0, size = $scope.entireForm[i].items.length; j < size; j++) {
                    if ($scope.entireForm[i].items[j].fieldIds != undefined) {
                        const item = $scope.entireForm[i].items[j];
                        if(item.condition) {
                            const parent_name = item.conditionInfo.parent_name;
                            const lookupValue = item.conditionInfo.lookup_value;
                            const input_type = item.conditionInfo.input_type;

                            const isModelParentNameUndefined = $scope.model[parent_name] === undefined;
                            const isInputNotACheckbox = input_type != "checkbox";
                            const isLookupValueNotIncludedInParent = checkIfLookupValueIsNotIncludedInParent(lookupValue, $scope.model[parent_name]);

                            if (isModelParentNameUndefined || (isInputNotACheckbox && isLookupValueNotIncludedInParent)){
                                continue;
                            }
                        }
                        if ($scope.entireForm[i].items[j].showOverride) {
                            if (!$scope.entireForm[i].items[j].override)
                                $scope.calQueue.push($scope.entireForm[i].items[j].fieldIds);
                        } else {
                            $scope.calQueue.push($scope.entireForm[i].items[j].fieldIds);
                        }
                    }
                }
            }
        }
        $scope.calQueue = FormService.reorderCalQueue($scope.calQueue);
        calQueueExc();
    };

    function checkIfLookupValueIsNotIncludedInParent(lookupValue, parent) {
        if (!(parent && _.isFunction(parent.includes))) {
            return true;
        }

        if (Array.isArray(lookupValue)) {
            let returned = true;
            lookupValue.forEach(function(val) {
                returned = returned && !parent.includes(val);
            })

            return returned;
        }

        return !parent.includes(lookupValue);
    }

    var getCurrentUserRole = function() {
        if (!$rs.userRole || !$rs.userRole.id) {
            UserService.getCurrentUserRole().then(function() {
                $rs.userRole = UserService.userRole;
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }
    }

    var buildForm = function(productFields, productFieldGroups) {
        let clientId = null;
        if ($scope.order && $scope.order.client_id) {
            clientId = $scope.order.client_id[0]
        }
        getCurrentUserRole();
        FormService.buildDynamicForm($scope.model, $scope.entireForm, $scope.formParentChildInfo, $scope.schema, $scope.productFieldMap, productFieldGroups,
            productFields, $scope.isEditing, clientId, $scope.chosenProduct, $scope.order, $rs.userRole, 'lineItem', $scope.lineItem);
        _.each($scope.entireForm, function (section) {
            if (section.type == 'hidden') {
                return;
            }
            $scope.form.push(section);
        });

        $timeout(() => {
            const inputElement = document.querySelector('input[name="triton_push_product"]');
            inputElement?.parentNode?.parentNode?.parentNode?.classList.add('hidden');
            if (!$scope.enableFlightPush) {
                inputElement?.parentNode?.parentNode?.parentNode?.parentNode?.previousElementSibling?.classList.add('hidden');
            }
        }, 0);

        if ($scope.schema.properties.dfp_adsizes) {
            $scope.imageTypeAdSizes = $scope.schema.properties.dfp_adsizes.items.filter((item) => item.value.indexOf('v (Custom)') == -1 && item.value.slice(-1) !=="v");
            $scope.videoTypeAdSizes = $scope.schema.properties.dfp_adsizes.items.filter((item) => item.value.includes('v (Custom)') || item.value.slice(-1)=="v");
            $scope.schema.properties.dfp_adsizes.items = [];
        }
        $scope.$watch(function() { return $scope.model.creative_type;}, function() {
            if ($scope.model.creative_type == 'Display') {
                $scope.schema.properties.dfp_adsizes.items = $scope.imageTypeAdSizes;
            } else if ($scope.model.creative_type == 'Video') {
                $scope.schema.properties.dfp_adsizes.items = $scope.videoTypeAdSizes;
            } else if ($scope.model.creative_type != 'Display' && $scope.model.creative_type != 'Video') {
                if ($scope.schema.properties.dfp_adsizes) {
                    $scope.schema.properties.dfp_adsizes.items = [];
                }
            }
        });
        let cluster_id = null;
        if ($scope.order && $scope.order.cluster_id) {
            cluster_id = $scope.order.cluster_id[0];
        }
        $scope.form.push(FormService.getCommentInputSection('lineItemPage', $scope.addCommentRequired && $scope.isEditing, cluster_id));
        $scope.form.push(FormService.getSubmitControl("Save Line Item", "formObj"));
        FormService.registerForDateChanges($scope.form, $scope.productFields, $scope.model);
    };

    var getOrder = function(orderId, loadForm) {
        OrdersService.getOrder(orderId, true).then(function() {
            $scope.order = OrdersService.order;
            getProducts($scope.order.cluster_id[0]);

            if (loadForm) {
                $scope.loadForm();
            }
        }, function() {
            console.log("Error getting order for id: " + orderId);
        });
    };

    var getLineItem = function(lineItemId) {
        $scope.lineItemLoader = true;
        OrdersService.getLineItem(lineItemId).then(function() {
            $scope.lineItem = OrdersService.lineItem;
            $scope.chosenProduct = {};
            $scope.chosenProduct.id = $scope.lineItem.product_id;
            $scope.chosenProduct.service_id = $scope.lineItem.product_service_id;
            if($scope.lineItem && OrdersService.isValidJSONSting($scope.lineItem.client_id)) {
                $scope.lineItem.clientId = JSON.parse($scope.lineItem.client_id);
                $scope.chosenProduct.ta_service_id = $scope.lineItem.clientId.service_id;
            }
            if($scope.lineItem && $scope.lineItem.package_id) {
                $scope.chosenPackage = {};
                $scope.chosenPackage.id = $scope.lineItem.package_id;
            }
            $scope.order = {};
            getOrder($scope.lineItem.order_id, true);
            $scope.getComments(lineItemId);
            handleEOAPreviews();
        }, function() {
            console.log("Error getting line item for id: " + lineItemId);
        });
    };

    const handleEOAPreviews = function() {
        if ($scope.lineItem.__eoa_previews) {
            if ($scope.lineItemIdToFormattedEOAPreviewsArray[$scope.lineItem.id]) {
                $scope.lineItem.__eoa_previews = $scope.lineItemIdToFormattedEOAPreviewsArray[$scope.lineItem.id];
                return;
            }
            const original__eoa_previews = $scope.lineItem.__eoa_previews;
            $scope.lineItem.__eoa_previews = '...computing...';

            OrdersService.getFormattedEOAPreviews(original__eoa_previews).then(function(formatted) {
                $scope.lineItem.__eoa_previews = formatted;
                $scope.lineItemIdToFormattedEOAPreviewsArray[$scope.lineItem.id] = formatted;
            }, function() {
                $scope.lineItem.__eoa_previews = '(Could not display)';
                $scope.lineItemIdToFormattedEOAPreviewsArray[$scope.lineItem.id] = '(Could not display)';
            });
        }
    };

    var getProducts = function(clusterId) {
        ProductService.getProducts(clusterId, false).then(function() {
            const order_type_id = $scope.order.order_type_id[0];
            $scope.products = ProductService.products.filter(function(product) {
                return product.order_type_ids.includes(order_type_id);
            });
        }, function() {
            console.log("Error getting products for business unit: " + clusterId);
        });
    };

    /*
    * Handle get all packages or load form based on product_package_enabled
    */
    $scope.selectProduct = function() {
        if($scope.productPackageEnabled) {
            $scope.hasForm = false;
            $rs.loadingInProgress = true;
            $rs.loadertitle = "Fetching Packages";
            ProductService.getPackages($scope.chosenProduct.id, $scope.order.cluster_id[0]).then(function() {
                $rs.loadingInProgress = false;
                $scope.packages = ProductService.packages;
                if($scope.packages.length===0){
                    $scope.loadForm();
                }
            }, function() {
                $scope.packages = [];
                console.log("Error getting packages for product id: " + $scope.chosenProduct.id + "and business unit" + $scope.order.cluster_id[0]);
            });
        } else {
            $scope.loadForm();
        }
    };

    var getCreatives = function() {
        MediaService.getLineItemCreatives($scope.lineItem.id).then(function() {
            $scope.creatives = [];
            $scope.creatives = MediaService.creatives;
            if ($scope.creatives) {
                $scope.hasCreatives = true;
            }
            $scope.creativesReceived = true;
            loadFormPhase2();
        }, function() {
            console.log("Error getting creatives for line item: " + $scope.lineItem.id);
            $scope.creativesReceived = true;
            loadFormPhase2();
        });
    };

    var getRuleSet = function() {
        RuleService.getRuleSet($scope.chosenProduct.rule_set_id).then(function() {
            var ruleSet = RuleService.ruleSet;

            if (ruleSet) {
                for (var i = 0; i < ruleSet.rules.length; i++) {
                    var rule = ruleSet.rules[i];
                    var productFieldId = "pf" + rule.rule_target;
                    $scope.ruleSet[productFieldId] = rule;
                }
            }
        }, function() {
            console.log("Error getting rule set for product: ");
        });
    };

    $scope.loadForm = function() {
        if ($scope.chosenProduct) {
            $scope.model = {};
            $scope.schema = {};
            $scope.form = [];
            $scope.entireForm = [];
            $scope.hasForm = false;
            $scope.creativesReceived = false;
            $scope.fieldGroupsReceived = false;
            $rs.loadingInProgress = true;
            $rs.loadertitle = "Fetching Product Information";

            // Get product field groups and creatives (if editing) in parallel (we are making some effort to speed
            // things up as this page is quite a high runner), then carry on (loadFormPhase2).
            ProductService.getProductFieldGroups($scope.chosenProduct.id).then(function() {
                $scope.productFieldGroups = ProductService.productFieldGroups;
                $scope.fieldGroupsReceived = true;
                loadFormPhase2();
            });

            if ($scope.isEditing) {
                getCreatives();
            } else {
                $scope.creativesReceived = true;
                loadFormPhase2();  // In case fetching groups was real quick!
            }
        }
    };

    function loadFormPhase2() {
        // Wait for the response to both getCreatives and getProductFieldGroups, done in parallel, before continuing.
        if ($scope.creativesReceived && $scope.fieldGroupsReceived) {
            $scope.creativesReceived = false;
            $scope.fieldGroupsReceived = false;
            let productFieldGroups = $scope.productFieldGroups;
            let params = {};
            params.productId = $scope.chosenProduct.id;
            params.clusterId = $scope.order.cluster_id[0];
            params.addLookupContent = true;
            if ($scope.order && $scope.order.client_id && $scope.order.client_id.length > 0) {
                params.clientId = $scope.order.client_id[0];
            }
            if ($scope.chosenPackage) {
                params.packageId = $scope.chosenPackage.id;
            }
            ProductService.getProductFields(params).then(function () {
                $scope.productFields = ProductService.productFields;

                _.each(productFieldGroups, function (productFieldGroup) {
                    let productGroupFields = _.filter($scope.productFields, {
                        group_id: productFieldGroup.id,
                        active: "1"
                    });
                    productFieldGroup.hasActiveFields = (productGroupFields.length > 0);
                });
                productFieldGroups = _.filter(productFieldGroups, function (item) {
                    return (item.hasActiveFields == true);
                });

                if ($scope.isEditing) {
                    for (var i = 0; i < $scope.productFields.length; i++) {
                        if ($scope.productFields[i].field_name == 'dfp_adsizes') {
                            $scope.productFields[i].default_value = [];
                        }
                    }
                }

                if($scope.productFields[4]?.field_name == 'triton_push_product'){
                    $scope.productFields[4].default_value = $scope.enableFlightPush ? "1" : "0";
                }

                if ($scope.productFields && $scope.productFields.length > 0) {
                    if ($scope.order && $scope.order.client_id && $scope.order.client_id.length > 0) {
                        const clientId = $scope.order.client_id[0];
                        UserService.getClient(clientId).then(function (success) {
                            $scope.client = UserService.client;
                            // We need to set cascading values before invoking buildForm so that multiselect fields
                            // are initialized properly.
                            buildSchema($scope.productFields);
                            setCascadingValues();
                            buildForm($scope.productFields, productFieldGroups);
                            $scope.hasForm = true;
                            $rs.loadingInProgress = false;
                        }, function (error) {
                            $ioAlert.show('Error!', 'An error occurred getting the client information for client_id ' + clientId, 'error');
                            buildSchema($scope.productFields);
                            setCascadingValues();  // We can go ahead anyway, we just won't have client fields available.
                            buildForm($scope.productFields, productFieldGroups);
                            $scope.hasForm = true;
                            $rs.loadingInProgress = false;
                        });
                    } else {
                        buildSchema($scope.productFields);
                        setCascadingValues();
                        buildForm($scope.productFields, productFieldGroups);
                        $scope.hasForm = true;
                        $rs.loadingInProgress = false;
                    }
                } else {
                    $scope.hasForm = false;
                    $rs.loadingInProgress = false;
                }

                if ($scope.chosenProduct.rule_set_id) {
                    getRuleSet();
                }

            }, function () {
                console.log("Error loading form for product: " + $scope.chosenProduct);
                $scope.hasForm = false;
            });
        }
    }

    function setCascadingValues() {
        for (let i = 0; i < $scope.productFields.length; i++) {
            const field = $scope.productFields[i];
            if (field.cascading_field_name && !$scope.model[field.field_name + '_override_cascade']) {

                let cascadingValue = $scope.order[field.cascading_field_name],
                    modelValue;
                if (cascadingValue === undefined && $scope.client) {
                    // Cascading field is not present in the order, look for it in the client instead.
                    cascadingValue = $scope.client[field.cascading_field_name];
                }
                if (field.data_type == "date" && cascadingValue) {
                    let dt = new Date(cascadingValue);
                    modelValue = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
                } else if (field.input_type == "multiselect" || field.input_type == "multiselect-custom") {
                    modelValue = cascadingValue ? transformCascadingArray(cascadingValue) : [];
                    if (!_.isArray(modelValue)) {
                        // This could happen if we are cascading from a select to a multiselect.
                        modelValue = [modelValue];
                    }
                } else if (field.input_type == "checkbox") {
                    modelValue = (cascadingValue === true || cascadingValue == "1");
                } else if (field.input_type == "timeselect") {
                    if (String(cascadingValue).indexOf(":") > 0) {
                        let timeObj = new Date();
                        let timeData = String(cascadingValue).split(" ");
                        let timeVal = String(timeData[0]).split(":");
                        timeVal[0] = (timeData[1] === "PM") ? parseInt(timeVal[0]) + 12 : timeVal[0];
                        timeObj = new Date(timeObj.getFullYear(), timeObj.getMonth(), timeObj.getDate(), parseInt(timeVal[0]), parseInt(timeVal[1]), 0);
                        modelValue = timeObj;
                    }
                } else {
                    modelValue = cascadingValue ? transformCascadingArray(cascadingValue) : cascadingValue;
                    if (_.isArray(modelValue)) {
                        modelValue = _.join(modelValue, ', ');
                    }
                }

                $scope.model[field.field_name] = modelValue;
            }
        }
    }

    function transformCascadingArray(val) {
        if (!_.isArray(val)) {
            return val
        }

        if (_.isArray(val) && !_.isArray(val[0])) {
            return val[1];
        }

        return _.map(val, (lookupContentPair) => {
            let valueIndex = 1
            return lookupContentPair[valueIndex]
        });
    }

    var updateForm = function(lineItemForm, editArray) {

        for (let i = 0, len = editArray.length; i < len; i++) {
            for (let j in lineItemForm) {
                if (lineItemForm.hasOwnProperty(j)) {
                    if (editArray[i].prop == j && editArray[i].type == 'boolean') {
                        lineItemForm[j] = (lineItemForm[j] == 1);
                    } else if (editArray[i].prop == j && editArray[i].type == 'array') {
                        let selectArr = [];
                        let arrlen = lineItemForm[j].length;

                        if (arrlen == 2 && typeof lineItemForm[j][1] === 'string') {
                            selectArr.push(lineItemForm[j][1]);
                        } else {
                            for (let k = 0; k < arrlen; k++) {
                                if (lineItemForm[j][k] && lineItemForm[j][k].length == 1) {
                                    selectArr.push(String(lineItemForm[j][k][0]).replace("CUST_", ""));
                                }else if (lineItemForm[j][k]) {
                                    selectArr.push(lineItemForm[j][k][1]);
                                }
                            }
                        }
                        lineItemForm[j] = selectArr;
                    } else if (editArray[i].prop == j && editArray[i].type == 'json') {
                        // do nothing. This one's for geo location
                    } else if (editArray[i].prop == j && editArray[i].type == 'string' && lineItemForm[j] && typeof lineItemForm[j] === 'object') {
                        lineItemForm[j] = lineItemForm[j][1];
                    }
                }
            }
        }
        $scope.model = lineItemForm;
    };

    $scope.submitForm = function(lineItemForm) {
        let returnObj = FormService.validateCustomWidgets($scope.productFields, $scope.model);
        let dayPartingValidation = FormService.validateDayPartingWidget($scope.productFields, $scope.model);
        let adScheduleValidation = FormService.validateAdScheduleWidget($scope.productFields, $scope.model);
        let pinterestFrequencyValidation = FormService.validatePinterestFrequencyCap($scope.productFields, $scope.model);
        let googleAdsBiddingWidget = $scope.isEditing ? FormService.validateAdsBiddingWidget($scope.productFields, $scope.model) : true;
        let isFormWidgetsValid = !returnObj.validateWidgets.includes(false);
        returnObj.dfpAdsizesValidationStatus = true;
        let creativeRequiredFieldsValid = true;
        $scope.model = returnObj.model;
        if (returnObj.model.dfp_adsizes || returnObj.model.creative_type) {
            if (returnObj.model.dfp_adsizes && returnObj.model.creative_type) {
                if (returnObj.model.dfp_adsizes && returnObj.model.creative_type == "Video") {
                    returnObj.model.dfp_adsizes.forEach((element, index, array) => {
                        const regExp1 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[V|v]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp2 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp3 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp4 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v][\(]Video[\/]Vast|VAST[\)][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index].value):(/^\d{1,4}[X|x]\d{1,4}[V|v][\(]Video[\/]Vast|VAST[\)][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        if (regExp1 || regExp2 || regExp3 || regExp4) {
                            returnObj.dfpAdsizesValidationStatus = true;
                        } else {
                            returnObj.dfpAdsizesValidationStatus = false;
                        }
                    });
                } else if (returnObj.model.dfp_adsizes && returnObj.model.creative_type == "Display") {
                    returnObj.model.dfp_adsizes.forEach((element, index, array) => {
                        const regExp1 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}$/g).test(returnObj.model.dfp_adsizes[index].value):(/^\d{1,4}[X|x]\d{1,4}$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp2 = returnObj.model.dfp_adsizes[index].value ? (/^(4|6)[:](1)$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^(4|6)[:](1)$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp3 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp4 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp5 = returnObj.model.dfp_adsizes[index].value ? (/[a-zA-Z &+,:;=?@#|'<>.^*()%!-]+$/g).test(returnObj.model.dfp_adsizes[index].value) : (/[a-zA-Z &+,:;=?@#|'<>.^*()%!-]+$/g).test(returnObj.model.dfp_adsizes[index]);
                        if (regExp1 || regExp2 || regExp3 || regExp4 || regExp5) {
                            returnObj.dfpAdsizesValidationStatus = true;
                        } else {
                            returnObj.dfpAdsizesValidationStatus = false;
                        }
                    });
                }
            } else if (!returnObj.model.creative_type) {
                $ioAlert.show("Error!", "Please select Ad Type", "error");
                returnObj.dfpAdsizesValidationStatus = false;
            }
        }

        if (typeof returnObj.model.__facebook_adset_creative !== 'undefined' && returnObj.model.__facebook_adset_creative.length > 0) {
            FormService.restoreRequiredCreativeFieldIds($scope.productFields, $scope.savedRequiredCreativeFieldIds);
            const hasRequiredCreativeFields = FormService.formHasRequiredCreativeFields($scope.productFields, $scope.model);
            // When adding (i.e. not editing), validation is done via the standard form required field validation.
            creativeRequiredFieldsValid = ($scope.isEditing && hasRequiredCreativeFields) ?
                FormService.validateCreativesPresent($scope.productFields, $scope.model, $scope.realCreativeFilenames) : true;
        }
        if (typeof returnObj.model.__facebook_adset_creative !== 'undefined' && returnObj.model.__facebook_adset_creative.length === 0) {
            lineItemForm.$valid = true;
        }

        $scope.$broadcast('schemaFormValidate');
        if (lineItemForm.$valid && returnObj.isValid && isFormWidgetsValid && dayPartingValidation && adScheduleValidation &&
            returnObj.dfpAdsizesValidationStatus && creativeRequiredFieldsValid && googleAdsBiddingWidget && pinterestFrequencyValidation) {

            $scope.form[$scope.form.length - 1].isSubmitted = true;
            var lineItem = FormService.harvestData($scope.productFields, $scope.entireForm, $scope.model, 'lineItem', $scope.formParentChildInfo);
            lineItem.productId = $scope.chosenProduct.id;
            lineItem.orderId = $scope.order.id;
            if($scope.chosenPackage) {
                lineItem.packageId = $scope.chosenPackage.id;
            }
            var adgroupCreativeFiles=[];
            var facebookCreativeFiles =[];
            if(lineItem.adGroupField){
                adgroupCreativeFiles.push(lineItem.adGroupField)
            }
            if(lineItem.multiProductAdgroup){
                adgroupCreativeFiles.push(lineItem.multiProductAdgroup);
            }
            if(lineItem.fbCreateAdGroup){
                facebookCreativeFiles = lineItem.fbCreateAdGroup;
            }
            delete lineItem.adGroupField;
            delete lineItem.multiProductAdgroup;
            delete lineItem.fbCreateAdGroup;
            if ($scope.validationErrors.length == 0) {
                if (lineItem.orderId) {
                    $scope.lineItemLoader = true;
                    FormService.getCreateAdvertiserWarning($scope.order.client_id[0], $scope.chosenProduct.service_id, 'lineItem').then(function (data) {
                        if(data === true) {
                            if ($scope.isEditing) {
                                lineItem.id = $scope.lineItem.id;
                                updateLineItem(lineItem, adgroupCreativeFiles, facebookCreativeFiles);
                            } else {
                                addLineItem(lineItem, adgroupCreativeFiles, facebookCreativeFiles);
                            }
                        } else {
                            $scope.form[$scope.form.length - 1].isSubmitted = false;
                        }
                    }, function () {
                        $ioAlert.show("Error!", "An error occurred saving the new line item.", 'error');
                    });
                } else {
                    console.log("Missing order Id for new line item.");
                    $scope.form[$scope.form.length - 1].isSubmitted = false;
                }
            } else {
                for (var x = 0; x < $scope.validationErrors.length; x++) {
                    $ioAlert.show("Error", $scope.validationErrors[x], "error");
                }
                $scope.validationErrors = [];
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            }
        } else {
            if (!("parse" in lineItemForm.$error) && ("date" in lineItemForm.$error)) {
                $ioAlert.show("Validation Error!", "Please fill in date value " + lineItemForm.$error.date[0].$viewValue + " using calendar", 'error');
            } else if(!dayPartingValidation){
                $ioAlert.show("Validation Error!", "Please check the day parting time slots", "error");
            } else if(!returnObj.dfpAdsizesValidationStatus) {
                $ioAlert.show("Validation Error!", "Please check the DFP Adsizes", "error");
            } else if (!creativeRequiredFieldsValid) {
                $ioAlert.show("Validation Error!", "Please add in the required creative", 'error');
            } else if(!googleAdsBiddingWidget){
                $ioAlert.show('Error!',' There are not enough conversions to use Target ROAS. Select a different bid strategy or wait for the tracker to generate min 15 conversions', 'error');
            } else if (!pinterestFrequencyValidation){
                $ioAlert.show("Validation Error!", "Frequency cap value must be between -1 and 20", "error");
            }
            else {
                $ioAlert.show("Validation Error!", "Please fill in the required fields", 'error');
            }
        }
    };

    let addLineItem = function (lineItem, adGroupField, facebookCreativeFiles) {
        if (formHasFileOrCreativeUpload($scope.model, $scope.productFields)) {
            lineItem.uploadPending = '1';
            $scope.uploadFileSet = new Set();
        }
        OrdersService.addLineItem(lineItem).then(function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            } else if (OrdersService.lineItem) {
                if ($scope.model.comment) {
                    addComment(OrdersService.lineItem.id, $scope.model.comment);
                }

                $scope.fileUploadKey = OrdersService.lineItem.__FILE_UPLOAD_KEY;
                let hasFileUpload = false;
                _.each($scope.model, function(itemVal, prop){
                    let fieldObj = _.find($scope.productFields, {field_name: prop});
                    if (fieldObj && fieldObj.input_type == "file" && $scope.model[prop]) {
                        hasFileUpload = true;
                        let adGroupMoreInfo = { 'fieldId': fieldObj.id, 'fieldType': fieldObj.input_type, 'entityType': 'line_item' };
                        addCreativeAssets(OrdersService.lineItem.id, $scope.model[prop], false, adGroupMoreInfo);
                    } else if (fieldObj && ['creative', 'facebook-create-ads'].includes(fieldObj.input_type) && $scope.model[prop]) {
                        hasFileUpload = true;
                        if ($scope.model[prop].media) {
                            addCreativeAssets(OrdersService.lineItem.id, $scope.model[prop].media, $scope.model[prop].library);
                        } else if ($scope.model[prop].selectedImages) {
                            attachCreatives(OrdersService.lineItem.id, $scope.model[prop].selectedImages)
                            if ($scope.model[prop].thirdPartyCreatives) {
                                attachCreatives(OrdersService.lineItem.id, $scope.model[prop].thirdPartyCreatives)
                            }
                        }
                    } else if (fieldObj && fieldObj.input_type == "custom_file" && $scope.model[prop]) {
                        $scope.uploadCustomFile(OrdersService.lineItem.id, prop);
                    }
                });

                if (adGroupField) {
                    _.each(adGroupField, function (adGroupData) {
                        if(adGroupData.obj){
                            _.each(adGroupData.obj, function (adGroupObj, adGroupIndex) {
                                if (adGroupObj.ads) {
                                    _.each(adGroupObj.ads, function (adObj, adIndex) {
                                        if (adObj.creativeImages && adObj.creativeImages.length > 0) {
                                            let adGroupMoreInfo = { 'adGroupIndex': adGroupIndex, 'adIndex': adIndex, 'fieldId': adGroupData.field.id };
                                            addCreativeAssets(OrdersService.lineItem.id, adObj.creativeImages, false, adGroupMoreInfo);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                if (facebookCreativeFiles && facebookCreativeFiles.length > 0) {
                    _.each(facebookCreativeFiles, function (fbAdGroupObj, adIndex) {
                        if (fbAdGroupObj) {
                            if (fbAdGroupObj.uploadedCreatives && fbAdGroupObj.uploadedCreatives.length > 0) {
                                attachCreatives(OrdersService.lineItem.id, fbAdGroupObj.uploadedCreatives)
                            }
                        }
                    });
                }

                if (!hasFileUpload) {
                    $scope.savedSuccessfully = true;
                }
                $scope.backToOrder();
            } else {
                $ioAlert.show("Error!", "An error occurred saving the new line item.", 'error');
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            }
        }, function() {
            $ioAlert.show("Error!", "An error occurred saving the new line item.", 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
        });
    };

    let updateLineItem = function(lineItem, adGroupField, facebookCreativeFiles) {
        lineItem.statusId = $scope.lineItem.status_id;

        if ($scope.addCommentRequired && !$scope.model.comment) {
            $ioAlert.show("Error", 'A comment is required when editing a line item', "error");
            $scope.form[$scope.form.length - 1].isSubmitted = false;
            return;
        }

        let uploadingFiles;
        let deletedFiles;
        let hasFileOrCreativeModifications;
        [uploadingFiles, deletedFiles, hasFileOrCreativeModifications] =
            computeUploadingAndDeletedFiles($scope.model, $scope.form, $scope.productFields);

        if (hasFileOrCreativeModifications) {
            lineItem.uploadPending = '1';
            $scope.uploadFileSet = new Set();
        }

        OrdersService.updateLineItem(lineItem).then(function() {
            if (OrdersService.errors) {
                for (let x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            } else if (OrdersService.lineItem) {
                if ($scope.model.comment) {
                    addComment(OrdersService.lineItem.id, $scope.model.comment, 'entity_update');
                }

                $scope.fileUploadKey = OrdersService.lineItem.__FILE_UPLOAD_KEY;

                _.each($scope.model, function(itemVal, prop) {
                    let fieldObj = _.find($scope.productFields, {field_name: prop});
                    if (fieldObj && fieldObj.input_type == "file" && $scope.model[prop]) {
                        let adGroupMoreInfo = {
                            fieldId: fieldObj.id,
                            fieldType: fieldObj.input_type,
                            entityType: 'line_item',
                        };
                        if (deletedFiles[prop].length > 0) {
                            deleteCreativeAssets(OrdersService.lineItem.id, deletedFiles[prop], adGroupMoreInfo);
                        }
                        if (uploadingFiles[prop].length > 0) {
                            addCreativeAssets(OrdersService.lineItem.id, uploadingFiles[prop], false, adGroupMoreInfo);
                        }
                    } else if (fieldObj && ['creative', 'facebook-create-ads'].includes(fieldObj.input_type) && $scope.model[prop]) {
                        if ($scope.model[prop]) {
                            if ($scope.model[prop].media && $scope.model[prop].media.length > 0) {
                                addCreativeAssets(OrdersService.lineItem.id, $scope.model[prop].media, $scope.model[prop].library);
                            }
                            if ($scope.model[prop].selectedImages && $scope.model[prop].selectedImages.length > 0) {
                                attachCreatives(OrdersService.lineItem.id, $scope.model[prop].selectedImages)
                            }
                            if ($scope.model[prop].thirdPartyCreatives && $scope.model[prop].thirdPartyCreatives.length > 0) {
                                attachCreatives(OrdersService.lineItem.id, $scope.model[prop].thirdPartyCreatives)
                            }
                        }
                    } else if (fieldObj && fieldObj.input_type == "custom_file" && $scope.model[prop]) {
                        $scope.uploadCustomFile(OrdersService.lineItem.id, prop);
                    }
                });

                if (adGroupField) {
                    _.each(adGroupField, function (adGroupData) {
                        if(adGroupData.obj){
                            _.each(adGroupData.obj, function (adGroupObj, adGroupIndex) {
                                if (adGroupObj.ads) {
                                    _.each(adGroupObj.ads, function (adObj, adIndex) {
                                        if (adObj.creativeImages && adObj.creativeImages.length > 0) {
                                            var adGroupMoreInfo = { 'adGroupIndex': adGroupIndex, 'adIndex': adIndex, 'fieldId': adGroupData.field.id };
                                            addCreativeAssets(OrdersService.lineItem.id, adObj.creativeImages, false, adGroupMoreInfo);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                if (facebookCreativeFiles && facebookCreativeFiles.length > 0) {
                    _.each(facebookCreativeFiles, function (fbAdGroupObj, adIndex) {
                        if (fbAdGroupObj) {
                            if (fbAdGroupObj.uploadedCreatives && fbAdGroupObj.uploadedCreatives.length > 0) {
                                attachCreatives(OrdersService.lineItem.id, fbAdGroupObj.uploadedCreatives)
                            }
                        }
                    });
                }

                if (!hasFileOrCreativeModifications) {
                    $scope.savedSuccessfully = true;
                }
                $scope.backToOrder();
            } else {
                $ioAlert.show("Error!", "An error occurred updating the line item.", 'error');
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            }
        }, function(error) {
            console.log(error);
            $ioAlert.show("Error!", "An error occurred updating the line item.", 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
        });
    };

    $scope.uploadCustomFile = function(lineItemId, prop) {
        $scope.waitForLineItemCustomFileUpload = true;
        FormService.processCustomFile('line_item', lineItemId, prop, $scope.model[prop], 0).then(function() {
            $scope.waitForLineItemCustomFileUpload = false;
            $scope.backToOrder();
        }, function() {
            $scope.waitForLineItemCustomFileUpload = false;
            $scope.backToOrder();
        });
    };

    const formHasFileOrCreativeUpload = function(model, fields) {
        let found = false;
        _.each(model, function(itemVal, prop) {
            let fieldObj = _.find(fields, {field_name: prop});
            if (fieldObj && (fieldObj.input_type == "file" || fieldObj.input_type == "facebook-create-ads" || fieldObj.input_type == "creative") && model[prop]) {
                found = true;
            }
            if (prop === "__facebook_adset_creative" && model[prop] && model[prop].length === 0) {
                found = false;
            }
        })

        return found;
    }

    const computeUploadingAndDeletedFiles = function(model, form, fields) {
        let uploadingFiles = [];
        let deletedFiles = [];
        let hasFileOrCreativeModifications = false;

        _.each(model, function(itemVal, prop) {
            let fieldObj = _.find(fields, {field_name: prop});
            if (fieldObj && fieldObj.input_type == "file" && model[prop]) {
                let oldFileObjList = [];
                for (let k = 0, len = form.length; k < len; k++) {
                    if (form[k].items) {
                        let fileField = _.find(form[k].items, {field_name: prop});
                        if (fileField) {
                            oldFileObjList = fileField.fileObjList ? fileField.fileObjList : [];
                            break;
                        }
                    }
                }

                let currentUploadingFiles = model[prop];
                let currentDeletedFiles = [];
                if (oldFileObjList && oldFileObjList.length > 0) {
                    // newFileObjList is the file list on the form
                    // oldFileObjList is the file list from database
                    // uploadingFileObjs will keep the new files the user added
                    // deleteFiles will keep the files the user removed
                    // We remove the duplicated files between 2 arrays to avoid duplicated file uploading
                    // newFileObjList structure [{ name: val }, { name: val }]
                    // oldListObjList structure [{ attr_id: val, file_name: val}, {attr_id: val, file_name: val}]
                    let newFileObjList = model[prop];
                    let newListSet = new Set(newFileObjList.map(({ name }) => name));
                    let oldListSet = new Set(oldFileObjList.map(({file_name}) => file_name));
                    currentUploadingFiles = newFileObjList.filter(({ name }) => !oldListSet.has( name ));
                    currentDeletedFiles = oldFileObjList.filter(({file_name}) => !newListSet.has(file_name));
                }
                if (currentUploadingFiles.length > 0 || currentDeletedFiles.length > 0) {
                    hasFileOrCreativeModifications = true;
                }
                uploadingFiles[prop] = currentUploadingFiles;
                deletedFiles[prop] = currentDeletedFiles;
            } else if (fieldObj && ['creative', 'facebook-create-ads'].includes(fieldObj.input_type) && model[prop]) {
                if ($scope.model[prop].media || ($scope.model[prop].selectedImages && $scope.model[prop].selectedImages.length > 0)) {
                    hasFileOrCreativeModifications = true;
                }
                if ($scope.model[prop].media || ($scope.model[prop].thirdPartyCreatives && $scope.model[prop].thirdPartyCreatives.length > 0)) {
                    hasFileOrCreativeModifications = true;
                }
            }
        });

        return [uploadingFiles, deletedFiles, hasFileOrCreativeModifications];
    }

    const fileUploadComplete = function(lineItemId, fileUploadIdx) {
        $scope.uploadFileSet.delete(fileUploadIdx);
        if ($scope.uploadFileSet.size == 0 && $scope.fileUploadKey) {
            // All uploads (or deleteCreatives, or attachCreatives) are done, so we can let the backend know,
            // so that it can run the workflow and perform any webhook pushes.
            OrdersService.lineItemFileUploadCompleted(lineItemId, $scope.fileUploadKey).then(function(lineItem) {
                // Comments can be generated by the system during workflow execution (e.g. when a webhook is sent),
                // so fetch them again now that it has run. However, cannot just invoke getComments as we moved
                // to another controller (the ViewLineItemController), so broadcast a message so that it reloads
                // comments from there. Use a more generic event name in case other actions (besides reloading
                // comments) are necessary.
                $rs.$broadcast('fileUploadWorkflowExecutionComplete', lineItem);
            }, function() {});
            $scope.fileUploadKey = null;
        }
    }

    /*$rs.$on('$destroy', function() {
        lineItemUploadSuccess();
    });*/

    let lineItemAdsUpload = function(moreInfo, successObj, uploadFileIdx) {
        let litemItemAttrData = null;
        if (moreInfo.fieldId) {
            litemItemAttrData = _.find(OrdersService.lineItemAttrs, {product_field_id: moreInfo.fieldId});
        }
        if (litemItemAttrData) {
            if(typeof litemItemAttrData.attr_value === 'string'){
                litemItemAttrData.attr_value = JSON.parse(litemItemAttrData.attr_value);
            }
            litemItemAttrData.attr_value[moreInfo.adGroupIndex].ads[moreInfo.adIndex].creativeImages = [{
                "name": successObj.file_name,
                "url": successObj.secure_url
            }];
            let lineItemAttrObj = {
                id: litemItemAttrData.id,
                lineItemId: litemItemAttrData.line_item_id,
                productFieldId: litemItemAttrData.product_field_id,
                attrValue: JSON.stringify(litemItemAttrData.attr_value)
            }
            OrdersService.updateLineItemAttributes(lineItemAttrObj).then(function(response) {
                var litemItemAttrIndex = _.findIndex(OrdersService.lineItemAttrs, {product_field_id: moreInfo.fieldId});
                if(litemItemAttrIndex && response.attr_value){
                    OrdersService.lineItemAttrs[litemItemAttrIndex] = response.attr_value;
                }
                fileUploadComplete(moreInfo.lineItemId, uploadFileIdx);
            }, function() {
                $ioAlert.show("Error!", "An error occurred updating the line item attr.", 'error');
                fileUploadComplete(moreInfo.lineItemId, uploadFileIdx);
            });
        } else {
            fileUploadComplete(moreInfo.lineItemId, uploadFileIdx);
        }
    };

    let lineItemFileUpload = function(moreInfo, successObj, uploadFileIdx) {
        if (moreInfo.fieldId) {
            let lineItemAttrObj = {
                lineItemId: moreInfo.lineItemId,
                productFieldId: moreInfo.fieldId,
                attrValue: successObj.id,
            }
            OrdersService.addNewLineItemAttributes(lineItemAttrObj).then(function(response) {
                $rs.$broadcast('updateLineItemFileFieldAndCreatives', moreInfo.lineItemId, moreInfo);
                fileUploadComplete(moreInfo.lineItemId, uploadFileIdx);
            }, function() {
                $ioAlert.show("Error!", "An error occurred adding the line item attr.", 'error');
                fileUploadComplete(moreInfo.lineItemId, uploadFileIdx);
            });
        } else {
            fileUploadComplete(moreInfo.lineItemId, uploadFileIdx);
        }
    };

    // Hack to remove any previous listener, otherwise they add up every time you load that page.  Could not destroy
    // it when destroying the scope because it is needed to finish loading the creatives after having left the page, so
    // in this case $destroy runs too early...
    $rs.$$listeners['lineItemUploadSuccess'] = [];
    $rs.$$listenerCount['lineItemUploadSuccess'] = 0;
    /* (ideally) let destroyLineItemUploadSuccess = */ $rs.$on("lineItemUploadSuccess", function(event, successObj, additionalParams){
        let moreInfo = additionalParams.adGroupMoreInfo;
        if (!moreInfo) {
            fileUploadComplete(additionalParams.lineItemId, additionalParams.uploadFileIdx);
            return;
        }
        moreInfo.lineItemId = additionalParams.lineItemId;
        switch (moreInfo.entityType) {
            case 'line_item':
                lineItemFileUpload(moreInfo, successObj, additionalParams.uploadFileIdx);
                break;
            default:
                lineItemAdsUpload(moreInfo, successObj, additionalParams.uploadFileIdx);
        }
    });

    let deleteCreativeAssets = function(lineItemId, creativeFileObjs, adGroupMoreInfo) {
        let lineItemAttrIds = [];
        _.each(creativeFileObjs, function(fileObj){
            lineItemAttrIds.push(fileObj.attr_id);
        });
        let params = {
            'lineItemAttrIds': lineItemAttrIds.toString(),
        }
        if (lineItemAttrIds.length > 0) {
            const uploadFileIdx = $scope.uploadFileSetIndex++;
            $scope.uploadFileSet.add(uploadFileIdx);
            OrdersService.removeLineItemFileAttrsAndCreatives(params).then(function(response) {
                $rs.$broadcast('updateLineItemFileFieldAndCreatives', lineItemId, adGroupMoreInfo);
                $ioAlert.show("", "File was removed successfully.", 'success');
                fileUploadComplete(lineItemId, uploadFileIdx);
            }, function() {
                $ioAlert.show("Error!", "An error occurred deleting the line item attrs.", 'error');
                fileUploadComplete(lineItemId, uploadFileIdx);
            });
        }
    };

    let addCreativeAssets = function(lineItemId, creativeFiles, addToLibrary, adGroupMoreInfo) {

        angular.forEach(creativeFiles, function(file, index) {
            if (index == 0) {
                $ioAlert.show('', "Creatives are being uploaded, please don't refresh the page. You can check the status in the notifications tab", 'success');
            }
            if (file && file.name && file.size) {
                const uploadFileIdx = $scope.uploadFileSetIndex++;
                // Here we add a value to uploadFileSet, but we do not invoke fileUploadComplete, because we'll do another
                // backend query after getting the image signature and uploading the file.
                // The reason we're adding a value to the set, then, is to prevent another file or backend query
                // from causing us to think we are done with all the uploads.
                $scope.uploadFileSet.add(uploadFileIdx);
                CreativeServicePro.getImgSignature({ lineItemId: lineItemId, fileName: file.name }).then(function(success) {
                    let additionalParams = {
                        "from": "lineItem",
                        "lineItemId": lineItemId,
                        "libraryCreative": addToLibrary ? "yes" : "no",
                        "file_name": file.name,
                        "file_size": file.size,
                        "uploadFileIdx": uploadFileIdx
                    };
                    if (adGroupMoreInfo) {
                        additionalParams.adGroupMoreInfo = adGroupMoreInfo;
                    }
                    $rs.$broadcast('cloudinaryImageUpload', success, file, additionalParams);
                }, function(error) {
                    const details = JSON.stringify(error);
                    $ioAlert.show('Error!', 'Could not get img signature.' + details, 'error');
                    console.log(error);
                    fileUploadComplete(lineItemId, uploadFileIdx);
                });
            }
        });
    };

    let attachCreatives = function(lineItemId, selectedCreatives) {
        for (let i = 0, len = selectedCreatives.length; i < len; i++) {
            if (selectedCreatives[i].creative_id) {
                selectedCreatives[i].id = selectedCreatives[i].creative_id;
            }
            const obj = {
                "creativeId": selectedCreatives[i].id,
                "lineItemId": lineItemId
            };
            const uploadFileIdx = $scope.uploadFileSetIndex++;
            $scope.uploadFileSet.add(uploadFileIdx);
            creativesService.assignToLineItem(obj).then(function(response) {
                if (response && response.errors) {
                    for (let j = 0, len = response.errors.length; j < len; j++) {
                        $ioAlert.show('Error', response.errors[j]);
                    }
                }
                fileUploadComplete(lineItemId, uploadFileIdx);
            }, function() {
                $ioAlert.show('ERROR!', 'Attaching creative failed. Please try again later.', 'error');
                fileUploadComplete(lineItemId, uploadFileIdx);
            });
        }
    };

    let addComment = function(lineItemId, commentText, behaviour) {
        const params = {
            'tableName': 'line_item',
            'tableRowId': lineItemId,
            'comment': commentText,
            'skipWorkflow': '0',
            'addedByBehaviour': behaviour,
        };

        CommentService.addLineItemComment(params).then(function() {},
            function() {
                console.log("Error saving comment for line item: " + lineItemId);
            });
    };

    $scope.getComments = function(lineItemId) {
        CommentService.getLineItemComments(lineItemId).then(function(comments) {
            $scope.comments = comments;
        }, function() {
            console.log("Error getting comments for line item: " + lineItemId);
        });
    };

    function deleteCreativeModal(type, data) {
        return $modal.open(ModalService.returnModalType(type, { data: data }));
    }
    $scope.deleteCreative = function(creative) {
        deleteCreativeModal('deleteCreative', { filename: creative.file_name }).result.then(function(selectedItem) {
            if (selectedItem == 'ok') {
                MediaService.deleteCreative(creative.id).then(function(success) {
                    if (success.id) {
                        for (let i = 0; i < $scope.creatives.length; i++) {
                            if ($scope.creatives[i].creative_id == success.id) {
                                $scope.creatives.splice(i, 1);
                            }
                        }
                        // Remove it from our list of "real" creatives (i.e. creatives for "creative" and not "file"
                        // form fields), so that if they're empty, and if we have required creative fields on the form,
                        // we validate that a new one has been added during the edit.  If there was a real creative on
                        // the line item, we do not require a creative to be added again when editing it even if the creative
                        // is a required field.
                        for (let i = 0; i < $scope.realCreativeFilenames.length; i++) {
                            if ($scope.realCreativeFilenames[i] == creative.file_name) {
                                $scope.realCreativeFilenames.splice(i, 1);
                            }
                        }
                        success.updated_fields.forEach((field) => {
                            $scope.model[field.field_name] = field.field_value;
                        });
                        $ioAlert.show("", "Successfully deleted " + creative.file_name, "success");
                    } else {
                        $ioAlert.show("Error!", "An error occurred deleting the creative media.", 'error');
                    }
                }, function() {
                    $ioAlert.show("Error!", "An error occurred deleting the creative media.", 'error');
                });
            }
        }, function() {
            // dismiss modal
        });
    };

    $scope.backToOrder = function() {
        if (!$scope.waitForLineItemCustomFileUpload) {
            let message = null;
            if ($scope.savedSuccessfully) {
                if ($scope.isEditing) {
                    message = "editLineItem";
                } else {
                    message = "newLineItem";
                }
            }
            if ($stateParams.comeFrom == 'lineItemView') {
                $state.go('viewLineItem', {
                    lineItemId: $stateParams.lineItemId,
                    formattedEOAPreviews: $scope.lineItemIdToFormattedEOAPreviewsArray[$stateParams.lineItemId]
                });
            } else {
                $state.go('viewOrder', {
                    id: $scope.order.id,
                    message: message
                });
            }
        }
    };

    /*
    *   Handle enable/disable package and add comment required
    */
    function getInstanceSettings() {
        let params = [
            "product_package_enabled",
            "add_comment_is_required_for_editing",
            "triton_enable_push_flights",
        ];
        FormService.getInstanceSettingArray(params).then(function(data) {
            if (data && data.instance_settings) {
                let productPackageEnabled = data.instance_settings.product_package_enabled;
                $scope.productPackageEnabled = (productPackageEnabled === "1");
                let addCommentRequired = data.instance_settings.add_comment_is_required_for_editing;
                $scope.addCommentRequired = (addCommentRequired === "1");
                let enableFlightPush = data.instance_settings.triton_enable_push_flights;
                $scope.enableFlightPush = (enableFlightPush === "1");
            }
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    }

    var init = function() {
        getInstanceSettings();
        if (!$scope.order && !$stateParams.lineItemId) {
            getOrder($stateParams.orderId, false);
        } else if ($stateParams.lineItemId) {
            getLineItem($stateParams.lineItemId);
            $scope.lineItemIdToFormattedEOAPreviewsArray[$stateParams.lineItemId] = $stateParams.formattedEOAPreviews;
            $scope.isEditing = true;
        } else if ($scope.order) {
            getProducts($scope.order.cluster_id[0]);
        }
        if ($stateParams.comeFrom && $stateParams.comeFrom == "lineItemView") {
            $scope.backBtnText = "Return to view line item";
        } else {
            $scope.backBtnText = "Return to view orders";
        }
    };

    init();

}

ViewLineItemController.$inject = ['$scope', '$timeout', '$window', '$rootScope', '$stateParams', '$state', '$confirm', 'DisplayService', 'OrdersService', 'ClusterService', 'DateRangeService', 'ProductService', 'MediaService', 'CommentService', 'AppConfig', '$ioAlert', '$filter', 'TaskService', '$modal', 'ModalService', 'creativesService', 'CreativeServicePro', 'CurrentUser', 'FormService', 'UserService', 'WorkflowsService', 'UserFactory'];

function ViewLineItemController($scope, $timeout, $window, $rs, $stateParams, $state, $confirm, DisplayService, OrdersService, ClusterService, DateRangeService, ProductService, MediaService, CommentService, AppConfig, $ioAlert, $filter, TaskService, $modal, ModalService, creativesService, CreativeServicePro, CurrentUser, FormService, UserService, WorkflowsService, UserFactory) {
    const STATUS_LINEITEM_PENDING_CANCEL = 30;
    const STATUS_LINEITEM_CANCEL = 32;
    const STATUS_LINEITEM_COMPLETE = 13;
    const STATUS_LINEITEM_CLOSED = 14;

    const ASYNCHRONOUS_FIELDS_POLL_TIMEOUT = 30000;

    const FlightStatusNames = {
        STATUS_NAME_FLIGHT_COMPLETE: 'Complete',
        STATUS_NAME_FLIGHT_CLOSED: 'Closed',
        STATUS_NAME_FLIGHT_CANCELLED: 'Cancelled'
    };

    const watcher_authorized_roles = [
        'superadmin',
        'ad_operations_manager',
        'ad_operations',
        'sales_manager',
    ];

    $scope.lineItem = null;
    $scope.lineItemIdToFormattedEOAPreviewsArray = [];
    $scope.waitingForProductFields = false;
    $scope.waitingForEOAPreviews = false;
    $scope.productFields = null;
    $scope.productDisplayGroups = [];
    $scope.flightDisplayGroups = [];
    $scope.lineItemActivity = null;
    $scope.lineItemId = 0;
    $scope.creatives = [];
    $scope.comments = [];
    $scope.loadComplete = false;
    $scope.showOnDemandTasks = false;
    $scope.duplicateOnDemandTasks = false;
    $scope.allowDeleteComments = false;
    $scope.isAdmin = false;
    $scope.restrictEdit = false;
    $scope.currentUserId = $rs.userRole.id;
    $scope.showStartOver = false;
    $scope.fromMyTasks = false;
    $scope.startLineItemWatcherForMeHide = true;
    $scope.lineItemWatchersList = [];
    let lineItemFlightTabClasses;
    $scope.flightTabCurrentlyShown = false;
    $scope.product = null;
    $scope.flightLoaded = false;
    $scope.initialFlightId = undefined;
    $scope.showArchivedFlights = false;
    $scope.currentViewFlight = null;
    $scope.currentEditedFlight = {};
    $scope.flights = [];   // This is the flights list, that does not have task details
    $scope.visibleFlights = [];   // This is the visible (archived or not) flights list, that does not have task details
    $scope.cachedFlights = []; // The flights cached here are complete, including task fields.
    $scope.flightFormattedEOAPreviews = [];
    $scope.cachedFlightDisplayGroups = [];
    $scope.cachedFlightComments = [];
    $scope.cachedLineItemComments = null;
    $scope.cachedLineItemOnDemandTasks = [];
    $scope.cachedFlightOnDemandTasks = [];
    $scope.cachedLineItemDuplicateTasks = false;
    $scope.cachedFlightDuplicateTasks = false;
    $scope.cachedFlightCreatives = [];
    $scope.flightIdsWithNewCreatives = [];
    $scope.flightCreatives = null;
    $scope.autoCreatedFlightsPresent = false;  // To disable the "Create Flights" button if we already created them.
    $scope.savedRequiredCreativeFieldIds = [];
    $scope.realCreativeFilenames = [];
    // cachedTasks and cachedHistoryTasks are for when showSpecificLineItemAndFlightTasks is false.
    let cachedTasks = [];
    let cachedHistoryTasks = [];
    // cachedLineItemTasks, cachedFlightTasks, cachedLineItemHistoryTasks and cachedFlightHistoryTasks are for when
    // showSpecificLineItemAndFlightTasks is true.
    let cachedLineItemTasks = undefined;
    let cachedFlightTasks = []; // array of array, indexed by flight_id
    let cachedLineItemHistoryTasks = undefined;
    let cachedFlightHistoryTasks = []; // array of array, indexed by flight_id
    $scope.isAdding = false;
    $scope.isEditing = false;
    $scope.formFieldMap = {};
    $scope.schema = {};
    $scope.form = [];
    $scope.entireForm = [];
    $scope.model = {};
    $scope.flightFormFieldsWithContent = null;
    $scope.waitingForCreatives = false;
    $scope.formParentChildInfo = {};
    $scope.hasForm = false;
    $scope.client = null;
    $scope.validationErrors = [];
    $scope.showHistoryTaskProgress = false;
    $scope.showTaskProgress = false;
    $scope.waitForFlightFileUpload = false;
    $scope.waitForFlightFileDelete = false;
    $scope.waitForFlightCustomFileUpload = false;
    $scope.filterDate = [{name:"Order Approval",key:"1"},
    {name:"Display Radio Fulfillment",key:"2"},{name:"Rejected Items",key:"3"},{name:"Draft Orders",key:"4"},{name:"Creative Build",key:"5"},{name:"Ongoing Campaign Management",key:"6"}]
    $scope.commentTaskModel = {
        quickComment: null,
        onDemandTask: null,
        addOnDemandTask: null,
        addOnDemandTaskToggle: false
    };
    $scope.commentFilter = {
        items: [],
        search: ""
    };
    $scope.goals = null;
    $scope.hasGoals = false;
    $scope.someFlightsSelected = false;
    $scope.allFlightsSelected = false;
    $scope.autoCreateFlightEnabled = false;
    $scope.hasDistributedTargetFields = false;
    // instance settings initialize
    $scope.addCommentRequired = true;
    $scope.uploadFileSetIndex = 1;
    $scope.uploadFileSet = null;
    $scope.budgetManagementEnabled = false;
    $scope.showLineItemComments = true;
    $scope.showSpecificLineItemAndFlightTasks = false;
    $scope.hasLineItemAsynchronousFields = false;
    $scope.hasFlightAsynchronousFields = false;
    $scope.asynchronousTimerId = null;
    $scope.allowed_order_statuses = [];
    $scope.disableClone = false;
    $scope.enablePushFlights = false;

    if ($stateParams.taskId) {
        $scope.taskId = $stateParams.taskId;
        $scope.hasTaskId = true;
        $scope.fromMyTasks = true;
    } else {
        $scope.hasTaskId = false;
        $scope.fromMyTasks = false;
    }
    if ($rs.userRole.permissions && $rs.userRole.permissions.indexOf("admin") > -1) {
        $scope.allowDeleteComments = true;
    }

    $scope.openExceptionLog = function (exception_log_id) {
        var modalInstance = $modal.open(
            ModalService.returnModalType('commonLog', {
                data: {
                    title: 'Exception Log',
                    logType: 'exceptionLog',
                    id: exception_log_id,
                }
            })
        );
    };

    $scope.openLineitemInGAM = function () {
        var GAMLink = 'https://admanager.google.com/' + $scope.lineItem.network_id + '#delivery/line_item/detail/order_id=' + $scope.lineItem.dfp_order_id + '&line_item_id=' + $scope.lineItem.dfp_line_item_id + '&sort_by=ComputedStatusRank';
        $window.open(GAMLink);
    };
    $scope.openLineitemInFacebookAdManager = function () {
        var FacebookAdManagerLink = 'https://adsmanager.facebook.com/adsmanager/manage/adsets?act=853647471888371&selected_campaign_ids=' + $scope.lineItem.line_item_external_id.external_id ;
        $window.open(FacebookAdManagerLink);
    };
    $scope.openLineitemInSnapChatAdsManager = function () {
        var SnapChatAdsManagerLink = 'https://ads.snapchat.com/cd48b5f0-4479-48eb-a190-455201e08267/manage?manageViewId=' + $scope.lineItem.order_external_id;
        $window.open(SnapChatAdsManagerLink);
    };
    $scope.openLineitemInGoogleAdsAdManager = function () {
        let GoogleAdsAdManagerLink = 'https://ads.google.com/aw/campaigns?campaignId=' + $scope.lineItem.line_item_external_id ;
        $window.open(GoogleAdsAdManagerLink);
    };

    $scope.openFlightInGAM = function () {
        var GAMLink = 'https://admanager.google.com/' + $scope.currentViewFlight.network_id + '#delivery/line_item/detail/order_id=' +
            $scope.currentViewFlight.dfp_order_id + '&line_item_id=' + $scope.currentViewFlight.dfp_line_item_id +
            '&flight_id=' + $scope.currentViewFlight.dfp_flight_id + '&sort_by=ComputedStatusRank';
        $window.open(GAMLink);
    };

    $scope.openDfpFlightInGAM = function () {
        var GAMLink = 'https://admanager.google.com/' + $scope.currentViewFlight.network_id + '#delivery/line_item/detail/order_id=' +
            $scope.currentViewFlight.dfp_order_id + '&line_item_id=' + $scope.currentViewFlight.dfp_flight_id + '&sort_by=ComputedStatusRank';
        $window.open(GAMLink);
    };
    $scope.openLineitemInLinkedin = function () {
        var LinkedinAdLink = 'https://www.linkedin.com/campaignmanager/accounts/' + $scope.lineItem.ad_account_id +'/campaigns/' + $scope.lineItem.line_item_external_id + '/details';
        $window.open(LinkedinAdLink);
    };
    $scope.openLineitemInPinterest = function () {
        var PinterestAdLink = 'https://ads.pinterest.com/advertiser/' + $scope.lineItem.ad_account_id +'/reporting/adgroups/?campaignIds=[' + $scope.lineItem.line_item_external_id + ']';
        $window.open(PinterestAdLink);
    };
    $scope.openLineitemInTiktok = function () {
        var TiktokAdLink = 'https://ads.tiktok.com/i18n/perf/campaign?aadvid=' + $scope.lineItem.ad_account_id
        $window.open(TiktokAdLink);
    };
    $scope.openLineitemInTriton = function () {
        var TritonAdLink = 'https://triton-ad-platform.tritondigital.com/campaigns/' + $scope.lineItem.line_item_external_id;
        $window.open(TritonAdLink);
    };
    $scope.openLineitemInXandr = function () {
        var XandrLink = 'https://invest.xandr.com/bmw/line-item/' + $scope.lineItem.line_item_external_id;
        $window.open(XandrLink);
    };
    $scope.openGoals = async function(goal) {
        let path = AppConfig.TA_URL;
        if (goal.is_old === "0" || !goal.is_old) {
            if (!$rs.user.user.use_nui) {
                await UserFactory.updateAppPreference({use_nui: true});
            }
            window.location = `${AppConfig.NUI_PATH}#/sup/goals?id=${goal.id}`;
        } else {
            const start_date = moment.unix(goal.start).utc().format('YYYY-MM-DD');
            const end_date = moment.unix(goal.end).utc().format('YYYY-MM-DD');
            $window.open(path + `#/goals?start_date=${start_date}&end_date=${end_date}`, '_self');
        }
    };

    var getOnDemandTasks = function() {
        TaskService.getOnDemandTasksForProduct($scope.lineItem.product_id).then(function(onDemandTasks) {
            $scope.cachedLineItemOnDemandTasks = onDemandTasks;
            $scope.cachedLineItemDuplicateTasks = areThereOnDemandTaskDuplicates(onDemandTasks);
            if (!$scope.flightTabCurrentlyShown) {
                $scope.onDemandTasks = onDemandTasks;
                $scope.showOnDemandTasks = (onDemandTasks && onDemandTasks.length > 0);
                $scope.duplicateOnDemandTasks = $scope.cachedLineItemDuplicateTasks;
            }

            TaskService.getOnDemandTasksForFlight($scope.lineItem.product_id).then(function(onDemandTasks) {
                // No effect until the flights tab is selected, but we remember them so we don't keep fetching them.
                // If going back from some other page, we might go directly to the flights tab, in which case it will
                // have an immediate effect.
                $scope.cachedFlightOnDemandTasks = onDemandTasks;
                $scope.cachedFlightDuplicateTasks = areThereOnDemandTaskDuplicates(onDemandTasks);
                if ($scope.flightTabCurrentlyShown) {
                    $scope.onDemandTasks = onDemandTasks;
                    $scope.showOnDemandTasks = (onDemandTasks && onDemandTasks.length > 0);
                    $scope.duplicateOnDemandTasks = $scope.cachedFlightDuplicateTasks;
                }
            }, function() {
                $ioAlert.show('Error getting on demand flight tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }, function() {
            $ioAlert.show('Error getting on demand line item tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    function areThereOnDemandTaskDuplicates(tasks) {
        const definitionsArray = tasks.map(task => task.definition);  // Extract only definitions and put in an array
        return new Set(definitionsArray).size !== definitionsArray.length;  // A set does not contain duplicate values...
    }

    function initLineItemFlightTabs() {
        lineItemFlightTabClasses = ["",""];
    }

    $scope.getLineItemFlightTabClass = function (tabNum) {
        return lineItemFlightTabClasses[tabNum];
    };

    $scope.getLineItemFlightTabPaneClass = function (tabNum) {
        return "tab-pane " + lineItemFlightTabClasses[tabNum];
    }

    $scope.setActiveLineItemFlightTab = function (tabNum) {
        initLineItemFlightTabs();
        lineItemFlightTabClasses[tabNum] = "active";
        if (tabNum == 2) {
            $scope.flightTabCurrentlyShown = true;
            $scope.onDemandTasks = $scope.cachedFlightOnDemandTasks;
            $scope.duplicateOnDemandTasks = $scope.cachedFlightDuplicateTasks
        } else {
            $scope.flightTabCurrentlyShown = false;
            $scope.onDemandTasks = $scope.cachedLineItemOnDemandTasks;
            $scope.duplicateOnDemandTasks = $scope.cachedLineItemDuplicateTasks
        }
        $scope.commentTaskModel.addOnDemandTask = false;  // Reset whenever we switch tabs
        $scope.commentTaskModel.addOnDemandTaskToggle = false;
        $scope.commentTaskModel.onDemandTask = null;
        $scope.showOnDemandTasks = $scope.onDemandTasks && $scope.onDemandTasks.length > 0;
        $scope.getComments();
        if ($scope.isTasksTabActive()) {
            getTaskAssignments(false);
        } else if ($scope.isTasksHistoryTabActive()) {
            getTaskAssignments(true);
        }
    };

    $scope.switchFlightsArchivedStatus = function() {
        $scope.showArchivedFlights = !$scope.showArchivedFlights;
        $scope.currentViewFlight = null;
        $scope.currentEditedFlight = {};  // We will loose any unsaved edits.
        $scope.isEditing = false;
        filterVisibleFlights();
        // Clear comments (and tasks when in "specific" mode) since no flight is selected anymore.
        $scope.comments = [];
        if ($scope.showSpecificLineItemAndFlightTasks) {
            $scope.associatedTasks = [];
        }
    }

    function getFlights() {
        if (!$scope.flightLoaded && !$scope.waitForFlightFileUpload && !$scope.waitForFlightFileDelete && !$scope.waitForFlightCustomFileUpload) {
            // If product has not been set yet (not finished loading), user will have to try again.
            $rs.loadingInProgress = true;
            $rs.loadertitle = "Fetching Flights Information";
            $scope.autoCreatedFlightsPresent = false;
            OrdersService.getFlights($scope.lineItemId).then(function(data) {
                if (data) {
                    $scope.flights = data;
                    _.each($scope.flights, function(flight) {
                        const autoCreated = flight.hasOwnProperty('auto_created') ? flight.auto_created : 0;
                        if (autoCreated > 0 && !isArchived(flight)) {
                            $scope.autoCreatedFlightsPresent = true;
                        }
                    });
                }
                calculateDueDates();
                getFlightFields();
                // Some operations may cause tasks to be added, so reload them now if the tasks or history tabs are visible.
                if ($scope.isTasksTabActive()) {
                    getTaskAssignments(false);
                } else if ($scope.isTasksHistoryTabActive()) {
                    getTaskAssignments(true);
                }
            }, function(error) {
                console.log("Error getting flights: " + error);
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                calculateDueDates();
            });
        }
    }

    function filterVisibleFlights() {
        // Clear/destroy the datatable as we're rebuilding it with new data
        if ($.fn.DataTable.isDataTable('#flights-table')) {
            let flightsDatatable = $('#flights-table').DataTable();
            if (flightsDatatable) {
                flightsDatatable.clear();
                flightsDatatable.destroy();
            }
        }

        $scope.visibleFlights = [];
        _.each($scope.flights, function(flight) {
            flight.checked = false;  // Always reset whenever the list is updated.
            if ($scope.showArchivedFlights === isArchived(flight)) {
                $scope.visibleFlights.push(flight);
            }
        });

        setDataTableOptions();
        $scope.someFlightsSelected = false;
        const selectAllCheckbox = document.getElementById('allFlightsSelectedCheckbox');
        if (selectAllCheckbox) {
            selectAllCheckbox.checked = false;
        }
    }

    function isArchived(flight) {
        return (flight.flight_status == FlightStatusNames.STATUS_NAME_FLIGHT_CANCELLED ||
                flight.flight_status == FlightStatusNames.STATUS_NAME_FLIGHT_CLOSED ||
                flight.flight_status == FlightStatusNames.STATUS_NAME_FLIGHT_COMPLETE);
    }

    function getFlightFields() {
        FormService.getFormFields($scope.product.flight_form_id, null, false).then(function(data) {
            if (data) {
                $scope.flightFields = data;
                if (FormService.hasAsynchronousFields($scope.flightFields)) {
                    $scope.hasFlightAsynchronousFields = true;
                    if ($scope.asynchronousTimerId === null) {
                        $scope.asynchronousTimerId = setTimeout(pollAsynchronousFields, ASYNCHRONOUS_FIELDS_POLL_TIMEOUT);
                    }
                }
            }
            $scope.flightLoaded = true;
            $rs.loadingInProgress = false;

            // If this page is invoked with the flightId parameter, then we go straight to the flights tab
            // and display that flight.  We use a fake $scope.currentViewFlight with just the id, and it gets set
            // properly from $scope.flights just below.
            if ($scope.initialFlightId) {
                $scope.currentViewFlight = {};
                $scope.currentViewFlight.id = $scope.initialFlightId;
                $scope.initialFlightId = undefined;  // Reset initialFlightId now that we've used it.
            }

            // At least in the case where a flight was deleted while it was being displayed, we have to reload that
            // flight after the flights have been loaded (we can't really do both in parallel since both calls reset
            // the loadingInProgress flag).  The logic here is if this flight is currently being viewed, but it was
            // deleted from the cachedFlights (due to the delete), then we should reload it.
            if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0 && ($scope.cachedFlights[$scope.currentViewFlight.id] == null)) {
                // We cannot use currentViewFlight as it's the old version (before it was deleted) or the fake version
                // with just the id.  $scope.flights now contains the updated (or full) version though.
                const currentFlight = _.find($scope.flights, {id: $scope.currentViewFlight.id});
                if (currentFlight) {
                    // Need to clear $scope.currentViewFlight prior to invoking viewFlight, otherwise it won't load it, thinking
                    // we're already showing it (we are, but it's outdated).
                    $scope.currentViewFlight = null;
                    $scope.viewFlight(currentFlight);
                    // Set the showArchivedFlights toggle according to our initial flight's archived status, so that
                    // the corresponding list is shown.  This is useful if we got here with an initialFlightId, otherwise
                    // it has no effect.
                    $scope.showArchivedFlights = isArchived(currentFlight);
                }
            }
            // Handle race condition where flight's has_creative flags are not yet set in the DB when we fetch then,
            // because we were still processing creatives, which is done in the background after clicking the save button.
            _.each($scope.flightIdsWithNewCreatives, function(flightId) {
                setHasCreativeFlag(flightId);
            });
            $scope.flightIdsWithNewCreatives = [];
            filterVisibleFlights();  // must do this when $scope.flightLoaded is true, otherwise the datatable does not exist for setDataTableOptions.
            // For the Create Flights (auto-create flights) button to be present, a few conditions must be met:
            // 1) Flight form must have the start_date and end_date fields defined.
            // 2) The line item must actually have a start_date and an end_date configured.
            // 3) The product's configuration must have the flight_auto_create_timeframe defined.
            // 4) The product's configuration must have the day_of_week_start_on or monthly_flight_start_on defined,
            //    depending on the flight_auto_create_timeframe value (weekly vs monthly).
            $scope.autoCreateFlightEnabled =
                _.find($scope.flightFields, {field_name: 'start_date'}) &&
                _.find($scope.flightFields, {field_name: 'end_date'}) &&
                $scope.lineItem.hasOwnProperty('start_date') &&
                $scope.lineItem.hasOwnProperty('end_date') &&
                (($scope.product.flight_auto_create_timeframe === 'weekly' && $scope.product.day_of_week_start_on) ||
                 ($scope.product.flight_auto_create_timeframe === 'monthly' && $scope.product.monthly_flight_start_on));

            $scope.hasDistributedTargetFields = _.find($scope.flightFields, {input_type: 'distributed-target'});
        }, function(error) {
            $rs.loadingInProgress = false;
            console.log("Error getting flight fields: " + error);
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    }

    $scope.showEditFlight = function(flight = null) {
        if ($rs.loadingInProgress) {
            // Don't start something if we're already loading.
            return;
        }
        if (!flight) {
            // If no flight is passed to us, it means this was accessed via the Edit Flight button at the bottom of
            // the page.  Else, it was the edit button, in which case the specific flight this applies to is passed to us.
            flight = $scope.currentViewFlight;
        }
        $scope.EditAddFlightText = 'Edit Flight #' + flight.id;
        $scope.isAdding = false;
        $scope.flightCreatives = null;   // Reset for now, so as not to show outdated values.
        $scope.isEditing = true;
        $scope.currentEditedFlight = _.clone(flight);  // Don't let schemaform modify original copy
        getCreatives();  // Needed to display and allow deletion of creatives and files.
        startEditOrAdd();
        if ($scope.isTasksTabActive()) {
            getTaskAssignments(false);
        } else if ($scope.isTasksHistoryTabActive()) {
            getTaskAssignments(true);
        }
    };

    $scope.showAddFlight = function() {
        if ($rs.loadingInProgress) {
            // Don't start something if we're already loading.
            return;
        }
        $scope.EditAddFlightText = 'Add Flight';
        $scope.isAdding = true;
        $scope.isEditing = false;
        $scope.currentEditedFlight = {};
        startEditOrAdd();
    };

    $scope.showAddFlightBtn = function() {
        let show = !$scope.lineItem.nonEditable;
        if ($scope.allowed_order_statuses.length > 0 && $scope.order &&
            $scope.allowed_order_statuses.indexOf(parseInt($scope.order.status_id[0])) == -1) {
            show = false;
        }

        return show;
    };

    $scope.viewFlight = function(flight) {
        if (!$scope.waitingForCreatives && !$rs.loadingInProgress) {
            getCreatives(handleFlightEOAPreviews, flight, flight.id);
        }
    };

    const doViewFlight = function(flight) {
        if ($rs.loadingInProgress) {
            // Don't start something if we're already loading.
            return;
        }
        $scope.isAdding = false;
        $scope.isEditing = false;
        $scope.currentEditedFlight = {};
        if ($scope.currentViewFlight != null && $scope.currentViewFlight.id == flight.id) {
            // Don't do anything if the user clicks on the same flight id twice in a row.
            return;
        }
        if ($scope.cachedFlights[flight.id] != null) {
            // Optimization to avoid fetching stuff from the backend (which is slow) if this flight has already been
            // seen.  This allows quickly switching between flight details.
            $scope.currentViewFlight = $scope.cachedFlights[flight.id];
            $scope.flightDisplayGroups = $scope.cachedFlightDisplayGroups[flight.id];
            $scope.getComments();
            if ($scope.isTasksTabActive()) {
                getTaskAssignments(false);
            } else if ($scope.isTasksHistoryTabActive()) {
                getTaskAssignments(true);
            }
            return;
        }

        $scope.currentViewFlight = null;
        $scope.flightDisplayGroups = [];
        let staticInfoSection = {};
        staticInfoSection.title = "Flight Info";
        staticInfoSection.data = [];

        let productName = {};
        let lineItemId = {};
        let statusName = {};
        productName['Product Name'] = $scope.product.name;
        lineItemId['Line Item Id'] = $scope.lineItemId;
        statusName['Status Name'] = flight.status_name;
        $scope.network_id = flight.network_id;
        $scope.flighId = flight.id;
        $scope.locationpickerOptions = {
            location: {
                latitude: 0,
                longitude: 0
            },
            inputBinding: {},
            radius: 0,
            enableAutocomplete: true
        };

        staticInfoSection.data.push(productName);
        staticInfoSection.data.push(lineItemId);
        staticInfoSection.data.push(statusName);
        if (flight.dfp_flight_id && flight.dfp_line_item_id && flight.dfp_order_id && flight.network_id) {
            let flightId = {};
            flightId['GAM line item ID'] = flight.dfp_flight_id;
            staticInfoSection.data.push(flightId);
        }

        if (flight.dfp_flight_id  && flight.dfp_order_id && flight.network_id) {
            let flightId = {};
            flightId['GAM line item ID'] = flight.dfp_flight_id;
            staticInfoSection.data.push(flightId);
        }

        const autoCreateNumber = flight.hasOwnProperty('auto_created') ? flight.auto_created : 0;
        if (autoCreateNumber > 0) {
            let autoCreated = {};
            autoCreated['Auto-Create Index'] = autoCreateNumber;
            staticInfoSection.data.push(autoCreated);
        }

        $scope.flightDisplayGroups.push(staticInfoSection);

        for (let x = 0; x < $scope.flightFormFieldGroups.length; x++) {
            const flightFieldGroup = $scope.flightFormFieldGroups[x];
            let section = {};
            section.title = flightFieldGroup.group_name;
            section.groupId = flightFieldGroup.id;
            section.data = [];
            if (flightFieldGroup.hideForCondition && flightFieldGroup.hideForCondition.includes($rs.userRole.role_id)) {
                continue;
            }
            $scope.flightDisplayGroups.push(section);
        }

        if ($scope.flightFields && $scope.flightFields.length) {
            for (let i = 0; i < $scope.flightFields.length; i++) {
                const flightField = $scope.flightFields[i];
                if (!hiddenByParent(flightField, $scope.flightFields, flight)) {
                    if (flightField.input_type === "readonly") {
                        if (flightField.default_value) {
                            flight[flightField.field_name] = flightField.default_value;
                        } else if (flightField.cascading_field_name) {
                            let cascadingValue = $scope.lineItem ? $scope.lineItem[flightField.cascading_field_name] : null;
                            if (cascadingValue != undefined) {
                                flight[flightField.field_name] = cascadingValue;
                            }
                        }
                    }
                    if (flight.hasOwnProperty(flightField.field_name) ||
                        (['creative', 'facebook-create-ads'].indexOf(flightField.input_type) > -1
                            && $scope.flightCreatives && $scope.flightCreatives.length > 0) ||
                        flightField.input_type == 'custom-macro-mapping') {
                        const group = _.find($scope.flightDisplayGroups, {groupId: flightField.group_id});
                        if (!group) continue;
                        let displayPair = DisplayService.applyTransformation(
                            flightField,
                            flight.hasOwnProperty(flightField.field_name) ? flight[flightField.field_name] : null,
                            $scope.flightCreatives);
                        displayPair.formFieldType = ['hyperlink'].includes(flightField.data_type) ?
                            flightField.data_type : flightField.input_type;
                        displayPair.formFieldName = flightField.field_name;
                        if (flightField.input_type == 'custom-macro-mapping') {
                            displayPair.audience_macro_mappings = flight['__audience_macro_mappings'] || [];
                            displayPair.audience_match_mappings = flight['__audience_match_mappings'] || [];
                            displayPair.email_macros = flight['__email_macros'] || "[]"; // A string containing JSON
                            displayPair.html_validation_status = flight['__html_validation_status'] ?? '';
                        }
                        if (flightField.input_type == 'bridge-email-test') {
                            displayPair.form_content_type_id = flightField.form_id;
                            displayPair.flight_id = flight['id'];
                            displayPair.entire_flight = flight;
                            displayPair.client_id = $scope.order.client_id[0];
                        }
                        group.data.push(displayPair);
                    }
                } else {
                    delete flight[flightField.field_name];
                }
            }
        }

        // If any form field group is empty (i.e. it contains no fields), remove it.
        for (let i = 0; i < $scope.flightDisplayGroups.length; i++) {
            if ($scope.flightDisplayGroups[i].data && $scope.flightDisplayGroups[i].data.length === 0) {
                $scope.flightDisplayGroups.splice(i--, 1);
            }
        }

        $scope.cachedFlights[flight.id] = flight;
        $scope.cachedFlightDisplayGroups[flight.id] = $scope.flightDisplayGroups;

        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Flight Information";

        // Start fetching activity log, which will update $scope.flightDisplayGroups and $scope.cachedFlightDisplayGroups
        // asynchronously.
        getFlightActivity(flight.id);

        FormService.getTaskFormFieldsForFlight(flight.id).then(function (data) {
            $scope.currentViewFlight = flight;
            $rs.loadingInProgress = false;
            showAudienceStatusPopupForFlight(flight);

            if (!data.formFields || data.formFields.constructor.name !== 'Array') {
                $scope.getComments();
                if ($scope.isTasksTabActive()) {
                    getTaskAssignments(false);
                } else if ($scope.isTasksHistoryTabActive()) {
                    getTaskAssignments(true);
                }
                return
            }

            let groupData = []

            data.formFields.forEach(function (formField) {
                let attrValue = flight[formField.field_name]
                if (attrValue) {
                    let displayPair = DisplayService.applyTransformation(formField, attrValue);
                    displayPair.formFieldType = ['hyperlink'].includes(formField.data_type) ?
                        formField.data_type : formField.input_type;
                    displayPair.formFieldName = formField.field_name;
                    groupData.push(displayPair)
                }
            })

            if (groupData.length === 0) {
                $scope.getComments();
                if ($scope.isTasksTabActive()) {
                    getTaskAssignments(false);
                } else if ($scope.isTasksHistoryTabActive()) {
                    getTaskAssignments(true);
                }
                return;
            }

            $scope.flightDisplayGroups.push({
                title: 'Task Information',
                data: groupData
            });
            $scope.getComments();
            if ($scope.isTasksTabActive()) {
                getTaskAssignments(false);
            } else if ($scope.isTasksHistoryTabActive()) {
                getTaskAssignments(true);
            }
        })
    };

    const showAudienceStatusPopupForFlight = function(flight) {
        if (flight.hasOwnProperty('__audience_status')) {
            if (flight['__audience_status'] != 'audience_current') {
                $ioAlert.show('Error!', "Audience is not in 'Ready' Status.", 'error');
            }
        }
    };

    const handleFlightEOAPreviews = function(flight) {
        if (flight.__eoa_previews) {
            if ($scope.flightFormattedEOAPreviews[flight.id]) {
                flight.__eoa_previews = $scope.flightFormattedEOAPreviews[flight.id];
                doViewFlight(flight);
                return;
            }
            $rs.loadingInProgress = true;
            $rs.loadertitle = "Fetching Email Preview Information";
            const original__eoa_previews = flight.__eoa_previews;
            flight.__eoa_previews = '...computing...';

            OrdersService.getFormattedEOAPreviews(original__eoa_previews).then(function(formatted) {
                flight.__eoa_previews = formatted;
                $scope.flightFormattedEOAPreviews[flight.id] = formatted;
                $rs.loadingInProgress = false;
                doViewFlight(flight);
            }, function() {
                flight.__eoa_previews = '(Could not display)';
                $scope.flightFormattedEOAPreviews[flight.id] = '(Could not display)';
                $rs.loadingInProgress = false;
                doViewFlight(flight);
            });
        } else {
            doViewFlight(flight);
        }
    };

    const handleLineItemEOAPreviews = function(lineItem, skipBuildDisplayData = false) {
        if (lineItem.__eoa_previews) {
            if ($scope.lineItemIdToFormattedEOAPreviewsArray[lineItem.id]) {
                lineItem.__eoa_previews = $scope.lineItemIdToFormattedEOAPreviewsArray[lineItem.id];
                $scope.waitingForEOAPreviews = false;
                if (!skipBuildDisplayData) {
                    gateBuildDisplayData();
                }
                return;
            }
            const original__eoa_previews = lineItem.__eoa_previews;
            lineItem.__eoa_previews = '...computing...';

            OrdersService.getFormattedEOAPreviews(original__eoa_previews).then(function(formatted) {
                lineItem.__eoa_previews = formatted;
                $scope.lineItemIdToFormattedEOAPreviewsArray[lineItem.id] = formatted;
                $scope.waitingForEOAPreviews = false;
                if (!skipBuildDisplayData) {
                    gateBuildDisplayData();
                }
            }, function() {
                lineItem.__eoa_previews = '(Could not display)';
                $scope.lineItemIdToFormattedEOAPreviewsArray[lineItem.id] = '(Could not display)';
                $scope.waitingForEOAPreviews = false;
                if (!skipBuildDisplayData) {
                    gateBuildDisplayData();
                }
            });
        } else {
            $scope.waitingForEOAPreviews = false;
            if (!skipBuildDisplayData) {
                gateBuildDisplayData();
            }
        }
    };

    $scope.downloadGptTags = function () {
        var params = {
            networkId: $scope.network_id,
            linpid: $scope.flighId,
            export_to_csv: 'csvdata'
        }
        OrdersService.downloadGpt(params).then(function (response) {
            var csvString = response.data;
            var a = $('<a/>', {
                style: 'display:none',
                href: 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(csvString))),
                download: 'GPT_Tags.csv'
            }).appendTo('body');
            a[0].click();
            a.remove();
        }, function (error) {
            console.log("error downloading gpt tags");
        });
    }

    $scope.copyFlight = function(flight) {
        if ($rs.loadingInProgress) {
            // Don't start something if we're already loading.
            return;
        }
        let options = options = [{
            'value': false,
            'text': ' Copy uploaded files?'
        }];
        if ($scope.budgetManagementEnabled) {
            options.push({
                'value': false,
                'text': 'Clear total_budget values on copied entities'
            });
        }
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to copy this flight?',
            options: options,
        }).then(function (data) {
            const clearTotalBudget = $scope.budgetManagementEnabled ? data.options[1].value : false;
            OrdersService.copyFlight(flight.id, clearTotalBudget).then(function(newFlight) {
                if (newFlight.errors) {
                    for (let x = 0; x < newFlight.errors.length; x++) {
                        $ioAlert.show("Error", newFlight.errors[x], "error");
                    }
                } else {
                    if (data.options[0].value) {
                        copyFlightUploadedFiles(flight.id, newFlight.newFlightId);
                    } else {
                        $scope.flightLoaded = false;
                        refreshLineItem();
                        getFlights();
                    }

                    $ioAlert.show('', 'Flight copied successfully', 'success');
                }
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
            });
        }, function () {
        });
    };

    var copyFlightUploadedFiles = function(srcFlightId, destFlightId) {
        MediaService.cloneFlightCreatives(srcFlightId, destFlightId).then(function() {
            if (MediaService.errors) {
                for (var x = 0; x < MediaService.errors.length; x++) {
                    $ioAlert.show("Error", MediaService.errors[x], "error");
                }
                MediaService.errors = null;
            } else {
                $scope.flightLoaded = false;
                refreshLineItem();
                getFlights();
                $ioAlert.show('', 'Flight uploads copied successfully', 'success');
            }
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
        });
    };

    $scope.showDeleteConfirm = function(flight) {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to delete this flight?'
        }).then(function() {
            deleteFlight(flight);
        }, function() {});
    };

    let deleteFlight = function(flight) {
        if ($rs.loadingInProgress) {
            // Don't start something if we're already loading.
            return;
        }
        $scope.cachedFlightDisplayGroups[flight.id] = null;
        $scope.cachedFlights[flight.id] = null;
        OrdersService.closeFlight(flight.id).then(function() {
            $scope.flightLoaded = false;
            refreshLineItem();
            getFlights();
            $ioAlert.show('', 'Flight deleted successfully', 'success');
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
        });
    };

    let startEditOrAdd = function() {
        $scope.currentViewFlight = null;
        $scope.flightDisplayGroups = null;
        // Reset these to prevent issues when clicking edit/add buttons on different flights
        $scope.form = [];
        $scope.entireForm = [];
        $scope.model = {};
        $scope.schema = {};
        $scope.formParentChildInfo = {};
        $scope.formFieldMap = {};
        if ($scope.client == null && $scope.order && $scope.order.client_id && $scope.order.client_id.length > 0) {
            getClient();
        } else {
            // We already have the client, so go straight to loading the form.
            loadCompleteFlightFormFields();
        }
        $scope.getComments();  // Shows comments for the edited flight, or clears them when adding flight.
    };

    let getClient = function() {
        $rs.loadertitle = "Fetching Form Information";
        $rs.loadingInProgress = true;
        const clientId = $scope.order.client_id[0];
        UserService.getClient(clientId).then(function(success) {
            $scope.client = UserService.client;
            loadCompleteFlightFormFields();
        }, function(error) {
            $ioAlert.show('Error!', 'An error occurred getting the client information for client_id ' + clientId + ': ' + error);
            loadCompleteFlightFormFields();
        });
    };

    let loadCompleteFlightFormFields = function() {
        if ($scope.flightFormFieldsWithContent == null) {
            $rs.loadertitle = "Fetching Form Information";
            $rs.loadingInProgress = true;
            FormService.getFlightFormFields($scope.product.flight_form_id, $scope.product.id, $scope.order.cluster_id[0], false, 'yes', $scope.order?.client_id[0]).then(function(data) {
                $scope.flightFormFieldsWithContent = FormService.formFields;
                waitForLoadFormDataOrLoadForm();
            }, function(error) {
                console.log("Error getting flight fields: " + error);
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else {
            waitForLoadFormDataOrLoadForm();
        }
    }

    // We use this gating method to make sure we have both the form fields and the creatives, both of which being fetched
    // in parallel (along with the client before the form fields).
    const waitForLoadFormDataOrLoadForm = function() {
        if ($scope.flightFormFieldsWithContent != null && !$scope.waitingForCreatives) {
            loadForm();
        } else if ($scope.waitingForCreatives) {
            $scope.notifyOnceCreativesReceived = true;
        } else {
            console.log('Waiting for creatives or flight form fields');  // Not expected to happen so logging just in case.
        }
    }

    const loadForm = function() {
        $scope.hasForm = false;
        $rs.loadingInProgress = false;

        if ($scope.isEditing) {
            for (let i = 0; i < $scope.flightFormFieldsWithContent.length; i++) {
                if ($scope.flightFormFieldsWithContent[i].field_name == 'dfp_adsizes') {
                    $scope.flightFormFieldsWithContent[i].default_value = [];
                }
            }
        }

        if ($scope.flightFormFieldsWithContent && $scope.flightFormFieldsWithContent.length > 0) {
            let flightFieldGroups = $scope.flightFormFieldGroups;
            _.each(flightFieldGroups, function (flightFieldGroup) {
                let groupFields = _.filter($scope.flightFormFieldsWithContent, {
                    group_id: flightFieldGroup.id,
                    active: "1"
                });
                flightFieldGroup.hasActiveFields = (groupFields.length > 0);
            });
            flightFieldGroups = _.filter(flightFieldGroups, function (item) {
                return (item.hasActiveFields == true);
            });
            // We need to set cascading values before invoking buildForm so that multiselect fields
            // are initialized properly.
            buildSchema($scope.flightFormFieldsWithContent);
            setCascadingValues();
            buildForm($scope.flightFormFieldsWithContent, flightFieldGroups);
            FormService.registerForDateChanges($scope.form, $scope.flightFields, $scope.model);
            $scope.hasForm = true;
            $scope.$broadcast('schemaFormRedraw');  // Required if we edit/add multiple flights one after the other.
        } else {
            $scope.hasForm = false;
        }
    };

    function setCascadingValues() {
        for (let i = 0; i < $scope.flightFormFieldsWithContent.length; i++) {
            const field = $scope.flightFormFieldsWithContent[i];
            if (field.cascading_field_name && !$scope.model[field.field_name + '_override_cascade']) {

                let cascadingValue = $scope.lineItem[field.cascading_field_name],
                    modelValue;

                if (cascadingValue === undefined && $scope.order) {
                    // Cascading field source is not present in the line item, look for it in the order instead.
                    cascadingValue = $scope.order[field.cascading_field_name];
                }
                if (cascadingValue === undefined && $scope.client) {
                    // Cascading field source is not present in the line item or order, look for it in the client instead.
                    cascadingValue = $scope.client[field.cascading_field_name];
                }
                if (field.data_type == "date" && cascadingValue) {
                    let dt = new Date(cascadingValue);
                    modelValue = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
                } else if (field.input_type == "multiselect" || field.input_type == "multiselect-custom") {
                    modelValue = cascadingValue ? transformCascadingArray(cascadingValue) : [];
                    if (!_.isArray(modelValue)) {
                        // This could happen if we are cascading from a select to a multiselect.
                        modelValue = [modelValue];
                    }
                } else if (field.input_type == "checkbox") {
                    modelValue = (cascadingValue === true || cascadingValue == "1");
                } else if (field.input_type == "timeselect") {
                    if (String(cascadingValue).indexOf(":") > 0) {
                        let timeObj = new Date();
                        let timeData = String(cascadingValue).split(" ");
                        let timeVal = String(timeData[0]).split(":");
                        timeVal[0] = (timeData[1] === "PM") ? parseInt(timeVal[0]) + 12 : timeVal[0];
                        timeObj = new Date(timeObj.getFullYear(), timeObj.getMonth(), timeObj.getDate(), parseInt(timeVal[0]), parseInt(timeVal[1]), 0);
                        modelValue = timeObj;
                    }
                } else {
                    modelValue = cascadingValue ? transformCascadingArray(cascadingValue) : cascadingValue;
                    if (_.isArray(modelValue)) {
                        modelValue = _.join(modelValue, ', ');
                    }
                }

                $scope.model[field.field_name] = modelValue
            }
        }
    }

    function transformCascadingArray(val) {
        if (!_.isArray(val)) {
            return val
        }

        if (_.isArray(val) && !_.isArray(val[0])) {
            return val[1];
        }

        return _.map(val, (lookupContentPair) => {
            let valueIndex = 1
            return lookupContentPair[valueIndex]
        });
    }

    let buildSchema = function(flightFields) {
        $scope.realCreativeFilenames = [];  // Reset in case we have edited before.
        // Remember the required creative field ids so we can later restore their required flag.
        $scope.savedRequiredCreativeFieldIds = FormService.getRequiredCreativeFieldIds(flightFields);
        FormService.buildDynamicFormSchema($scope.schema, $scope.formFieldMap, flightFields, $scope.isEditing, $scope.currentEditedFlight, $scope.flightCreatives, $scope.realCreativeFilenames, !$scope.isEditing);
        FormService.addCommentSchema($scope.schema);
        if ($scope.isEditing) {
            let editArray = [];
            for (let x in $scope.schema.properties) {
                if ($scope.schema.properties.hasOwnProperty(x)) {
                    editArray.push({
                        "prop": x,
                        "type": $scope.schema.properties[x].type
                    });
                }
            }
            updateForm($scope.currentEditedFlight, editArray);
        }
    };

    let updateForm = function(flightForm, editArray) {

        for (let i = 0, len = editArray.length; i < len; i++) {
            for (let j in flightForm) {
                if (flightForm.hasOwnProperty(j) && flightForm[j] != null) {
                    if (editArray[i].prop == j && editArray[i].type == 'boolean') {
                        flightForm[j] = (flightForm[j] == 1);
                    } else if (editArray[i].prop == j && editArray[i].type == 'array') {
                        let selectArr = [];
                        const arrlen = flightForm[j].length;

                        if (arrlen == 2 && typeof flightForm[j][1] === 'string') {
                            selectArr.push(flightForm[j][1]);
                        } else {
                            for (let k = 0; k < arrlen; k++) {
                                if (flightForm[j][k] && flightForm[j][k].length == 1) {
                                    selectArr.push(String(flightForm[j][k][0]).replace("CUST_", ""));
                                } else if (flightForm[j][k]) {
                                    selectArr.push(flightForm[j][k][1]);
                                }
                            }
                        }
                        flightForm[j] = selectArr;
                    } else if (editArray[i].prop == j && editArray[i].type == 'json') {
                        // do nothing. This one's for geo location
                    } else if (editArray[i].prop == j && editArray[i].type == 'string' && flightForm[j] && typeof flightForm[j] === 'object') {
                        flightForm[j] = flightForm[j][1];
                    }
                }
            }
        }
        $scope.model = flightForm;
    };

    const buildForm = function(flightFields, flightFieldGroups) {
        // TODO: need to pass line item and handle start_date and end_date for date rules.
        FormService.buildDynamicForm($scope.model, $scope.entireForm, $scope.formParentChildInfo, $scope.schema, $scope.formFieldMap, flightFieldGroups,
            flightFields, $scope.isEditing, $scope.client.id, $scope.product, $scope.order, $rs.userRole, 'flight', $scope.lineItem);
        _.each($scope.entireForm, function (section) {
            if (section.type == 'hidden') {
                return;
            }
            $scope.form.push(section);
        });
        if ($scope.schema.properties.dfp_adsizes) {

            $scope.imageTypeAdSizes = $scope.schema.properties.dfp_adsizes.items.filter((item) => item.value.indexOf('v (Custom)') == -1 && item.value.slice(-1) !=="v");
            $scope.videoTypeAdSizes = $scope.schema.properties.dfp_adsizes.items.filter((item) => item.value.includes('v (Custom)') || item.value.slice(-1)=="v");
            $scope.schema.properties.dfp_adsizes.items = [];
        }
        $scope.$watch(function() { return $scope.model.creative_type;}, function() {
            if ($scope.model.creative_type == 'Display') {
                $scope.schema.properties.dfp_adsizes.items = $scope.imageTypeAdSizes;
            } else if ($scope.model.creative_type == 'Video') {
                $scope.schema.properties.dfp_adsizes.items = $scope.videoTypeAdSizes;
            } else if ($scope.model.creative_type != 'Display' && $scope.model.creative_type != 'Video') {
                if ($scope.schema.properties.dfp_adsizes) {
                    $scope.schema.properties.dfp_adsizes.items = [];
                }
            }
        });
        const cluster_id = ($scope.order && $scope.order.cluster_id) ? $scope.order.cluster_id[0] : null;
        $scope.form.push(FormService.getCommentInputSection('flightPage', $scope.addCommentRequired && $scope.isEditing, cluster_id));
        $scope.formName = 'formObj' + ($scope.isEditing ? $scope.currentEditedFlight.id : 'Add');
        $scope.form.push(FormService.getSubmitControl($scope.isEditing ? 'Update Flight' : 'Save Flight', $scope.formName));
    };

    $scope.submitForm = function(flightForm) {
        let returnObj = FormService.validateCustomWidgets($scope.flightFormFieldsWithContent, $scope.model);
        let dayPartingValidation = FormService.validateDayPartingWidget($scope.flightFormFieldsWithContent, $scope.model);
        let adScheduleValidation = FormService.validateAdScheduleWidget($scope.flightFormFieldsWithContent, $scope.model);
        let isFormWidgetsValid = !returnObj.validateWidgets.includes(false);
        returnObj.dfpAdsizesValidationStatus = true;
        $scope.model = returnObj.model;
        if (returnObj.model.dfp_adsizes || returnObj.model.creative_type) {
            if (returnObj.model.dfp_adsizes && returnObj.model.creative_type) {
                if (returnObj.model.dfp_adsizes && returnObj.model.creative_type == "Video") {
                    returnObj.model.dfp_adsizes.forEach((element, index, array) => {
                        const regExp1 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[V|v]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp2 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp3 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[V|v][\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp4 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[V|v][\(]Video[\/]Vast|VAST[\)][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index].value):(/^\d{1,4}[X|x]\d{1,4}[V|v][\(]Video[\/]Vast|VAST[\)][\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        if (regExp1 || regExp2 || regExp3 || regExp4) {
                            returnObj.dfpAdsizesValidationStatus = true;
                        } else {
                            returnObj.dfpAdsizesValidationStatus = false;
                        }
                    });
                } else if (returnObj.model.dfp_adsizes && returnObj.model.creative_type == "Display") {
                    returnObj.model.dfp_adsizes.forEach((element, index, array) => {
                        const regExp1 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}$/g).test(returnObj.model.dfp_adsizes[index].value):(/^\d{1,4}[X|x]\d{1,4}$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp2 = returnObj.model.dfp_adsizes[index].value ? (/^(4|6)[:](1)$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^(4|6)[:](1)$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp3 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[\s][\(][\s]Custom[\s][\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp4 = returnObj.model.dfp_adsizes[index].value ? (/^\d{1,4}[X|x]\d{1,4}[\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index].value) : (/^\d{1,4}[X|x]\d{1,4}[\s][\(]Custom[\)]$/g).test(returnObj.model.dfp_adsizes[index]);
                        const regExp5 = returnObj.model.dfp_adsizes[index].value ? (/[a-zA-Z &+,:;=?@#|'<>.^*()%!-]+$/g).test(returnObj.model.dfp_adsizes[index].value) : (/[a-zA-Z &+,:;=?@#|'<>.^*()%!-]+$/g).test(returnObj.model.dfp_adsizes[index]);
                        if (regExp1 || regExp2 || regExp3 || regExp4 || regExp5) {
                            returnObj.dfpAdsizesValidationStatus = true;
                        } else {
                            returnObj.dfpAdsizesValidationStatus = false;
                        }
                    });
                }
            } else if (!returnObj.model.creative_type) {
                $ioAlert.show("Error!", "Please select Ad Type", "error");
                returnObj.dfpAdsizesValidationStatus = false;
            }
        }

        FormService.restoreRequiredCreativeFieldIds($scope.flightFormFieldsWithContent, $scope.savedRequiredCreativeFieldIds);
        const hasRequiredCreativeFields = FormService.formHasRequiredCreativeFields($scope.flightFormFieldsWithContent, $scope.model);
        // When adding (i.e. not editing), validation is done via the standard form required field validation.
        const creativeRequiredFieldsValid = ($scope.isEditing && hasRequiredCreativeFields) ?
        FormService.validateCreativesPresent($scope.flightFormFieldsWithContent, $scope.model, $scope.realCreativeFilenames) : true;

        $scope.$broadcast('schemaFormValidate');
        if (flightForm.$valid && returnObj.isValid && isFormWidgetsValid && dayPartingValidation && adScheduleValidation &&
            returnObj.dfpAdsizesValidationStatus && creativeRequiredFieldsValid) {

            $scope.form[$scope.form.length - 1].isSubmitted = true;
            let flight = FormService.harvestData($scope.flightFormFieldsWithContent, $scope.entireForm, $scope.model, 'flight', $scope.formParentChildInfo);
            flight.lineItemId = $scope.lineItemId;
            let adgroupCreativeFiles=[];
            if(flight.adGroupField){
                adgroupCreativeFiles.push(flight.adGroupField)
            }
            if(flight.multiProductAdgroup){
                adgroupCreativeFiles.push(flight.multiProductAdgroup);
            }
            delete flight.adGroupField;
            delete flight.multiProductAdgroup;
            if ($scope.validationErrors.length == 0) {
                if (flight.lineItemId) {
                    FormService.getCreateAdvertiserWarning($scope.order.client_id[0], $scope.lineItem.product_service_id, 'flight').then(function (data) {
                        if(data === true) {
                            if ($scope.isEditing) {
                                flight.id = $scope.currentEditedFlight.id;
                                updateFlight(flight, adgroupCreativeFiles);
                            } else {
                                addFlight(flight, adgroupCreativeFiles);
                            }
                        } else {
                            $scope.form[$scope.form.length - 1].isSubmitted = false;
                        }
                    }, function () {
                        $ioAlert.show("Error!", "An error occurred saving the new flight.", 'error');
                    });
                } else {
                    console.log("Missing Line Item Id for new flight.");
                    $scope.form[$scope.form.length - 1].isSubmitted = false;
                }
                $scope.comments = [];  // Clear comments since we're not showing any flight after saving.
            } else {
                for (let x = 0; x < $scope.validationErrors.length; x++) {
                    $ioAlert.show("Error", $scope.validationErrors[x], "error");
                }
                $scope.validationErrors = [];
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            }
        } else {
            if (!("parse" in flightForm.$error) && ("date" in flightForm.$error)) {
                $ioAlert.show("Validation Error!", "Please fill in date value " + flightForm.$error.date[0].$viewValue + " using calendar", 'error');
            } else if(!dayPartingValidation){
                $ioAlert.show("Validation Error!", "Please check the day parting time slots", "error");
            } else if(!returnObj.dfpAdsizesValidationStatus){
                $ioAlert.show("Validation Error!", "Please check the DFP Adsizes", "error");
            } else if (!creativeRequiredFieldsValid) {
                $ioAlert.show("Validation Error!", "Please add in the required creative", 'error');
            } else {
                $ioAlert.show("Validation Error!", "Please fill in the required fields", 'error');
            }
        }
    };

    let addFlight = function(flight, adGroupField) {
        if (formHasFileOrCreativeUpload($scope.model, $scope.flightFormFieldsWithContent)) {
            flight.uploadPending = '1';
            $scope.uploadFileSet = new Set();
        }
        OrdersService.addFlight(flight).then(function() {
            if (OrdersService.errors) {
                for (let x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            } else if (OrdersService.flight) {
                if ($scope.model.comment) {
                    addFlightComment(OrdersService.flight.id, $scope.model.comment);
                }

                $scope.fileUploadKey = OrdersService.flight.__FILE_UPLOAD_KEY;

                _.each($scope.model, function(itemVal, prop){
                    let fieldObj = _.find($scope.flightFormFieldsWithContent, {field_name: prop});
                    if (fieldObj && fieldObj.input_type == "file" && $scope.model[prop]) {
                        let adGroupMoreInfo = { 'fieldId': fieldObj.id, 'fieldType': fieldObj.input_type, 'entityType': 'flight' };
                        addCreativeAssets(OrdersService.flight.id, $scope.model[prop], false, adGroupMoreInfo);
                    } else if (fieldObj && ['creative', 'facebook-create-ads'].includes(fieldObj.input_type) && $scope.model[prop]) {
                        if ($scope.model[prop].media) {
                            addCreativeAssets(OrdersService.flight.id, $scope.model[prop].media, $scope.model[prop].library);
                        } else if ($scope.model[prop].selectedImages) {
                            attachCreatives(OrdersService.flight.id, $scope.model[prop].selectedImages)
                            if ($scope.model[prop].thirdPartyCreatives) {
                                attachCreatives(OrdersService.flight.id, $scope.model[prop].thirdPartyCreatives)
                            }
                        }
                    } else if (fieldObj && fieldObj.input_type == "custom_file" && $scope.model[prop]) {
                        $scope.uploadCustomFile(OrdersService.flight.id, prop);
                    }
                });

                if (adGroupField) {
                    _.each(adGroupField, function (adGroupData) {
                        if(adGroupData.obj){
                            _.each(adGroupData.obj, function (adGroupObj, adGroupIndex) {
                                if (adGroupObj.ads) {
                                    _.each(adGroupObj.ads, function (adObj, adIndex) {
                                        if (adObj.creativeImages && adObj.creativeImages.length > 0) {
                                            let adGroupMoreInfo = { 'adGroupIndex': adGroupIndex, 'adIndex': adIndex, 'fieldId': adGroupData.field.id };
                                            addCreativeAssets(OrdersService.flight.id, adObj.creativeImages, false, adGroupMoreInfo);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                $scope.isAdding = false;
                $scope.flightLoaded = false;
                $ioAlert.show('', 'Flight added successfully', 'success');
                cachedTasks = [];  // Clearing so that if new tasks get created, they get loaded
                cachedHistoryTasks = [];
                cachedLineItemTasks = undefined;
                cachedFlightTasks = [];
                cachedLineItemHistoryTasks = undefined;
                cachedFlightHistoryTasks = [];
                refreshLineItem();
                getFlights();
            } else {
                $ioAlert.show("Error!", "An error occurred saving the new flight.", 'error');
                $scope.form[$scope.form.length - 1].isSubmitted = false;
                $scope.isAdding = false;
                $scope.flightLoaded = false;
                getFlights();
            }
        }, function() {
            $ioAlert.show("Error!", "An error occurred saving the new flight.", 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
            $scope.isAdding = false;
            $scope.flightLoaded = false;
            getFlights();
        });
    };

    let updateFlight = function(flight, adGroupField) {
        flight.statusId = $scope.currentEditedFlight.status_id;

        if ($scope.addCommentRequired && !$scope.model.comment) {
            $ioAlert.show("Error", 'A comment is required when editing a flight', "error");
            $scope.form[$scope.form.length - 1].isSubmitted = false;
            return;
        }

        let uploadingFiles;
        let deletedFiles;
        let hasFileOrCreativeModifications;
        [uploadingFiles, deletedFiles, hasFileOrCreativeModifications] =
            computeUploadingAndDeletedFiles($scope.model, $scope.form, $scope.flightFormFieldsWithContent);

        if (hasFileOrCreativeModifications) {
            flight.uploadPending = '1';
            $scope.uploadFileSet = new Set();
        }

        OrdersService.updateFlight(flight).then(function() {
            if (OrdersService.errors) {
                for (let x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
                $scope.form[$scope.form.length - 1].isSubmitted = false;
            } else if (OrdersService.flight) {
                // Reset cache since modified value will have changed
                $scope.cachedFlights[flight.id] = null;
                $scope.cachedFlightDisplayGroups[flight.id] = null;

                if ($scope.model.comment) {
                    addFlightComment(OrdersService.flight.id, $scope.model.comment, 'entity_update');
                }

                $scope.fileUploadKey = OrdersService.flight.__FILE_UPLOAD_KEY;

                _.each($scope.model, function(itemVal, prop){
                    let fieldObj = _.find($scope.flightFormFieldsWithContent, {field_name: prop});
                    if (fieldObj && fieldObj.input_type == "file" && $scope.model[prop]) {
                        let adGroupMoreInfo = {
                            fieldId: fieldObj.id,
                            fieldType: fieldObj.input_type,
                            entityType: 'flight',
                        };

                        if (deletedFiles[prop].length > 0) {
                            deleteCreativeAssets(OrdersService.flight.id, deletedFiles[prop], adGroupMoreInfo);
                        }
                        if (uploadingFiles[prop].length > 0) {
                            addCreativeAssets(OrdersService.flight.id, uploadingFiles[prop], false, adGroupMoreInfo);
                        }
                    } else if (fieldObj && ['creative', 'facebook-create-ads'].includes(fieldObj.input_type) && $scope.model[prop]) {
                        if ($scope.model[prop]) {
                            if ($scope.model[prop].media && $scope.model[prop].media.length > 0) {
                                addCreativeAssets(OrdersService.flight.id, $scope.model[prop].media, $scope.model[prop].library);
                            }
                            if ($scope.model[prop].selectedImages && $scope.model[prop].selectedImages > 0) {
                                attachCreatives(OrdersService.flight.id, $scope.model[prop].selectedImages)
                            }
                            if ($scope.model[prop].thirdPartyCreatives && $scope.model[prop].thirdPartyCreatives > 0) {
                                attachCreatives(OrdersService.flight.id, $scope.model[prop].thirdPartyCreatives)
                            }
                        }
                    } else if (fieldObj && fieldObj.input_type == "custom_file" && $scope.model[prop]) {
                        $scope.uploadCustomFile(OrdersService.flight.id, prop);
                    }
                });

                if (adGroupField) {
                    _.each(adGroupField, function (adGroupData) {
                        if(adGroupData.obj){
                            _.each(adGroupData.obj, function (adGroupObj, adGroupIndex) {
                                if (adGroupObj.ads) {
                                    _.each(adGroupObj.ads, function (adObj, adIndex) {
                                        if (adObj.creativeImages && adObj.creativeImages.length > 0) {
                                            let adGroupMoreInfo = { 'adGroupIndex': adGroupIndex, 'adIndex': adIndex, 'fieldId': adGroupData.field.id };
                                            addCreativeAssets($scope.flight.id, adObj.creativeImages, false, adGroupMoreInfo);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                $scope.isEditing = false;
                $scope.flightLoaded = false;
                $ioAlert.show('', 'Flight updated successfully', 'success');
                refreshLineItem();
                getFlights();
            } else {
                $ioAlert.show("Error!", "An error occurred updating the flight.", 'error');
                $scope.form[$scope.form.length - 1].isSubmitted = false;
                $scope.isEditing = false;
                $scope.flightLoaded = false;
                getFlights();
            }
        }, function() {
            $ioAlert.show("Error!", "An error occurred updating the flight.", 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
            $scope.isEditing = false;
            $scope.flightLoaded = false;
            getFlights();
        });
    };

    $scope.uploadCustomFile = function(flightId, prop) {
        $scope.waitForFlightCustomFileUpload = true;
        FormService.processCustomFile('flight', flightId, prop, $scope.model[prop], 0).then(function() {
            $scope.waitForFlightCustomFileUpload = false;
            getFlights();
        }, function() {
            $scope.waitForFlightCustomFileUpload = false;
            getFlights();
        });
    };

    const formHasFileOrCreativeUpload = function(model, fields) {
        let found = false;
        _.each(model, function(itemVal, prop) {
            let fieldObj = _.find(fields, {field_name: prop});
            if (fieldObj && (fieldObj.input_type == "file" || fieldObj.input_type == "facebook-create-ads" || fieldObj.input_type == "creative") && model[prop]) {
                found = true;
            }
        });
        return found;
    }

    const computeUploadingAndDeletedFiles = function(model, form, fields) {
        let uploadingFiles = [];
        let deletedFiles = [];
        let hasFileOrCreativeModifications = false;

        _.each(model, function(itemVal, prop) {
            let fieldObj = _.find(fields, {field_name: prop});
            if (fieldObj && fieldObj.input_type == "file" && model[prop]) {
                let oldFileObjList = [];
                for (let k = 0, len = form.length; k < len; k++) {
                    if (form[k].items) {
                        let fileField = _.find(form[k].items, {field_name: prop});
                        if (fileField) {
                            oldFileObjList = fileField.fileObjList ? fileField.fileObjList : [];
                            break;
                        }
                    }
                }

                let currentUploadingFiles = model[prop];
                let currentDeletedFiles = [];
                if (oldFileObjList && oldFileObjList.length > 0) {
                    // newFileObjList is the file list on the form
                    // oldFileObjList is the file list from database
                    // uploadingFileObjs will keep the new files the user added
                    // deleteFiles will keep the files the user removed
                    // We remove the duplicated files between 2 arrays to avoid duplicated file uploading
                    // newFileObjList structure [{ name: val }, { name: val }]
                    // oldListObjList structure [{ attr_id: val, file_name: val}, {attr_id: val, file_name: val}]
                    let newFileObjList = model[prop];
                    let newListSet = new Set(newFileObjList.map(({ name }) => name));
                    let oldListSet = new Set(oldFileObjList.map(({file_name}) => file_name));
                    currentUploadingFiles = newFileObjList.filter(({ name }) => !oldListSet.has( name ));
                    currentDeletedFiles = oldFileObjList.filter(({file_name}) => !newListSet.has(file_name));
                }
                if (currentUploadingFiles.length > 0 || currentDeletedFiles.length > 0) {
                    hasFileOrCreativeModifications = true;
                }
                uploadingFiles[prop] = currentUploadingFiles;
                deletedFiles[prop] = currentDeletedFiles;
            } else if (fieldObj && ['creative', 'facebook-create-ads'].includes(fieldObj.input_type) && model[prop]) {
                if ($scope.model[prop].media || ($scope.model[prop].selectedImages && $scope.model[prop].selectedImages.length > 0)) {
                    hasFileOrCreativeModifications = true;
                }
                if ($scope.model[prop].media || ($scope.model[prop].thirdPartyCreatives && $scope.model[prop].thirdPartyCreatives.length > 0)) {
                    hasFileOrCreativeModifications = true;
                }
            }
        });

        return [uploadingFiles, deletedFiles, hasFileOrCreativeModifications];
    }

    const fileUploadComplete = function(flightId, fileUploadIdx) {
        $scope.uploadFileSet.delete(fileUploadIdx);
        if ($scope.uploadFileSet.size == 0 && $scope.fileUploadKey) {
            // All uploads (or deleteCreatives, or attachCreatives) are done, so we can let the backend know,
            // so that it can run the workflow and perform any webhook pushes.
            OrdersService.flightFileUploadCompleted(flightId, $scope.fileUploadKey).then(function() {
                // Comments can be generated by the system during workflow execution (e.g. when a webhook is sent),
                // so fetch them again now that it has run.
                $scope.getComments(true);
            }, function() {});
            $scope.fileUploadKey = null;
        }
    }

    let addFlightComment = function(flightId, commentText, behaviour) {
        const params = {
            'tableName': 'flight',
            'tableRowId': flightId,
            'comment': commentText,
            'skipWorkflow': '0',
            'addedByBehaviour': behaviour,
        };
        CommentService.addFlightComment(params).then(function() {
            // Clear cached comments since there will be an additional one to load.
            $scope.cachedFlightComments[flightId] = null;
        },
        function() {
            console.log("Error saving comment for flight: " + flightId);
        });
    };

    let addCreativeAssets = function(flightId, creativeFiles, addToLibrary, adGroupMoreInfo) {

        $scope.cachedFlightCreatives[flightId] = null;
        $scope.flightCreatives = null;

        angular.forEach(creativeFiles, function(file, index) {
            if (index == 0) {
                $ioAlert.show('', "Creatives are being uploaded, please don't refresh the page. You can check the status in the notifications tab", 'success');
            }
            if (file && file.name && file.size) {
                // Wait for file upload confirmation, otherwise we might load invalid has_creative value when we next invoke getFlights
                const uploadFileIdx = $scope.uploadFileSetIndex++;
                // Here we add a value to uploadFileSet, but we do not invoke fileUploadComplete, because we'll do another
                // backend query after getting the image signature and uploading the file.
                // The reason we're adding a value to the set, then, is to prevent another file or backend query
                // from causing us to think we are done with all the uploads.
                $scope.uploadFileSet.add(uploadFileIdx);

                CreativeServicePro.getImgSignature({ flightId: flightId, fileName: file.name }).then(function(success) {
                    let additionalParams = {
                        "from": "flight",
                        "flightId": flightId,
                        "libraryCreative": addToLibrary ? "yes" : "no",
                        "file_name": file.name,
                        "file_size": file.size,
                        "uploadFileIdx": uploadFileIdx
                    };
                    if (adGroupMoreInfo) {
                        additionalParams.adGroupMoreInfo = adGroupMoreInfo;
                    }
                    $rs.$broadcast('cloudinaryImageUpload', success, file, additionalParams);
                }, function(error) {
                    console.log(error);
                    const errorMessage = JSON.stringify(error);
                    $ioAlert.show('Error!', 'Could not get flight img signature. ' + errorMessage, 'error');
                    fileUploadComplete(flightId, uploadFileIdx);
                });
            }
        });
    };

    let deleteCreativeAssets = function(flightId, creativeFileObjs, adGroupMoreInfo) {
        let flightAttrIds = [];
        _.each(creativeFileObjs, function(fileObj){
            flightAttrIds.push(fileObj.attr_id);
        });
        let params = {
            'flightAttrIds': flightAttrIds.toString(),
        }
        if (flightAttrIds.length > 0) {
            $scope.flightCreatives = null;
            const uploadFileIdx = $scope.uploadFileSetIndex++;
            $scope.uploadFileSet.add(uploadFileIdx);

            OrdersService.removeFlightFileAttrsAndCreatives(params).then(function() {
                OrdersService.getFlightFileFieldAttrs(flightId, adGroupMoreInfo.fieldId).then(function(flight_attrs) {
                    _.each($scope.flights, function (flight) {
                        if (flight.id == flight_attrs.flight_id) {
                            flight[flight_attrs.field_name] = flight_attrs.attrs;
                            if (flight_attrs.attrs.length == 0) {
                                delete flight[flight_attrs.field_name];
                            }
                        }
                    });

                    $rs.$broadcast('updateFlightFileFieldAndCreativesAfterDelete', flightId);
                    $ioAlert.show("", "File was removed successfully.", 'success');
                    fileUploadComplete(flightId, uploadFileIdx);
                }, function() {
                    $ioAlert.show("Error!", "An error occurred getting the flight attrs.", 'error');
                    fileUploadComplete(flightId, uploadFileIdx);
                });
            }, function() {
                $ioAlert.show("Error!", "An error occurred deleting the flight attrs.", 'error');
                fileUploadComplete(flightId, uploadFileIdx);
            });
        }
    };

    let attachCreatives = function(flightId, selectedCreatives) {
        $scope.cachedFlightCreatives[flightId] = null;
        $scope.flightCreatives = null;
        for (let i = 0, len = selectedCreatives.length; i < len; i++) {
            const obj = {
                "creativeId": selectedCreatives[i].id,
                "flightId": flightId
            };
            const uploadFileIdx = $scope.uploadFileSetIndex++;
            $scope.uploadFileSet.add(uploadFileIdx);

            creativesService.assignToFlight(obj).then(function(response) {
                if (response && response.errors) {
                    for (let j = 0, len = response.errors.length; j < len; j++) {
                        $ioAlert.show('Error', response.errors[j]);
                    }
                }
                // This leaves a race condition open where getFlights could be pending backend response, but it's better
                // than nothing, and plugging every hole is difficult since there are so many paths after add/updateFlight.
                if ($rs.loadingInProgress) {
                    // Race condition where getFlights may fetch flights that didn't yet have their creatives written to the DB,
                    // so remember them and set them after getFlights has returned.
                    $scope.flightIdsWithNewCreatives.push(flightId);
                } else {
                    setHasCreativeFlag(flightId);
                }
                fileUploadComplete(flightId, uploadFileIdx);
            }, function() {
                $ioAlert.show('ERROR!', 'Attaching flight creative failed. Please try again later.', 'error');
                fileUploadComplete(flightId, uploadFileIdx);
            });
        }
    };

    let setHasCreativeFlag = function(flightId) {
        let currentFlight = _.find($scope.flights, {id: flightId});
        currentFlight ? (currentFlight.has_creative = true) : '';  // Update this so that paperclip icon shows up in list
        if ($scope.currentEditedFlight && $scope.currentEditedFlight.id == flightId) {
            $scope.currentEditedFlight.has_creative = true;
        }
        if ($scope.currentViewFlight && $scope.currentViewFlight.id == flightId) {
            $scope.currentViewFlight.has_creative = true;
        }
    };

    function deleteCreativeModal(type, data) {
        return $modal.open(ModalService.returnModalType(type, { data: data }));
    }
    $scope.deleteCreative = function(creative) {
        const flightId = $scope.currentEditedFlight.id;  // Set now in case we changed flights by the time we get the backend response.
        deleteCreativeModal('deleteCreative', { filename: creative.filename }).result.then(function(selectedItem) {
            if (selectedItem == 'ok') {
                MediaService.deleteCreative(creative.id).then(function(success) {
                    if (success.id) {
                        if ($scope.flightCreatives) {    // Could be null if we navigated away from this flight edit page
                            for (let i = 0; i < $scope.flightCreatives.length; i++) {
                                if ($scope.flightCreatives[i].creative_id == success.id) {
                                    $scope.flightCreatives.splice(i, 1);
                                    if ($scope.flightCreatives.length == 0) {
                                        let currentFlight = _.find($scope.flights, {id: flightId});
                                        currentFlight.has_creative = false;  // Remove paperclip icon since this was the last file
                                        if ($scope.currentEditedFlight && $scope.currentEditedFlight.id == flightId) {
                                            $scope.currentEditedFlight.has_creative = false;
                                        }
                                        // In case we switched to view mode...
                                        if ($scope.currentViewFlight && $scope.currentViewFlight.id == flightId) {
                                            $scope.currentViewFlight.has_creative = false;
                                        }
                                    }
                                }
                            }
                        }
                        for (let i = 0; i < $scope.cachedFlightCreatives[flightId].length; i++) {
                            if ($scope.cachedFlightCreatives[flightId][i].creative_id == success.id) {
                                $scope.cachedFlightCreatives[flightId].splice(i, 1);
                                if ($scope.cachedFlightCreatives[flightId].length == 0) {
                                    let currentFlight = _.find($scope.flights, {id: flightId});
                                    currentFlight.has_creative = false;  // Remove paperclip icon since this was the last file
                                    if ($scope.currentEditedFlight && $scope.currentEditedFlight.id == flightId) {
                                        $scope.currentEditedFlight.has_creative = false;
                                    }
                                    if ($scope.currentViewFlight && $scope.currentViewFlight.id == flightId) {
                                        $scope.currentViewFlight.has_creative = false;
                                    }
                                }
                            }
                        }
                        // Remove it from our list of "real" creatives (i.e. creatives for "creative" and not "file"
                        // form fields), so that if they're empty, and if we have required creative fields on the form,
                        // we validate that a new one has been added during the edit.  If there was a real creative on
                        // the flight, we do not require a creative to be added again when editing it even if the creative
                        // is a required field.
                        for (let i = 0; i < $scope.realCreativeFilenames.length; i++) {
                            if ($scope.realCreativeFilenames[i] == creative.file_name) {
                                $scope.realCreativeFilenames.splice(i, 1);
                            }
                        }
                        success.updated_fields.forEach((field) => {
                            $scope.model[field.field_name] = field.field_value;
                        });
                        $ioAlert.show("", "Successfully deleted " + creative.file_name, "success");
                    } else {
                        $ioAlert.show("Error!", "An error occurred deleting the creative media.", 'error');
                    }
                }, function() {
                    $ioAlert.show("Error!", "An error occurred deleting the creative media.", 'error');
                });
            }
        }, function() {
            // dismiss modal
        });
    };

    let setDataTableOptions = function() {
        $timeout(function() {
            let options = $.core.datatable.defaultOptions('flight');
            options.iActions = 3;
            options.hasFixedHeader = false;
            options.isIoTool = true;
            // There's only 1 non-sortable column, so we can just use _.find to find a single one.
            options.nonSortableColumnIndex = $('#flights-table').find('th.non-sortable').index();
            $.core.datatable.build($('#flights-table'), options);
        });
    };

    $scope.selectAllVisibleFlights = function() {
        if ($scope.allFlightsSelected == false) {
            $scope.someFlightsSelected = true;
            $scope.allFlightsSelected = true;
            _.each($scope.visibleFlights, function (flight) {
                flight.checked = true;
            });
        } else {
            $scope.someFlightsSelected = false;
            $scope.allFlightsSelected = false;
            // Loop over all flights, just in case, even though only visibleFlights would be enough.
            _.each($scope.flights, function (flight) {
                flight.checked = false;
            });
        }
    }

    $scope.selectedFlight = function(flight) {
        if (flight.checked) {
            $scope.someFlightsSelected = true;
        }
        let allUnchecked = true;
        _.each($scope.visibleFlights, function(item) {
            if (item.checked) {
                allUnchecked = false;
            }
        });
        if (allUnchecked) {
            $scope.someFlightsSelected = false;
        }
    }

    $scope.moveFlights = function() {
        let checkedFlightIds = [];
        _.each($scope.visibleFlights, function (flight) {
            if (flight.checked) {
                checkedFlightIds.push(flight.id);
            }
        });
        handleMoveFlights(checkedFlightIds);
    }

    $scope.autoCreateFlights = function() {
        if ($scope.waitingForBackendResponse === true) {
            return;
        }
        $scope.waitingForBackendResponse = true;
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching auto-created flights preview data...";

        OrdersService.autoCreateFlightsDryRun($scope.lineItemId).then(function(data) {
            $rs.loadingInProgress = false;
            $scope.waitingForBackendResponse = false;
            if (data.errors) {
                if (data.errors.length == 1) {
                    console.log(data.errors[0]);
                } else {
                    console.log(data.errors);
                }
                $ioAlert.show('Error!', data.errors, 'error');
            } else {
                let modalInstance = $modal.open(
                    ModalService.returnModalType('autoCreateFlights', {
                        data: {
                            flights: data.flights,
                            flightFields: $scope.flightFields
                        }
                    })
                );
                modalInstance.result.then(function () {
                    $rs.loadingInProgress = true;
                    $rs.loadertitle = "Creating flights, please be patient...";
                    OrdersService.autoCreateFlights($scope.lineItemId).then(function (data) {
                        $rs.loadingInProgress = false;
                        if (data.errors) {
                            if (data.errors.length == 1) {
                                console.log(data.errors[0]);
                            } else {
                                console.log(data.errors);
                            }
                            $ioAlert.show('Error!', data.errors, 'error');
                        } else {
                            $ioAlert.show('', 'Flights created successfully', 'success');
                            cachedTasks = [];
                            cachedHistoryTasks = [];
                            cachedLineItemTasks = undefined;
                            cachedFlightTasks = [];
                            cachedLineItemHistoryTasks = undefined;
                            cachedFlightHistoryTasks = [];
                            $scope.flightLoaded = false;
                            $scope.currentViewFlight = null;
                            $scope.comments = [];
                            _.each(data.flights, function (flight) {
                                $scope.cachedFlightComments[flight.id] = null;
                            });
                            refreshLineItem();
                            getFlights();
                        }
                    }, function () {
                        $rs.loadingInProgress = false;
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    });
                });
            }
        }, function(error) {
            $rs.loadingInProgress = false;
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', error);
        });
    }

    $scope.regenerateFlights = function() {
        if ($scope.waitingForBackendResponse === true) {
            return;
        }
        $scope.waitingForBackendResponse = true;
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching regenerated flights preview data...";

        OrdersService.regenerateFlightsDryRun($scope.lineItemId).then(function(data) {
            $rs.loadingInProgress = false;
            $scope.waitingForBackendResponse = false;
            if (data.errors) {
                if (data.errors.length == 1) {
                    console.log(data.errors[0]);
                } else {
                    console.log(data.errors);
                }
                $ioAlert.show('Error!', data.errors, 'error');
            } else {
                let modalInstance = $modal.open(
                    ModalService.returnModalType('autoCreateFlights', {
                        data: {
                            flights: data.flights,
                            deletedFlights: data.deletedFlights,
                            flightFields: $scope.flightFields
                        }
                    })
                );
                modalInstance.result.then(function () {
                    $rs.loadingInProgress = true;
                    $rs.loadertitle = "Regenerating flights, please be patient...";
                    OrdersService.regenerateFlights($scope.lineItemId).then(function (data) {
                        $rs.loadingInProgress = false;
                        if (data.errors) {
                            if (data.errors.length == 1) {
                                console.log(data.errors[0]);
                            } else {
                                console.log(data.errors);
                            }
                            $ioAlert.show('Error!', data.errors, 'error');
                        } else {
                            $ioAlert.show('', 'Flights regenerated successfully', 'success');
                            cachedTasks = [];
                            cachedHistoryTasks = [];
                            cachedLineItemTasks = undefined;
                            cachedFlightTasks = [];
                            cachedLineItemHistoryTasks = undefined;
                            cachedFlightHistoryTasks = [];
                            $scope.flightLoaded = false;
                            $scope.currentViewFlight = null;
                            $scope.comments = [];
                            _.each(data.flights, function (flight) {
                                $scope.cachedFlightComments[flight.id] = null;
                            });
                            refreshLineItem();
                            getFlights();
                        }
                    }, function () {
                        $rs.loadingInProgress = false;
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    });
                });
            }
        }, function(error) {
            $rs.loadingInProgress = false;
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', error);
        });
    }

    $scope.redistributeValues = function() {
        if ($scope.waitingForBackendResponse === true) {
            return;
        }
        $scope.waitingForBackendResponse = true;
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching redistribution preview data...";

        OrdersService.redistributeValuesDryRun($scope.lineItemId).then(function(data) {
            $rs.loadingInProgress = false;
            $scope.waitingForBackendResponse = false;
            if (data.errors) {
                if (data.errors.length == 1) {
                    console.log(data.errors[0]);
                } else {
                    console.log(data.errors);
                }
                $ioAlert.show('Error!', data.errors, 'error');
            } else {
                let modalInstance = $modal.open(
                    ModalService.returnModalType('redistributeValues', {
                        data: {
                            flights: data.flights,
                            flightFields: $scope.flightFields
                        }
                    })
                );
                modalInstance.result.then(function () {
                    $rs.loadingInProgress = true;
                    $rs.loadertitle = "Redistributing values to distributed-target fields, please be patient...";
                    OrdersService.redistributeValues($scope.lineItemId).then(function (data) {
                        $rs.loadingInProgress = false;
                        if (data.errors) {
                            if (data.errors.length == 1) {
                                console.log(data.errors[0]);
                            } else {
                                console.log(data.errors);
                            }
                            $ioAlert.show('Error!', data.errors, 'error');
                        } else {
                            $ioAlert.show('', 'Values redistributed successfully', 'success');
                            cachedTasks = [];
                            cachedHistoryTasks = [];
                            cachedLineItemTasks = undefined;
                            cachedFlightTasks = [];
                            cachedLineItemHistoryTasks = undefined;
                            cachedFlightHistoryTasks = [];
                            $scope.flightLoaded = false;
                            $scope.currentViewFlight = null;
                            $scope.comments = [];
                            _.each(data.flights, function (flight) {
                                $scope.cachedFlightComments[flight.id] = null;
                            });
                            // Values can be rolled up to the line item, so we need to refresh it.  And of course refresh flights.
                            refreshLineItem();
                            getFlights();
                        }
                    }, function () {
                        $rs.loadingInProgress = false;
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    });
                });
            }
        });
    }

    $scope.copyFlights = function() {
        let checkedFlightIds = [];
        _.each($scope.visibleFlights, function (flight) {
            if (flight.checked) {
                checkedFlightIds.push(flight.id);
            }
        });
    }

    let getLineItem = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Line Item Information";

        OrdersService.getLineItem($scope.lineItemId, true, true).then(function() {
            $scope.lineItem = OrdersService.lineItem;
            if ($scope.lineItem) {
                if ($scope.lineItem.status_id == STATUS_LINEITEM_PENDING_CANCEL || $scope.lineItem.status_id == STATUS_LINEITEM_CANCEL) {
                    $scope.showCancel = true;
                }

                $scope.waitingForEOAPreviews = true;
                $scope.waitingForProductFields = true;
                showAudienceStatusPopupForLineItem();
                getCreatives();
                getOrder($scope.lineItem.order_id);
                handleLineItemEOAPreviews($scope.lineItem);
                computeBudgetGuardrailTotals();
                getProduct($scope.lineItem.product_id);
                getOnDemandTasks();
                $scope.loadingStep = true;
                getLineItemSteps();
                getLineItemWatchersList();
            } else {
                $ioAlert.show('Error!', 'Something went wrong here. Navigating back to myTasks', 'error');
                $state.go('myTasks');
            }
        }, function(error) {
            if (error && error.code === 403) {
                $scope.loadFail = true;
                $scope.loadComplete = false;
                $scope.error = error;
                $rs.loadingInProgress = false;
            } else {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            }
        });
    };

    // This gets the line item just like getLineItem above, but that's all it does, it does not get the rest of the
    // line item data (e.g. product fields).  This is for use when adding, copying, deleting, modifying a flight,
    // so that the allocated_budget can be updated, since if the flights change, the line item's allocated_budget can
    // change, since it's the sum of the flights' total_budget.
    let refreshLineItem = function() {
        OrdersService.getLineItem($scope.lineItemId, true, true).then(function() {
            $scope.lineItem = OrdersService.lineItem;
            if ($scope.lineItem) {
                computeBudgetGuardrailTotals();
                updateLineItemActivity();
                // We will rebuild display data in case a rollup field target has changed value
                $scope.waitingForEOAPreviews = true;
                handleLineItemEOAPreviews($scope.lineItem);
            }
        }, function(error) {
            console.log("Error refreshing line item: " + error);
        });
    };

    const showAudienceStatusPopupForLineItem = function() {
        if ($scope.lineItem.hasOwnProperty('__audience_status')) {
            if ($scope.lineItem['__audience_status'] != 'audience_current') {
                $ioAlert.show('Error!', "Audience is not in 'Ready' Status.", 'error');
            }
        }
    };

    let computeBudgetGuardrailTotals = function() {
        if ($scope.lineItem.hasOwnProperty('total_budget') && $scope.lineItem.hasOwnProperty('allocated_budget')) {
            $scope.formatted_line_item_total_budget =  FormService.currencyFormating($scope.lineItem.total_budget, true);
            $scope.formatted_line_item_allocated_budget = FormService.currencyFormating(''+$scope.lineItem.allocated_budget, true);
            $scope.budget_guardrails_underallocated_line_item_budget = Math.abs($scope.lineItem.allocated_budget - $scope.lineItem.total_budget) > 1;
        }
    };

    $scope.loadingStep = false;
    $scope.stepError = false;
    var getLineItemSteps = function() {
        $scope.loadingStep = true;

        OrdersService.getLineItemSteps($scope.lineItemId).then(function(success) {
            if ($scope.lineItem.workflow_step_id) {
                for (var i = 0; i < success.length; i++) {
                    if (success[i].id == $scope.lineItem.workflow_step_id) {
                        success[i].isCurrentStep = true;
                    } else {
                        success[i].isCurrentStep = false;
                    }
                }
                if ($scope.lineItem.completed_workflow_steps && $scope.lineItem.completed_workflow_steps.length) {
                    _.each($scope.lineItem.completed_workflow_steps, function(completed) {
                        _.each(success, function(steps) {
                            if (steps.id == completed.id) {
                                steps.completedStep = true;
                            }
                        });
                    });
                }
            } else {
                $scope.stepError = true;
            }

            $scope.lineItem.steps = success;
            $scope.loadingStep = false;

        }, function() {
            $scope.loadingStep = false;
            $ioAlert.show('Error!', 'Something went wrong getting Steps. Please refresh the page to try again', 'error');
        });
    };

    $scope.showTasks = function(showAll) {
        getTaskAssignments(showAll);
    }

    $scope.naturalSortByIdDesc = function(a, b) {
        return FormService.naturalSortByIdDesc(a, b);
    }

    let getTaskAssignments = function(showAll) {
        if ($scope.showSpecificLineItemAndFlightTasks) {
            doGetSpecificTaskAssignments(showAll);
        } else {
            doGetTaskAssignments(showAll);
        }
    }

    let doGetTaskAssignments = function(showAll) {
        if (showAll) {
            if (cachedHistoryTasks.length > 0) {
                $scope.associatedTasks = cachedHistoryTasks;
                return;
            }
            if ($scope.showHistoryTaskProgress === false) {
                $scope.showHistoryTaskProgress = true;
                $scope.associatedTasks = [];
                TaskService.getTaskAssignmentsForLineItem($scope.lineItemId).then(function (taskAssignments) {
                    $scope.associatedTasks = _.sortBy(taskAssignments, 'id').reverse();
                    const len = $scope.flights.length;
                    if (len == 0) {
                        cachedHistoryTasks = $scope.associatedTasks
                        $scope.showHistoryTaskProgress = false;
                    } else {
                        let itemsProcessed = 0;
                        for (let i = 0; i < len; i++) {
                            TaskService.getTaskAssignmentsForFlight($scope.flights[i].id).then(function (taskAssignments) {
                                itemsProcessed++;
                                cachedHistoryTasks = $scope.associatedTasks = $scope.associatedTasks.concat(taskAssignments);
                                if (itemsProcessed >= len) {
                                    $scope.showHistoryTaskProgress = false;
                                    cachedHistoryTasks = $scope.associatedTasks = _.sortBy($scope.associatedTasks, 'id').reverse();
                                }
                            }, function () {
                                itemsProcessed++;
                                $ioAlert.show('Error getting flight tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                                if (itemsProcessed >= len) {
                                    $scope.showHistoryTaskProgress = false;
                                    cachedHistoryTasks = $scope.associatedTasks = _.sortBy($scope.associatedTasks, 'id').reverse();
                                }
                            });
                        }
                    }
                }, function() {
                    $scope.showHistoryTaskProgress = false;
                    $ioAlert.show('Error getting line item tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                });
            }
        } else {
            if (cachedTasks.length > 0) {
                $scope.associatedTasks = cachedTasks;
                return;
            }
            if ($scope.showTaskProgress === false) {
                $scope.showTaskProgress = true;
                let taskAssignmentsArr = [];
                $scope.associatedTasks = [];
                TaskService.getOpenTaskAssignmentsForLineItem($scope.lineItemId).then(function (taskAssignments) {
                    if ($scope.flights.length) {
                        taskAssignmentsArr = taskAssignmentsArr.concat(taskAssignments);
                        let itemsProcessed = 0;
                        for (let x = 0, len = $scope.flights.length; x < len; x++) {
                            (function(indexPos) {
                                TaskService.getOpenTaskAssignmentsForFlight($scope.flights[indexPos].id).then(function(taskAssignments) {
                                    itemsProcessed++;
                                    taskAssignmentsArr = taskAssignmentsArr.concat(taskAssignments);
                                    if (itemsProcessed >= len) {
                                        $scope.showTaskProgress = false;
                                        parseFlightsHelper(taskAssignmentsArr);
                                    }
                                }, function() {
                                    itemsProcessed++;
                                    $ioAlert.show('Error getting flight tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                                    if (itemsProcessed >= len) {
                                        $scope.showTaskProgress = false;
                                    }
                                });
                            }(x));
                        }
                    } else {
                        $scope.showTaskProgress = false;
                        cachedTasks = $scope.associatedTasks = _.sortBy(taskAssignments, 'id').reverse();
                        calculateDueDates();
                    }
                }, function() {
                    $ioAlert.show('Error getting line item tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                });
            }
        }
    };

    let doGetSpecificTaskAssignments = function(showAll) {
        if (showAll) {
            if ($scope.flightTabCurrentlyShown) {
                if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0 || $scope.currentEditedFlight && $scope.currentEditedFlight.id > 0) {
                    const shownFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
                    if (shownFlightId in cachedFlightHistoryTasks) {
                        $scope.associatedTasks = cachedFlightHistoryTasks[shownFlightId];
                    } else {
                        if ($scope.showHistoryTaskProgress === false) {
                            $scope.showHistoryTaskProgress = true; // Prevent multiple identical backend calls (e.g. if tab is repeatedly selected)
                            $scope.associatedTasks = [];
                            TaskService.getTaskAssignmentsForFlight(shownFlightId).then(function (taskAssignments) {
                                $scope.showHistoryTaskProgress = false;
                                cachedFlightHistoryTasks[shownFlightId] = _.sortBy(taskAssignments, 'id').reverse();
                                const currentFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : ($scope.currentEditedFlight ? $scope.currentEditedFlight.id : 0);
                                if ($scope.isTasksHistoryTabActive() && currentFlightId === shownFlightId) {
                                    // Only show the received tasks if the same flight is still shown (race condition
                                    // where we might have switched flight or even moved back to the line item).
                                    $scope.associatedTasks = cachedFlightHistoryTasks[shownFlightId];
                                } else if ($scope.isTasksHistoryTabActive() && currentFlightId in cachedFlightHistoryTasks) {
                                    // If we have the cached tasks for the newly active flight, use its tasks!
                                    $scope.associatedTasks = cachedFlightHistoryTasks[currentFlightId];
                                } else if ($scope.isTasksTabActive() && currentFlightId in cachedFlightTasks) {
                                    // Highly unlikely, but we switched to the non-historical tasks while waiting for the
                                    // backend response!
                                    $scope.associatedTasks = cachedFlightTasks[currentFlightId];
                                }
                            }, function () {
                                $scope.showHistoryTaskProgress = false;
                                $ioAlert.show('Error getting flight tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                                cachedFlightHistoryTasks[shownFlightId] = $scope.associatedTasks = [];
                            });
                        }
                    }
                } else {
                    // Else don't load anything as no flight is selected, but clear any previously displayed tasks.
                    $scope.associatedTasks = [];
                }
            } else {
                if (cachedLineItemHistoryTasks !== undefined) {
                    $scope.associatedTasks = cachedLineItemHistoryTasks;
                } else {
                    if ($scope.showHistoryTaskProgress === false) {
                        $scope.showHistoryTaskProgress = true;
                        $scope.associatedTasks = [];
                        TaskService.getTaskAssignmentsForLineItem($scope.lineItemId).then(function (taskAssignments) {
                            $scope.showHistoryTaskProgress = false;
                            cachedLineItemHistoryTasks = $scope.associatedTasks = _.sortBy(taskAssignments, 'id').reverse();
                        }, function () {
                            $scope.showHistoryTaskProgress = false;
                            $ioAlert.show('Error getting line item tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                            cachedLineItemHistoryTasks = []; // Set to empty array, so we don't attempt to fetch again
                        });
                    }
                }
            }
        } else {
            if ($scope.flightTabCurrentlyShown) {
                if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0 || $scope.currentEditedFlight && $scope.currentEditedFlight.id > 0) {
                    const shownFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
                    if (shownFlightId in cachedFlightTasks) {
                        $scope.associatedTasks = cachedFlightTasks[shownFlightId];
                    } else {
                        if ($scope.showTaskProgress === false) {
                            $scope.showTaskProgress = true; // Prevent multiple identical backend calls (e.g. if tab is repeatedly selected)
                            $scope.associatedTasks = [];
                            TaskService.getOpenTaskAssignmentsForFlight(shownFlightId).then(function (taskAssignments) {
                                $scope.showTaskProgress = false;
                                cachedFlightTasks[shownFlightId] = _.sortBy(taskAssignments, 'id').reverse();
                                const currentFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : ($scope.currentEditedFlight ? $scope.currentEditedFlight.id : 0);
                                if ($scope.isTasksTabActive() && currentFlightId === shownFlightId) {
                                    // Only show the received tasks if the same flight is still shown (race condition
                                    // where we might have switched flight or even moved back to the line item).
                                    $scope.associatedTasks = cachedFlightTasks[shownFlightId];
                                } else if ($scope.isTasksTabActive() && currentFlightId in cachedFlightTasks) {
                                    // If we have the cached tasks for the newly active flight, use its tasks!
                                    $scope.associatedTasks = cachedFlightTasks[currentFlightId];
                                } else if ($scope.isTasksHistoryTabActive() && currentFlightId in cachedFlightHistoryTasks) {
                                    // Highly unlikely, but we switched to the historical tasks while waiting for the
                                    // backend response!
                                    $scope.associatedTasks = cachedFlightHistoryTasks[currentFlightId];
                                }
                            }, function () {
                                $scope.showTaskProgress = false;
                                $ioAlert.show('Error getting flight tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                                cachedFlightTasks[shownFlightId] = $scope.associatedTasks = [];
                            });
                        }
                    }
                } else {
                    // Else don't load anything as no flight is selected, but clear any previously displayed tasks.
                    $scope.associatedTasks = [];
                }
            } else {
                if (cachedLineItemTasks !== undefined) {
                    $scope.associatedTasks = cachedLineItemTasks;
                } else {
                    if ($scope.showTaskProgress === false) {
                        $scope.showTaskProgress = true;
                        $scope.associatedTasks = [];
                        TaskService.getOpenTaskAssignmentsForLineItem($scope.lineItemId).then(function (taskAssignments) {
                            $scope.showTaskProgress = false;
                            cachedLineItemTasks = $scope.associatedTasks = _.sortBy(taskAssignments, 'id').reverse();
                        }, function () {
                            $scope.showTaskProgress = false;
                            $ioAlert.show('Error getting line item tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                            cachedLineItemTasks = []; // Set to empty array, so we don't attempt to fetch again
                        });
                    }
                }
            }
        }
    }

    $scope.navigateToTask = function(taskId) {
        // Whatever we were showing (line item or some task, or just the task list), we can go to any line item or flight
        // task.  However, when we go back from the task page, we want to go back to whatever line item or flight we
        // were *displaying*.  We don't want to go back to the line item or flight associated with the task we went to.
        // So we invoke the task page accordingly.  Note that we use flightId = 0 to indicate that we were on the
        // flight tab, but without any specific flight currently shown.  If we were adding a new flight, we also use
        // flightId = 0 and we'll come back to the flights list page.  If we were editing a flight, we'll come back
        // to this flight's display page.  Basically we don't support coming back in add/edit mode, only display mode.
        if ($scope.flightTabCurrentlyShown) {
            let shownFlightId = 0;  // This means either flights list page, or that we were on the flight add form.
            if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0 || $scope.currentEditedFlight && $scope.currentEditedFlight.id > 0) {
                shownFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
            }
            $state.go('viewFlightTask', {
                flightId: shownFlightId,
                lineItemId: $scope.lineItemId,
                taskId: taskId,
                fromPage: 'flight'
            });
        } else {
            $state.go('viewLineItemTask', {
                lineItemId: $scope.lineItemId,
                taskId: taskId,
                fromPage: 'lineitem'
            });
        }
    };

    var getOrder = function(orderId) {
        // Do not fetch line items when getting the order data
        OrdersService.getOrder(orderId, true).then(function() {
            $scope.order = OrdersService.order;
            if (!$scope.order.editable) {
                $scope.restrictEdit = true;
            }
            ClusterService.initCluster($rs.userRole, 'disabled', $scope.order.cluster_id[0]);
            $rs.$broadcast('fetchTaggingUsers', $scope.order.cluster_id[0]);
            getProductFields();
            $scope.getComments();
        }, function() {
            console.log("Error getting order info for line item: " + $scope.lineItemId);
        })
    };

    var getProduct = function(productId) {
        ProductService.getProduct(productId).then(function() {
            $scope.product = ProductService.product;
            // Needed for escalate and overdue icons.
            if ($scope.lineItem.hasOwnProperty('__enable_flights') && $scope.lineItem.__enable_flights == 1) {
                // Must be invoked after we got the product since we need the product to have the flight_form_id.
                getFlightFieldGroups();
            }
        }, function(error) {
            console.log("Error getting product info for line item: " + $scope.lineItemId + " " + error);
        })
    };

    const getFlightFieldGroups = function() {
        FormService.getFlightFormFieldGroups($scope.product.flight_form_id).then(function (formFieldGroups) {
            $scope.flightFormFieldGroups = formFieldGroups;
            _.each($scope.flightFormFieldGroups, function (group) {
                group.group_display_order = parseInt(group.group_display_order);
            });
            getFlights();
        }, function () {
            console.log("Error getting flight form field groups for product: " + $scope.product.id);
            $rs.loadingInProgress = false;
        });
    }

    const getGoals = function(lineItemId) {
        OrdersService.getGoalsForLineItem(lineItemId).then(function(goals) {
            if (goals && goals.length !== 0) {
                $scope.goals = goals;
                $scope.hasGoals = true;
            }
        }, function(error) {
            console.log("Error getting goals for line item: " + $scope.lineItemId + " " + error);
        })
    };

    let getCreatives = function(callbackFunction = null, callbackArg = null, explicitFlightId = null) {

        if ($scope.flightTabCurrentlyShown) {
            if (explicitFlightId != null || $scope.currentEditedFlight && $scope.currentEditedFlight.id > 0) {
                const flightId = explicitFlightId ? explicitFlightId : ($scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id);
                if ($scope.cachedFlightCreatives[flightId] != null) {
                    // Great, it's already cached.
                    $scope.flightCreatives = $scope.cachedFlightCreatives[flightId];
                    if (callbackFunction != null) {
                        callbackFunction(callbackArg);
                    }
                } else {
                    if ($scope.waitForFlightFileDelete || $scope.waitForFlightFileUpload) {
                        // There's no point waiting for out-of-date info... we're in the process of adding or removing
                        // creatives, so do it later!
                        if (callbackFunction != null) {
                            callbackFunction(callbackArg);
                        }
                        return;
                    }
                    $scope.waitingForCreatives = true;
                    MediaService.getFlightCreatives(flightId).then(function () {
                        $scope.cachedFlightCreatives[flightId] = MediaService.creatives;
                        // Check that we have not selected another flight by the time we get the response from the backend,
                        // unless we were provided a specific flight to handle.
                        const currentFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : ($scope.currentEditedFlight ? $scope.currentEditedFlight.id : null);
                        if ((explicitFlightId != null) || (flightId == currentFlightId)) {
                            $scope.flightCreatives = $scope.cachedFlightCreatives[flightId];
                            // We have to set has_creative again (it was originally set in the backend) in case creatives
                            // were deleted (and then re-added).
                            const hasCreatives = MediaService.creatives && MediaService.creatives.length > 0;
                            if ($scope.currentViewFlight && $scope.currentViewFlight.id == flightId) {
                                $scope.currentViewFlight.has_creative = hasCreatives;
                            }
                            if ($scope.currentEditedFlight && $scope.currentEditedFlight.id == flightId) {
                                $scope.currentEditedFlight.has_creative = hasCreatives;
                            }
                        }
                        $scope.waitingForCreatives = false;
                        if ($scope.notifyOnceCreativesReceived) {
                            $scope.notifyOnceCreativesReceived = false;
                            waitForLoadFormDataOrLoadForm();
                        }
                        if (callbackFunction != null) {
                            callbackFunction(callbackArg);
                        }
                    }, function () {
                        console.log("Error getting creatives for flight: " + flightId);
                        if ($scope.notifyOnceCreativesReceived) {
                            $scope.notifyOnceCreativesReceived = false;
                            waitForLoadFormDataOrLoadForm();
                        }
                        if (callbackFunction != null) {
                            callbackFunction(callbackArg);
                        }
                    });
                }
            }
        } else {
            MediaService.getLineItemCreatives($scope.lineItemId).then(function () {
                $scope.creatives = MediaService.creatives;
                if (callbackFunction != null) {
                    callbackFunction(callbackArg);
                }
            }, function () {
                console.log("Error getting creatives for line item: " + $scope.lineItemId);
                if (callbackFunction != null) {
                    callbackFunction(callbackArg);
                }
            });
        }
    };

    $scope.getComments = function(forceUpdate = false) {
        // We cache comments so that we can switch faster between flights and also the line-item/flight tabs.
        if ($scope.flightTabCurrentlyShown) {
            if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0 || $scope.currentEditedFlight && $scope.currentEditedFlight.id > 0) {
                const flightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
                if ($scope.cachedFlightComments[flightId] != null && !forceUpdate) {
                    $scope.comments = $scope.cachedFlightComments[flightId];   // Cache hit
                } else {
                    CommentService.getFlightComments(flightId, $scope.commentFilter.search).then(function () {
                        const newFlightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
                        // This can be a race condition: if we started loading the comments, but in the mean time we
                        // started editing/showing another flight, or decided to add a new flight, then don't show
                        // these comments as they are not for the flight we're showing anymore.
                        // We can still populate the cache however.
                        if (newFlightId == flightId) {
                            $scope.comments = CommentService.comments;
                        }
                        $scope.cachedFlightComments[flightId] = CommentService.comments;  // Populate (or update) cache
                    }, function () {
                        console.log("Error getting comments for flight: " + flightId);
                    });
                }
            } else {
                $scope.comments = [];
            }
        } else {
            if ($scope.cachedLineItemComments && !forceUpdate) {
                $scope.comments = $scope.cachedLineItemComments;
            } else {
                CommentService.getLineItemComments($scope.lineItemId, $scope.commentFilter.search).then(function (comments) {
                    $scope.comments = comments;
                    $scope.cachedLineItemComments = $scope.comments;
                }, function () {
                    console.log("Error getting comments for line item: " + $scope.lineItemId);
                });
            }
        }
    };

    const hiddenByParent = function(field, fieldList, entity) {
        if (field.parent_id) {
            let parent_field = null;
            for (let i = 0; i < fieldList.length; i++) {
                if (fieldList[i]['id'] == field.parent_id) {
                    parent_field = fieldList[i];
                    break;
                }
            }

            if (parent_field && entity.hasOwnProperty(parent_field.field_name)) {
                let parentValues = entity[parent_field.field_name];
                if (Array.isArray(parentValues)) {
                    if (Array.isArray(parentValues[0])) {
                        for (let i in parentValues) {
                            if (field.parent_trigger_id.indexOf(parentValues[i][0]) != -1) { // multi select
                                return false;
                            }
                        }
                    } else if (field.parent_trigger_id.indexOf(parentValues[0]) != -1) { // single select
                        return false;
                    }
                } else if (field.parent_trigger_id.indexOf(parentValues) != -1) { // checkbox
                    return false;
                }
                return true;
            } else {
                return true;
            }
        }
        return false;
    }

    const buildDisplayData = function() {
        $scope.productDisplayGroups = [];
        let staticInfoSection = {};
        staticInfoSection.title = 'Product Info';
        staticInfoSection.data = [];

        let productName = {};
        let orderId = {};
        let statusName = {};
        let serviceId = $scope.product.ta_service_id;
        productName['Product Name'] = $scope.lineItem.product_name;
        orderId['Order Id'] = $scope.lineItem.order_id;
        statusName['Status Name'] = $scope.lineItem.status_name;

        $scope.locationpickerOptions = {
            location: {
                latitude: 0,
                longitude: 0
            },
            inputBinding: {},
            radius: 0,
            enableAutocomplete: true
        };

        staticInfoSection.data.push(productName);
        staticInfoSection.data.push(orderId);
        staticInfoSection.data.push(statusName);
        
        if ($scope.lineItem.dfp_line_item_id && $scope.lineItem.dfp_order_id && $scope.lineItem.network_id) {
            let lineitemId = {};
            lineitemId['GAM Lineitem Id'] = $scope.lineItem.dfp_line_item_id;
            staticInfoSection.data.push(lineitemId);
        } else if (serviceId && $scope.lineItem.id && $scope.lineItem.line_item_external_id) {
            let lineItemExternalId = {};
            if (serviceId == 34) {
                lineItemExternalId['GoogleAds Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            } else if (serviceId == 39) {
                lineItemExternalId['FacebookAds Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            } else if (serviceId == 194) {
                lineItemExternalId['LinkedinAds Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            } else if (serviceId == 275) {
                lineItemExternalId['PinterestAds Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            } else if (serviceId == 282) {
                lineItemExternalId['TiktokAds Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            } else if (serviceId == 286) {
                lineItemExternalId['TritonAds Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            } else if (serviceId == 62) {
                lineItemExternalId['Xandr Lineitem External Id'] = $scope.lineItem.line_item_external_id;
            }
            staticInfoSection.data.push(lineItemExternalId);
        }

        if (serviceId && $scope.lineItem.order_external_id) {
            let OrderExternalId = {};
            if (serviceId == 230) {
                OrderExternalId['Snapchat Campaign External Id'] = $scope.lineItem.order_external_id;
            }
            staticInfoSection.data.push(OrderExternalId);
        }
        if ($scope.lineItem.campaign_manager_name) {
            let campaignManager = {};
            campaignManager['Campaign Manager'] = $scope.lineItem.campaign_manager_name;
            staticInfoSection.data.push(campaignManager);
        }

        $scope.productDisplayGroups.push(staticInfoSection);

        let staticCustomerInfo = {};
        staticCustomerInfo.title = "Client Information";
        staticCustomerInfo.data = [];
        staticCustomerInfo.data.push({ 'Client': $scope.order.client_company_name });
        staticCustomerInfo.data.push({ 'Advertiser Account Id': $scope.order.client_billing_id });
        staticCustomerInfo.data.push({ 'CRM Customer Id': $scope.order.client_crm_id });
        $scope.productDisplayGroups.push(staticCustomerInfo);
        let productFieldGroups = ProductService.productFieldGroups;

        for (let x = 0; x < productFieldGroups.length; x++) {
            let productFieldGroup = productFieldGroups[x];
            let section = {};
            section.title = productFieldGroup.group_name;
            section.groupId = productFieldGroup.id;
            section.data = [];
            if (productFieldGroup.hideForCondition && productFieldGroup.hideForCondition.includes($rs.userRole.role_id)) {
                continue;
            }
            $scope.productDisplayGroups.push(section);
        }

        if ($scope.productFields && $scope.productFields.length) {
            for (let i = 0; i < $scope.productFields.length; i++) {
                let productField = $scope.productFields[i];
                if (!hiddenByParent(productField, $scope.productFields, $scope.lineItem)) {
                    if (productField.input_type === "readonly") {
                        if (productField.default_value) {
                            $scope.lineItem[productField.field_name] = productField.default_value;
                        } else if (productField.cascading_field_name) {
                            let cascadingValue = $scope.order ? $scope.order[productField.cascading_field_name] : null;
                            if (cascadingValue != undefined) {
                                $scope.lineItem[productField.field_name] = cascadingValue;
                            }
                        }
                    }
                    if ($scope.lineItem.hasOwnProperty(productField.field_name) ||
                        (productField.input_type == "" && $scope.creatives && $scope.creatives.length > 0) ||
                        productField.input_type == 'custom-macro-mapping') {
                        let group = _.find($scope.productDisplayGroups, {groupId: productField.group_id});
                        if (!group) continue;
                        // Note that we have a race conditions since we don't wait for creatives to be received, so
                        // if they take longer than the order, product fields and groups to arrive, they may be empty.
                        // We'll live with the risk for now (it's very unlikely to be a problem in practice).
                        let displayPair = DisplayService.applyTransformation(
                            productField,
                            $scope.lineItem.hasOwnProperty(productField.field_name) ? $scope.lineItem[productField.field_name] : null,
                            $scope.creatives);
                        displayPair.formFieldType = ['hyperlink'].includes(productField.data_type) ?
                            productField.data_type : productField.input_type;
                        displayPair.formFieldName = productField.field_name;
                        if (productField.input_type == 'custom-macro-mapping') {
                            displayPair.audience_macro_mappings = $scope.lineItem['__audience_macro_mappings'] || [];
                            displayPair.audience_match_mappings = $scope.lineItem['__audience_match_mappings'] || [];
                            displayPair.email_macros = $scope.lineItem['__email_macros'] || "[]";  // A string containing JSON
                            displayPair.html_validation_status = $scope.lineItem['__html_validation_status'] ?? '';
                        }
                        if (productField.input_type == 'bridge-email-test') {
                            displayPair.entire_line_item = $scope.lineItem;
                        }
                        group.data.push(displayPair);
                    }
                } else {
                    delete $scope.lineItem[productField.field_name];
                }
            }
        }

        for (let i = 0; i < $scope.productDisplayGroups.length; i++) {
            if ($scope.productDisplayGroups[i].data && $scope.productDisplayGroups[i].data.length === 0) {
                $scope.productDisplayGroups.splice(i, 1);
                i--;
            }
        }
    }

    function parseLineItemActivity(activity) {
        const arrMap = [];
        if (activity != null) {
            for (let i = 0, len = activity.length; i < len; i++) {
                if (activity[i].action?.startsWith('update')) {
                    let jsonObj = JSON.parse(activity[i].delta);
                    let keys = Object.keys(jsonObj);

                    keys.forEach(function(value) {
                        let obj = {};
                        obj.field = value;
                        obj.before = jsonObj[value].before;
                        obj.after = jsonObj[value].after;
                        obj.date = activity[i].created_at;
                        obj.userName = activity[i].first_name + " " + activity[i].last_name;

                        if (arrMap[value]) {
                            arrMap[value].push(obj);
                        } else {
                            arrMap[value] = [obj];
                        }
                    });
                }
            }
        }
        return arrMap;
    }

    function updateLineItemAuditHistory() {
        _.each($scope.productDisplayGroups, function(group) {
            _.each(group.data, function(data) {
                if ($scope.arrMap[data.formFieldName]) {
                    data.isEdited = true;
                    data.fieldAuditHistory = $scope.arrMap[data.formFieldName];
                } else {
                    data.isEdited = false;
                }
            });
        });
    }

    // This is for use when flight changes can have an impact on the line item. One reason could be that new/updated
    // flights have rollup fields that modify their line item target.
    function updateLineItemActivity() {
        OrdersService.getActivity('line_item', $scope.lineItemId).then(function() {
            $scope.lineItemActivity = OrdersService.lineItemActivity;
            $scope.arrMap = parseLineItemActivity($scope.lineItemActivity);
            updateLineItemAuditHistory();
        }, function() {
            console.log("Error updating line item activity for line item: " + $scope.lineItemId);
        });
    }

    const getLineItemActivity = function() {
        OrdersService.getActivity('line_item', $scope.lineItemId).then(function() {
            $scope.lineItemActivity = OrdersService.lineItemActivity;
            $scope.arrMap = parseLineItemActivity($scope.lineItemActivity);

            // This is to add fields that are currently empty, but have a history and were not always empty.
            if ($scope.productFields && $scope.productFields.length) {
                for (let i = 0; i < $scope.productFields.length; i++) {
                    let productField = $scope.productFields[i];
                    if (!$scope.lineItem.hasOwnProperty(productField.field_name) &&
                        $scope.arrMap.hasOwnProperty(productField.field_name) &&
                        !hiddenByParent(productField, $scope.productFields, $scope.lineItem)) {
                        let group = _.find($scope.productDisplayGroups, {groupId: productField.group_id});
                        if (!group) continue;
                        let displayPair = {};
                        displayPair[productField.display_name] = "";
                        displayPair.formFieldType = productField.input_type;
                        displayPair.formFieldName = productField.field_name;
                        group.data.push(displayPair);
                    }
                }
            }

            //Helper function to update the audit logging
            updateLineItemAuditHistory();

            FormService.getTaskFormFieldsForLineItem($scope.lineItemId).then(function (data) {
                if (!data.formFields || data.formFields.constructor.name !== 'Array') {
                    return
                }

                let group = {
                    title: 'Task Information',
                    data: [],
                };
                data.formFields.forEach(function (formField) {
                    let attrValue = $scope.lineItem[formField.field_name];
                    if (attrValue) {
                        let displayPair = DisplayService.applyTransformation(formField, attrValue);
                        displayPair.formFieldType = ['hyperlink'].includes(formField.data_type) ?
                            formField.data_type : formField.input_type;
                        displayPair.formFieldName = formField.field_name;
                        group.data.push(displayPair);
                    }
                });
                if (group.data.length === 0) {
                    return;
                }
                $scope.productDisplayGroups.push(group);
            })

        }, function() {
            console.log("Error getting line item activity for line item: " + $scope.lineItemId);
        });

        OrdersService.getActivity('line_item_task_attr', $scope.lineItemId).then(function() {
            $scope.lineItemTaskActivity = OrdersService.lineItemTaskActivity;
            $scope.taskArrMap = {};
            if ($scope.lineItemTaskActivity != null) {
                for (var i = 0, len = $scope.lineItemTaskActivity.length; i < len; i++) {
                    if ($scope.lineItemTaskActivity[i].action?.startsWith('update')) {
                        var jsonObj = JSON.parse($scope.lineItemTaskActivity[i].delta);
                        var keys = Object.keys(jsonObj);

                        keys.forEach(function(value) {
                            var obj = {};
                            obj.field = value;
                            obj.before = jsonObj[value].before;
                            obj.after = jsonObj[value].after;
                            obj.date = $scope.lineItemTaskActivity[i].created_at;
                            obj.userName = $scope.lineItemTaskActivity[i].first_name + " " + $scope.lineItemTaskActivity[i].last_name;

                            if ($scope.taskArrMap[value]) {
                                $scope.taskArrMap[value].push(obj);
                            } else {
                                $scope.taskArrMap[value] = [obj];
                            }
                        });
                    }
                }
            }

        }, function() {
            console.log("Error getting line item task activity for line item: " + $scope.lineItemId);
        });
    };

    let getFlightActivity = function(flightId) {
        OrdersService.getActivity('flight', flightId).then(function() {
            const flightActivity = OrdersService.flightActivity;
            if (flightActivity != null) {
                let flightActivityMap = {};
                for (let i = 0, len = flightActivity.length; i < len; i++) {
                    if (flightActivity[i].action?.startsWith('update')) {
                        const jsonObj = JSON.parse(flightActivity[i].delta);
                        const keys = Object.keys(jsonObj);

                        keys.forEach(function(value) {
                            let obj = {};
                            obj.field = value;
                            obj.before = jsonObj[value].before;
                            obj.after = jsonObj[value].after;
                            obj.date = flightActivity[i].created_at;
                            obj.userName = flightActivity[i].first_name + " " + flightActivity[i].last_name;

                            if (flightActivityMap[value]) {
                                flightActivityMap[value].push(obj);
                            } else {
                                flightActivityMap[value] = [obj];
                            }
                        });
                    }
                }
                // Note that $scope.cachedFlightDisplayGroups was already filled with other info by the time
                // this method (getFlightActivity) was invoked, so we're okay to add the activity information now.
                let foundItems = [];
                _.each($scope.cachedFlightDisplayGroups[flightId], function(group) {
                    _.each(group.data, function(data) {
                        if (flightActivityMap[data.formFieldName]) {
                            foundItems.push(data.formFieldName);
                            data.isEdited = true;
                            data.fieldAuditHistory = flightActivityMap[data.formFieldName];
                        } else {
                            data.isEdited = false;
                        }
                    });
                    if (!group.hasOwnProperty('groupId')) {
                        // If it's not a proper group (e.g. for the Flight Info group), there's no adding missing fields.
                        return;
                    }
                    // Any items not found means they are empty for this flight, but have an activity log history,
                    // so display them as empty fields with the pencil icon so users can see their history.  Otherwise,
                    // they just aren't displayed.
                    _.each(flightActivityMap, function(value, key) {
                        if (_.indexOf(foundItems, key) < 0) {
                            const flightField = _.find($scope.flightFields, {field_name: key});
                            if (flightField && flightField.group_id == group.groupId) {
                                // Insert this empty field to the displayed fields... in the right display order position.
                                let displayPair = {};
                                displayPair[flightField.display_name] = "";
                                displayPair.formFieldType = flightField.input_type;
                                displayPair.formFieldName = flightField.field_name;
                                displayPair.displayOrder  = parseInt(flightField.display_order);
                                displayPair.isEdited = true;
                                displayPair.fieldAuditHistory = value;
                                let added = false;
                                const currentFieldDisplayOrder = parseInt(flightField.display_order);
                                for (let j = 0, len = group.data.length; j < len; j++) {
                                    let currentDisplayField = group.data[j];
                                    // mainGroup.data is already sorted by displayOrder in the backend.
                                    if (currentDisplayField.displayOrder > currentFieldDisplayOrder) {
                                        group.data.splice(j, 0, displayPair);
                                        added = true;
                                        break;
                                    }
                                }
                                if (!added) {
                                    // Not inserted above, so it goes at the end.
                                    group.data.push(displayPair);
                                }
                            }
                        }
                    });
                });
            }
        }, function() {
            console.log("Error getting flight activity for flight: " + flightId);
        });
    };

    $scope.upload = function(creativeFiles) {
        if (creativeFiles.length) {
            angular.forEach(creativeFiles, function(file, index) {
                if (!(/^[A-Za-z0-9_.@()-\s]+\.[^.]+$/i.test(file.name))) {
                    console.log('Warning, filename contains metacharacters.');
                }
                if (index == 0) {
                    $ioAlert.show('', "Creatives are being uploaded, please don't refresh the page. You can check the status in the notifications tab", 'success');
                }
                if ($scope.flightTabCurrentlyShown) {
                    if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0 || $scope.currentEditedFlight && $scope.currentEditedFlight.id > 0) {
                        const flightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
                        CreativeServicePro.getImgSignature({
                            flightId: flightId,
                            fileName: file.name
                        }).then(function (success) {
                            let additionalParams = {
                                "from": "flight",
                                "flightId": flightId,
                                "libraryCreative": "no",
                                "file_name": file.name,
                                "file_size": file.size,
                                "section": "comments"
                            };
                            $scope.cachedFlightCreatives[flightId] = null;
                            $scope.flightCreatives = null;
                            let currentFlight = _.find($scope.flights, {id: flightId});
                            currentFlight.has_creative = true;  // Update this so that paperclip icon shows up in list
                            if ($scope.currentEditedFlight && $scope.currentEditedFlight.id == flightId) {
                                $scope.currentEditedFlight.has_creative = true;
                            }
                            if ($scope.currentViewFlight && $scope.currentViewFlight.id == flightId) {
                                $scope.currentViewFlight.has_creative = true;
                            }
                            $rs.$broadcast('cloudinaryImageUpload', success, file, additionalParams);
                            if (index === creativeFiles.length - 1) {
                                $scope.uploadMedia = {};
                            }
                        }, function (error) {
                            console.log(error);
                            const errorMessage = JSON.stringify(error);
                            $ioAlert.show('Error!', 'Could not get flight img signature. ' + errorMessage, 'error');
                        });
                    } else {
                        if (index == 0) {
                            $ioAlert.show('Error!', 'Could not upload flight file: no flight selected.', 'error');
                        }
                    }
                } else {
                    CreativeServicePro.getImgSignature({
                        lineItemId: $scope.lineItemId,
                        fileName: file.name
                    }).then(function (success) {
                        let additionalParams = {
                            "from": "lineItem",
                            "lineItemId": $scope.lineItemId,
                            "libraryCreative": "no",
                            "file_name": file.name,
                            "file_size": file.size,
                            "section": "comments"
                        };
                        $rs.$broadcast('cloudinaryImageUpload', success, file, additionalParams);
                        if (index === creativeFiles.length - 1) {
                            $scope.uploadMedia = {};
                        }
                    }, function (error) {
                        console.log(error);
                        const errorMessage = JSON.stringify(error);
                        $ioAlert.show('Error!', 'Could not get line item img signature. ' + errorMessage, 'error');
                    });
                }
            });
        }
    };

    $scope.addLineItemFileToCreativeLibrary = function(creative_id) {
        const params = {
            'id': creative_id,
            'lineItemId': $scope.lineItemId,
            'libraryCreative': 'yes',
        }
        $rs.loadingInProgress = true;
        MediaService.updateCreative(params).then(function(creative) {
            const index = $scope.creatives.findIndex(x => x.creative_id == creative.id);
            $scope.creatives[index].shared = creative.shared;
            $ioAlert.show('', 'File was added to creative library successfully.', 'success');
            $rs.loadingInProgress = false;
        }, function(error) {
            $ioAlert.show('error', error);
            console.log("Error adding file to creative library with creative_id: " + creative_id);
            $rs.loadingInProgress = false;
        });
    }

    $scope.addFlightFileToCreativeLibrary = function(creative_id) {
        const params = {
            'id': creative_id,
            'flightId': $scope.currentViewFlight.id,
            'libraryCreative': 'yes',
        }
        $rs.loadingInProgress = true;
        MediaService.updateCreative(params).then(function(creative) {
            const index = $scope.flightCreatives.findIndex(x => x.creative_id == creative.id);
            $scope.flightCreatives[index].shared = creative.shared;
            $ioAlert.show('', 'File was added to creative library successfully.', 'success');
            $rs.loadingInProgress = false;
        }, function(error) {
            $ioAlert.show('error', error);
            console.log("Error adding file to creative library with creative_id: " + creative_id);
            $rs.loadingInProgress = false;
        });
    }

    // See previous comment on this hack
    $rs.$$listeners['commentsUploadSuccess'] = [];
    $rs.$$listenerCount['commentsUploadSuccess'] = 0;
    $rs.$on('commentsUploadSuccess', function() {
        getCreatives();
    });

    $rs.$$listeners['updateLineItemFileFieldAndCreatives'] = [];
    $rs.$$listenerCount['updateLineItemFileFieldAndCreatives'] = 0;
    $rs.$on('updateLineItemFileFieldAndCreatives', function(event, lineItemId, adGroupMoreInfo) {
        updateLineItemFieldAttrs(lineItemId, adGroupMoreInfo);
        getCreatives();
    });

    $rs.$$listeners['updateFlightFileFieldAndCreativesAfterDelete'] = [];
    $rs.$$listenerCount['updateFlightFileFieldAndCreativesAfterDelete'] = 0;
    $rs.$on('updateFlightFileFieldAndCreativesAfterDelete', function(event, flightId) {
        $scope.waitForFlightFileDelete = false;
        // Clear the creative cache to make sure remove the flag,
        // when delete all the files
        $scope.cachedFlightCreatives[flightId] = null;
        if ($scope.cachedFlightDisplayGroups) {
            $scope.cachedFlightDisplayGroups[flightId] = null;
        }
        $scope.cachedFlights[flightId] = null;
        getCreatives(null, null, flightId);
    });

    $rs.$$listeners['flightUploadSuccess'] = [];
    $rs.$$listenerCount['flightUploadSuccess'] = 0;
    $rs.$on("flightUploadSuccess", function(event, successObj, additionalParams){
        let moreInfo = additionalParams.adGroupMoreInfo;
        if (!moreInfo) {
            $rs.$broadcast('updateFlightFileFieldAndCreatives', additionalParams.flightId, additionalParams);
            fileUploadComplete(additionalParams.flightId, additionalParams.uploadFileIdx);
            return;
        }
        moreInfo.flightId = additionalParams.flightId;
        flightFileUpload(moreInfo, successObj, additionalParams.uploadFileIdx);
    });

    $rs.$$listeners['updateFlightFileFieldAndCreatives'] = [];
    $rs.$$listenerCount['updateFlightFileFieldAndCreatives'] = 0;
    $rs.$on('updateFlightFileFieldAndCreatives', function(event, successObj, additionalParams) {
        $scope.waitForFlightFileUpload = false;
        if (!$rs.loadingInProgress) {
            getCreatives(null, null, additionalParams.flightId);
            updateFlightFieldAttrs(additionalParams.flightId, additionalParams);
            setHasCreativeFlag(additionalParams.flightId);
        } else {
            // Race condition where getFlights may fetch flights that didn't yet have their creatives written to the DB,
            // so remember them and set them after getFlights has returned.
            $scope.flightIdsWithNewCreatives.push(additionalParams.flightId);
        }
    });

    $rs.$$listeners['fileUploadWorkflowExecutionComplete'] = [];
    $rs.$$listenerCount['fileUploadWorkflowExecutionComplete'] = 0;
    $rs.$on('fileUploadWorkflowExecutionComplete', function(event, successObj, additionalParams) {
        // Comments can be generated by the system during workflow execution (e.g. when a webhook is sent),
        // so fetch them again now that it has run. This event is sent from the AddLineItemController (which is used
        // when adding or editing line items), since it passes control back to this controller when done (but it still
        // receives a late cloudinary upload notice).
        if (successObj) {
            $scope.productDisplayGroups.forEach((group) => {
                if (group.title == 'Product Info') {
                    group.data.forEach((attr) => {
                        if (attr['Status Name']) {
                            attr['Status Name'] = successObj.status_name;
                            return;
                        }
                    });
                    return;
                }
            });
            $scope.lineItem.status_name = successObj.status_name;
            console.log($scope.productDisplayGroups);
        }
        $scope.getComments(true);
    });

    let flightFileUpload = function(moreInfo, successObj, uploadFileIdx) {
        if (moreInfo.fieldId) {
            const flightAttrObj = {
                flightId: moreInfo.flightId,
                formFieldId: moreInfo.fieldId,
                attrValue: successObj.id,
            }
            OrdersService.addNewFlightAttributes(flightAttrObj).then(function(response) {
                $rs.$broadcast('updateFlightFileFieldAndCreatives', moreInfo.flightId, moreInfo);
                fileUploadComplete(moreInfo.flightId, uploadFileIdx);
            }, function() {
                $ioAlert.show("Error!", "An error occurred adding the flight attr.", 'error');
                $rs.$broadcast('updateFlightFileFieldAndCreatives', moreInfo.flightId, moreInfo);
                fileUploadComplete(moreInfo.flightId, uploadFileIdx);
            });
        } else {
            $rs.$broadcast('updateFlightFileFieldAndCreatives', moreInfo.flightId, moreInfo);
            fileUploadComplete(moreInfo.flightId, uploadFileIdx);
        }
    };

    let updateLineItemFieldAttrs = function(lineItemId, adGroupMoreInfo) {
        if(!adGroupMoreInfo) {
            return;
        }
        OrdersService.getLineItemFileFieldAttrs(lineItemId, adGroupMoreInfo.fieldId).then(function(fieldInfo) {
            if(!fieldInfo.attr_value) {
                return;
            }

            if ($scope.productDisplayGroups.length > 0) {
                updateFieldForEntityView(fieldInfo, $scope.productDisplayGroups);
            }

            if($scope.lineItem) {
                $scope.lineItem[fieldInfo.field_name] = fieldInfo.attr_value;
            }

        }, function() {
            console.log("Error getting lineItem field attributes for lineItem: " + lineItemId);
        });
    };

    let updateFlightFieldAttrs = function(flightId, adGroupMoreInfo) {
        if(!adGroupMoreInfo) {
            return;
        }
        OrdersService.getFlightFileFieldAttrs(flightId, adGroupMoreInfo.fieldId).then(function(fieldInfo) {
            if(!fieldInfo.attrs) {
                return;
            }

            if ($scope.cachedFlightDisplayGroups) {
                $scope.cachedFlightDisplayGroups[flightId] = null;
            }

            if($scope.flights) {
                _.each($scope.flights, function(flight) {
                    if (flight.id == flightId) {
                        flight[fieldInfo.field_name] = fieldInfo.attrs;
                        if (fieldInfo.attrs.length == 0) {
                            delete flight[flight_attrs.field_name];
                        }
                        $scope.cachedFlights[flightId] = null;
                    }
                });
            }

        }, function() {
            console.log("Error getting flight field attributes for flight: " + flightId);
        });
    };

    const updateFieldForEntityView = function(fieldInfo, displayGroups) {
        let group = displayGroups.find((group) => group.groupId == fieldInfo.group_id);
        if (group) {
            let field = group.data.find((data) => data.formFieldName == fieldInfo.field_name);
            if (field) { // update the field for existing group
                let displayPair = DisplayService.applyTransformation(fieldInfo, fieldInfo.attr_value);
                field[fieldInfo.display_name] = displayPair[fieldInfo.display_name];
            } else { // add the field for existing group
                let displayPair = DisplayService.applyTransformation(fieldInfo, fieldInfo.attr_value);
                displayPair.formFieldType = fieldInfo.input_type;
                displayPair.formFieldName = fieldInfo.field_name;
                group.data.push(displayPair);
            }
        } else { // add the group together with the field
            let section = {};
            section.title = fieldInfo.group_name;
            section.groupId = fieldInfo.id;
            section.data = [];
            let displayPair = DisplayService.applyTransformation(fieldInfo, fieldInfo.attr_value);
            displayPair.formFieldType = fieldInfo.input_type;
            displayPair.formFieldName = fieldInfo.field_name;
            section.data.push(displayPair);
            displayGroups.push(section);
        }
    }

    const getProductFields = function() {
        ProductService.getProductFieldGroupsForDisplay($scope.lineItem.product_id).then(function() {
            ProductService.getProductFieldsForDisplay($scope.lineItem.product_id).then(function() {
                $scope.productFields = ProductService.productFields;
                $scope.waitingForProductFields = false;
                if (!$scope.hasLineItemAsynchronousFields) { // Don't start another timer...
                    if (FormService.hasAsynchronousFields($scope.productFields)) {
                        $scope.hasLineItemAsynchronousFields = true;
                        if ($scope.asynchronousTimerId === null) {
                            $scope.asynchronousTimerId = setTimeout(pollAsynchronousFields, ASYNCHRONOUS_FIELDS_POLL_TIMEOUT);
                        }
                    }
                }
                gateBuildDisplayData();
            }, function() {
                console.log("Error getting product fields for product: " + $scope.lineItem.product_id);
                $scope.waitingForProductFields = false;
                $rs.loadingInProgress = false;
            });

        }, function() {
            console.log("error getting product field groups for product: " + $scope.lineItem.product_id);
            $scope.waitingForProductFields = false;
            $rs.loadingInProgress = false;
        });
    };

    const gateBuildDisplayData = function() {
        if ($scope.waitingForProductFields || $scope.waitingForEOAPreviews) {
            return;
        } else {
            buildDisplayData();
            getLineItemActivity();
            $scope.showTasks(false);
            $scope.loadComplete = true;
            $rs.loadingInProgress = false;
        }
    };

    const cancelLineItem = function(rebook = false) {
        OrdersService.getCancelReasons('lineitem').then(function() {

            var data = OrdersService.cancelReasons;
            var existingCancellationReason = null;
            if (rebook) {
                let cancellationReason = _.find(data, {name: "Rebooked"});
                if (cancellationReason) {
                    existingCancellationReason = cancellationReason.id;
                }
            }
            var modalInstance = $modal.open(
                ModalService.returnModalType('cancelReason', {
                    data: {
                        items: data,
                        scope: $scope,
                        type: 'Line Item',
                        cancelReason: existingCancellationReason
                    }
                })
            );
            modalInstance.result.then(function(cancelReason) {
                OrdersService.cancelLineItem($scope.lineItem.id, cancelReason.id, cancelReason.text, rebook).then(function() {
                    $ioAlert.show('', 'Line Item cancellation request submitted', 'success');
                    if (rebook) {
                        $state.go('addLineItem', {
                            orderId: $scope.order.id,
                            order: $scope.order
                        });
                    } else {
                        $scope.backToOrder();
                    }
                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });

        }, function() {
            console.log("Error getting line item cancellation reasons");
        });
    };

    let cancelFlight = function() {
        OrdersService.getCancelReasons('flight').then(function() {

            const data = OrdersService.cancelReasons;
            let modalInstance = $modal.open(
                ModalService.returnModalType('cancelReason', {
                    data: {
                        items: data,
                        scope: $scope,
                        type: 'Flight'
                    }
                })
            );
            modalInstance.result.then(function(cancelReason) {
                OrdersService.cancelFlight($scope.currentViewFlight.id, cancelReason.id, cancelReason.text).then(function(flightData) {
                    $scope.flightLoaded = false;
                    $scope.cachedFlights[$scope.currentViewFlight.id] = null; // State will have changed, so reset cache
                    $scope.cachedFlightDisplayGroups[$scope.currentViewFlight.id] = null; // Together with cachedFlights
                    $scope.comments = [];  // Clear comments since we're not showing any flight after the cancel operation.
                    $scope.cachedFlightComments[$scope.currentViewFlight.id] = null;
                    $scope.currentViewFlight = null;  // Since that flight have been moved, we can't keep showing it!
                    $ioAlert.show('', 'Successfully cancelled flight ' + flightData.id, 'success');
                    refreshLineItem();  // Allocated budget needs to be refreshed since the cancelled flight's budget won't count anymore
                    getFlights();
                    }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });
        }, function() {
            console.log("Error getting flight cancellation reasons");
        });
    };

    $scope.editCancelReason = function() {
        OrdersService.getCancelReasons('lineitem').then(function() {

            var data = OrdersService.cancelReasons;
            var modalInstance = $modal.open(
                ModalService.returnModalType('cancelReason', {
                    data: {
                        items: data,
                        scope: $scope,
                        type: 'Line Item',
                        cancelReason: $scope.lineItem.cancel_lineitem_reason,
                        cancelReasonText: $scope.lineItem.cancel_lineitem_reason_text
                    }
                })
            );
            modalInstance.result.then(function(cancelReason) {
                OrdersService.editCancelReason($scope.lineItem.id, 'lineitem', cancelReason.id, cancelReason.text).then(function() {
                    OrdersService.getLineItem($scope.lineItem.id, true, true).then(function() {
                        $scope.lineItem = OrdersService.lineItem;
                        $ioAlert.show('', 'Line Item cancellation reason updated', 'success');
                        handleLineItemEOAPreviews($scope.lineItem, true);
                    });

                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });
        }, function() {
            console.log("Error getting line item cancellation reasons");
        });
    };

    $scope.editFlightCancelReason = function() {
        OrdersService.getCancelReasons('flight').then(function(cancelReasons) {

            let modalInstance = $modal.open(
                ModalService.returnModalType('cancelReason', {
                    data: {
                        items: cancelReasons,
                        scope: $scope,
                        type: 'Flight',
                        cancelReason: $scope.currentViewFlight.cancel_flight_reason,
                        cancelReasonText: $scope.currentViewFlight.cancel_flight_reason_text
                    }
                })
            );
            modalInstance.result.then(function(cancelReason) {
                OrdersService.editCancelReason($scope.currentViewFlight.id, 'flight', cancelReason.id, cancelReason.text).then(function(flightId) {
                    // The modal only gives us the id of the new selected cancel reason, so get the rest of the data
                    // (e.g. the corresponding reason name) from the cancelReasons we just got from the backend.
                    const selectedCancelReason = _.find(cancelReasons, {id: cancelReason.id});
                    if (selectedCancelReason) {
                        // Simple change, so update all our copies of this flight (cached and currentViewFlight)...
                        $scope.currentViewFlight.cancel_flight_reason = selectedCancelReason.id;
                        $scope.currentViewFlight.cancel_flight_reason_name = selectedCancelReason;
                        $scope.currentViewFlight.cancel_flight_reason_text = cancelReason.text;
                        const currentFlight = _.find($scope.flights, {id: $scope.currentViewFlight.id});
                        if (currentFlight) {
                            currentFlight.cancel_flight_reason = selectedCancelReason.id;
                            currentFlight.cancel_flight_reason_name = selectedCancelReason;
                            currentFlight.cancel_flight_reason_text = cancelReason.text;
                        }
                        $scope.cachedFlights[$scope.currentViewFlight.id].cancel_flight_reason = selectedCancelReason.id;
                        $scope.cachedFlights[$scope.currentViewFlight.id].cancel_flight_reason_name = selectedCancelReason;
                        $scope.cachedFlights[$scope.currentViewFlight.id].cancel_flight_reason_text = cancelReason.text;
                        $ioAlert.show('', 'Flight cancellation reason updated to ' + selectedCancelReason.name, 'success');
                    } else {
                        $ioAlert.show('', 'Flight cancellation reason updated', 'success');
                    }
                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });
        }, function() {
            console.log("Error getting flight cancellation reasons");
        });
    };

    $scope.commentSubmitDisabled = false;
    $scope.addComment = function () {
        if ($scope.commentTaskModel.quickComment) {
            if ($scope.showOnDemandTasks && $scope.commentTaskModel.addOnDemandTask && !$scope.commentTaskModel.onDemandTask) {
                $ioAlert.show('Error!', 'Please select a task', 'error');
                return;
            }
            $scope.commentSubmitDisabled = true;
            if ($scope.flightTabCurrentlyShown) {
                const flightId = $scope.currentViewFlight ? $scope.currentViewFlight.id : $scope.currentEditedFlight.id;
                const params = {
                    'tableName': 'flight',
                    'tableRowId': flightId,
                    'comment': $scope.commentTaskModel.quickComment,
                    'skipWorkflow': '0',
                    'addOnDemandTask': $scope.commentTaskModel.addOnDemandTask,
                    'onDemandTask': $scope.commentTaskModel.onDemandTask,
                };
                CommentService.addFlightComment(params).then(function () {
                    if ($scope.commentTaskModel.addOnDemandTask) {
                        // Clear task cache since they will need to be fetched again if displayed.
                        cachedTasks = [];
                        cachedHistoryTasks = [];
                        if (flightId in cachedFlightTasks || flightId in cachedFlightHistoryTasks) {
                            cachedFlightTasks.splice(flightId, 1);
                            cachedFlightHistoryTasks.splice(flightId, 1);
                        }
                        if ($scope.isTasksTabActive()) {
                            getTaskAssignments(false);
                        } else if ($scope.isTasksHistoryTabActive()) {
                            getTaskAssignments(true);
                        }
                    }
                    $scope.commentTaskModel.quickComment = null;
                    $scope.commentTaskModel.addOnDemandTask = false;  // Reset now that a comment (and possibly on-demand task) has been added
                    $scope.commentTaskModel.addOnDemandTaskToggle = false;
                    $scope.commentTaskModel.onDemandTask = null;
                    $scope.commentSubmitDisabled = false;
                    $scope.getComments(true);
                }, function () {
                    console.log("Error saving comment for flight: " + flightId);
                    $scope.commentSubmitDisabled = false;
                });
            } else {
                const params = {
                    'tableName': 'line_item',
                    'tableRowId': $scope.lineItem.id,
                    'comment': $scope.commentTaskModel.quickComment,
                    'skipWorkflow': '0',
                    'addOnDemandTask': $scope.commentTaskModel.addOnDemandTask,
                    'onDemandTask': $scope.commentTaskModel.onDemandTask,
                };
                CommentService.addLineItemComment(params).then(function () {
                    if ($scope.commentTaskModel.addOnDemandTask) {
                        // Clear task cache since they will need to be fetched again if displayed.
                        cachedTasks = [];
                        cachedHistoryTasks = [];
                        if (cachedLineItemTasks !== undefined || cachedLineItemHistoryTasks !== undefined) {
                            cachedLineItemTasks = undefined;
                            cachedLineItemHistoryTasks = undefined;
                        }
                        if ($scope.isTasksTabActive()) {
                            getTaskAssignments(false);
                        } else if ($scope.isTasksHistoryTabActive()) {
                            getTaskAssignments(true);
                        }
                    }
                    $scope.commentTaskModel.quickComment = null;
                    $scope.commentTaskModel.addOnDemandTask = false;  // Reset now that a comment (and possibly on-demand task) has been added
                    $scope.commentTaskModel.addOnDemandTaskToggle = false;
                    $scope.commentTaskModel.onDemandTask = null;
                    $scope.commentSubmitDisabled = false;
                    $scope.getComments(true);
                }, function () {
                    console.log("Error saving comment for line item: " + $scope.lineItem.id);
                    $scope.commentSubmitDisabled = false;
                });
            }
        } else {
            $ioAlert.show('Error!', 'Please enter a comment.', 'error');
            $scope.commentSubmitDisabled = false;
        }
    };

    $scope.showCancelLineItemConfirm = function(rebook = false) {
        let message = rebook ? 'Are you sure you want to cancel/rebook this line item?' : 'Are you sure you want to cancel this line item?';
        $confirm.open({
            size: 'sm',
            text: message
        }).then(function() {
            cancelLineItem(rebook)
        }, function() {});
    };

    $scope.showCancelFlightConfirm = function() {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to cancel this flight?'
        }).then(function() {
            cancelFlight()
        }, function() {});
    };

    $scope.backToOrder = function() {
        $state.go('viewOrder', {
            id: $scope.lineItem.order_id
        });
    };

    $scope.showEditLineItem = function() {
        $state.go('editLineItem', {
            orderId: $scope.lineItem.order_id,
            lineItemId: $scope.lineItemId,
            formattedEOAPreviews: $scope.lineItemIdToFormattedEOAPreviewsArray[$scope.lineItemId],
            comeFrom: "lineItemView"
        });
    };

    const pollAsynchronousFields = function() {
        // Only actually do something if we're visible, so as not to send backend requests when the tab isn't even shown.
        if (document.visibilityState === 'visible') {
            if ($scope.flightTabCurrentlyShown) {
                if ($scope.currentViewFlight && $scope.currentViewFlight.id > 0) {
                    const currentFlightValues = FormService.extractAsynchronousFieldValues($scope.currentViewFlight);
                    OrdersService.flightHasAsynchronousAttrUpdates($scope.currentViewFlight.id, currentFlightValues).then(function(data) {
                        if (data == true) {
                            $scope.flightLoaded = false;
                            $scope.cachedFlights[$scope.currentViewFlight.id] = null; // Flight will have changed, so reset cache
                            $scope.cachedFlightDisplayGroups[$scope.currentViewFlight.id] = null; // Together with cachedFlights
                            refreshLineItem();
                            getFlights();
                        } else {
                        }
                    }, function() {
                        console.log("Error fetching asynchronous flight field info");
                    });
                }
            } else {
                const currentLineItemValues = FormService.extractAsynchronousFieldValues($scope.lineItem);
                OrdersService.lineItemHasAsynchronousAttrUpdates($scope.lineItemId, currentLineItemValues).then(function(data) {
                    if (data == true) {
                        $scope.flightLoaded = false;
                        refreshLineItem();
                        getFlights();
                    } else {
                    }
                }, function() {
                    console.log("Error fetching asynchronous line item field info");
                });
            }
        }
        // For subsequent polls, double the wait time.
        $scope.asynchronousTimerId = setTimeout(pollAsynchronousFields, ASYNCHRONOUS_FIELDS_POLL_TIMEOUT * 2);
    }

    $scope.$on('$destroy', function() {
        if ($scope.asynchronousTimerId !== null) {
            clearTimeout($scope.asynchronousTimerId);
            $scope.asynchronousTimerId = null;
        }
    });

    $scope.onBackAction = function() {
        if ($scope.hasTaskId ) {
            if ($stateParams.myQueues) {
                $state.go('myQueuesOrder', {
                    taskId: $stateParams.taskId,
                    orderId: $stateParams.orderId,
                    myQueues: true
                })
            }
            else if($stateParams.lineItemId)
            {
                $state.go('myTasks', {
                    taskId: $stateParams.taskId,
                    lineItemId: $stateParams.lineItemId,
                })
            }
            else {
                $state.go('myTasksOrder', {
                    taskId: $stateParams.taskId,
                    orderId: $stateParams.orderId
                })
            }
        }
        else {
            $state.go('myTasks');
        }
    };

    $scope.goPrevious = function() {
        $state.go('viewLineItem', {
            lineItemId: $scope.lineItem.__previous_line_item_id,
            formattedEOAPreviews: undefined
        });
    }

    $scope.goNext = function() {
        $state.go('viewLineItem', {
            lineItemId: $scope.lineItem.__next_line_item_id,
            formattedEOAPreviews: undefined
        });
    }

    $scope.showChanges = function(changesObj) {
        var list = changesObj.fieldAuditHistory;
        $modal.open(ModalService.returnModalType('lineItemChanges', { data: { list: list } }));
    };

    $scope.showFlightChanges = function(changesObj) {
        var list = changesObj.fieldAuditHistory;
        $modal.open(ModalService.returnModalType('flightChanges', { data: { list: list } }));
    };

    $scope.auditHistory = function() {
        $state.go('auditHistory', {
            type: 'line_item',
            id: $scope.lineItemId
        });
    };

    $scope.workflowLogHistory = function() {
        $state.go('workflowLogHistory', {
            type: 'line_item',
            id: $scope.lineItemId
        });
    };

    $scope.flightAuditHistory = function() {
        $state.go('auditHistory', {
            type: 'flight',
            id: $scope.currentViewFlight.id,
            lineItemId: $scope.lineItemId
        });
    };

    $scope.flightWorkflowLogHistory = function() {
        $state.go('workflowLogHistory', {
            type: 'flight',
            id: $scope.currentViewFlight.id,
            lineItemId: $scope.lineItemId
        });
    };

    $scope.moveFlight = function(flight) {
        handleMoveFlights([flight.id], flight.status_id, flight.workflow_step_id);
    }

    let handleMoveFlights = function(flightIds, defaultStatusId, defaultWorkflowStepId) {
        if ($rs.loadingInProgress) {
            // Don't start something if we're already loading.
            console.log('Please wait for page to complete loading before pressing the Move Flight button');
            return;
        }

        // Perform all backend queries in parallel and wait for responses before opening the modal window.
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Please wait while fetching flight and target line item information...";
        $scope.moveFlightReceivedGetLineItemsResponse = false;
        $scope.moveFlightReceivedGetFlightStatusesResponse = false;
        $scope.moveFlightReceivedGetWorkflowStepsResponse = false;
        $scope.moveFlightReceivedGetWorkflowActionResponse = false;

        OrdersService.getLineItems($scope.lineItem.order_id).then(function(data) {
            $scope.validTargetLineItems = [];
            for (let i = 0, len = data.length; i < len; i++) {
                if (data[i].id != $scope.lineItem.id && data[i].product_id == $scope.lineItem.product_id
                    && data[i].status_id != STATUS_LINEITEM_CANCEL && data[i].status_id != STATUS_LINEITEM_COMPLETE
                    && data[i].status_id != STATUS_LINEITEM_CLOSED) {
                    $scope.validTargetLineItems.push(data[i]);
                }
            }
            $scope.moveFlightReceivedGetLineItemsResponse = true;
            handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
        }, function() {
            console.log("Error getting line item list for order " + $scope.lineItem.order_id);
            $scope.moveFlightReceivedGetLineItemsResponse = true;
            handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
        });

        OrdersService.getFlightStatuses().then(function(data) {
            $scope.flightStatusList = data;
            $scope.moveFlightReceivedGetFlightStatusesResponse = true;
            handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
        }, function(error) {
            console.log("Error loading flight statuses: " + error);
            $scope.moveFlightReceivedGetFlightStatusesResponse = true;
            handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
        });

        if (flightIds.length === 1) {
            OrdersService.getStepsForFlight(flightIds[0]).then(function (data) {
                $scope.flightWorkflowSteps = data;
                $scope.moveFlightReceivedGetWorkflowStepsResponse = true;
                handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
            }, function (error) {
                console.log("Error loading flight workflow steps: " + error);
                $scope.moveFlightReceivedGetWorkflowStepsResponse = true;
                handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
            });
        } else {
            // If we move several flights, they may not all have the same workflow (if the workflow was modified
            // between the creation of those flights), so don't provide the ability to set the default step in this
            // case.
            $scope.moveFlightReceivedGetWorkflowStepsResponse = true;
            $scope.flightWorkflowSteps = [];
        }

        WorkflowsService.getWorkflowActions().then(function(data) {
            $scope.workflowActions = data;
            $scope.moveFlightReceivedGetWorkflowActionResponse = true;
            handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
        }, function(error) {
            console.log("Error loading workflow action list: " + error);
            $scope.moveFlightReceivedGetWorkflowActionResponse = true;
            handleMoveFlightBackEndResponses(flightIds, defaultStatusId, defaultWorkflowStepId);
        });
    };

    let handleMoveFlightBackEndResponses = function(flightIds, defaultStatusId, defaultWorkflowStepId) {
        if ($scope.moveFlightReceivedGetLineItemsResponse &&
            $scope.moveFlightReceivedGetFlightStatusesResponse &&
            $scope.moveFlightReceivedGetWorkflowStepsResponse &&
            $scope.moveFlightReceivedGetWorkflowActionResponse) {

            $rs.loadingInProgress = false;
            let modalInstance = $modal.open(
                ModalService.returnModalType('moveFlight', {
                    data: {
                        lineItemList: $scope.validTargetLineItems,
                        flightStatusList: $scope.flightStatusList,
                        flightWorkflowSteps: $scope.flightWorkflowSteps,
                        workflowActions: $scope.workflowActions,
                        statusId: defaultStatusId,
                        workflowStepId: defaultWorkflowStepId,
                        moreThanOneFlight: flightIds.length > 1
                    }
                })
            );
            modalInstance.result.then(function(flightObj) {
                OrdersService.moveFlight(flightIds, flightObj.selectedLineItem, flightObj.selectedStatusId, flightObj.selectedWorkflowStepId, flightObj.selectedWorkflowAction).then(function(data) {
                    if (data.errors) {
                        if (data.errors.length == 1) {
                            console.log(data.errors[0]);
                        } else {
                            console.log(data.errors);
                        }
                        $ioAlert.show('Error!', data.errors, 'error');
                    } else if (!data.hasOwnProperty('flight_ids')) {
                        $ioAlert.show('Error!', 'An error occurred, please contact your support representative', 'error');
                    } else {
                        $ioAlert.show('', 'Flight moved successfully', 'success');
                        cachedTasks = []; // So that tasks for the moved flight are removed.
                        cachedHistoryTasks = [];
                        cachedLineItemTasks = undefined;
                        cachedFlightTasks = [];
                        cachedLineItemHistoryTasks = undefined;
                        cachedFlightHistoryTasks = [];
                        $scope.flightLoaded = false;
                        $scope.currentViewFlight = null;  // Since that flight have been moved, we can't keep showing it!
                        $scope.comments = [];  // Clear comments since we're not showing any flight after moving one.
                        _.each(flightIds, function (flight) {
                            $scope.cachedFlightComments[flight.id] = null;
                        });
                        refreshLineItem();
                        getFlights();
                    }
                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });
        }
    }

    $scope.deleteComment = function(commentId) {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to delete this Comment?'
        }).then(function() {
            CommentService.deleteComment(commentId).then(function() {
                $scope.getComments(true);
                $ioAlert.show('Comment deleted', 'Comment has been successfully deleted', 'success');
            }, function() {
                console.log("error deleting comment");
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }, function() {})
    };

    var getLineItemWatchersList = function () {
        OrdersService.getLineItemWatchersList($scope.lineItem.id).then(function (lineItemWatchers) {
            $scope.lineItemWatchersList = lineItemWatchers;
            checkLineItemWatcherForMeHide();
        }, function () {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    var saveLineItemWatcherUsers = function (watcherUsers) {
        if (watcherUsers) {
            var watcherUserIds = [];
            _.each(watcherUsers, function (userObj) {
                watcherUserIds.push(userObj.id);
            });
            OrdersService.updateLineItemWatchersList($scope.lineItem.id, watcherUserIds).then(function (lineItemWatchers) {
                if (lineItemWatchers) {
                    $scope.lineItemWatchersList = lineItemWatchers;
                    checkLineItemWatcherForMeHide();
                    $ioAlert.show('', 'Watchers updated successfully', 'success');
                } else {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                }
            }, function () {
                console.log("Error saving Watchers for Line Item: " + $scope.lineItem.id);
            });
        }
    };

    var checkLineItemWatcherForMeHide = function () {
        $scope.startLineItemWatcherForMeHide = true;
        if ($scope.lineItemWatchersList) {
            _.each($scope.lineItemWatchersList, function (watcherObj) {
                if ($rs.userRole.id == watcherObj.user_id) {
                    $scope.startLineItemWatcherForMeHide = false;
                }
            });
        }
    }

    $scope.addWatcherToLineItem = function (watcher) {
        if ($scope.lineItem && $rs.userRole && watcher_authorized_roles.includes($rs.userRole.predefined_role_key)) {
            if (watcher == 'start') {
                OrdersService.addLineItemWatcher($scope.lineItem.id, $rs.userRole.id).then(function (lineItemWatcher) {
                    if (lineItemWatcher) {
                        $scope.lineItemWatchersList.push(lineItemWatcher);
                        $ioAlert.show('', 'Watcher added successfully', 'success');
                    } else {
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    }
                    checkLineItemWatcherForMeHide();
                }, function () {
                    console.log("Error saving Watchers for Line Item: " + $scope.lineItem.id);
                });
            } else if (watcher == 'stop') {
                var lineitemWatcher = _.find($scope.lineItemWatchersList, {user_id: $rs.userRole.id});
                OrdersService.deleteLineItemWatcher(lineitemWatcher.id).then(function (lineItemWatcherId) {

                    if (lineItemWatcherId) {
                        var lineitemWatcherIndex = _.findIndex($scope.lineItemWatchersList, {id: lineItemWatcherId});
                        if(lineitemWatcherIndex>=0){
                            $scope.lineItemWatchersList.splice(lineitemWatcherIndex, 1);
                        }
                        checkLineItemWatcherForMeHide();
                        $ioAlert.show('', 'Watcher removed successfully', 'success');
                    } else {
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    }
                }, function () {
                    console.log("Error deleting Watchers for Line Item: " + $scope.lineItem.id);
                });
            } else {
                UserService.getUsersForCluster($scope.order.cluster_id[0]).then(function () {
                    var modalInstance = $modal.open(
                        ModalService.returnModalType('addWatchersList', {
                            data: {
                                users: UserService.users,
                                lineItemWatchers: $scope.lineItemWatchersList,
                            }
                        })
                    );
                    modalInstance.result.then(function (modalObj) {
                        saveLineItemWatcherUsers(modalObj.selectedUsers);
                    }, function () { });
                }, function () {
                    console.log("Error getting Watchers.");
                });
            }
        } else {
            $ioAlert.show('Error!', 'You are not allowed to add watchers.', 'error');
            // we need to know what happen to user role when the user cannot add watchers.
            console.log($rs.userRole);
        }
    };

    $scope.showAssociationHistoryForLineItem = function() {
        $state.go('associationHistory',
            {
                id: $scope.lineItem.id,
                fromPage: 'lineitem'
            });
    };

    $scope.showAssociationHistoryForFlight = function() {
        $state.go('associationHistory',
            {
                id: $scope.currentViewFlight.id,
                fromPage: 'flight',
                lineItemId: $scope.lineItem.id
            });
    };

    var applyPermissions = function() {
        if ($rs.userRole.permissions.indexOf("adops") > -1 ||
            $rs.userRole.permissions == 'superadmin' ||
            $rs.userRole.permissions == 'sales,admin') {
            $scope.isAdmin = true;
        }

        if ($rs.userRole.permissions == 'adops,admin' ||
            $rs.userRole.permissions == 'superadmin') {
            $scope.showStartOver = true;
        }
    };

    var calculateDueDates = function() {
        $scope.escalatedTasks = parseInt($scope.lineItem.line_item_escalated_task_count);
        $scope.overDueTasks = parseInt($scope.lineItem.line_item_due_task_count);
        _.each($scope.flights, function(flight) {
            $scope.escalatedTasks += parseInt(flight.flight_escalated_task_count);
            $scope.overDueTasks += parseInt(flight.flight_due_task_count);
        });
    };

    // Helper function to merge duplicate task id
    function parseFlightsHelper(associatedTasks) {
        var uniqArr = _.uniqBy(associatedTasks, 'id');
        _.each(uniqArr, function(arr) {
            // We have both line-item and flight tasks, so only set flightIds for flight tasks.
            if (arr.flight_id) {
                arr.flightIds = [];
                _.each(associatedTasks, function (task) {
                    if (task.id == arr.id) {
                        if (arr.flightIds.indexOf(task.flight_id) == -1) {
                            arr.flightIds.push(task.flight_id);
                        }
                    }
                })
            }
        });
        cachedTasks = $scope.associatedTasks = _.sortBy($scope.associatedTasks.concat(uniqArr), 'id').reverse();
        calculateDueDates();
    }

    $scope.activateTab = function(tab) {
        $scope.sideActiveTab = tab;
    };

    $scope.activateCommentsTab = function() {
        $scope.activateTab('comments');
    };

    $scope.activateTasksTab = function() {
        $scope.activateTab('tasks');
    };

    $scope.activateTasksHistoryTab = function() {
        $scope.activateTab('tasks-history');
    };

    $scope.isTabActive = function(tab) {
        return $scope.sideActiveTab === tab;
    };

    $scope.isCommentsTabActive = function() {
        return $scope.isTabActive('comments')
    };

    $scope.isTasksTabActive = function() {
        return $scope.isTabActive('tasks');
    };

    $scope.isTasksHistoryTabActive = function() {
        return $scope.isTabActive('tasks-history');
    };

    /*
     * Workflow start over function
     */
    $scope.workflowStartOver = function() {
        $confirm.open({
            size: 'sm',
            text: 'You are about to reset this workflow. Are you sure you want to proceed?'
        }).then(function() {
            // workflow_type_id and will always be 2 for line item workflows
            OrdersService.workflowReset(2, $scope.lineItem.id).then(function() {
                $ioAlert.show('', 'Workflow reset successful', 'success');
                $scope.activateCommentsTab();
                $state.reload('viewLineItem');
            }, function(error) {
                $ioAlert.show('Error!', error, 'error');
            });
        }, function() {})
    }

    var currentFileDownload = null;
    $scope.downloadLineitemPdf = function(){
        let element = document.getElementById('print-container');
        let element_goals = document.getElementById('print-container-goals');
        let export_html = element.innerHTML + ( element_goals ? element_goals.innerHTML : '' );
        $scope.$evalAsync(function() {
            $rs.loadertitle = "Fetching Line Item Information";
            $rs.loadingInProgress = true;
            currentFileDownload = $.fileDownload(OrdersService.getLineItemPdfDownloadUrl(), {
                httpMethod: 'POST',
                data: {html: export_html, filename: 'Order' + $scope.lineItem.order_id + '-LineItem' + $scope.lineItem.id + '.pdf' },
                successCallback: function () {
                    doneLoading();
                },
                failCallback: function (err) {
                    if (err && err.length) {
                        console.log(err);
                    }
                    doneLoading();
                }
            });
        });
    }

    var currentFlightFileDownload = null;
    $scope.downloadFlightPdf = function(){
        var element = document.getElementById('print-container-flights');
        $scope.$evalAsync(function() {
            $rs.loadertitle = "Fetching Flight Information";
            $rs.loadingInProgress = true;
            currentFlightFileDownload = $.fileDownload(OrdersService.getLineItemPdfDownloadUrl(), {
                httpMethod: 'POST',
                data: {html: element.innerHTML, filename: 'Order' + $scope.lineItem.order_id + '-LineItem' + $scope.lineItem.id + '-Flight' + $scope.currentViewFlight.id + '.pdf' },
                successCallback: function () {
                    doneLoading();
                },
                failCallback: function (err) {
                    if (err && err.length) {
                        console.log(err);
                    }
                    doneLoading();
                }
            });
        });
    }

    var getCommentFilterItems = function() {
        TaskService.getTasksList().then(function(response) {
            $scope.commentFilter.items = [];
            if(response){
                _.each(response, function(item){
                    if(item.reference_table == "order"){
                        $scope.commentFilter.items.push({id: item.id, definition: item.definition});
                    }
                });
            }
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    function doneLoading() {
        currentFileDownload = null;
        currentFlightFileDownload = null;
        $scope.$evalAsync(function() {
            $rs.loadingInProgress = false;
        });
    }

    let resolvePredefinedValue = function(key) {
        let val = null;
        let keyArray = key.split('_');
        let objectName = keyArray.shift();
        let prop = keyArray[0];

        // Need to rebuild object properties containing underscores
        if (keyArray.length > 1) {
            prop = keyArray.join('_');
        }

        switch (objectName) {
            case 'order':
                val = ($scope.order && $scope.order[prop]) ? $scope.order[prop] : 'NA';
                break;

            case 'lineItem':
                val = ($scope.lineItem && $scope.lineItem[prop]) ? $scope.lineItem[prop] : 'NA';
                break;

            case 'flight':
                val = ($scope.currentEditedFlight && $scope.currentEditedFlight[prop]) ? $scope.currentEditedFlight[prop] : 'NA';
                break;
        }

        return val;
    };

    $scope.calculate = function(data) {
        if(data === undefined) {
            calQueueExc();
            return;
        }
        let dataArray = data.split(',');
        let fieldId = dataArray.shift();
        let formFieldsLen = $scope.flightFormFieldsWithContent.length;
        let calculateFields = {
            'fieldId': parseInt(fieldId)
        };

        let getFamilyNestedCondition = function(field) {
            let condition = true;
            if (field.parentField) {
                let modelValue = $scope.model[field.parentField.field_name];
                let isConditionValueAnArray = Array.isArray(field.conditionValue);


                if (isConditionValueAnArray && field.parentField.input_type == 'checkbox' && _.isBoolean(modelValue)) {
                    modelValue = modelValue === true ? '1' : '0'
                }

                if (Array.isArray(modelValue)) {
                    condition = isConditionValueAnArray ?
                        modelValue.some(oneVal => field.conditionValue.includes(oneVal)) :
                        modelValue.includes(field.conditionValue)
                } else {
                    condition = isConditionValueAnArray ?
                        field.conditionValue.includes(modelValue) :
                        modelValue == (field.conditionValue);
                }
                condition = getFamilyNestedCondition(field.parentField) && condition;
            }
            return condition;
        }

        const numericTypes = ['integer', 'decimal', 'currency', 'percentage'];

        for (let i = 0; i < formFieldsLen; i++) {
            for (let j = 0, len = dataArray.length; j < len; j++) {
                if (parseInt(dataArray[j]) == $scope.flightFormFieldsWithContent[i].id) {
                    const parameterName = 'ff' + $scope.flightFormFieldsWithContent[i].id;
                    const field = $scope.flightFormFieldsWithContent[i];
                    const condition = getFamilyNestedCondition(field);
                    const valueOfFieldInFormula = $scope.model[$scope.flightFormFieldsWithContent[i].field_name];
                    if (valueOfFieldInFormula && condition) {
                        if ($scope.flightFormFieldsWithContent[i].lookup_content) {
                            let formValue = valueOfFieldInFormula;
                            formValue = $scope.flightFormFieldsWithContent[i].input_type == 'select2' ? formValue.text : formValue;
                            let lookupContentIds = FormService.getLookupContentIds(
                                $scope.flightFormFieldsWithContent[i].field_name,
                                $scope.flightFormFieldsWithContent[i].lookup_content,
                                formValue, false, $scope.model,
                                $scope.formParentChildInfo);
                            if (Array.isArray(lookupContentIds)) {
                                lookupContentIds = lookupContentIds.length === 0 ? "" : lookupContentIds;
                                calculateFields[parameterName + "[]"] = lookupContentIds;
                            } else {
                                calculateFields[parameterName] = lookupContentIds;
                            }
                            // we do not need to handle symbol or format for ids in the formula
                        } else {
                            calculateFields[parameterName] = valueOfFieldInFormula;
                            // we need to handle symbol or format for numbers in the formula
                            if ($scope.flightFormFieldsWithContent[i].data_type == "currency" &&
                                calculateFields[parameterName].contains("$")) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/\$/g, '');
                            }
                            if ($scope.flightFormFieldsWithContent[i].data_type == "percentage" &&
                                calculateFields[parameterName].contains('%')) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/\%/g, '');
                            }
                            if (numericTypes.includes($scope.flightFormFieldsWithContent[i].data_type)) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/,/g, '');
                            }
                            if ($scope.flightFormFieldsWithContent[i].data_type == "date") {
                                calculateFields[parameterName] = $filter('date')(calculateFields[parameterName], 'yyyy-MM-dd');
                            }
                            if ($scope.flightFormFieldsWithContent[i].input_type == "timeselect") {
                                if (typeof valueOfFieldInFormula == 'object') {
                                    calculateFields[parameterName] = valueOfFieldInFormula.toLocaleTimeString();
                                }
                            }
                        }
                    } else {
                        calculateFields[parameterName] = 0;
                    }
                } else {
                    let predefinedValue = resolvePredefinedValue(dataArray[j]);

                    if (predefinedValue) {
                        let pname = 'ff' + dataArray[j];
                        calculateFields[pname] = predefinedValue;
                    }
                }
            }
        }

        calculateFields['orderId'] = $scope.order.id;
        if ($scope.lineItem) {
            calculateFields['lineItemId'] = $scope.lineItem.id;
        }
        calculateFields['flightId'] = $scope.isAdding ? 0 : $scope.currentEditedFlight.id;
        calculateFields['isFlightCalc'] = 1;

        // Does the job for flights too, since flights and order both use ff (form fields) and not product fields.
        FormService.calculateOrder(calculateFields).then(function(success) {
            if (success) {
                if (angular.isUndefined(success.value) || success.status === 'fail') {
                    if (angular.isUndefined(success.message)) {
                        $ioAlert.show('', "Please make sure all required fields are filled in.", 'error');
                    } else {
                        $ioAlert.show('', success.message, 'error');
                    }
                    success.value = '';
                    if (success.message) {
                        console.log(success.message);
                    }
                }

                $scope.flightFormFieldsWithContent.forEach((field) => {
                    if (parseInt(fieldId) == field.id) {
                        switch (field.data_type) {
                            case 'currency':
                                $scope.model[field.field_name] = FormService.currencyFormating(
                                    String(success.value),
                                    true
                                );
                                break;
                            case 'integer':
                                $scope.model[field.field_name] = FormService.numberFormating(String(success.value), 0);
                                break;
                            case 'decimal':
                                $scope.model[field.field_name] = FormService.numberFormating(String(success.value));
                                break;
                            case 'percentage':
                                $scope.model[field.field_name] = String(success.value) + '%';
                                break;
                            case 'date':
                                $scope.model[field.field_name] = FormService.convertStringToDate(String(success.value));
                                break;
                            default:
                                $scope.model[field.field_name] = String(success.value);
                        }
                    }
                });
            }
            calQueueExc();
        }, function(error) {
            $ioAlert.show("Error!", "An error occurred calculating the fields.", 'error');
            $rs.loadingInProgress = false;
            calQueueExc();
        });
    };


    function calQueueExc() {
        if ($scope.calQueue.length > 0) {
            let fieldIds = $scope.calQueue.shift();
            $scope.calculate(fieldIds);
        } else if ($scope.calQueue.length == 0) {
            $rs.loadingInProgress = false;
        }
    }

    $scope.calculateAllFields = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Calculate Details";
        $scope.calQueue = [];
        for (let i = 0, len = $scope.entireForm.length; i < len; i++) {
            if ($scope.entireForm[i].items) {
                for (let j = 0, size = $scope.entireForm[i].items.length; j < size; j++) {
                    if ($scope.entireForm[i].items[j].fieldIds != undefined) {
                        //$scope.calculate($scope.form[i].items[j].fieldIds);
                        const item = $scope.entireForm[i].items[j];
                        if (item.condition) {
                            const parent_name = item.conditionInfo.parent_name;
                            const lookupValue = item.conditionInfo.lookup_value;
                            const input_type = item.conditionInfo.input_type;

                            const isModelParentNameUndefined = $scope.model[parent_name] === undefined;
                            const isInputNotACheckbox = input_type != "checkbox";
                            const isLookupValueNotIncludedInParent = checkIfLookupValueIsNotIncludedInParent(lookupValue, $scope.model[parent_name]);

                            if (isModelParentNameUndefined || (isInputNotACheckbox && isLookupValueNotIncludedInParent)) {
                                continue;
                            }
                        }
                        if ($scope.entireForm[i].items[j].showOverride) {
                            if (!$scope.entireForm[i].items[j].override)
                                $scope.calQueue.push($scope.entireForm[i].items[j].fieldIds);
                        } else {
                            $scope.calQueue.push($scope.entireForm[i].items[j].fieldIds);
                        }
                    }
                }
            }
        }
        $scope.calQueue = FormService.reorderCalQueue($scope.calQueue);
        calQueueExc();
    };

    function checkIfLookupValueIsNotIncludedInParent(lookupValue, parent) {
        if (!(parent && _.isFunction(parent.includes))) {
            return true;
        }

        if (Array.isArray(lookupValue)) {
            let returned = true;
            lookupValue.forEach(function(val) {
                returned = returned && !parent.includes(val);
            })

            return returned;
        }

        return !parent.includes(lookupValue);
    }

    var initInstanceSettings = function() {
        let params = [
            'disable_clone',
            'budget_management',
            'role_sales_hide_sidebar',
            'add_comment_is_required_for_editing',
            'show_specific_line_item_and_flight_tasks',
            'cancel_rebook_enabled',
            'order_statuses_control_adding',
            'triton_enable_push_flights',
        ];
        FormService.getInstanceSettingArray(params).then(function(data) {
            if (data && data.instance_settings) {
                const disableClone = data.instance_settings.disable_clone;
                $scope.disableClone = (disableClone === "1");
                const budgetManagementEnabled = data.instance_settings.budget_management;
                $scope.budgetManagementEnabled = (budgetManagementEnabled === "1");
                const showLineItemComments = data.instance_settings.role_sales_hide_sidebar;
                $scope.showLineItemComments = !(showLineItemComments === "1");
                const addCommentRequired = data.instance_settings.add_comment_is_required_for_editing;
                $scope.addCommentRequired = (addCommentRequired === "1");
                const showSpecificLineItemAndFlightTasks = data.instance_settings.show_specific_line_item_and_flight_tasks;
                $scope.showSpecificLineItemAndFlightTasks = (showSpecificLineItemAndFlightTasks === "1");
                let cancelRebookSetting = data.instance_settings.cancel_rebook_enabled;
                $scope.cancelRebookEnabled = (cancelRebookSetting && (cancelRebookSetting === "1"));
                let enablePushFlights = data.instance_settings.triton_enable_push_flights;
                $scope.enablePushFlights  = (enablePushFlights && (enablePushFlights === "1"));

                if (isNaN(showLineItemComments)) {
                    $ioAlert.show('Error!', 'Cannot find instance setting: role_sales_hide_sidebar', 'error');
                }
                if (isNaN(addCommentRequired)) {
                    $ioAlert.show('Error!', 'Cannot find instance setting: add_comment_is_required_for_editing', 'error');
                }
                if (isNaN(enablePushFlights)) {
                    $ioAlert.show('Error!', 'Cannot find instance setting: triton_enable_push_flights', 'error');
                }
                try {
                    $scope.allowed_order_statuses = JSON.parse(data.instance_settings.order_statuses_control_adding);
                } catch(error) {
                    $scope.allowed_order_statuses = [];
                }
            } else {
                $ioAlert.show('Error!', 'Cannot find any instance setting', 'error');
            }
            //show only for user_type superadmin and sales, salesmanager roles
            if ($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin") {
                $scope.showLineItemComments = true;
            }
        }, function(error) {
            console.log(error);
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            //show only for user_type superadmin and sales, salesmanager roles
            if ($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin") {
                $scope.showLineItemComments = true;
            }
        });
    }

    var init = function() {
        $scope.activateCommentsTab();
        DateRangeService.init();
        $scope.lineItemId = $stateParams.lineItemId;
        if ($scope.lineItemId) {
            $scope.lineItemIdToFormattedEOAPreviewsArray[$scope.lineItemId] = $stateParams.formattedEOAPreviews;
        }
        const flightId = $stateParams.flightId;
        if (flightId) {
            $scope.initialFlightId = flightId;
        }
        getLineItem();
        applyPermissions();
        initLineItemFlightTabs();
        $scope.setActiveLineItemFlightTab($scope.initialFlightId ? 2 : 1);
        getGoals($scope.lineItemId);
        initInstanceSettings();
        getCommentFilterItems();
    };

    CurrentUser.get().then(function(user) {
        $scope.user = user;
        init();
    });
}

AuditHistoryController.$inject = ['$scope', '$http', '$stateParams', '$state', 'OrdersService'];

function AuditHistoryController($scope, $http, $stateParams, $state, OrdersService) {
    $scope.type = $stateParams.type;
    $scope.id = $stateParams.id;
    $scope.lineItemId = $stateParams.lineItemId;  // Actually used for flight audit history, to go back to line item/flight screen.
    $scope.activityLog = [];
    $scope.displayText = '';

    $scope.getEntityActivity = function() {
        OrdersService.getActivity($scope.type, $scope.id).then(function() {
            let entries = [];
            if ($scope.type === 'line_item') {
                entries = OrdersService.lineItemActivity;
            }
            if ($scope.type === 'order') {
                entries = OrdersService.orderActivity;
            }
            if ($scope.type === 'flight') {
                entries = OrdersService.flightActivity;
            }
            for (let i = 0, len = entries.length; i < len; i++) {
                if (entries[i].delta)
                    entries[i].delta = JSON.parse(entries[i].delta);
                $scope.activityLog.push(entries[i]);
            }
            $scope.auditLoading = false;
        }, function() {
            console.log("Error getting " + $scope.type + " activity for id: " + $scope.id);
            $scope.auditLoading = false;
        });
    };

    $scope.backToSender = function() {
        if ($scope.type === 'line_item') {
            $state.go('viewLineItem', {
                lineItemId: $scope.id,
                formattedEOAPreviews: undefined
            });
        } else if ($scope.type === 'order') {
            $state.go('viewOrder', {
                id: $scope.id
            });
        } else if ($scope.type === 'flight') {
            $state.go('viewLineItem', {
                lineItemId: $scope.lineItemId,
                flightId: $scope.id,
                formattedEOAPreviews: undefined
            });
        } else if ($scope.type === 'client') {
            $state.go('viewWorkflowClient', {
                id: $stateParams.id
            });
        }
    };

    $scope.init = function() {
        $scope.auditLoading = true;
        if ($scope.type === 'line_item') {
            $scope.displayText = 'Line Item';
        } else if ($scope.type === 'order') {
            $scope.displayText = 'Order';
        } else if ($scope.type === 'flight') {
            $scope.displayText = 'Flight';
        } else if ($scope.type === 'client') {
            $scope.displayText = 'Client';
            // In the case of the WorkflowClient page, it already has the activity log, so it's passing it in
            // so we don't need to fetch it again here.  We're just coming here to the AuditHistoryController since
            // this is where the HTML is defined.
            const clientLogEntries = $stateParams.activityLog;
            for (let i = 0, len = clientLogEntries.length; i < len; i++) {
                if (clientLogEntries[i].delta) {
                    clientLogEntries[i].delta = JSON.parse(clientLogEntries[i].delta);
                }
                $scope.activityLog.push(clientLogEntries[i]);
            }
            $scope.auditLoading = false;
            return;
        }
        $scope.getEntityActivity();
    };

    $scope.init();
}

ViewFlightController.$inject = ['$stateParams', '$state', 'OrdersService', '$ioAlert'];

function ViewFlightController($stateParams, $state, OrdersService, $ioAlert) {
    // This is for use when someone is redirecting to a flight but does not have the line_item_id available.  In this
    // case we fetch it here, then redirect to the most often used endpoint that uses both the line_item_id and flight_id.
    const flightId = $stateParams.flightId;
    if (flightId) {
        OrdersService.getFlight(flightId).then(function() {
            const flight = OrdersService.flight;
            const lineItemId = flight.line_item_id;
            if (!lineItemId) {
                $ioAlert.show("Error!", "Error loading flight " + flightId, 'error');
            } else {
                $state.go('viewLineItem', {
                    lineItemId: lineItemId,
                    flightId: flightId,
                    formattedEOAPreviews: undefined
                });
            }
        }, function() {
            $ioAlert.show("Error!", "Error loading flight " + flightId, 'error');
        });
    } else {
        $ioAlert.show("Error!", "Cannot load flight information since no flightId was provided", 'error');
    }
}

WorkflowLogHistoryController.$inject = ['$rootScope', '$scope', '$http', '$stateParams', '$state', 'OrdersService', 'WorkflowsService', 'WorkflowClientService', 'DisplayService'];

function WorkflowLogHistoryController($rs, $scope, $http, $stateParams, $state, OrdersService, WorkflowsService, WorkflowClientService, DisplayService) {
    $scope.type = $stateParams.type;
    $scope.id = $stateParams.id;
    $scope.lineItemId = $stateParams.lineItemId;  // Actually used for flight audit history, to go back to line item/flight screen.
    $scope.logs = [];
    $scope.taskAssignmentHistory = [];
    $scope.statuses = [];
    $scope.steps = [];
    $scope.workflowLog = [];
    $scope.displayText = '';
    $scope.logsReceived = false;
    $scope.taskAssignmentHistoryReceived = false;
    $scope.statusesReceived = false;
    $scope.workflowActionsReceived = false;
    $scope.stepsReceived = false;

    const getWorkflowLogs = function() {
        OrdersService.getWorkflowLogs($scope.type, $scope.id).then(function(logs) {
            $scope.logs = logs;
            $scope.logsReceived = true;
            processResponses();
        }, function() {
            console.log("Error getting " + $scope.type + " workflow logs for id: " + $scope.id);
        });
    };

    const getTaskAssignmentHistory = function() {
        OrdersService.getTaskAssignmentHistoryForEntity($scope.type, $scope.id).then(function(history) {
            $scope.taskAssignmentHistory = history;
            $scope.taskAssignmentHistoryReceived = true;
            processResponses();
        }, function() {
            console.log("Error getting " + $scope.type + " task assignment history for id: " + $scope.id);
        });
    };

    const getStatuses = function() {
        switch ($scope.type) {
            case 'client':
                OrdersService.getClientStatuses().then(function(data) {
                    $scope.statuses = data;
                    $scope.statusesReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching client statuses.");
                    $scope.statusesReceived = true;
                    processResponses();
                });
                break;
            case 'order':
                OrdersService.getOrderStatuses().then(function() {
                    $scope.statuses = OrdersService.statuses;  // duh
                    $scope.statusesReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching order statuses.");
                    $scope.statusesReceived = true;
                    processResponses();
                });
                break;
            case 'line_item':
                OrdersService.getLineItemStatuses().then(function(data) {
                    $scope.statuses = data;
                    $scope.statusesReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching line item statuses.");
                    $scope.statusesReceived = true;
                    processResponses();
                });
                break;
            case 'flight':
                OrdersService.getFlightStatuses().then(function(data) {
                    $scope.statuses = data;
                    $scope.statusesReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching flight statuses.");
                    $scope.statusesReceived = true;
                    processResponses();
                });
                break;
            default:
                console.log("Error: invalid entity type: " + $scope.type);
                $scope.statusesReceived = true;
                processResponses();
        }
    }

    const getWorkflowActions = function() {
        WorkflowsService.getWorkflowActions().then(function(data) {
            $scope.workflowActions = data;
            $scope.workflowActionsReceived = true;
            processResponses();
        }, function() {
            console.log("Error fetching workflow actions.");
            $scope.workflowActionsReceived = true;
            processResponses();
        });
    }

    const getWorkflowSteps = function() {
        switch ($scope.type) {
            case 'client':
                WorkflowClientService.getStepsForClient($scope.id).then(function(data) {
                    $scope.steps = data;
                    $scope.stepsReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching client workflow steps.");
                    $scope.stepsReceived = true;
                    processResponses();
                });
                break;
            case 'order':
                OrdersService.getStepsForOrder($scope.id).then(function(data) {
                    $scope.steps = data;
                    $scope.stepsReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching order workflow steps.");
                    $scope.stepsReceived = true;
                    processResponses();
                });
                break;
            case 'line_item':
                OrdersService.getLineItemSteps($scope.id).then(function(data) {
                    $scope.steps = data;
                    $scope.stepsReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching line item workflow steps.");
                    $scope.stepsReceived = true;
                    processResponses();
                });
                break;
            case 'flight':
                OrdersService.getStepsForFlight($scope.id).then(function(data) {
                    $scope.steps = data;
                    $scope.stepsReceived = true;
                    processResponses();
                }, function() {
                    console.log("Error fetching flight workflow steps.");
                    $scope.stepsReceived = true;
                    processResponses();
                });
                break;
            default:
                console.log("Error: invalid entity type: " + $scope.type);
                $scope.stepsReceived = true;
                processResponses();
        }
    }

    const processResponses = function() {
        // Wait for all backend queries to end, then process.
        if ($scope.logsReceived && $scope.statusesReceived && $scope.workflowActionsReceived &&
            $scope.taskAssignmentHistoryReceived && $scope.stepsReceived) {
            $rs.loadingInProgress = false;
            const entries = $scope.logs;
            const ta_entries = _.sortBy($scope.taskAssignmentHistory, 'created_at');
            let ta_index = 0;
            const ta_length = ta_entries.length;

            for (let i = 0, len = entries.length; i < len; i++) {
                // Note that the web page uses workflowLog's created_at, user_id, and text fields.
                const currentEntry = entries[i];

                // Insert any task assignment history entries that were created before this next workflow log entry.
                while (ta_index < ta_length && ta_entries[ta_index].created_at < currentEntry.created_at) {
                    pushTaskAssignmentEntry(ta_entries[ta_index++]); // Increment index
                }

                let text = currentEntry.event_text;
                if (currentEntry.workflow_action_id) {
                    let workflowActionToUse = currentEntry.workflow_action_id;
                    const actionEntry = _.find($scope.workflowActions, {id: currentEntry.workflow_action_id});
                    if (actionEntry) {
                        workflowActionToUse = actionEntry.name;  // Use name if available, otherwise keep the id.
                    }
                    text = text + ' | Workflow Action: ' + workflowActionToUse;
                }
                if (currentEntry.workflow_step_id) {
                    let workflowStepToUse = currentEntry.workflow_step_id;
                    const stepEntry = _.find($scope.steps, {id: currentEntry.workflow_step_id});
                    if (stepEntry) {
                        workflowStepToUse = stepEntry.name;  // Use name if available, otherwise keep the id.
                    }
                    text = text + ' | New Workflow Step: ' + workflowStepToUse;
                }
                if (currentEntry.status_id) {
                    let statusToUse = currentEntry.status_id;
                    const statusEntry = _.find($scope.statuses, {id: currentEntry.status_id});
                    if (statusEntry) {
                        statusToUse = statusEntry.name;  // Use name if available, otherwise keep the id.
                    }
                    text = text + ' | New Status: ' + statusToUse;
                }
                currentEntry.text = text;
                currentEntry.created_at = DisplayService.utcToLocalDatetime(currentEntry.created_at);
                $scope.workflowLog.push(currentEntry);
            }

            // Any remaining task assignment entries?  These would have been created after the last workflow log entry.
            while (ta_index < ta_length) {
                pushTaskAssignmentEntry(ta_entries[ta_index++]); // Increment index
            }
        }
    }

    const pushTaskAssignmentEntry = function(entry) {
        // user_id and created_at already set by backend. Add text now.
        entry.text = entry.action + ' ' + entry.task_name;
        entry.created_at = DisplayService.utcToLocalDatetime(entry.created_at);
        $scope.workflowLog.push(entry);
    }

    $scope.backToSender = function() {
        if ($scope.type === 'line_item') {
            $state.go('viewLineItem', {
                lineItemId: $scope.id,
                formattedEOAPreviews: undefined
            });
        } else if ($scope.type === 'order') {
            $state.go('viewOrder', {
                id: $scope.id
            });
        } else if ($scope.type === 'flight') {
            $state.go('viewLineItem', {
                lineItemId: $scope.lineItemId,
                flightId: $scope.id,
                formattedEOAPreviews: undefined
            });
        } else if ($scope.type === 'client') {
            $state.go('viewWorkflowClient', {
                id: $stateParams.id
            });
        }
    };

    $scope.init = function() {
        if ($scope.type === 'line_item') {
            $scope.displayText = 'Line Item';
        } else if ($scope.type === 'order') {
            $scope.displayText = 'Order';
        } else if ($scope.type === 'flight') {
            $scope.displayText = 'Flight';
        } else if ($scope.type === 'client') {
            $scope.displayText = 'Client';
        }
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Workflow Log Information";
        getWorkflowLogs();
        getTaskAssignmentHistory();
        getStatuses();
        getWorkflowActions();
        getWorkflowSteps();
    };

    $scope.init();
}
