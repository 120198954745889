'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from'lodash';
import EventBus from "../../../../../grok/src/modules/core/app/helpers/EventBus";
import { UPDATE_NAV_ITEM } from "../../../../../grok/src/modules/core/nav/nav.constants";
import {WidgetType} from '../widget/design.widget.constants';

angular.module('design.services', [])
    .factory('DesignFactory', DesignFactory)
    .factory('DesignFactoryDelegate', DesignFactoryDelegate);

/**
 * @ngInject
 */
function DesignFactory(
    $rootScope,
    DesignResource,
    ScopeFactory,
    ServiceDataFactory,
    DataSourceType,
    DataSourceFactory,
    FontSize,
    gettextCatalog,
    PubSub
) {
    var $designScope;

    var state = {};
    state.loading = false;
    state.exporting = {
        loading: false,
        text: null,
        icon: null
    };

    var props = {
        hasVariableHeightWidgets: false
    };

    /**
     * Refers to the current page loaded
     * @type {{}}
     */
    var currentPage = {};

    /**
     * Refers to the currently selected layout id
     * @type {null}
     */
    var currentLayoutId = null;

    /**
     * Refers to the all the pages being loaded into the nav
     * @type {null}
     */
    var pageEntities = null;

    /**
     * Determines if page being rendered is being exported or not
     * @type {boolean}
     */
    var isExportingPage = false;

    /**
     * Determines if a widget is currently being created
     * @type {boolean}
     */
    var isCreatingWidget = false;

    return {
        state: state,
        props: props,
        getResource: getResource,
        // Layout + masonry helpers
        getDataSourceIcon: getDataSourceIcon,
        getDataSourceColor: getDataSourceColor,
        getWidgetTitle: getWidgetTitle,
        // Design helpers
        setIsExportingPage: setIsExportingPage,
        getIsExportingPage: getIsExportingPage,
        setIsCreatingWidget: setIsCreatingWidget,
        getIsCreatingWidget: getIsCreatingWidget,
        setCurrentPage: setCurrentPage,
        getCurrentPage: getCurrentPage,
        getPageEntity: getPageEntity,
        getDashboardFilters: getDashboardFilters,
        pageHasWidgets: pageHasWidgets,
        setCurrentLayoutId: setCurrentLayoutId,
        getCurrentLayout: getCurrentLayout,
        getWidgets: getWidgets,
        getCurrentWidgets: getCurrentWidgets,
        getAllWidgets: getAllWidgets,
        swapWidgetInCurrentLayout: swapWidgetInCurrentLayout,
        getWidget: getWidget,
        $getAllWidgetElements: $getAllWidgetElements,
        updateLayout: updateLayout,
        getLayout: getLayout,
        getLayouts: getLayouts,
        layoutHasWidgets: layoutHasWidgets,
        getNextLayoutDisplayOrder: getNextLayoutDisplayOrder,
        getNextWidgetDisplayOrder: getNextWidgetDisplayOrder,
        getPageEntities: getPageEntities,
        getNavPageList: getNavPageList,
        getFontSize: getFontSize,
        getFontSizeValue: getFontSizeValue,
        resetVars: resetVars,
        $registerScope: $registerScope,
        $rebuildPage: $rebuildPage,
        $triggerPageLoader: $triggerPageLoader,
        $refreshMenu: $refreshMenu,
        canShowEmptyLayoutsNotice: canShowEmptyLayoutsNotice,
        isDemoModeEnabled: isDemoModeEnabled,
        isBenchmarksEnabled: isBenchmarksEnabled,
        widgetsMayBeHidden: widgetsMayBeHidden,
        setIsFavorite: setIsFavorite,
        hasNonChatGptWidgets: hasNonChatGptWidgets,
        hasOnlyChatGptWidgets: hasOnlyChatGptWidgets,
        hasMissingWidgets: hasMissingWidgets
    };

    function canShowEmptyLayoutsNotice(){
        return $designScope.design && _.isEmpty($designScope.design.page.layouts);
    }

    function setCurrentPage(val) {
        currentPage = val;
    }

    function getCurrentPage() {
        return currentPage;
    }

    /**
     * Get current page assigned entity
     * @returns {*}
     */
    function getPageEntity() {
        return currentPage.metadata ? currentPage.metadata.selected_entity : null;
    }

    /**
     * Get current page dashboard_filters
     * @returns {*}
     */
    function getDashboardFilters() {
        return (currentPage.metadata && currentPage.metadata.dashboard_filters) ? currentPage.metadata.dashboard_filters : null;
    }

    /**
     * Check if at least one layout contains a widget in a whole dashboard
     * @returns {boolean}
     */
    function pageHasWidgets() {
        return _.size(getAllWidgets()) > 0;
    }

    /**
     *
     * @param val
     */
    function setCurrentLayoutId(val) {
        currentLayoutId = val;
    }

    /**
     * Get layout currently selected
     * @returns {*}
     */
    function getCurrentLayout() {
        return currentLayoutId ? currentPage['layouts'][currentLayoutId] : {};
    }

    /**
     * Get all widgets in the current layout
     * @param layoutId
     * @returns {*}
     */
    function getCurrentWidgets() {
        var layout = getCurrentLayout();
        return layout ? layout.widgets || {} : {};
    }

    /**
     * Get all widgets in a layout
     * @param layoutId
     * @returns {*}
     */
    function getWidgets(layoutId) {
        return getLayout(layoutId).widgets || {};
    }

    /**
     * Get all widgets in all layouts
     * @param layoutId
     * @returns {*}
     */
    function getAllWidgets() {
        return _.reduce(getCurrentPage().layouts, function(acc, layout) {
            return _.assign(acc, layout.widgets);
        }, {});
    }

    function swapWidgetInCurrentLayout(widgetId, widget) {
        getCurrentLayout().widgets[widgetId] = widget;
    }

    /**
     * Gets a specific widget in any layout
     * @param id
     * @returns {any}
     */
    function getWidget(id) {
        return getAllWidgets()[id];
    }

    /**
     * Returns all widget elements in the current layout
     * @returns {JQuery | jQuery}
     */
    function $getAllWidgetElements() {
        return $('div.layout-inner:visible').find('div.widget-inner');
    }

    /**
     * Update a specifc layout in the current page
     * @param layout
     */
    function updateLayout(layout) {
        currentPage['layouts'][layout.id] = layout;
    }

    /**
     * Gets a specific layout in the page
     * @param layoutId
     * @returns {*}
     */
    function getLayout(layoutId) {
        return currentPage['layouts'][layoutId] || getCurrentLayout();
    }

    /**
     * Gets a specific layout in the page
     * @param layoutId
     * @returns {*}
     */
    function getLayouts() {
        return currentPage['layouts'];
    }

    /**
     * If no layoutId is specified, current layout is used
     * @param layoutId
     * @returns {boolean}
     */
    function layoutHasWidgets(layoutId) {
        var layout = getLayout(layoutId);
        return layout
            && layout.widgets
            && _.size(layout.widgets) > 0
            && hasNonChatGptWidgets(layout);
    }

    /**
     * Returns the next highest layout display_order
     * @param layouts
     */
    function getNextLayoutDisplayOrder(layouts) {
        return !_.isEmpty(layouts)
            ? _.max(_.map(layouts, 'display_order')) + 1
            : 1;
    }

    /**
     * Returns the next highest widget display_order
     * @param widgets
     */
    function getNextWidgetDisplayOrder(widgets) {
        return !_.isEmpty(widgets)
            ? _.max(_.map(widgets, 'display_order')) + 1
            : 1;
    }

    /**
     * @param type
     * @returns {any[] | string[]}
     */
    function getPageEntities(type) {
        return _.filter(pageEntities, {type: type, can_be_listed: true});
    }

    function getNavPageList() {
        return DesignResource.getPages({
            sort: 'display_order',
            is_dynamic: true,
            is_in_library: false,
            all: true,
            extra: false
        }).then(function (pages) {
            pageEntities = pages
        });
    }

    function setIsFavorite(page, value) {
        DesignResource.setIsFavorite(page.id, value).then(function() {
            page.is_favorite = value;
            if (window.isNUI) {
                PubSub.emit('SegmentEvents', {
                    event: 'DashboardToggleFavoriteEvent',
                    payload: page
                });
            }
            EventBus.signal(UPDATE_NAV_ITEM);
        });
    }

    /**
     * Returns the appropriate Restanguar resource
     * @param pageType
     */
    function getResource(pageType) {
        return DesignResource.getResource(pageType);
    }

    /**
     * Return correct icon based on widget data source
     * @param datasource
     * @returns {string}
     */
    function getDataSourceIcon(datasource) {
        return DataSourceFactory.getDataSourceIcon(datasource);
    }

    /**
     * Return correct color based on widget data source
     * @param datasource
     * @returns {string}
     */
    function getDataSourceColor(datasource) {
        return DataSourceFactory.getDataSourceColor(datasource);
    }

    /**
     * @param widget
     */
    function getWidgetTitle(widget) {
        if (_.isEmpty(widget.title)) {
            return gettextCatalog.getString('Untitled Widget');
        }
        return widget.title;
    }

    function setIsExportingPage(val) {
        isExportingPage = val;
    }

    function getIsExportingPage() {
        return isExportingPage;
    }

    function setIsCreatingWidget(val) {
        isCreatingWidget = val;
    }

    function getIsCreatingWidget() {
        return isCreatingWidget;
    }

    /*
     * Resets all set variables
     */
    function resetVars() {
        currentPage = {};
        currentLayoutId = null;

        // Since AmCharts is unaware of state changes, this is a good location to clear them out
        // NOTE: since we are mutating the array as we loop and clear, we need to always grab the head
        var charts = AmCharts.charts;
        _.each(charts, function () {
            var chart = _.first(charts);
            if (!_.isUndefined(chart)) {
                try {
                    chart.clear();
                    chart = null;
                }
                catch (e) {
                    console.log('Could not clear chart: ' + e);
                }
            }
        });

        // Reset any factory specific setting
        ServiceDataFactory.setPageEntity(null);
    }

    /**
     * Returns a the relative font size set for the dashboard
     * @returns {number} (Default: 12)
     */
    function getFontSize() {
        return currentPage && currentPage.metadata ? currentPage.metadata.font_size : FontSize.SIZE_SMALL;
    }

    /**
     * Returns a pixel value for font size based on relative dashboard font size scale
     * @returns {number}
     */
    function getFontSizeValue() {
        switch (getFontSize()) {
            case FontSize.SIZE_SMALL:
                return 12;
            case FontSize.SIZE_MEDIUM:
                return 16;
            case FontSize.SIZE_LARGE:
                return 20;
            default:
                return 14;
        }
    }

    /**
     * Local implementation of $registerScope
     * @param scope
     */
    function $registerScope(scope) {
        $designScope = ScopeFactory.$registerScope(scope, 'design');
    }

    /**
     * Refetches the design and reloads all layouts and widgets
     */
    function $rebuildPage() {
        $designScope.rebuildPage();
    }

    /**
     * Triggers the page loader to appear
     */
    function $triggerPageLoader() {
        $designScope.triggerPageLoader();
    }

    /**
     * Streamline broadcast event for refreshing design nav menu
     */
    function $refreshMenu() {
        $rootScope.$broadcast('design:refreshMenu');
    }
    
    /**
     * If the dashboard is currently set as demo mode
     * @returns {boolean|*}
     */
    function isDemoModeEnabled() {
        const page = getCurrentPage();
        return !_.isEmpty(page) && !_.isEmpty(page.metadata) && page.metadata.enable_demo_mode;
    }

    function isBenchmarksEnabled() {
        const page = getCurrentPage();
        return !_.isEmpty(page) && !_.isEmpty(page.metadata) && page.metadata.enable_benchmarks;
    }

    function widgetsMayBeHidden() {
        const layout = getCurrentLayout();

        if (layout && layout.metadata && layout.widgets) {
            const hasHiddenWidget = !!_.find(layout.widgets, widget => widget.metadata.dynamic.shouldHide);
            const hideWidgetsWithNoDataValue = !layout.metadata.draw_options.show_widgets_with_no_data.value;
            const hideWidgetsWithNoDataOverriden = !layout.metadata.draw_options.show_widgets_with_no_data.overridden;

            return (hideWidgetsWithNoDataValue || hideWidgetsWithNoDataOverriden) && hasHiddenWidget;
        }

        return false;
    }

    /**
     * Returns if layout has only ChatGPT widgets or not
     * @param layout
     *
     * @returns {boolean}
     */
    function hasNonChatGptWidgets(layout) {
        let hasNonChatGptWidgets = false;
        for (const widgetId in layout.widgets) {
            let widget = layout.widgets[widgetId];
            if (widget.type !== WidgetType.CHATGPT) {
                hasNonChatGptWidgets = true;
                break;
            }
        }

        return hasNonChatGptWidgets;
    }

    function hasOnlyChatGptWidgets() {
        let currentPageLayouts = getLayouts();
        let hasOnlyChatGptWidgets = true;
        for (const layoutKey in currentPageLayouts) {
            let layout = currentPageLayouts[layoutKey];
            if (hasNonChatGptWidgets(layout)) {
                hasOnlyChatGptWidgets = false;
                break;
            }
        }

        return hasOnlyChatGptWidgets;
    }

    function hasMissingWidgets() {
        return _.size(getCurrentPage().widget_ids) !== _.size(getAllWidgets());
    }
}

/**
 * Allows to get child factory based on page entity type
 * @ngInject
 */
function DesignFactoryDelegate(
    $injector,
    PageEntity
) {
    /**
     * @param pageEntity
     * @returns {*}
     */
    var getFactory = function(pageEntity) {
        switch (pageEntity) {
            case PageEntity.PAGE_OVERVIEW:
                return $injector.get('ServiceOverviewFactory');
                break;

            case PageEntity.PAGE_DRILLDOWN:
                return $injector.get('ServiceDrilldownFactory');
                break;

            default:
                return null;
        }
    };

    return {
        getFactory: getFactory
    }
}