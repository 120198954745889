import angular from 'angular';

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderMenuService', ExportBuilderMenuService);

/**
 * @ngInject
 */
function ExportBuilderMenuService(
) {
    var state = {
        position: {}
    };

    return {
        setPosition: setPosition,
        getPosition: getPosition
    };

    function setPosition(position) {
        state.position = position
    }

    function getPosition() {
        return state.position;
    }
}