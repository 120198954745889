import ChartWidgetRenderService from '@/modules/ta/widget/services/types/ChartWidgetRenderService';
import { isSmallPercentageInSeries } from '@/modules/core/charts/adapters.helper';
import { dataContext } from '@/modules/core/charts/events.helper';
import { PieChartDrawOption } from '@/modules/ta/widget/builder.constants';
import { color } from '@amcharts/amcharts5';
import { colorGenerator } from '@/modules/core/charts/am5/base/composables/fills/colorGenerator';
import SliceWidgetConfigDataModel from '@/modules/core/charts/am5/pie/models/SliceWidgetConfigDataModel';

export default class SliceWidgetRenderServicePie extends ChartWidgetRenderService {
  /**
   * @var {boolean}
   */
  isComparison;

  /**
   * @var {Object}
   */
  periodData;

  /**
   * @param index
   * @param metric
   * @param rawValue
   * @param rawMetric
   * @param dataFormat
   * @returns {SliceWidgetConfigDataModel}
   */
  getChartDefaultDatum(index, metric, rawValue, rawMetric, dataFormat) {
    metric = metric || PieChartDrawOption.NULL_VALUE;
    return new SliceWidgetConfigDataModel({ index, metric, rawValue, rawMetric, dataFormat });
  }

  /**
   * @param datum
   * @returns {String}
   */
  getDataMetric(datum) {
    let datumMetric = datum[this.getCurrentGroupByField()];
    datumMetric = !datumMetric ? PieChartDrawOption.NULL_VALUE : datumMetric;
    return this._isCurrentGroupedColumnDate() ? this._getVisibleDate(datum) : datumMetric;
  }

  /**
   * @param datum
   * @param total
   * @returns {number}
   */
  getDatumPercent(datum, total) {
    return Number.parseFloat((datum.rawValue / total) * 100).toFixed(2);
  }

  /**
   * @param chartData
   * @param total
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getPercentUpdatedData(chartData, total) {
    const otherPercent =
      chartData.length > 100 && this.getDrawOptions().other_percent < 1
        ? 0.4
        : this.getDrawOptions().other_percent;

    return chartData.map((datum) => {
      datum.percent = this.getDatumPercent(datum, total);
      if (otherPercent) {
        datum.isOther = datum.percent <= otherPercent;
      }
      return datum;
    });
  }

  /**
   * @param chartData
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getNormalizedChartData(chartData) {
    return chartData.map((chartDatum) => {
      chartDatum.value = 1;
      return chartDatum;
    });
  }

  /**
   * @param chartData
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getUnNormalizedChartData(chartData) {
    return chartData.map((chartDatum) => {
      chartDatum.value = chartDatum.rawValue;
      return chartDatum;
    });
  }

  /**
   * @param datum
   * @param metricSuffix
   * @returns {string}
   */
  getDatumMetricLabel(datum, metricSuffix) {
    return metricSuffix
      ? `${this.getDataMetric(datum)} ${metricSuffix}`
      : this.getDataMetric(datum);
  }

  /**
   * @param field
   * @param metricSuffix
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getChartDataFromPeriodData(field, metricSuffix) {
    return this.periodData.map((datum, index) =>
      this.getChartDefaultDatum(
        index,
        this.getDatumMetricLabel(datum, metricSuffix),
        datum[field],
        this.getDataMetric(datum),
        this.nonGroupedColumns[0].format
      )
    );
  }

  /**
   * @param chartData
   * @param total
   * @returns {SliceWidgetConfigDataModel}
   */
  getOtherPercentDatum(chartData, total) {
    const newDatum = this.getChartDefaultDatum(
      chartData.length,
      PieChartDrawOption.OTHERS_METRIC_LABEL,
      0
    );
    chartData.forEach((datum) => {
      if (datum.isOther) {
        newDatum.rawValue += datum.rawValue;
        newDatum.subs = newDatum.subs.concat(datum.subs);
      }
    });
    newDatum.isOther = newDatum.rawValue === 0;
    newDatum.percent = this.getDatumPercent(newDatum, total);
    return newDatum;
  }

  /**
   * @param chartData
   * @param total
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getDrawOptionAppliedChartData(chartData, total) {
    const { other_percent, is_normalized } = this.getDrawOptions();
    let newChartData = this.getPercentUpdatedData(chartData, total);
    const otherPercent = newChartData.length > 100 && other_percent < 1 ? 0.4 : other_percent;

    if (otherPercent > 0) {
      newChartData.push(this.getOtherPercentDatum(newChartData, total));
    }
    newChartData = is_normalized
      ? this.getNormalizedChartData(newChartData)
      : this.getUnNormalizedChartData(newChartData);
    return newChartData.filter((datum) => !datum.isOther);
  }

  /**
   * @param field
   * @param total
   * @param metricSuffix
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getChartDataForSingleMetric(field, total, metricSuffix) {
    const chartData = this.getChartDataFromPeriodData(field, metricSuffix);
    return this.getDrawOptionAppliedChartData(chartData, total);
  }

  /**
   * @param index
   * @param column
   * @returns {Array<string|SliceWidgetConfigDataModel>}
   */
  getChartDefaultDatumEntry(index, column) {
    return [column.field, this.getChartDefaultDatum(index, column.label, 0, false, column.format)];
  }

  /**
   * @returns {{}}
   */
  getChartDataFromNonGroupedColumns() {
    const defaultDatumEntries = this.nonGroupedColumns.map((column, index) =>
      this.getChartDefaultDatumEntry(index, column)
    );
    return Object.fromEntries(defaultDatumEntries);
  }

  /**
   * @param chartDataByField
   * @param field
   * @param fieldIndex
   * @param total
   */
  updateChartDataByField(chartDataByField, field, fieldIndex, total) {
    const subs = this.getChartDataForSingleMetric(field, total, chartDataByField[field].metric);
    chartDataByField[field].index = fieldIndex;
    chartDataByField[field].subs = subs;
    subs.forEach((sub) => {
      if (sub.isMetric) {
        sub.isMetric = false;
        sub.curMetricName = chartDataByField[field].metric;
      }
      if (!sub.isOther) {
        chartDataByField[field].rawValue += sub.rawValue;
      }
    });
  }

  /**
   * @param total
   * @returns {Array<SliceWidgetConfigDataModel>}
   */
  getChartDataForMultipleMetrics(total) {
    const chartDataByField = this.getChartDataFromNonGroupedColumns();
    Object.keys(chartDataByField).forEach((field, index) => {
      this.updateChartDataByField(chartDataByField, field, index, total);
    });
    return this.getDrawOptionAppliedChartData(Object.values(chartDataByField), total);
  }

  /**
   * @returns {Number}
   */
  getChartDataTotal() {
    return this.periodData.reduce(
      (dataTotal, datum) =>
        dataTotal +
        this.nonGroupedColumns.reduce(
          (metricTotal, column) => metricTotal + Number(datum[column.field]),
          0
        ),
      0
    );
  }

  /**
   * @returns {{priorPeriodData: (*|{}), currentPeriodData: *}}
   */
  getChartData() {
    const currentPeriodData = this.setLegendColor(this.splitCurrentData(false));
    const priorPeriodData = this._hasComparisonData
      ? this.setLegendColor(this.splitCurrentData(true))
      : {};
    return { currentPeriodData, priorPeriodData };
  }

  setLegendColor(dataPrior) {
    dataPrior.forEach((val, index) => {
      val.legendData = {
        fill: color(this.getFillColorPie(index)),
      };
      val.subs.forEach((subs, subIndex) => {
        subs.legendData = {
          fill: color(this.getFillColorPie(subIndex + dataPrior.length)),
        };
      });
    });
    return dataPrior;
  }

  getFillColorPie(index) {
    const { generateColorWithPaletteOverflow } = colorGenerator();
    return generateColorWithPaletteOverflow(this._chartPalette, index);
  }

  splitCurrentData(comparisonStatus = false) {
    if (comparisonStatus) {
      this.periodData = this.priorPeriodData;
    }
    const total = this.getChartDataTotal();

    if (this.nonGroupedColumns.length > 1) {
      return this.getChartDataForMultipleMetrics(total);
    }
    return this.getChartDataForSingleMetric(this.nonGroupedColumns[0]?.field, total);
  }

  /**
   * @returns {string}
   */
  getTooltipTextTemplate() {
    return PieChartDrawOption.SHOW_TOOLTIP;
  }

  /**
   * @returns {string}
   * @private
   */
  getSeriesText() {
    let text = '';
    const { show_labels, show_label_names, show_label_values, show_label_percent, is_normalized } =
      this.getDrawOptions();
    if (!show_labels) {
      return text;
    }
    if (show_label_percent) {
      text += PieChartDrawOption.SHOW_LABEL_PERCENT;
    }
    if (show_label_values) {
      const labelValue = is_normalized
        ? PieChartDrawOption.SHOW_RAW_VALUE
        : PieChartDrawOption.SHOW_VALUE;
      text = text !== '' ? `${labelValue} (${text})` : labelValue;
    }
    if (show_label_names) {
      text =
        text !== ''
          ? `${PieChartDrawOption.SHOW_CATEGORY}: ${text}`
          : PieChartDrawOption.SHOW_CATEGORY;
    }
    return text;
  }

  /**
   * @returns {{any}}
   */
  getSeries() {
    const { label_percent, has_tooltip } = this.getDrawOptions();
    const labelText = this.getSeriesText();
    return {
      value: PieChartDrawOption.SHOW_VALUE_PIE,
      category: PieChartDrawOption.SHOW_METRIC_PIE,
      labelText,
      showTicks: !!labelText,
      showLabels: !!labelText,
      alignLabels: !!labelText,
      seriesType: this.getSeriesType(),
      fillOpacity: this.isComparison ? 0.6 : 1,
      colors: this.getFullChartPalette(),
      tooltipDisabled: !has_tooltip,
      tooltipText: this.getTooltipTextTemplate(),
      isLabelHidden: (hidden, target) => isSmallPercentageInSeries(target, label_percent),
    };
  }

  /**
   * @returns {{any}}
   */
  getChartSeries() {
    return [this.getSeries()];
  }

  /**
   * @returns {{valueLabelTemplate: string}}
   */
  getChartLegend() {
    return {
      ...super.getChartLegend(),
      valueLabelTemplate: PieChartDrawOption.SHOW_RAW_VALUE,
    };
  }

  /**
   * @param isComparison
   */
  setIsComparison(isComparison) {
    this.isComparison = isComparison;
  }

  setPeriodData() {
    this.periodData = this.isComparison ? this.priorPeriodData : this.currentPeriodData;
  }

  setup(metadata) {
    super.setup();
    this.setIsComparison(metadata.isComparison);
    this.setPeriodData();
  }

  /**
   * @returns {null|Array}
   */
  getChartTitles() {
    return this.canUndoDrillDown() ? [this.getUndoDrillDownLabel()] : null;
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    const { depth, angle } = this.getDrawOptions();
    return {
      ...super.getChartConfigProperties(),
      labels: this.hasComparison() ? [this.getPeriodLabel()] : null,
      depth,
      angle,
      cursorOverStyle: this.getCursorOverStyle(),
      isInlineDrillDown: this.isInlineDrillDown(),
      widgetData: this._widget,
      comparisonEnabled: this.isComparison,
    };
  }

  isInlineDrillDown() {
    return this.isMultiGrouped() && this.getGroupedColumns().length > 1;
  }

  /**
   * @param isDrillDownNotApplied
   * @returns {boolean}
   */
  canDrillDown(isDrillDownNotApplied = true) {
    return (
      super.canDrillDown() &&
      (this.getGroupByIndexOnDrillDown() !== this.currentGroupByIndex || isDrillDownNotApplied)
    );
  }

  /**
   * @param event
   * @returns {DrillDownConfig}
   */
  drillDown(event) {
    const categoryIndex = dataContext(event)?.index;
    this.setCurrentGroupByIndex(this.getGroupByIndexOnDrillDown());
    const drillDownConfig = this.getDrillDownConfig(categoryIndex, this.periodData);
    // Below we push and return last element in drillDownConfigStack
    this.drillDownConfigStack.push(drillDownConfig);
    return drillDownConfig;
  }
}
