'use strict';

import angular from 'angular';
import _ from 'lodash';

import {
    WidgetBuilderConstants,
    $WidgetBuilderEvents
} from "coreModules/design/widget/builder/widget.builder.constants";

import {WeeklyStartDay, WidgetSize, ChartPlotType} from "coreModules/design/widget/design.widget.constants";
import {ColumnFormat} from "coreModules/shared/scripts/app.constants";
import WidgetBuilderUtilService from "coreModules/design/widget/builder/base/WidgetBuilderUtilService";
import {WidgetType} from '../../design.widget.constants';
import { DashboardEventType } from "../../../../../../../grok/src/modules/ta/dashboard/dashboard.constants";
import {DrawOption} from "../../../layout/drawoptionpanel/drawoptionpanel.constants";
import $ from "jquery";
import WidgetBuilderConditionalPlotService from "coreModules/design/widget/builder/base/WidgetBuilderConditionalPlotService";

angular.module('widget.builder.services', [])
    .factory('WidgetBuilderUIService', WidgetBuilderUIService)
    .factory('WidgetBuilderService', WidgetBuilderService)
    .value('WidgetBuilderConditionalPlotService', WidgetBuilderConditionalPlotService)
    .value('WidgetBuilderUtilService', WidgetBuilderUtilService);

/**
 * @ngInject
 */
function WidgetBuilderUIService(
    PubSub,
    $SlidePanelEvents,
    SlidePanelFactory
) {
    let uiModel = new WidgetBuilderPanelUIModel();

    let positionRelative = false;

    let getFilteredData;

    return {
        getUIModel: getUIModel,
        resetUIModel: resetUIModel,
        isActive: isActive,
        getCurrentTabIndex: getCurrentTabIndex,
        showPanel: showPanel,
        hidePanel: hidePanel,
        toggleTab: toggleTab,
        isWidgetProductPanelActive: isWidgetProductPanelActive,
        closeProblematicMetricCard: closeProblematicMetricCard,
        toggleAllProblematicMetricCards: toggleAllProblematicMetricCards,
        togglePositionRelativeAbsolute: togglePositionRelativeAbsolute,
        checkAllCardsStatus: checkAllCardsStatus,
        getPositionStatus: getPositionStatus,
        getProblematicMetricData: getProblematicMetricData
    };

    function getUIModel() {
        // DONT modify the returned model in components
        return uiModel;
    }

    function resetUIModel() {
        uiModel = new WidgetBuilderPanelUIModel();
    }

    function isActive() {
        return uiModel.isShowing;
    }

    function getCurrentTabIndex() {
        return uiModel.selectedTabIndex;
    }

    function showPanel() {
        SlidePanelFactory.show(uiModel);
    }

    function hidePanel() {
        SlidePanelFactory.hide(uiModel);
    }

    /**
     * @param index
     */
    function toggleTab(index) {
        uiModel.selectedTabIndex = index;
        SlidePanelFactory.toggleTab(index);
    }

    /**
     * @constructor
     */
    function WidgetBuilderPanelUIModel() {
        let self = this;

        self.panelId = 'widget-builder-panel';
        self.selectedTabIndex = WidgetBuilderConstants.DATA_TAB_INDEX;
        self.isShowing = false;
        self.isLargePanel = true;
        self.showOuterElements = true;
    }

    function isWidgetProductPanelActive() {
        if (window.isNUI && !_.isUndefined($('#product-widget-panel')?.[0])) {
            return $('#product-widget-panel')?.[0].className.includes('active');
        }

        return false;
    }

    function closeProblematicMetricCard(card) {
        card.warning_card_hidden = !card.warning_card_hidden;
    }

    function toggleAllProblematicMetricCards(toggleState){
        const warningCardStatus = !(toggleState === DrawOption.CARD_TEXT_DISPLAY_HIDE);
        getFilteredData = getFilteredData.map(function (card) {
            card.warning_card_hidden = !warningCardStatus;
            return card;
        });
    }

    function togglePositionRelativeAbsolute() {
        positionRelative = !positionRelative;
    }

    function checkAllCardsStatus() {
        return getFilteredData.every(card => card.warning_card_hidden);
    }

    function getPositionStatus() {
        //Based on card's length decide the position
        return (getFilteredData.length <= 2 || positionRelative) ? DrawOption.POSITION_RELATIVE : DrawOption.POSITION_ABSOLUTE;
    }

    function getProblematicMetricData(data, filterObj) {
        //ex: filter object which has values, avoid null
        return getFilteredData = _.filter(data, filterObj);
    }
}

/**
 * @ngInject
 */
function WidgetBuilderService(
    $q,
    PubSub,
    AppFactory,
    $timeout,
    DesignFactory,
    WidgetFactory,
    WidgetUtilService,
    DashboardContextService,
    ChartFactory,
    DataSourceFactory,
    ServiceFactory,
    $WidgetHeaderEvents,
    WidgetBuilderModelFactory,
    WidgetBuilderDataModelFactory,
    ReportStudioTemplateDataService,
    ChartPlotType,
    $PageGenerateThumbnailEvents,
    GeoConfigurationType,
    WidgetFilterFactory,
    $WidgetEvents,
) {

    /**
     * @type {WidgetBuilderPanelState}
     * @private
     */
    let _panelState = WidgetBuilderModelFactory.getWidgetBuilderPanelState();

    /**
     * @type {WidgetBuilderPanelData}
     * @private
     */
    let _panelData = null;

    /**
     * @type {WidgetBuilderModel}
     */
    let _widgetModel = null;

    /**
     * Used to store unused columns when switching widget types
     * @type {array}
     */
    let _tempGroupedColumns = [];

    /**
     * Used to reset edited widget to original state
     *
     * @type {WidgetBuilderModel}
     */
    let _originalWidgetModel = null;

    let liveIntegrationVal = true;

    return {
        getPanelState: getPanelState,
        resetPanelState: resetPanelState,
        setPanelData: setPanelData,
        setIsEditing: setIsEditing,
        getIsEditing: getIsEditing,
        setAsActive: setAsActive,
        getIsActive: getIsActive,
        setHasChanges: setHasChanges,
        setIsFirstCreate: setIsFirstCreate,
        setIsNewWidget: setIsNewWidget,
        getIsNewWidget: getIsNewWidget,
        setIsDataWidget: setIsDataWidget,

        getPanelData: getPanelData,
        setSelectedWidgetType: setSelectedWidgetType,
        getTempGroupedColumns: getTempGroupedColumns,
        setTempGroupedColumns: setTempGroupedColumns,

        getWidgetModel: getWidgetModel,
        isCurrentWidgetInBuildMode: isCurrentWidgetInBuildMode,
        setWidgetModel: setWidgetModel,
        setMediaWidgetModel: setMediaWidgetModel,
        setAdminWidgetModel: setAdminWidgetModel,
        setTitle: setTitle,
        setShowSampleData: setShowSampleData,
        setLiveIntegration: setLiveIntegration,
        setAssignments: setAssignments,
        setLiveFormulas: setLiveFormulas,
        setIsMultiGrouped: setIsMultiGrouped,
        setWidgetType: setWidgetType,
        setTimeGrouping: setTimeGrouping,
        setWeeklyStartDay: setWeeklyStartDay,
        setSelectedGeoCode: setSelectedGeoCode,
        resetGeoCode: resetGeoCode,
        getTimeGroupingQueryParams: getTimeGroupingQueryParams,
        setGroupedColumns: setGroupedColumns,
        setDrawOptions: setDrawOptions,
        setDefaultPlotType: setDefaultPlotType,
        setChartPalette: setChartPalette,
        copyLiveIntegrationValue: copyLiveIntegrationValue,
        restoreHasLiveIntegration: restoreHasLiveIntegration,


        storeOriginalWidgetModel: storeOriginalWidgetModel,
        restoreOriginalWidgetModel: restoreOriginalWidgetModel,
        updateWidgetTypeStates: updateWidgetTypeStates,
        updateSelectedColumnValueStates: updateSelectedColumnValueStates,
        updateDataSourceSelectValues: updateDataSourceSelectValues,
        updateDataSourceSelectedValues: updateDataSourceSelectedValues,
        updateSelectedColumnSelectedValues: updateSelectedColumnSelectedValues,
        updateSelectedBenchmarkColumnSelectedValues: updateSelectedBenchmarkColumnSelectedValues,
        updateGroupedColumnSelectedValues: updateGroupedColumnSelectedValues,
        getMatchingSelectColumn: getMatchingSelectColumn,
        setWidgetAsPreview: setWidgetAsPreview,
        canSelectDataView: canSelectDataView,
        setLibraryWidgetAsPreview: setLibraryWidgetAsPreview,
        updateWidgetDisplay: updateWidgetDisplay,
        updatePreviewWidgetFromData: updatePreviewWidgetFromData,
        saveWidget: saveWidget,
        cancelWidget: cancelWidget,
        refreshDataTab: refreshDataTab,
        refreshDataTabColumns: refreshDataTabColumns,
        refreshStylesTab: refreshStylesTab,
        updateWidgetType: updateWidgetType,
        getColumnColor: getColumnColor,
        getBenchmarkColumnColor: getBenchmarkColumnColor,
        $applySortOrder: $applySortOrder,
        $applyRoundedMetric: $applyRoundedMetric,
        $applyMultiSortOrder: $applyMultiSortOrder,
        $applyYAxisPosition: $applyYAxisPosition,
        $applyBulletShape: $applyBulletShape,
        resetWidgetModel: resetWidgetModel,
        retrieveConnectedServices: retrieveConnectedServices,
        updateWidgetTypeWithoutPlotType: updateWidgetTypeWithoutPlotType,
        canApplyHeapMapGradient: canApplyHeapMapGradient,
        setDataViewId: setDataViewId,
        setLibraryWidgetUsed: setLibraryWidgetUsed,
        resetMapboxLayers: resetMapboxLayers,
        sortGroupedColumns: sortGroupedColumns,
        getUserGroupedSelectedColumn: getUserGroupedSelectedColumn,
        moveSelectedColumn: moveSelectedColumn
    };

    /**
     * @readonly
     * @returns {WidgetBuilderPanelState}
     */
    function getPanelState() {
        return _panelState;
    }

    function resetPanelState() {
        _panelState = WidgetBuilderModelFactory.getWidgetBuilderPanelState();
    }

    /**
     * @param value
     */
    function setAsActive(value) {
        _panelState.isActive = value;
    }

    /**
     * @param value
     */
    function getIsActive(value) {
        return _panelState.isActive;
    }

    /**
     * Setting this value to true means we are not creating a new widget.
     *
     * @param value
     */
    function setIsEditing(value) {
        _panelState.isEditing = value;
    }

    /**
     *
     * @returns {boolean}
     */
    function getIsEditing() {
        return _panelState.isEditing;
    }

    /**
     * @param value
     */
    function setIsDataWidget(value) {
        _panelState.isDataWidget = value;
    }

    /**
     * @param value
     */
    function setHasChanges(value) {
        _panelState.hasChanges = value;
    }

    function resetMapboxLayers() {
        if(_widgetModel?.metadata?.draw_options?.layers) {
            _widgetModel.metadata.draw_options.layers = [];
        }
    }

    /**
     *
     * @param value
     */
    function setIsFirstCreate(value) {
        _panelState.isFirstCreate = value;
    }
    /**
    *
    * @param value
    */
    function setIsNewWidget(value) {
        _panelState.isNewWidget = value;
    }

    /**
     *
     * @returns {boolean}
     */
    function getIsNewWidget() {
        return _panelState.isNewWidget;
    }

    /**
     * @readonly
     * @returns {WidgetBuilderPanelData}
     */
    function getPanelData() {
        return _panelData;
    }

    /**
     * @param widgetType
     * @returns {*}
     */
    function setPanelData(widgetType) {
        _panelData = _panelState.isDataWidget
            ? WidgetBuilderModelFactory.getWidgetBuilderPanelData(widgetType)
            : WidgetBuilderModelFactory.getWidgetBuilderAdminPanelData(widgetType);
    }

    /**
     * @param value
     */
    function setSelectedWidgetType(value) {
        _panelData.selectedWidgetType = value;
    }

    /**
     * @returns {Array}
     */
    function getTempGroupedColumns() {
        return _tempGroupedColumns;
    }

    /**
     * @param columns
     */
    function setTempGroupedColumns(columns) {
        _tempGroupedColumns = columns;
    }

    /**
     * @readonly
     * @returns {WidgetBuilderModel}
     */
    function getWidgetModel() {
        return _widgetModel;
    }

    /**
     * @param widgetId
     * @returns {boolean}
     */
    function isCurrentWidgetInBuildMode(widgetId) {
        if (_panelState.isSaving) {
            // This prevents showing build mode widget while saving
            return false;
        }
        return _widgetModel ? _widgetModel.id === widgetId : false;
    }

    function _resetWidgetModel() {
        _widgetModel = null;
    }

    /**
     * @param model
     */
    function setWidgetModel(model) {
        // Resolve layout_id or report_id based on context
        DashboardContextService.resolveWidgetParentId(model);
        _widgetModel = WidgetBuilderDataModelFactory.getWidgetBuilderModel(model);
    }

    /**
     * @param model
     */
    function setMediaWidgetModel(model) {
        DashboardContextService.resolveWidgetParentId(model);
        _widgetModel = WidgetBuilderDataModelFactory.getMediaWidgetBuilderModel(model);
    }

    /**
     * @param model
     */
    function setAdminWidgetModel(model) {
        // Resolve layout_id or report_id based on context
        DashboardContextService.resolveWidgetParentId(model);
        _widgetModel = WidgetBuilderDataModelFactory.getAdminWidgetBuilderModel(model);
    }

    /**
     * @param type
     */
    function setWidgetType(type) {
        _widgetModel.type = type;
        if(_widgetModel.metadata.type){
            _widgetModel.metadata.type = type;
        }
    }

    /**
     * @param title
     */
    function setTitle(title) {
        _widgetModel.title = title;
    }

    /**
     * @param columns
     */
    function setGroupedColumns(columns) {
        _widgetModel.metadata.data_columns.grouped = columns;
    }

    /**
     * @param columns
     */
    function setSelectedColumns(columns) {
        _widgetModel.metadata.data_columns.selected = columns;
    }

    /**
     * @param columns
     */
    function setBenchmarkColumns(columns) {
        _widgetModel.metadata.data_columns.benchmarks = columns;
    }

    /**
     * @param timeGrouping
     */
    function setTimeGrouping(timeGrouping) {
        _widgetModel.metadata.time_grouping = timeGrouping;
    }

    /**
     * @param startDay
     */
    function setWeeklyStartDay(startDay) {
        _widgetModel.metadata.weekly_start_day = startDay;
    }

    /**
     * @returns {{}}
     */
    function getTimeGroupingQueryParams() {

        var timeGroupingQueryParam = { service_id: _widgetModel.metadata.data_source.id, has_live_integration: _widgetModel.has_live_integration};
        if (!_.isEmpty(ReportStudioTemplateDataService.getReport())) {
            const reportLanguage = ReportStudioTemplateDataService.getReportLanguage();
            _.assign(timeGroupingQueryParam, {lang: reportLanguage});
        }
        return DataSourceFactory.isServiceDataSourceType(_widgetModel.metadata.data_source.type)
            ? timeGroupingQueryParam
            : {};
    }

    /**
     * Set the selected geo code in the metadata when the user select the country from the dropdown
     *
     * @param selectedGeoOption
     */
    function setSelectedGeoCode(selectedGeoOption) {
        _widgetModel.metadata.geo_code = selectedGeoOption.map;
        // map_id is null for enterprise if it is world, though saving map id as world wont break anything.
        _widgetModel.metadata.map_id = selectedGeoOption.map !== 'world' ? selectedGeoOption.id.toLowerCase() : null;
        // resetting the zoom factors to load the new map
        _widgetModel.metadata.map_zoom = {};
    }

    /**
     * Reset all geo variables
     */
    function resetGeoCode() {
      _widgetModel.metadata.geo_code = null;
      _widgetModel.metadata.map_id = null;
      _widgetModel.metadata.map_zoom = {};
    }

    /**
     * @param value
     */
    function setShowSampleData(value) {
        _widgetModel.metadata.draw_options.show_sample_data = value;
    }

    /**
     * @param value
     */
    function setLiveIntegration(value) {
        _widgetModel.has_live_integration = value;
    }

    /**
     * @param value
     */
    function setAssignments(value) {
        _widgetModel.assignments = value;
    }

    /**
     * @param formulaFields
     * @param formulas
     */
    function setLiveFormulas(formulaFields, formulas) {
        _widgetModel.metadata.live_formula_fields = formulaFields;
        _widgetModel.metadata.live_formulas = formulas;
    }

    /**
     * @param value
     */
    function setIsMultiGrouped(value) {
        _widgetModel.metadata.is_multi_grouped = value;
    }

    /**
     * @returns {*|boolean}
     */
    function getIsMultiGrouped() {
        return _widgetModel.metadata.is_multi_grouped;
    }

    /**
     * @param drawOptions
     */
    function setDrawOptions(drawOptions) {
        _widgetModel.metadata.draw_options = drawOptions;
    }

    /**
     * @param drawOptions
     */
    function updateDrawOptions(drawOptions = {}) {
        _widgetModel.metadata.draw_options = {
            ..._widgetModel.metadata.draw_options,
            ...drawOptions,
        }
    }

    /**
     * @private
     */
    function _setShowTotalRowDrawOptionToFalse() {
        _widgetModel.metadata.draw_options.show_total_row = false;
    }

    /**
     * @param widgetType
     */
    function setDefaultPlotType(widgetType) {
        _widgetModel.metadata.draw_options.plot_type = ChartFactory.getDefaultPlotType(widgetType);
    }

    /**
     * @param dataViewId
     */
    function setDataViewId(dataViewId) {
        _widgetModel.data_view_id = dataViewId;
    }

    /**
     * @param currentChartPalette
     * @param newColor
     * @param paletteIndex
     */
    function setChartPalette(currentChartPalette, newColor, paletteIndex) {
        _widgetModel.metadata.chart_palette = currentChartPalette;
        _widgetModel.metadata.chart_palette[paletteIndex] = newColor;
    }

    function copyLiveIntegrationValue(val) {
        liveIntegrationVal = angular.copy(val);
    }

    function restoreHasLiveIntegration() {
        return liveIntegrationVal;
    }

    /**
     * @param widgetModel
     */
    function storeOriginalWidgetModel(widgetModel) {
        _originalWidgetModel = angular.copy(widgetModel);
    }

    function restoreOriginalWidgetModel() {
        return angular.copy(_originalWidgetModel);
    }

    /**
     * @param selectedDataSource
     */
    function updateWidgetTypeStates(selectedDataSource) {
        _.each(_panelData.widgetTypes, function(widgetType) {
            if (WidgetUtilService.isGeoChart(widgetType.id)) {
                widgetType.is_hidden = !selectedDataSource.data_view_is_geo
                    || !DataSourceFactory.dataSourceContainsServices(selectedDataSource.type);
            }
        });
    }

    /**
     * @param {SelectedColumnsSelect} columnSelect
     */
    function updateSelectedColumnValueStates(columnSelect) {
        _.each(columnSelect.values, function(group) {
            _.each(group.children, function(column) {
                column.disabled = !_shouldBeEnabledInSelect(column);
            });
        });
    }

    /**
     * @param dataSourceSelect
     * @param isLiveIntegration
     */
    function updateDataSourceSelectValues(dataSourceSelect, isLiveIntegration = false) {
         dataSourceSelect.values = WidgetBuilderDataModelFactory.getDataSourceSelectValues(isLiveIntegration).selectValues;
    }

    /**
     *
     * @param {*} datasourceSelect
     */
    function updateDataSourceSelectedValues(datasourceSelect) {
        var newSelectedVal = _.first(_.first(datasourceSelect.values).children);
        datasourceSelect.selectedValue = newSelectedVal;
        datasourceSelect.options.updateSelectValues(newSelectedVal);
    }

    /**
     * @param column
     * @returns {*|boolean}
     * @private
     */
    function _isValidSelectedColumn(column) {
        if (WidgetUtilService.isAdminWidget(_widgetModel.type)) {
            return column.is_selectable;
        }

        if (!WidgetUtilService.isDataGrid(_widgetModel.type) &&
            !WidgetUtilService.isBigNumber(_widgetModel.type) &&
            !WidgetUtilService.isSerialChart(_widgetModel.type)) {
            return column.is_metric || !_.isNil(column.aggregate_query);
        }
        return true;
    }

    /**
     * @param column
     * @returns {*|boolean}
     * @private
     */
    function _shouldBeEnabledInSelect(column) {
        if (_widgetModel.has_live_integration && column.is_groupable) {
            return false;
        }

        if (_widgetModel.has_live_integration && column.is_invalid) {
            return false;
        }

        if (WidgetUtilService.isAdminWidget(_widgetModel.type)) {
            return column.is_selectable;
        }

        if ((WidgetUtilService.isBigNumber(_widgetModel.type) || WidgetUtilService.isBarChart(_widgetModel.type))
            && AppFactory.util.column.isText(column.format)) {
            return false;
        }

        if (WidgetUtilService.isBigNumber(_widgetModel.type) && AppFactory.util.column.isDate(column.format)) {
            return false;
        }

        if (WidgetUtilService.isBubbleChart(_widgetModel.type) && AppFactory.util.column.isNumeric(column.format)) {
            return !AppFactory.util.column.isTime(column.format);
        }

        if (WidgetUtilService.isDataGrid(_widgetModel.type) ||
            WidgetUtilService.isBigNumber(_widgetModel.type) ||
            WidgetUtilService.isBarChart(_widgetModel.type)
        ) {
            return true;
        }

        return column.is_metric || !_.isNull(column.aggregate_query);
    }

    /**
     * @param {SelectedColumnsSelect} columnSelect
     * @param columnSelect
     * @param benchmarkColumnSelect
     */
    function updateSelectedBenchmarkColumnSelectedValues(columnSelect, benchmarkColumnSelect) {
        _.each(benchmarkColumnSelect.selectedValues, function(column) {
            column.locked = false;
            column.color = getBenchmarkColumnColor(
                column,
                benchmarkColumnSelect.selectedValues,
                columnSelect.selectedValues
            );
        });
        benchmarkColumnSelect.options.updateSelectValues(benchmarkColumnSelect.selectedValues);

        setBenchmarkColumns(benchmarkColumnSelect.selectedValues);
    }

    /**
     * @param {SelectedColumnsSelect} columnSelect
     * @param {GroupedColumnsSelect} groupedColumnSelect
     */
    function updateSelectedColumnSelectedValues(columnSelect, groupedColumnSelect) {
        let minColumnCount = 1;
        let gaugeChartIsMultiAxis = false;
        if (WidgetUtilService.isGaugeChart(_widgetModel.type) &&
            WidgetUtilService.isAm5Chart(_widgetModel.type, _widgetModel.metadata?.draw_options?.plot_type)) {
            // multi axis can go down to 1 regardless of type
            gaugeChartIsMultiAxis = _widgetModel.metadata?.draw_options?.plot_type === ChartPlotType.MULTI_AXIS_GAUGE;
            minColumnCount = gaugeChartIsMultiAxis ? 1 : 2;
        }

        // Chart widgets can ONLY contain metric values (i.e. NO text, dates, etc..)
        // Below it has to be in place filter to avoid TA-33693
        let customIndex = 0;
        _.each(columnSelect.selectedValues, (column) => {
            if (_isValidSelectedColumn(column)) {
                columnSelect.selectedValues[customIndex++] = column;
            }
        })
        columnSelect.selectedValues.length = customIndex;

        let _selectedValuesLength = columnSelect.selectedValues.length;

        // Add a metric to gauge chart if there isnt at least 2 metrics
        // (if we're not dealing with an am5 chart in non-compare mode)
        if (WidgetUtilService.isGaugeChart(_widgetModel.type) && !gaugeChartIsMultiAxis) {
            columnSelect.selectedValues = _.filter(columnSelect.selectedValues, (column) => column.format !== ColumnFormat.FORMAT_DATETIME);

            if (columnSelect.selectedValues.length < 2) {
                const children = _.flatMap(columnSelect.values, value => {
                    return value.children
                });
                const values = _.filter(children, {disabled: false});
                columnSelect.selectedValues.push(
                    _.find(values, column => column.format !== ColumnFormat.FORMAT_DATETIME)
                );
            }

            setSelectedColumns(columnSelect.selectedValues);
        }

        if(_widgetModel.has_live_integration && WidgetUtilService.isBigNumber(_widgetModel.type)) {
            // For Ondemand - Big number widgets -> A groupby column cannot be a metric
            columnSelect.selectedValues = _.filter(columnSelect.selectedValues, (column) => !column.is_groupable);
        }

        if(WidgetUtilService.isBigNumber(_widgetModel.type) || WidgetUtilService.isBarChart(_widgetModel.type)) {
            columnSelect.selectedValues = _.filter(columnSelect.selectedValues, (column) => !AppFactory.util.column.isText(column.format));
            setSelectedColumns(columnSelect.selectedValues);
        }

        if (WidgetUtilService.isBubbleChart(_widgetModel.type)) {
            columnSelect.selectedValues = _.filter(columnSelect.selectedValues, column => {
                if (column.is_groupby_name_field) {
                    return true;
                }
                return AppFactory.util.column.isNumeric(column.format) && !AppFactory.util.column.isTime(column.format);
            });
            setSelectedColumns(columnSelect.selectedValues);
        }

        _.each(columnSelect.selectedValues, function(column) {
            column.locked = _selectedValuesLength <= minColumnCount;
            column.color = getColumnColor(column, columnSelect.selectedValues);

            // Grouped column values cannot be removed from selected values in a data grid
            if ((WidgetUtilService.isDataGrid(_widgetModel.type)
                || WidgetUtilService.isSerialChart(_widgetModel.type))
                && _.find(groupedColumnSelect.selectedValues, {groupby_name_field: column.field})) {
                column.locked = true;
            }
        });

        columnSelect.options.updateSelectValues(columnSelect.selectedValues);
    }

    /**
     * @param {SelectedColumnsSelect} columnSelect
     * @param {GroupedColumnsSelect} groupedColumnSelect
     * @param liveIntegration
     */
    function updateGroupedColumnSelectedValues(columnSelect, groupedColumnSelect, liveIntegration = false) {
        let selectedValuesLength = groupedColumnSelect.selectedValues.length;
        let widgetType = WidgetFactory.getWidgetType(_widgetModel.type);
        let dataSource = _widgetModel.metadata.data_source;
        let selectedDefaultGroupbyColumnsLength = _.filter(groupedColumnSelect.selectedValues, {is_default_groupby: true}).length;

        if (_panelData.selectedWidgetType.can_group_by
            && !WidgetUtilService.isFunnelChart(_widgetModel.type)
            && !groupedColumnSelect.selectedValues.length
            && (dataSource.requires_group_by || widgetType.requires_group_by)) {
            const groupedColumn = WidgetBuilderUtilService.findGroupedColumnCandidateFromGroupedSelect(groupedColumnSelect);
            groupedColumn.locked = true;
            groupedColumnSelect.selectedValues.push(groupedColumn);
        }

        _.each(groupedColumnSelect.selectedValues, function(column) {
            let shouldLockColumn = selectedValuesLength === 1;
            if (liveIntegration && column.is_default_groupby) {
                shouldLockColumn = selectedDefaultGroupbyColumnsLength === 1;
            }
            column.locked = shouldLockColumn
                && (dataSource.requires_group_by || widgetType.requires_group_by)
                && !WidgetUtilService.isFunnelChart(_widgetModel.type);

            //Single column in the Dimensions (Group by) for datagrid should not be removed
            if(!column.locked && shouldLockColumn && WidgetUtilService.isDataGrid(_widgetModel.type)) {
                column.locked = true;
            }

            // Every grouped column should be present in the select values
            if ((WidgetUtilService.isDataGrid(_widgetModel.type)
                || WidgetUtilService.isSerialChart(_widgetModel.type))
                && !_.filter(columnSelect.selectedValues, {groupby_id_field: column.field}).length) {
                let matchingColumn = getMatchingSelectColumn(columnSelect, column);
                matchingColumn.locked = true;
                columnSelect.selectedValues.unshift(matchingColumn);
                setSelectedColumns(columnSelect.selectedValues);
            }

            if(WidgetUtilService.isBigNumber(_widgetModel.type)) {
                columnSelect.selectedValues = _.filter(columnSelect.selectedValues, (column) => column.format !== ColumnFormat.FORMAT_DATETIME);
                setSelectedColumns(columnSelect.selectedValues);
            }
            
            if (column.format === ColumnFormat.FORMAT_TIME) {
                _setShowTotalRowDrawOptionToFalse()
            }
        });
        setGroupedColumns(groupedColumnSelect.selectedValues);
        setIsMultiGrouped(getIsMultiGrouped() && selectedValuesLength > 1);


        columnSelect.options.updateSelectValues(columnSelect.selectedValues);
        groupedColumnSelect.options.updateSelectValues(groupedColumnSelect.selectedValues);
    }

    /**
     * Finds the equivalent select column using a grouped column
     *
     * @param {SelectedColumnsSelect} columnSelect
     * @param groupedColumn
     *
     * @returns {*}
     */
    function getMatchingSelectColumn(columnSelect, groupedColumn) {
        let columnsOfType = _.find(columnSelect.values, {key: groupedColumn.groupby_field_format});
        if (!columnsOfType) {
            throw new Error('No matching group by field for: ' + groupedColumn.field);
        }
        let matchingColumn = _.find(columnsOfType.children, {groupby_id_field: groupedColumn.field});
        if (!matchingColumn) {
            matchingColumn = _.find(columnsOfType.children, {groupby_name_field: groupedColumn.field});
            if (!matchingColumn) {
                throw new Error('No matching column in children of columnsOfType for: ' + groupedColumn.field);
            }
        }
        return matchingColumn;
    }

    /**
     * @param dataSourceType
     * @returns {boolean}
     */
    function canSelectDataView(dataSourceType) {
        return DataSourceFactory.dataSourceContainsServices(dataSourceType);
    }

    function getBenchmarkColumnColor(column, selectedBenchmarks, selectedColumns) {
        // color should only be calculated based on actual metric values.
        // group by values are gray
        const selectedMetrics = selectedColumns.filter((selectedColumn) => selectedColumn.is_metric);
        const selectedColumnIndex = selectedMetrics.length;
        const benchmarkColumnIndex = _.indexOf(selectedBenchmarks, column);

        return ChartFactory.getPaletteColor(selectedColumnIndex + benchmarkColumnIndex, _getCurrentPalette());
    }

    /**
     * @param column
     * @param selectedColumns
     * @returns {null}
     */
    function getColumnColor(column, selectedColumns) {
        let index = _getColumnColorIndex(column, selectedColumns);

        switch (_widgetModel.type) {
            case WidgetType.BUBBLECHART:
                return index < 2
                    ? null
                    : ChartFactory.getPaletteColor(index - 2, _getCurrentPalette());
            default:
                return index < 0
                    ? null
                    : ChartFactory.getPaletteColor(index, _getCurrentPalette());
        }
    }

    /**
     * @returns {*}
     * @private
     */
    function _getCurrentPalette() {
        return DashboardContextService.resolveChartPalette(_widgetModel.metadata.chart_palette);
    }

    /**
     * @param widgetModel
     * @returns {boolean}
     */
    function canApplyHeapMapGradient(widgetModel) {
        const isHeatMapSelected = WidgetUtilService.isDataGrid(widgetModel.type) && widgetModel.metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.HEAT_MAP;
        if (isHeatMapSelected && !_widgetModel.metadata?.chart_palette) {
            _widgetModel.metadata.chart_palette = DesignFactory.getCurrentPage().metadata?.chart_palette
                || ReportStudioTemplateDataService.getReport().metadata?.chart_palette;
        }
        return isHeatMapSelected;
    }

    /**
     * @param column
     * @param selectedColumns
     * @returns {number}
     */
    function _getColumnColorIndex(column, selectedColumns) {
        let index = 0;

        if (WidgetUtilService.isChartWidget(_widgetModel.type) || canApplyHeapMapGradient(_widgetModel)) {
            let groupedColumns = _widgetModel.metadata.data_columns.grouped;
            if (groupedColumns.length && WidgetUtilService.isSerialChart(_widgetModel.type)) {
                const removedGroupableColumns = _.filter(selectedColumns, {is_groupable: false});
                selectedColumns = _.filter(removedGroupableColumns, {is_groupby_name_field: false});
            }
            index = _.indexOf(selectedColumns, column);
        } else {
            index = 0;
        }

        return index;
    }

    /**
     * @param data
     * @param widgetModel
     */
    async function setWidgetAsPreview(data, widgetModel) {
        if (data.dataSourceSelect && data.dataSourceSelect.promise) {
            await $q.resolve(data.dataSourceSelect.promise);
        }

        let promisesToResolve = [data.selectedColumnsSelect.promise];
        if (_panelState.isDataWidget) {
            promisesToResolve.push(data.groupedColumnsSelect.promise);
            if (canSelectDataView(data.dataSourceSelect.selectedValue.type)) {
                promisesToResolve.push(data.dataViewSelect.promise);
            }
        }

        // Is adding widget
        if (!widgetModel) {
            return $q.all(promisesToResolve).then(function () {
                updatePreviewWidgetFromData(data);
            });
        } else {
            if (_panelState.isDataWidget) {
                setWidgetModel(widgetModel);
            } else {
                setAdminWidgetModel(widgetModel);
            }
            updateWidgetDisplay();
            return $q.all(promisesToResolve);
        }
    }

    /**
     * @param data
     */
    function updatePreviewWidgetFromData(data) {
        let updatedModel = _panelState.isDataWidget
            ? WidgetBuilderDataModelFactory.getWidgetBuilderModel(_widgetModel || undefined)
            : WidgetBuilderDataModelFactory.getAdminWidgetBuilderModel(_widgetModel || undefined);

        let metadata = updatedModel.metadata;
        metadata.data_columns.selected = data.selectedColumnsSelect ? data.selectedColumnsSelect.selectedValues : [];
        metadata.data_columns.benchmarks = data.selectedBenchmarkColumnsSelect ? data.selectedBenchmarkColumnsSelect.selectedValues : [];

        if (_panelState.isDataWidget) {
            metadata.data_source = WidgetBuilderDataModelFactory.getDataSourceModel(
                data.dataSourceSelect.selectedValue,
                WidgetBuilderDataModelFactory.getDataViewModel(
                    canSelectDataView(data.dataSourceSelect.selectedValue.type)
                        ? data.dataViewSelect.selectedValue
                        : {id: 0})
            );

            metadata.data_columns.grouped = _panelData.selectedWidgetType.can_group_by
                ? data.groupedColumnsSelect.selectedValues
                : [];
            if (metadata.data_columns.grouped && metadata.data_columns.grouped[0])
            {
                const { type } = metadata.data_columns.grouped[0].geo_config || {};
                const { plot_type } = metadata.draw_options;
                const types = [GeoConfigurationType.COUNTRY, GeoConfigurationType.STATE, GeoConfigurationType.COUNTY];
                if (type && types.indexOf(type) !== -1)
                {
                    if (plot_type === ChartPlotType.BUBBLE_MAP) {
                        updatedModel.metadata.draw_options.plot_type = ChartPlotType.HEAT_MAP;
                    }
                }
            }

            if (data.widgetAssignmentSelect) {
                updatedModel.assignments = WidgetBuilderDataModelFactory.getSelectedAssignmentsFromWidget(data.widgetAssignmentSelect);
            }

            setWidgetModel(updatedModel);
        } else {
            setAdminWidgetModel(updatedModel);
        }
        updateWidgetTypeStates(metadata.data_source);
        updateWidgetDisplay();
    }

    /**
     * Will set widget as add/edit model as well as to be displayed in the UI
     * @param {WidgetBuilderModel} model
     */
    function setLibraryWidgetAsPreview(model) {

        model.id = _widgetModel.id || WidgetBuilderConstants.NEW_WIDGET_ID;

        if (_panelState.isEditing) {
            model.width = _widgetModel.width;
            model.height = _widgetModel.height;
            model.display_order = _widgetModel.display_order;
        } else {
            model.width = WidgetSize.MAX_WIDTH;
            model.height = WidgetSize.PREVIEW_HEIGHT;
        }

        model.is_predefined = false;
        model.is_in_library = false;
        model.created_from_library = true;
        model.metadata.tags = [];

    }

    function setLibraryWidgetUsed(value) {
        _widgetModel.library_widget_clicked = value;
    }

    var panelPreviousSelectedWidgetType = null;
    function updateWidgetDisplay() {
        _panelState.hasChanges = true;

        // console.log("prev grid theme => ", _widgetModel.metadata.draw_options.grid_theme);
        // console.log("grid type => ", _widgetModel.metadata.draw_options.plot_type);
        if (panelPreviousSelectedWidgetType === ChartPlotType.DEFAULT) {
            _widgetModel.metadata.draw_options.grid_theme = _widgetModel.metadata.draw_options.plot_type === ChartPlotType.DEFAULT;
        } else if (_widgetModel.metadata.draw_options.plot_type === ChartPlotType.DEFAULT) {
            _widgetModel.metadata.draw_options.grid_theme = true;
        }
        panelPreviousSelectedWidgetType = _widgetModel.metadata.draw_options.plot_type;

        if (_panelState.isFirstCreate) {
            _panelState.isFirstCreate = false;
            panelPreviousSelectedWidgetType = _widgetModel.metadata.draw_options.plot_type;
            _widgetModel.metadata.draw_options.grid_theme = _widgetModel.metadata.draw_options.plot_type === ChartPlotType.DEFAULT;
            PubSub.emit($WidgetBuilderEvents.ADD_WIDGET, _widgetModel);
        } else {
            PubSub.emit($WidgetBuilderEvents.UPDATE_WIDGET, _widgetModel);
        }
        _refreshTitle();
    }

     async function _saveFilterSet(newWidget) {
        const datasource = newWidget.metadata.data_source;
        const widgetId = newWidget.id;
        const name = `${datasource.id_name} ${datasource.data_view_name} filter set`;
        const filterSetId = _widgetModel.metadata.filter_set_id || null;
        const filters = _widgetModel.metadata.dynamic.filters;
        const filterModel = WidgetBuilderDataModelFactory.getFilterSetModel({id: filterSetId, name, filters: filters, widget_id: widgetId, datasource, is_live_integration: true});

        const newFilter = await WidgetFilterFactory.updateFilterSet(filterModel);

        newWidget.metadata.dynamic.filters = filters;
        newWidget.metadata.dynamic.is_live_filter = true;
        newWidget.metadata.filter_set_id = newFilter.id;
    }

    async function saveWidget() {
        _endBuildMode();

        if (!_panelState.isEditing) {
            _widgetModel.id = null;
        }

        _panelState.isSaving = true;
        const isEditing = _panelState.isEditing;
        const saveFilterSet = _widgetModel.metadata.dynamic.save_filter || false;
        if (WidgetUtilService.isChartWidget(_widgetModel.type) && _widgetModel.metadata?.draw_options?.show_total_row) {
            _widgetModel.metadata.draw_options.show_total_row = false;
        }
        _sanitizeWidgetModel(_widgetModel);
        WidgetFactory.save(angular.copy(_widgetModel))
            .then(widget => WidgetFactory.getWidget(widget.id, {all: true}))
            .then(async function(newWidget) {

                if (saveFilterSet) {
                    await _saveFilterSet(newWidget, isEditing);
                }

                // If widget was added/edited, it is safe to assume that it can be modified by the same user
                newWidget.can_be_edited = true;
                newWidget.can_be_deleted = true;
                newWidget.can_be_copied = true;

                if (WidgetUtilService.isMediaWidget(newWidget.type)) {
                    setMediaWidgetModel(newWidget);
                    // forces a refresh
                    _widgetModel.metadata.content = null;
                } else {
                    setWidgetModel(newWidget);
                }

                if (newWidget.layout_id) {
                    PubSub.emit($PageGenerateThumbnailEvents.ENQUEUE, { layoutId: newWidget.layout_id, page: DesignFactory.getCurrentPage() });
                }

                if (isEditing) {
                    PubSub.emit($WidgetBuilderEvents.DID_SAVE_WIDGET, newWidget);
                    if (window.isNUI) {
                        PubSub.emit("SegmentEvents", {
                            event: newWidget.type === WidgetType.ACCOUNTMANAGER ? DashboardEventType.EDIT_ADMIN_WIDGET : DashboardEventType.EDIT_DATA_WIDGET,
                            payload: {widget: newWidget, page: DesignFactory.getCurrentPage()}
                        });
                    }
                } else {
                    PubSub.emit($WidgetBuilderEvents.SWAP_WIDGET, {id: WidgetBuilderConstants.NEW_WIDGET_ID, model: _widgetModel});
                    if (window.isNUI) {
                            PubSub.emit("SegmentEvents", {
                                event: newWidget.type === WidgetType.ACCOUNTMANAGER ? DashboardEventType.ADD_ADMIN_WIDGET : DashboardEventType.ADD_DATA_WIDGET,
                                payload: {widget: newWidget, page: DesignFactory.getCurrentPage()}
                            });
                    }
                }
                _resetAll();
            })
            .finally(function() {
                // isSaving flag should reset at the end after competing the save operation either successfully or with error, moving it to finally block
                _panelState.isSaving = false;
            });
    }

    function cancelWidget() {
        _endBuildMode();
        if (_panelState.isEditing) {
            copyLiveIntegrationValue(_widgetModel.has_live_integration)
            _widgetModel = restoreOriginalWidgetModel();
            PubSub.emit($WidgetBuilderEvents.UPDATE_WIDGET, _widgetModel);
        } else {
            PubSub.emit($WidgetBuilderEvents.REMOVE_WIDGET, WidgetBuilderConstants.NEW_WIDGET_ID);
        }
        _resetAll();
    }

    /**
     * Remove unwanted properties before saving
     *
     * @param widgetModel
     * @private
     */
    function _sanitizeWidgetModel(widgetModel) {
        if (WidgetUtilService.isBigNumber(widgetModel.type)) {
            const memoizedGroupedColumns = AppFactory.arrayToMemoizedObj(widgetModel.metadata.data_columns.grouped, 'field');
            widgetModel.metadata.data_columns.selected = _.filter(widgetModel.metadata.data_columns.selected, column => {
                return !memoizedGroupedColumns[column.field]
            });
        }

        if (WidgetUtilService.isBigNumber(widgetModel.type) ||
            WidgetUtilService.isGaugeChart(widgetModel.type)) {
            widgetModel.metadata.data_columns.grouped = [];
        }

        if (!WidgetUtilService.isConditionalPlotType(widgetModel.metadata.draw_options)) {
            widgetModel.metadata.user_conditional_columns = [];
        }

        if (!WidgetUtilService.isEmbeddedSparklinesPlotType(widgetModel.metadata.draw_options)) {
            widgetModel.metadata.fields_to_embed_sparklines = [];
        }
    }

    function _endBuildMode() {
        setAsActive(false);
        setHasChanges(false);
        PubSub.$emit($WidgetHeaderEvents.REFRESH_TITLE + _widgetModel.id);
    }

    function resetWidgetModel() {
        _resetWidgetModel();
    }

    /**
     * @param selectedEntity
     *
     * @returns {void|url|*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    async function retrieveConnectedServices() {
        const dataSourceParams = _.assign({
            is_connected: true
        }, DashboardContextService.resolvePageEntityQueryParam());

        const services = await ServiceFactory.getServices(dataSourceParams);
        return services.plain();
    }

    function refreshDataTab() {
        PubSub.$emit($WidgetBuilderEvents.RESET_DATA_TAB);
        PubSub.$emit($WidgetBuilderEvents.INIT_DATA_TAB);
    }

    /**
     * @param {WidgetTypeModel} widgetType
     */
    function refreshDataTabColumns(widgetType) {
        PubSub.emit($WidgetBuilderEvents.UPDATE_COLUMN_SELECTED_VALUES);
        PubSub.emit($WidgetBuilderEvents.UPDATE_COLUMN_VALUE_STATES);
        PubSub.emit($WidgetBuilderEvents.UPDATE_COLUMN_SELECTIONS, widgetType);
    }

    function refreshStylesTab() {
        PubSub.emit($WidgetBuilderEvents.RESET_STYLES_TAB);
        PubSub.$emit($WidgetBuilderEvents.INIT_STYLES_TAB);
    }

    /**
     * @param {WidgetTypeModel} widgetType
     */
    function updateWidgetType(widgetType) {
        PubSub.emit($WidgetBuilderEvents.UPDATE_WIDGET_TYPE, widgetType);
    }

    function updateWidgetTypeWithoutPlotType(widgetType) {
        PubSub.emit($WidgetBuilderEvents.UPDATE_WIDGET_TYPE_WITHOUT_PLOT, widgetType);
    }

    function _resetTabs() {
        PubSub.emit($WidgetBuilderEvents.RESET_DATA_TAB);
        PubSub.emit($WidgetBuilderEvents.RESET_STYLES_TAB);
        PubSub.emit($WidgetBuilderEvents.RESET_LIBRARY_TAB);
        PubSub.emit($WidgetBuilderEvents.CLOSE_LIVE_FILTERS_SECTION);
        PubSub.emit($WidgetBuilderEvents.CLOSE_LIVE_FORMULAS_SECTION);
    }

    function _resetAll() {
        _resetWidgetModel();
        _resetTabs();
    }

    /**
     * @private
     */
    function _refreshTitle() {
        PubSub.$emit($WidgetHeaderEvents.REFRESH_TITLE + _widgetModel.id);
    }

    /**
     * When in edit mode, $applySortOrder will apply sort for selected column and rebuild the preview widget
     * @param column
     * @legacy
     */
    function $applySortOrder(column) {
        // On rebuild always require a little buffer
        $timeout(function() {
            WidgetFactory.$getScope(_widgetModel.id, ReportStudioTemplateDataService.getIsActive()).$broadcast('widget:setOrder', column, false);
        }, 0, false);
    }

    function $applyRoundedMetric(column) {
        const index = _widgetModel.metadata.rounded_metrics.indexOf(column.field)
        if (index !== -1) {
           _widgetModel.metadata.rounded_metrics.splice(index, 1);
        } else {
            _widgetModel.metadata.rounded_metrics.push(column.field);
        }

        $timeout(function() {
            WidgetFactory.$getScope(_widgetModel.id, ReportStudioTemplateDataService.getIsActive()).$broadcast($WidgetEvents.WIDGET_REBUILD);
        }, 0, false);
    }

    /**
     * When in edit mode, $applyMultiSortOrder will apply multi sort for selected column and rebuild the preview widget
     * @param column
     * @legacy
     */
    function $applyMultiSortOrder(column) {
        // On rebuild always require a little buffer
        $timeout(function() {
            WidgetFactory.$getScope(_widgetModel.id, ReportStudioTemplateDataService.getIsActive()).$broadcast('widget:setOrder', column, true);
        }, 0, false);
    }

    /**
     * When in edit mode, $applyYAxisPosition will apply y-axis position for selected column and rebuild the preview widget
     * @param column
     * @legacy
     */
    function $applyYAxisPosition(column) {
        $timeout(function() {
            WidgetFactory.$getScope(_widgetModel.id, ReportStudioTemplateDataService.getIsActive()).$broadcast('widget:setYAxisPosition', column);
        }, 0, false);
    }

    function $applyBulletShape() {
        $timeout(function() {
            updateWidgetDisplay();
        }, 0, false);
    }

    function sortGroupedColumns(widget) {
        if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options)) {
            const selectedValues = getSelectedValues(widget);
            const newUserGroupedColumns = [];
            let alreadySortedColumns = [];

            widget.metadata.data_columns.selected.forEach((selectedColumn, index) => {
                const selectedColumnField = selectedColumn.id || selectedColumn.groupby_id_field;
                const userGroupedSelectedColumn = getUserGroupedSelectedColumn(widget, selectedValues, selectedColumnField);

                if (userGroupedSelectedColumn) {
                    handleUserGroupedColumn(widget, userGroupedSelectedColumn, newUserGroupedColumns, alreadySortedColumns, index);
                }
            });

            updateWidgetMetadata(widget, newUserGroupedColumns);
        }
    }

    function getUserGroupedSelectedColumn(widget, selectedValues, selectedColumnField) {
        return selectedValues.find(selectedValue => selectedValue.id === selectedColumnField);
    }

    function handleUserGroupedColumn(widget, userGroupedSelectedColumn, newUserGroupedColumns, alreadySortedColumns, index) {
        const tempUserGroupedColumn = getTempUserGroupedColumn(widget, userGroupedSelectedColumn);

        // Sort tempUserGroupedColumn values according to data_columns.selected
        tempUserGroupedColumn.values = _.sortBy(tempUserGroupedColumn.values, item => {
            return widget.metadata.data_columns.selected.findIndex(aItem => Object.values(aItem)[0] === item);
        });

        tempUserGroupedColumn.values.forEach((value, valueIndex) => {
            if (value !== userGroupedSelectedColumn.id && !alreadySortedColumns.includes(value)) {
                moveSelectedColumn(widget, value, index + valueIndex);
            }
            alreadySortedColumns.push(value);
        });

        if (!areObjectsEqual(tempUserGroupedColumn, newUserGroupedColumns[newUserGroupedColumns.length - 1])) {
            newUserGroupedColumns.push(tempUserGroupedColumn);
        }
    }

    function updateWidgetMetadata(widget, newUserGroupedColumns) {
        widget.metadata.user_grouped_columns = newUserGroupedColumns;
    }

    function getSelectedValues(widget) {
        let selectedValues = [];

        widget.metadata.user_grouped_columns.forEach(userGroupedColumn => {
            const updatedGroupName = userGroupedColumn.name.replace(/ /g, "_");

            userGroupedColumn.values.forEach(value => {
                selectedValues.push({ id: value, groupby_id_field: value, group_name: updatedGroupName, name: userGroupedColumn.name });
            });
        });

        return selectedValues.slice().reverse();
    }

    function getTempUserGroupedColumn(widget, userGroupedSelectedColumn) {
        return angular.copy(widget.metadata.user_grouped_columns.find(column => column.values.includes(userGroupedSelectedColumn.id)));
    }

    function areObjectsEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    function moveSelectedColumn(widget, value, targetIndex) {
        const selectedColumnIndex = widget.metadata.data_columns.selected.findIndex(selectedColumn => selectedColumn.field === value);

        if (selectedColumnIndex !== -1) {
            const copyElement = angular.copy(widget.metadata.data_columns.selected[selectedColumnIndex]);
            widget.metadata.data_columns.selected.splice(selectedColumnIndex, 1);
            widget.metadata.data_columns.selected.splice(targetIndex, 0, copyElement);
        }
    }

}
