import { computed, effectScope, onScopeDispose, shallowRef, ref, watch } from 'vue';
import { debounce, isEmpty, isNil } from 'lodash';
import { Root } from '@amcharts/amcharts5';
import { getters } from '@/modules/core/app/helpers/store';
import { ThemeType } from '@/modules/core/app/constants/app.constants';
import { DarkTheme } from '@/modules/core/charts/am5/themes/DarkTheme';
import { LightTheme } from '@/modules/core/charts/am5/themes/LightTheme';
import { Exporting } from '@amcharts/amcharts5/plugins/exporting';
import { ChartPlace, Constant, ValueFormatters } from '@/modules/core/charts/am5/charts.constants';

/**
 * The chart context contains all the information related to current chart like config, chart object and
 * the root object of the amCharts5, etc.
 *
 * We can pass context object to any function and use each information related to chart via context.
 */
export function ChartContext(rawConfig) {
  const el = shallowRef(null);
  const root = shallowRef(null);
  const chart = shallowRef(null);
  const exporter = shallowRef(null);
  const config = ref(rawConfig);
  /**
   * @type {function[]}
   */
  const onBindEventFunctions = [];
  const onFrameEndedEventFunctions = [];

  const isDataEmpty = computed(() => !isNil(config.value.data) && isEmpty(config.value.data));

  const isRotated = computed(() => config.value.isRotated);

  const isDarkTheme = computed(
    () =>
      !config.value.isExporting &&
      config.value.get(Constant.CHART_PLACE) !== ChartPlace.REPORT &&
      getters.session.getUserSettings().themeType === ThemeType.DARK
  );

  const primaryColor = computed(() => getters.session.getUserSettings().primaryThemeColor);

  const benchmarksEnabled = getters.dashboardMetadata.getIsBenchmarksEnabled();

  function dispose() {
    disposeCharts();
    root.value?.dispose();
    root.value = null;
  }

  function init(domElement) {
    el.value = domElement;
    dispose();
    root.value = Root.new(el.value, {
      tooltipContainerBounds: config.value.isSparkLine
        ? { top: 25, right: 25, bottom: 25, left: 25 }
        : undefined,
      calculateSize() {
        return {
          width: el.value.clientWidth,
          height: el.value.clientHeight,
        };
      },
    });
    root.value.utc = true;
    root.value.events.on(Constant.FRAME_ENDED, debounce(frameEnded, 100));
    setTheme(isDarkTheme.value);
    root.value.numberFormatter.setAll({
      numberFormat: ValueFormatters.SINGLE_NUMBER_FORMAT,
      smallNumberThreshold: 0.001,
    });
    exporter.value = Exporting.new(root.value, {
      backgroundOpacity: config.value.get(Constant.CHART_TRANSPARENCY),
      pngOptions: {
        quality: 1,
        maintainPixelRatio: true,
      },
    });

    bind();
  }

  function refresh(_rawConfig) {
    config.value = _rawConfig;
    if (!el.value) {
      return;
    }
    disposeCharts();
    root.value?.container.children.clear();
    bind();
  }

  function disposeCharts() {
    if (Array.isArray(chart.value)) {
      chart.value.forEach((chartValue) => {
        clearModals(chartValue);
        chartValue.dispose();
      });
    } else {
      if (chart.value) {
        clearModals(chart.value);
      }
      chart.value?.dispose();
    }

    chart.value = null;
  }

  function clearModals(chartValue) {
    const userData = chartValue.get(Constant.USER_DATA, {});
    if (userData.MODAL) {
      userData.MODAL.close();
      userData.MODAL.dispose();
    }
  }

  function setTheme(isDark) {
    root.value.setThemes(isDark ? [DarkTheme.new(root.value)] : [LightTheme.new(root.value)]);
  }

  function bind() {
    onBindEventFunctions.forEach((func) => func());
  }

  function onBind(bindFactory) {
    onBindEventFunctions.push(bindFactory);
  }

  function frameEnded() {
    onFrameEndedEventFunctions.forEach((func) => func());
  }

  function onFrameEnded(frameEndedFactory) {
    onFrameEndedEventFunctions.push(frameEndedFactory);
  }

  onScopeDispose(() => {
    dispose();
  });

  watch(isDarkTheme, (isDark) => {
    setTheme(isDark);
  });

  return {
    el,
    root,
    config,
    chart,
    isDataEmpty,
    isRotated,
    init,
    onBind,
    onFrameEnded,
    exporter,
    isDarkTheme,
    primaryColor,
    refresh: debounce(refresh, 300),
    benchmarksEnabled,
  };
}

/**
 * @returns {function() : ChartContext}
 */
export function createChartContext(config) {
  let initialized = false;
  let state;
  const scope = effectScope(true);
  return function () {
    if (!initialized) {
      state = scope.run(() => ChartContext(config));
      initialized = true;
    }
    return state;
  };
}
