import { find } from 'lodash';
import { WidgetDataService } from '@/modules/ta/widget/services/data/WidgetDataService';

export class ChartWidgetDataService extends WidgetDataService {
  /**
   * @param {DrillDownConfig} drillDownConfig
   */
  setDrillDownConfig(drillDownConfig) {
    this.drillDownConfig = drillDownConfig;
  }

  /**
   * @returns {boolean}
   */
  isDrillingDown() {
    return !!this.drillDownConfig;
  }

  /**
   * @param widget
   * @returns {ApiColumn|*}
   */
  getGroupByColumnWhenDrillingDown(widget) {
    return widget.getGroupedColumns()[this.drillDownConfig.groupByIndex];
  }

  /**
   * @param {Widget} widget
   * @returns {*[]|(ApiColumn|*)[]}
   */
  getGroupByColumnsWhenNotDrillingDown(widget) {
    const groupedColumns = widget.getGroupedColumns();
    return groupedColumns.length ? [groupedColumns[0]] : [];
  }

  /**
   * @override
   *
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setGroupByQueryParam(widget, config) {
    let groupedColumnsToProcess = [];

    if (this.isDrillingDown()) {
      groupedColumnsToProcess = [this.getGroupByColumnWhenDrillingDown(widget)];
      config.params = { ...config.params, ...this.drillDownConfig.queryParams };
    } else {
      const groupedColumns = this.getGroupByColumnsWhenNotDrillingDown(widget);
      groupedColumnsToProcess = groupedColumnsToProcess.concat(groupedColumns);
    }

    this._appendFieldsToGroupByQueryParam(
      groupedColumnsToProcess.map((column) => column.field),
      config
    );

    this.setTimeGroupingQueryParam(widget, config);
  }

  /**
   * @override
   *
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  applySpecificQueryParams(widget, config) {
    this.setSortQueryParam(widget, config);
    this.setGroupByQueryParam(widget, config);
    this.setCumulativeQueryParam(widget, config);
  }

  /**
   * @override
   *
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setTimeGroupingQueryParam(widget, config) {
    super.setTimeGroupingQueryParam(widget, config);

    if (config.params.timegrouping) {
      return;
    }

    const dateField = find(widget.metadata.data_columns.grouped, { is_primary_date_field: true });
    if (this.isDrillingDown() && dateField in this.drillDownConfig.queryParams) {
      config.params.timegrouping = widget.metadata.time_grouping;
      this.setWeeklyStartDayQueryParam(widget, config);
    }
  }

  setCumulativeQueryParam(widget, config) {
    if (widget.metadata.cumulative_columns?.length) {
      config.params.cumulative = widget.metadata.cumulative_columns.join(',');
    }
  }
}
