export const AuditActions = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  PERFORM: 'perform',
};

export const AuditLogsRouteNames = {
  AUDIT_LOGS: 'auditlogs',
};

export const AuditLogsEntityTypes = {
  USERS: 'users',
  CLUSTERS: 'clusters',
  CLIENTS: 'clients',
  CLIENTGROUPS: 'clientgroups',
  ROLES: 'roles',
  CUSTOMTRANSLATIONS: 'customtranslations',
  DASH: 'dash',
  GRANULARPERMISSIONS: 'granularpermissions',
  CALCULATIONS: 'calculations',
  EXECUTIVESUMMARIES: 'executive_summary_items',
  SCHEDULEDREPORTS: 'scheduledreports',
};
