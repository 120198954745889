import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { ChatGptSummary } from '@/modules/ta/chatgptsummaries/models/ChatGptSummary';

export class ChatGptSummariesResource extends BaseEntityResource {
  constructor() {
    super(`${RouteGroup.CHATGPTSUMMARIES}`, ChatGptSummary);
  }

  /**
   * @param formData
   * @returns {Promise<AxiosResponse<any>>}
   */
  async generateChatGptSummary(formData = {}) {
    return this.apiHelper.post(`${RouteGroup.CHATGPTSUMMARIES}/generatesummary`, formData);
  }

  /**
   * @param formData
   * @returns {Promise<AxiosResponse<*>>}
   */
  async generateDalleSummary(formData = {}) {
    return this.apiHelper.post(`${RouteGroup.CHATGPTSUMMARIES}/generatedallesummary`, formData);
  }

  /**
   * @param id
   * @param formData
   * @returns {Promise<AxiosResponse<any>>}
   */
  async addExecutiveSummaryItem(formData = {}) {
    return this.apiHelper.post(`${RouteGroup.CHATGPTSUMMARIES}/executivesummary/item`, formData);
  }

  /**
   * @param id
   * @param formData
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getLatestResponse(id, formData) {
    return this.apiHelper.post(`${RouteGroup.CHATGPTSUMMARIES}/summary/latest/${id}`, formData);
  }

  /**
   * @param id
   * @param formData
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getLatestDalleResponse(id, formData) {
    return this.apiHelper.post(
      `${RouteGroup.CHATGPTSUMMARIES}/summary/latest/dalle/${id}`,
      formData
    );
  }

  /**
   * @param summaryId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async exportWidgetData(summaryId = {}) {
    await this.download(`export/${summaryId}/widgetdata`);
  }

  /**
   * @param summaryId
   * @param formData
   * @returns {Promise<void>}
   */
  async deleteItem(summaryId, formData = {}) {
    await this.apiHelper.post(
      `${RouteGroup.CHATGPTSUMMARIES}/summary/delete/${summaryId}`,
      formData
    );
  }
}

export default new ChatGptSummariesResource();
