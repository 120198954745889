import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { BenchmarkLineStyle } from '@/modules/ta/widget/widget.constants';
import { Constant, LineTypes } from '@/modules/core/charts/am5/charts.constants';
import { Bullet, color, Legend } from '@amcharts/amcharts5';
import { useAxesLabels } from '@/modules/core/charts/am5/base/composables/axis/useAxesLabels';
import { useBulletShape } from '@/modules/core/charts/am5/line/composables/useBulletShape';
import { useMetricLabels } from '@/modules/core/charts/am5/bar/composables/useMetricLabels';
import { useMetricLabels as useMetricLabelsLine } from '@/modules/core/charts/am5/line/composables/useMetricLabels';
import { LineSeries, StepLineSeries } from '@amcharts/amcharts5/xy';

export function useBenchmarkSeries(context) {
  const { root, chart, config, benchmarksEnabled } = context();

  const { createXAxis, createYAxis, getXAxes, getYAxes, getXAxis, getYAxis, getSeriesAxesProps } =
    useXYAxes(context);
  const { addLabelToXAxis, addLabelToYAxis, getTextLabelFromAxis } = useAxesLabels(context);
  const { createBulletShape } = useBulletShape(context);
  const { createMetricLabels } = useMetricLabels(context);
  const { createMetricLabels: createMetricLabelsLine } = useMetricLabelsLine(context);

  function addBenchmarkSeries() {
    if (!benchmarksEnabled) {
      return;
    }

    const { benchmarks } = config.value;

    benchmarks.data.forEach((benchmarkSeries) => {
      const { xAxis, yAxis } = getAxes(benchmarkSeries);
      const seriesClass = getSeriesClass(benchmarkSeries.lineType);
      const metricLabelFunction =
        seriesClass.constructor.name === StepLineSeries.name
          ? createMetricLabels
          : createMetricLabelsLine;
      const fieldName = `${benchmarkSeries.prefix} ${benchmarkSeries.name}`;
      const seriesAxesProps = getSeriesAxesProps({
        axisIndex: 0,
        categoryAxisIndex: 0,
        category: benchmarkSeries.category,
        value: benchmarkSeries.value,
      });

      const series = chart.value.series.push(
        seriesClass.new(root.value, {
          xAxis,
          yAxis,
          name: fieldName,
          connect: false,
          stroke: color(benchmarkSeries.color),
          fill: color(benchmarkSeries.color),
          opacity:
            benchmarkSeries.lineStyle === BenchmarkLineStyle.NONE ? 0 : benchmarkSeries.opacity,
          legendLabelText: fieldName,
          legendValueText: `{${benchmarkSeries.value}.formatNumber('#.##a')}`,
          ...seriesAxesProps,
        })
      );

      // dashed / dotted need the stroke settings changed
      if (benchmarkSeries.lineStyle !== BenchmarkLineStyle.NONE) {
        series.strokes.template.setAll({
          ...getStrokeOptions(benchmarkSeries.lineStyle),
        });
      }

      if (benchmarkSeries.showBullets) {
        series.bullets.push(() =>
          Bullet.new(root.value, {
            sprite: createBulletShape(series, benchmarkSeries.bullet),
          })
        );
      }

      const axesIndex = config.value.isRotated ? 0 : 1;

      series.set(Constant.USER_DATA, {
        color: benchmarkSeries.color,
        fill: benchmarkSeries.color,
        labelFill: benchmarkSeries.color,
        lineType: Constant.BENCHMARK,
        value: Object.values(seriesAxesProps)[axesIndex],
        dataItemFormat: benchmarkSeries.dataItemFormat,
        showMetricLabels: config.value.series[0].showMetricLabels,
        strokeWidth: 2,
        strokeOpacity: benchmarkSeries.opacity,
        opacity: benchmarkSeries.opacity,
        category: benchmarkSeries.category,
      });
      series.data.setAll(benchmarkSeries.data);

      if (config.value.legend.active) {
        addBenchmarkToLegend(series);
      }

      if (config.value.series[0].showMetricLabels) {
        metricLabelFunction(true);
      }
    });
  }

  /**
   * Loop through the provided axes and find the one that matches the benchmark
   */
  function getNonBenchmarkAxis(axes, benchmark) {
    let foundAxis = null;

    axes.each((axis) => {
      const userData = axis.get(Constant.USER_DATA);

      if (userData.titleText === benchmark.name) {
        foundAxis = axis;
      }
    });

    return foundAxis;
  }

  function getAxes(benchmark) {
    let xAxis = getXAxis();
    let yAxis = getYAxis();

    // if data is normalised then we'll need to add an axis
    if (config.value.isNormalizedData) {
      // match up the benchmark with the non-benchmark version of the axis to keep things consistent
      const axesToTest = config.value.isRotated ? getXAxes() : getYAxes();
      const nonBenchmarkAxis = getNonBenchmarkAxis(axesToTest, benchmark);

      // if we have a corresponding axis then we use that one and append "benchmark
      if (nonBenchmarkAxis) {
        const textLabel = getTextLabelFromAxis(nonBenchmarkAxis);
        if (textLabel) {
          textLabel.set(
            Constant.TEXT,
            `${textLabel.get(Constant.TEXT)} [#${benchmark.color};bold]+ ${benchmark.prefix}[/]`
          );
        }

        if (config.value.isRotated) {
          xAxis = nonBenchmarkAxis;
        } else {
          yAxis = nonBenchmarkAxis;
        }
      } else {
        // otherwise we create a new axis for the benchmark data using the value axis is a basis
        const { valueAxis } = config.value;
        const benchmarkValueAxis = { ...valueAxis[0] };

        benchmarkValueAxis.dataItemFormat = benchmark.dataItemFormat;
        benchmarkValueAxis.titleText = `${benchmark.prefix} ${benchmark.name}`;
        benchmarkValueAxis.titleColor = benchmark.color;

        // re-use the category axis and create a new value axis in the correct spot
        // add labels after pushing it into the chart
        if (config.value.isRotated) {
          xAxis = createXAxis(benchmarkValueAxis);
          chart.value.xAxes.push(xAxis);
          addLabelToXAxis(xAxis);
        } else {
          yAxis = createYAxis(benchmarkValueAxis);
          chart.value.yAxes.push(yAxis);
          addLabelToYAxis(yAxis);
        }
      }
    }

    return {
      xAxis,
      yAxis,
    };
  }

  function addBenchmarkToLegend(series) {
    chart.value.children.each((child) => {
      if (child.constructor.name === Legend.name) {
        child.data.push(series);
      }
    });
  }

  function getSeriesClass(lineType) {
    return lineType === LineTypes.LINE ? LineSeries : StepLineSeries;
  }

  function getStrokeOptions(lineStyle) {
    return {
      strokeWidth: 2,
      strokeDasharray: getDashArray(lineStyle),
    };
  }

  function getDashArray(lineStyle) {
    if (lineStyle === BenchmarkLineStyle.DOTTED) {
      return [2, 5];
    }

    if (lineStyle === BenchmarkLineStyle.DASHED) {
      return [10, 5];
    }

    return [];
  }

  return {
    addBenchmarkSeries,
  };
}
