import { DurationFormatter, NumberFormatter } from '@amcharts/amcharts5';
import {
  AxisFormatters,
  LABEL_DURATION_FORMAT,
  TOOLTIP_DURATION_FORMAT,
  ValueFormatters,
} from '@/modules/core/charts/am5/charts.constants';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';

export function useNumberFormatter(context) {
  const { root } = context();
  const { preFixCurrency } = useLabels(context);

  function formatNumber(number, format = ColumnFormat.FORMAT_INTEGER, forceAbbreviation = false) {
    if (format === null) {
      format = ColumnFormat.FORMAT_INTEGER;
    }

    if (format === ColumnFormat.FORMAT_TIME) {
      const durationFormatter = DurationFormatter.new(root.value, {
        durationFormat: forceAbbreviation ? LABEL_DURATION_FORMAT : TOOLTIP_DURATION_FORMAT,
      });
      return number ? durationFormatter.format(number) : '00:00';
    }

    const formatter = NumberFormatter.new(root.value, {});
    const numberFormat = getFormatString(number, format, forceAbbreviation);
    const formattedValue = formatter.format(number, numberFormat);

    if (format === ColumnFormat.FORMAT_CURRENCY) {
      return preFixCurrency(formattedValue);
    }

    return formattedValue;
  }

  function getFormatString(number, format, forceAbbreviation) {
    if (format === null) {
      format = ColumnFormat.FORMAT_INTEGER;
    }

    if (format === ColumnFormat.FORMAT_PERCENT) {
      return ValueFormatters.SINGLE_PERCENT;
    }

    let numberFormat = forceAbbreviation
      ? AxisFormatters[format].numberFormat
      : ValueFormatters[format].numberFormat;
    if (format === ColumnFormat.FORMAT_CURRENCY && number < 1000) {
      numberFormat = ValueFormatters.ABBR_CURRENCY;
    }

    return numberFormat;
  }

  /**
   * Get the fully formatted value to pass through to legendValueText
   * @param placeholder
   * @param format
   * @returns {string}
   */
  function getLegendPlaceholderString(placeholder, format) {
    if (format === ColumnFormat.FORMAT_TIME) {
      return `{${placeholder}}`;
    }

    const formatString = getFormatString(100, format, true);
    let placeholderString = `{${placeholder}.formatNumber('${formatString}')}`;
    if (format === ColumnFormat.FORMAT_CURRENCY) {
      placeholderString = preFixCurrency(placeholderString);
    }

    return placeholderString;
  }

  function getAxisFormatter(format) {
    if (AxisFormatters[format]) {
      return AxisFormatters[format];
    }

    return ValueFormatters[format];
  }

  return {
    formatNumber,
    getAxisFormatter,
    getLegendPlaceholderString,
  };
}
