import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { MetadataState } from '@/modules/ta/dashboard/metadata/metadata.state';
import { getters } from './metadata.getters';
import { mutations } from './metadata.mutations';
import { actions } from './metadata.actions';

VueXHelper.setStoreModule(Module.DASHBOARD_METADATA, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new MetadataState(),
  getters,
  mutations,
  actions,
};
