import { dispatches } from '@/modules/core/app/helpers/store';
import { ChartPlace } from '@/modules/core/charts/am5/charts.constants';
import { WidgetRendererServiceFactory } from '@/modules/core/charts/am5/base/services/WidgetRendererServiceFactory';

export const BaseChartWidgetMethodMixin = {
  methods: {
    async init() {
      dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
      const widget = await this.setDrillDownOptions();
      const palette = widget.metadata.chart_palette ?? this.chartPalette;
      const renderer = WidgetRendererServiceFactory.build(
        widget,
        palette,
        this.hasComparisonData,
        this.isExporting
      );
      const config = renderer.getChartConfig();
      let chartTransparency = 1;
      if (widget.report_id && this.elementMetadata) {
        chartTransparency = this.elementMetadata?.base_options?.background_color ? 1 : 0;
      }

      dispatches.dashboardMetadata.setMetadataState({
        benchmarksEnabled: this.isBenchmarksEnabled,
      });

      config.setAll({
        chartPlace: widget.report_id ? ChartPlace.REPORT : ChartPlace.DASHBOARD,
        clientCurrencySymbol: this.clientCurrencySymbol,
        chartTransparency,
        widget,
      });
      this.chartConfig = config;
    },
    async setIdle() {
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
  },
};
