export const AppMenu = {
  ADMIN: 'admin',
  ANALYZE: 'analyze',
  CONNECT: 'connect',
  DATA: 'data',
  HOME: 'home',
  MANAGE: 'manage',
  RAVEN: 'zest',
  REPORT: 'ripley',
  SHARE: 'share',
  TAP_ORDERS: 'tap_orders',
  TRANSFORM: 'transform',
  SHEETS: 'sheets',
  FAVORITES: 'favorites',
};

export const NavGroup = {
  DATA_SOURCES: 'datasources',
  DRILLDOWNS: 'drilldowns',
  DASHBOARDS: 'all_dashboards',
  DATA_GROUPING: 'datagrouping',
  ORGANIZATION: 'organization',
  ACCOUNTS: 'accounts',
  AUTOMAPPING: 'automapping',
  REPORT_ENHANCEMENTS: 'report_enhancements',
  ONBOARDING: 'onboarding',
};

export const CustomNavRoutes = {
  PLANNER: {
    url: 'https://tapclicks.mediaplanner.adportal.io',
    target: '_blank',
  },
};

export const UPDATE_NAV_ITEM = 'update_nav_item';
export const CHANGE_ROUTE_TO_DASHBOARD = 'change_route_to_dashboard';

export const UN_IMPERSONATE_USER = 'un_impersonate_user';
