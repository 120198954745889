import axios from 'axios';
import { isArray, random } from 'lodash';
import { apiAxios, getBaseUrlRoot } from '@/modules/core/app/helpers/AxiosHelper';
import PusherService from '@/modules/core/app/services/PusherService';
import { WorkerManager } from '@/modules/core/app/models/WorkerManager';
import { TimeoutError } from '@/modules/core/app/models/TimeoutError';
import { WorkerManagerError } from '@/modules/core/app/models/WorkerManagerError';

export const workerAxios = axios.create({
  baseURL: `${getBaseUrlRoot()}/server/api/`,
  withCredentials: true,
});

/**
 *
 * @param response
 * @return {Promise<*>}
 */
export const workerResponseInterceptor = async (response) => {
  // throw an error in case error is set
  if (response?.error) {
    throw new WorkerManagerError(response.error);
  }
  // process valid response
  if (response?.data?.data) {
    return handleApiResponse(response.data?.data, response.config?.workerOptions);
  }
  // just return response
  return response?.data || response;
};

/**
 *
 * @param response
 * @param options {WorkerManagerOptions}
 * @param pollCount
 * @return {Promise<*>}
 */
const handleApiResponse = (response, options, pollCount = 0) => {
  const workerManager = WorkerManager.factory({ ...(response || {}), options: options || {} });

  if (workerManager.isLoading()) {
    return waitForPusherEvent(workerManager.job_id, workerManager.options, pollCount);
  }

  if (workerManager.isError()) {
    throw workerManager.getError();
  }

  if (workerManager.isSuccess()) {
    return workerManager.getOutput();
  }

  // return plain response (response.data) in all other cases
  return response;
};

/**
 *
 * @param job_id
 * @param options {WorkerManagerOptions}
 * @param pollCount
 * @return {Promise<*>}
 */
const waitForPusherEvent = async (job_id, options, pollCount) => {
  const { maxPollCount, event, pollTimeout, statusUrl } = options;

  if (pollCount >= maxPollCount) {
    throw new TimeoutError(i18n.$t('Request timed out'));
  }

  /**
   * Add exponential backoff for retry if
   * timeout array is passed
   */
  let pusherPollTimeOut;
  if (isArray(pollTimeout)) {
    const pollTimeoutLength = pollTimeout.length;
    const timeoutIndex = pollCount < pollTimeoutLength ? pollCount : pollTimeoutLength - 1;
    pusherPollTimeOut = pollTimeout[timeoutIndex];
  } else {
    pusherPollTimeOut = pollTimeout;
  }

  /**
   * Adding delay between different poll requests to
   * prevent processing large no of job request at once.
   */
  pusherPollTimeOut += random(10, 1000);

  /**
   * ignore timeout errors when waiting for the event
   * by catching promise rejection
   */
  await PusherService.waitForEvent(event, pusherPollTimeOut, (data) => data.jobId === job_id).catch(
    () => {}
  );

  const data = await apiAxios.get(statusUrl, { params: { jobid: job_id } });
  return handleApiResponse(data, options, pollCount + 1);
};
