import { isNil, isNull, find, isEmpty } from 'lodash';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import { WidgetSampleDataResource } from '@/modules/ta/widget/resources/WidgetSampleDataResource';
import { DateRange } from '@/modules/core/daterange/models/DateRange';
import {
  ApiDataFields,
  DrawOption,
  TimeGrouping,
  WidgetData,
  WidgetState,
  WeeklyStartDay,
  WidgetType,
  WidgetComparisonOptions,
  WidgetPlotTypeOptions,
} from '@/modules/ta/widget/widget.constants';
import { OrderBy } from '@/modules/core/app/constants/data.constants';
import DateRangeService from '@/modules/core/daterange/services/DateRangeService';
import { WidgetProbeDataService } from '@/modules/ta/widget/services/data/WidgetProbeDataService';
import { CurrentDateRangeConfig } from '@/modules/core/daterange/models/CurrentDateRangeConfig';
import { MomentDateFormat } from '@/modules/core/daterange/daterange.constants';

export class WidgetDataService extends BaseService {
  /**
   * @param {WidgetDataResource} resource
   */
  constructor(resource) {
    super();
    this.resource = resource;
    this.widgetSampleDataResource = new WidgetSampleDataResource();
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @returns {Promise<GenericDataModel[]|DataGridData|GenericDataModel>}
   */
  async getData(widget, config) {
    this.prepareRequest(widget, config);
    config.context.applyContext(widget, config);
    if (this.isDataCached(widget)) {
      return this.retrieveCachedData(widget);
    }
    return this.postProcessData(await this.retrieveData(widget, config));
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @protected
   */
  prepareRequest(widget, config) {
    this.setDateRangeQueryParam(widget, config);
    this.setFilterQueryParam(widget, config);
    this.setFieldsQueryParam(widget, config);

    this.setShowEmptyDatesQueryParam(widget, config);

    this.applySpecificQueryParams(widget, config);

    this.setWidgetRequestQueryParam(widget, config);
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @returns {Promise<GenericDataModel>}
   */
  async retrieveData(widget, config) {
    return this.useSampleData(widget, config)
      ? this.widgetSampleDataResource.getWidgetData(widget, config.params)
      : this.resource.getWidgetData(widget, config.params);
  }

  /**
   * @param {Widget} widget
   * @returns {boolean}
   */
  isDataCached(widget) {
    return !!widget.cached_data;
  }

  /**
   * @param {Widget} widget
   * @returns {GenericDataModel|GenericModel}
   */
  async retrieveCachedData(widget) {
    return widget.cached_data;
  }

  /**
   * @param widget
   * @param config
   * @returns {Promise<DateRange|boolean>}
   */
  async probeData(widget, config) {
    config.params.sample = 1;
    const probeDataService = new WidgetProbeDataService();
    probeDataService.setDateRangeQueryParam(widget, config);
    probeDataService.setSortQueryParam(widget, config);
    probeDataService.setFieldsQueryParam(widget, config);
    this.setFilterQueryParam(widget, config);
    probeDataService.setGroupByQueryParam(widget, config);
    probeDataService.setTimeGroupingQueryParam(widget, config);
    this.setWeeklyStartDayQueryParam(widget, config);
    this.setWidgetRequestQueryParam(widget, config);

    widget.cached_data = null;
    const data = await this.retrieveData(widget, config);

    return probeDataService.formatResponse(widget, config, data);
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @returns {*|boolean}
   */
  useSampleData(widget, config) {
    return (
      (config?.context.isDemoModeEnabled() ||
        (widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] &&
          !widget.hasMetadataErrors())) &&
      !widget.isMediaWidget()
    );
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setDateRangeQueryParam(widget, config) {
    const { metadata } = widget;
    const { relativeRanges } = config;
    const dateRange = new DateRange();

    if (
      widget.type === WidgetType.TYPE_BIGNUMBER &&
      metadata.draw_options.plot_type === WidgetPlotTypeOptions.COMPARISON
    ) {
      /** If widget plot type is comparison */
      dateRange.start_date = relativeRanges.lastmonth.start;
      dateRange.end_date = relativeRanges.lastmonth.end;
      dateRange.comparison_start_date = relativeRanges.lastmonth.comparison_start;
      dateRange.comparison_end_date = relativeRanges.lastmonth.comparison_end;

      if (widget.metadata.comparison_option === WidgetComparisonOptions.QUARTER_OVER_QUARTER) {
        dateRange.start_date = relativeRanges.lastquarter.start;
        dateRange.end_date = relativeRanges.lastquarter.end;
        dateRange.comparison_start_date = relativeRanges.lastquarter.comparison_start;
        dateRange.comparison_end_date = relativeRanges.lastquarter.comparison_end;
      }
      if (widget.metadata.comparison_option === WidgetComparisonOptions.YEAR_OVER_YEAR) {
        dateRange.start_date = relativeRanges.yeartodate.start;
        dateRange.end_date = relativeRanges.yeartodate.end;
        dateRange.comparison_start_date = relativeRanges.yeartodate.comparison_start;
        dateRange.comparison_end_date = relativeRanges.yeartodate.comparison_end;
      }
      dateRange.is_comparison_active = true;
      config.params.daterange = DateRangeService.buildDateQueryParam(dateRange);
    } else if (metadata.is_overriding_date_range) {
      if (metadata.start_date_override) {
        dateRange.start_date = metadata.start_date_override;
        dateRange.end_date = metadata.end_date_override;
      } else {
        const { start, end } = relativeRanges[metadata.relative_date_range];
        dateRange.start_date = start;
        dateRange.end_date = end;
      }

      if (metadata.compare_to_prior_period) {
        dateRange.is_comparison_active = true;

        if (metadata.comparison_start_date_override) {
          dateRange.comparison_start_date = metadata.comparison_start_date_override;
          dateRange.comparison_end_date = metadata.comparison_end_date_override;
        } else {
          const { start, end } = relativeRanges[metadata.comparison_relative_date_range];
          dateRange.comparison_start_date = start;
          dateRange.comparison_end_date = end;
        }
      }
      config.params.daterange = DateRangeService.buildDateQueryParam(dateRange);
    } else {
      config.params.daterange = DateRangeService.buildDateQueryParam(config.dateRange);
    }
  }

  setComparisonMetadata(metadata, config) {
    const dateRange = new DateRange();
    const { relativeRanges } = config;
    if (metadata.start_date_override) {
      dateRange.start_date = metadata.start_date_override;
      dateRange.end_date = metadata.end_date_override;
    } else {
      const { start, end } = relativeRanges[metadata.relative_date_range];
      dateRange.start_date = start;
      dateRange.end_date = end;
    }

    if (metadata.compare_to_prior_period && !metadata.comparison_start_date_override) {
      dateRange.is_comparison_active = true;
      const dateRangeConfig = new CurrentDateRangeConfig(dateRange);
      const [start, end] = dateRangeConfig.calculatePeriod(
        dateRange.start_date,
        dateRange.end_date,
        metadata.relative_date_range,
        metadata.comparison_relative_date_range
      );
      metadata.comparison_start_date_override = start.format(MomentDateFormat.ISO);
      metadata.comparison_end_date_override = end.format(MomentDateFormat.ISO);
    }
    return metadata;
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setFilterQueryParam(widget, config) {
    const { metadata } = widget;
    let queryParams = {};

    if (metadata.dynamic && !isNil(metadata.dynamic.predefined_filters)) {
      const predefinedFilters = metadata.dynamic.predefined_filters;
      predefinedFilters.forEach((filter) => {
        queryParams = { ...queryParams, ...filter };
      });
    }

    if (metadata.filter_set_id) {
      queryParams.filter_set_id = metadata.filter_set_id;
    }

    config.params = { ...config.params, ...queryParams };
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setFieldsQueryParam(widget, config) {
    const selectedColumns = widget.metadata.data_columns.selected;

    if (!selectedColumns.length) {
      return;
    }

    const fields = selectedColumns.map((column) => column.field);
    this._appendFieldsToFieldsQueryParam(fields, config);
    const [extraRequestFields] = this.getExtraRequestFields(widget);
    if (extraRequestFields.length) {
      this._appendFieldsToFieldsQueryParam(extraRequestFields, config);
    }
  }

  /**
   * @param {Widget} widget
   * @returns {[Array.<string>]}
   */
  getExtraRequestFields(widget) {
    const extraRequestFields = [];
    const groupedColumns = widget.metadata.data_columns.grouped;

    groupedColumns.forEach((column) => {
      // Add associated group by name field to request field list if different from id field
      if (column.groupby_name_field !== column.groupby_id_field) {
        extraRequestFields.push(column.groupby_name_field);
      }
    });

    return [extraRequestFields];
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setGroupByQueryParam(widget, config) {
    const queryParams = {
      groupby: '',
    };
    const groupedColumns = widget.metadata.data_columns.grouped;

    groupedColumns.forEach((column, i) => {
      if (i > 0) queryParams.groupby += ',';
      queryParams.groupby += column.field;
    });

    config.params = { ...config.params, ...queryParams };

    this.setTimeGroupingQueryParam(widget, config);
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setTimeGroupingQueryParam(widget, config) {
    config.params.timegrouping = null;
    const groupedColumns = widget.metadata.data_columns.grouped;

    const timeGroupingObj = find(groupedColumns, { is_primary_date_field: true });
    if (!isEmpty(timeGroupingObj)) {
      const dateField = timeGroupingObj.field;
      config.params.timegrouping = widget.metadata.time_grouping;
      // If we are grouping by primary date field, add the time grouping param (i.e. timegrouping='monthly')
      if (config.params.groupby && config.params.groupby.includes(dateField)) {
        config.params.timegrouping = widget.metadata.time_grouping;
        this.setWeeklyStartDayQueryParam(widget, config);
      }
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setWeeklyStartDayQueryParam(widget, config) {
    if (
      config.params.timegrouping === TimeGrouping.GROUPING_WEEKLY &&
      widget.metadata.weekly_start_day === WeeklyStartDay.MONDAY
    ) {
      config.params.week_starts_on_monday = true;
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setWidgetRequestQueryParam(widget, config) {
    const queryParams = {};
    queryParams.widget_request = widget.id || 'null';
    queryParams.widget_type = widget.type || 'null';

    if (widget.has_live_integration) {
      queryParams.has_live_integration = widget.has_live_integration;

      if (widget.assignments) {
        queryParams.widget_assignments = widget.assignments;
      }
      if (
        !isEmpty(widget.metadata.live_formula_fields) &&
        !isEmpty(widget.metadata.live_formulas)
      ) {
        queryParams.live_formula_fields = widget.metadata.live_formula_fields.join(',');
        queryParams.live_formulas = widget.metadata.live_formulas.join(',');
      }
    }

    config.params = { ...config.params, ...queryParams };
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setSortQueryParam(widget, config) {
    const queryParams = {};

    const { data_columns, sort_by, sort_order } = widget.metadata;
    const groupedColumns = data_columns.grouped;
    const timeGroupingColumn = find(groupedColumns, { is_primary_date_field: true });

    if (!isEmpty(sort_by)) {
      const sortArr = [];
      sort_by.forEach((field, index) => {
        sortArr.push((sort_order[index].toLowerCase() === OrderBy.ORDER_DESC ? '-' : '') + field);
      });
      queryParams.sort = sortArr.join(',');
    } else if (!isEmpty(timeGroupingColumn)) {
      queryParams.sort = timeGroupingColumn.field;
    }

    if (queryParams.sort) {
      config.params = { ...config.params, ...queryParams };
    }
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  setShowEmptyDatesQueryParam(widget, config) {
    if (!widget.metadata.draw_options.show_empty_dates) {
      return;
    }

    const queryParams = {};
    queryParams.show_empty_dates = true;

    config.params = { ...config.params, ...queryParams };
  }

  /**
   * This method is called after setting global params to the config
   * and before sending the request
   *
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   */
  /* eslint-disable no-unused-vars */
  applySpecificQueryParams(widget, config) {}

  /**
   * @param {GenericDataModel} response
   *
   * @returns {GenericDataModel, DataGridData} data
   */
  postProcessData(response) {
    return response;
  }

  /**
   * @param {Widget} widget
   * @param {WidgetDataConfig} config
   * @param {GenericDataModel[]} data
   */
  evaluateLoadingState(widget, config, data) {
    const { metadata } = widget;

    let loadingState = WidgetState.STATE_DONE;

    /**
     * FORCING SAMPLE DATA
     */
    if (this.useSampleData(widget, config)) {
      return WidgetState.STATE_DONE;
    }

    /**
     * INACTIVE WIDGET
     */
    if (metadata.dynamic.is_inactive) {
      return WidgetState.STATE_INACTIVE;
    }

    /**
     * ERROR
     */
    if (widget.hasMetadataErrors()) {
      return WidgetState.STATE_HAS_ERROR;
    }

    // Backend formatted error
    if (data.error) {
      // metadata.dynamic.errors = data.data || [];
      // metadata.dynamic.retriable_error = data.retriable_error || false;
      return WidgetState.STATE_HAS_ERROR;
    }

    /**
     * DATA OVERFLOW (TOO MUCH DATA)
     * @TODO: on exporting all rows, ignore this- can only do when grok exports
     */
    if (data.length > WidgetData.MAX_RESULTS && !metadata.geo_code && !metadata.load_all_data) {
      return WidgetState.STATE_TOO_MANY_DATA;
    }

    /**
     * NO COLUMNS
     */
    if (isEmpty(metadata.data_columns?.selected)) {
      // It is possible that a data profile has no columns selected, hence we cannot continue
      loadingState = WidgetState.STATE_NO_COLUMNS;
    }

    /**
     * NO DATA
     */
    if (loadingState === WidgetState.STATE_DONE) {
      if (!data.length) {
        loadingState = WidgetState.STATE_NO_DATA;
      } else {
        let priorDatum;
        let currentDatum;

        // we can infer if we're comparing to prior period based on the data returned
        const isComparing = !isEmpty(data[0].prior_period);
        data.forEach((datum) => {
          priorDatum = isComparing ? datum.prior_period : null;
          currentDatum = datum.current_period || [];
          if (!isComparing && parseInt(currentDatum[ApiDataFields.ROW_GROUPING_COUNT], 10) === 0) {
            loadingState = WidgetState.STATE_NO_DATA;
          } else if (
            (isComparing || metadata.compare_to_prior_period) &&
            !isEmpty(currentDatum) &&
            parseInt(currentDatum[ApiDataFields.ROW_GROUPING_COUNT], 10) === 0 &&
            !isEmpty(priorDatum) &&
            parseInt(priorDatum[ApiDataFields.ROW_GROUPING_COUNT], 10) === 0
          ) {
            loadingState = WidgetState.STATE_NO_DATA;
          }
        });
      }
    }

    /**
     * NO RECORDS AT ALL in the system (can only work with predefined data)
     */
    if (loadingState === WidgetState.STATE_DONE || loadingState === WidgetState.STATE_NO_DATA) {
      const predefinedData = metadata.dynamic ? metadata.dynamic.predefined_data : null;
      if (predefinedData) {
        const extraData = !isNull(predefinedData.extra_data) ? predefinedData.extra_data : null;
        if (extraData) {
          if (parseInt(extraData[ApiDataFields.TOTAL_CLIENT_COUNT], 10) === 0) {
            loadingState = WidgetState.STATE_NO_RECORDS;
          }
        }
      }
    }

    return loadingState;
  }

  /**
   * @param {Array.<string>} fields
   * @param {WidgetDataConfig} config
   * @protected
   */
  _appendFieldsToFieldsQueryParam(fields, config) {
    if (config.params.fields?.length) {
      config.params.fields += `,${fields.join(',')}`;
    } else {
      config.params.fields = fields.join(',');
    }
  }

  /**
   * @param {Array.<string>} fields
   * @param {WidgetDataConfig} config
   * @protected
   */
  _appendFieldsToGroupByQueryParam(fields, config) {
    if (config.params.groupby?.length) {
      config.params.groupby += `,${fields.join(',')}`;
    } else {
      config.params.groupby = fields.join(',');
    }
  }
}
